import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxMonth } from '../helpers';

const MonthInput = ({ maxDate, minDate, year, ...otherProps }) => {
  const { maxMonth, minMonth } = getMinMaxMonth(maxDate, minDate, year);

  return <Input max={maxMonth} min={minMonth} name="month" {...otherProps} />;
};

MonthInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  maxDate: isMaxDate,
  minDate: isMinDate,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.number,
  year: PropTypes.number,
};

export default MonthInput;
