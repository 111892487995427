import styles from '../BasketFooter.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCurrencyInfo } from 'utils/hooks';
import PriceNode from '../../PriceNode';
import PromotionControl from '../../PromotionControl';
import TotalsLine from './TotalsLine';

const PromotionLine = ({ readOnly, line, enabled }) => {
  const currencyInfo = useCurrencyInfo();

  if (!enabled)
    return null;

  let valueBody = null, showLineInfo = true;
  if (line) {
    if (readOnly) {
      return (
        <TotalsLine label={line.title} value={-line.amount} className={styles.promotion} />
      );
    }

    showLineInfo = !!line.amount;
    valueBody = showLineInfo && (
      <td className={styles.cellValue}>
        <PriceNode price={-line.amount} currencyInfo={currencyInfo} />
      </td>
    );
  }

  if (readOnly)
    return null;

  return (
    <tr className={`${styles.promotion} basket-footer-promotion`}>
      <th className={valueBody ? 'text-left' : 'text-right'} colSpan={valueBody ? null : '2'}>
        <PromotionControl showTitle={showLineInfo} />
      </th>
      {valueBody}
    </tr>
  );
};

PromotionLine.propTypes = {
  readOnly: PropTypes.bool,
  line: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  enabled: PropTypes.bool,
};

const mapStateToProps = state => ({ enabled: state.settings.loaded && state.settings.checkout.enablePromotionCodes });

export default connect(mapStateToProps)(PromotionLine);

