import { ofType } from 'redux-observable';
import { combineEpics } from 'utils/rxjs';
import {
  map,
  switchMap,
  takeUntil,
  filter,
  pluck,
  tap,
  first,
  mergeMap,
} from 'rxjs/operators';
import {
  languagesLoaded,
  ALTERNATE_URLS_REQUESTED, alternateUrlsLoaded,
  URL_LANGUAGE_CHANGED, urlLanguageChanged,
} from './actions';
import { languagesQuery, alternateUrlsQuery } from './queries';
import { mapRouteToInput } from 'behavior/route/helpers';
import { NAVIGATED, NAVIGATING } from 'behavior/routing';
import { languageChanged } from 'behavior/events';
import { Scope } from 'utils/app';
import { APP_INIT } from 'behavior/app';

const languagesEpic = (action$, _state$, { api }) => action$.pipe(
  ofType(APP_INIT),
  switchMap(_ => api.graphApi(languagesQuery).pipe(
    map(data => languagesLoaded(data.languages)),
  )),
);

const alternateUrlsEpic = (action$, state$, { api, scope }) => action$.pipe(
  ofType(ALTERNATE_URLS_REQUESTED, scope === Scope.Server && NAVIGATED),
  switchMap(_ => {
    const state = state$.value;
    if (state.routing.routeData)
      return loadAlternateUrls(action$, state, api);

    return state$.pipe(
      first(s => s.routing.routeData),
      mergeMap(state => loadAlternateUrls(action$, state, api)),
    );
  }),
);

const navigateEpic = (action$, state$, { api }) => action$.pipe(
  ofType(NAVIGATED, NAVIGATING),
  pluck('payload', 'routeData', 'params'),
  map(params => params && params.language),
  filter(language => (language || !state$.value.localization.currentLanguage.id) && language !== state$.value.localization.currentLanguage.id),
  tap(language => language && api.setLanguage(language)),
  map(urlLanguageChanged),
);

const languageChangedEventEpic = action$ => action$.pipe(
  ofType(URL_LANGUAGE_CHANGED),
  filter(a => a.payload),
  map(languageChanged),
);

export default combineEpics(
  languagesEpic,
  alternateUrlsEpic,
  navigateEpic,
  languageChangedEventEpic,
);

function loadAlternateUrls(action$, state, api) {
  const route = mapRouteToInput(state.routing.routeData);
  route.key = '';

  route.params.push({ key: 'currentPath', value: state.routing.location.pathname });

  return api.graphApi(alternateUrlsQuery, { route }).pipe(
    map(({ routing }) => alternateUrlsLoaded(routing.alternateUrls)),
    takeUntil(action$.pipe(ofType(NAVIGATED))),
  );
}
