import { loadContentPageQuery } from './queries';
import { of } from 'rxjs';
import { pluckAsPage, initPageContentBlocks } from 'behavior/pages/helpers';
import { PageComponentNames } from '../componentNames';

export default ({ params: { id } }, _state$, { api }) => id
  ? api.graphApi(loadContentPageQuery, { id }).pipe(
    pluckAsPage('content', PageComponentNames.Content),
    initPageContentBlocks('content'),
  )
  : of(null);