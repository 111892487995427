import React from 'react';
import PropTypes from 'prop-types';
import { RadioField } from '../../../fields';
import hoistStatics from 'hoist-non-react-statics';
import validationFieldPropType from '../../fields/validationFieldPropType';
import { useSimpleTexts } from 'components/sanaText';

const GenderField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    disabled,
  }) => {
    const [mText, fText] = useSimpleTexts([
      'EntityField_ShippingAddress_Gender_M',
      'EntityField_ShippingAddress_Gender_F',
    ]).texts;

    const items = [
      { name: mText, value: 'M' },
      { name: fText, value: 'F' },
    ];

    return (
      <RadioField
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        items={items}
        className={className}
        disabled={disabled}
      />
    );
  },
  RadioField,
);

GenderField.propTypes = {
  ...RadioField.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  validation: validationFieldPropType,
};

export default GenderField;
