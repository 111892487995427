import PropTypes from 'prop-types';

export const createRouteData = to => typeof to === 'function' ? to() : to;

export const toPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    routeName: PropTypes.string.isRequired,
    params: PropTypes.object,
  })],
);