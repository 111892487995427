import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { useProductContext } from 'components/objects/product/hooks';
import QuantityContext from 'components/objects/product/QuantityContext';
import { startNewProductConfiguration } from 'behavior/productConfigurator/actions';
import Tooltip from 'components/primitives/tooltip';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';
import { UseSanaTexts } from 'components/sanaText';
import { generateKey } from 'utils/helpers';

const ConfigureButton = ({ productConfigurator, startConfiguration, isConfiguratorAvailable }) => {
  const [loading, setLoading] = useState(false);
  const { product, uomId } = useProductContext();
  const { configuratorId, modelId } = product.productConfiguratorInfo;
  const { quantity: { value: quantity } } = useContext(QuantityContext);

  const [componentId] = useState(generateKey);
  const [configurationFailed, setConfigurationFailed] = useState(false);

  useEffect(() => {
    if (productConfigurator
      && !productConfigurator.configuratorUrl
      && productConfigurator.updatedById === componentId) {
      setLoading(false);
      setConfigurationFailed(true);
    }
  }, [productConfigurator]);

  if (loading && productConfigurator && productConfigurator.configuratorUrl)
    setLoading(false);

  const configureProductCallback = () => {
    if (isNaN(quantity))
      return;

    setLoading(true);
    startConfiguration({
      configuratorId,
      modelId,
      productId: product.id,
      quantity,
      uomId,
    }, componentId);
  };

  return (
    <ConditionalWrap
      condition={!isConfiguratorAvailable || configurationFailed}
      wrapWith={children => (
        <UseSanaTexts options={['ButtonAltText_DisabledConfigureProduct']}>
          {([DisabledConfigureProduct]) => (
            <Tooltip className={styles.configureProductBtnTooltip} title="" body={DisabledConfigureProduct} sign={false}>
              {children}
            </Tooltip>)}
        </UseSanaTexts>
      )}
    >
      <SanaButton
        textKey="ConfigureProduct"
        titleTextKey={isConfiguratorAvailable && !configurationFailed ? 'ConfigureProduct' : ''}
        type="button"
        disabled={!isConfiguratorAvailable || configurationFailed}
        className={`${btnStyles.btnBig} ${styles.configureProductBtn} ${loading ? btnStyles.loading : ''}`}
        onClick={configureProductCallback}
      />
    </ConditionalWrap>
  );
};

ConfigureButton.propTypes = {
  productConfigurator: PropTypes.object,
  startConfiguration: PropTypes.func.isRequired,
  isConfiguratorAvailable: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  productConfigurator: state.productConfigurator,
  isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
});

const mapDispatchToProps = {
  startConfiguration: startNewProductConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureButton);