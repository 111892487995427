import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ListContext from './ListContext';

const ListProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    quantities: new Map(),
    quantitiesDependency: {},
  }));

  const quantities = state.quantities;
  const resetQuantities = useCallback(() => setState({
    quantities: new Map(),
    quantitiesDependency: {},
  }), []);

  const updateQuantity = useCallback((productId, uomId, quantity) => {
    setState(({ quantities }) => {
      if (quantity && quantity.value) {
        let productQuantity = quantities.get(productId);
        if (productQuantity && productQuantity.uomId === uomId)
          productQuantity.quantity = quantity;
        else
          productQuantity = { quantity, uomId };

        quantities.set(productId, productQuantity);
      }
      else
        quantities.delete(productId);

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const updateVariantQuantities = useCallback((productId, uomId, variantQuantityMap) => {
    setState(({ quantities }) => {
      let productQuantities = quantities.get(productId);

      if (!productQuantities) {
        productQuantities = new Map();
        quantities.set(productId, productQuantities);
      }

      if (variantQuantityMap.size > 0) {
        productQuantities.set(uomId, variantQuantityMap);
      } else {
        productQuantities.delete(uomId);

        if (productQuantities.size === 0)
          quantities.delete(productId);
      }

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const context = {
    quantities,
    updateQuantity,
    updateVariantQuantities,
    resetQuantities,
  };

  return (
    <ListContext.Provider value={context}>
      {children}
    </ListContext.Provider>
  );
};

ListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListProvider;