import React from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';

const UomTitle = ({ id, description, allowHtmlTags = true, children: renderTitle, placeholder = null }) => {
  const options = [createUomTextKey(id)];
  options.disableInsiteEditor = true;

  const { texts, loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;

  const result = (allowHtmlTags ? makeRichText : makeSimpleText)(texts[0] || description || id);
  return renderTitle
    ? renderTitle(result)
    : result;
};

UomTitle.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  allowHtmlTags: PropTypes.bool,
  children: PropTypes.func,
  placeholder: PropTypes.node,
};

export const createUomTextKey = id => `UnitOfMeasure_${id || 'Empty'}`;

export default React.memo(UomTitle);