import styles from '../Checkout.module.scss';
import React, { useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import { TextBoxField, TextAreaField, DatePickerField, DropdownField } from 'components/objects/forms/fields';
import { useSanaTexts, UseSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { isVisible, FieldVisibility } from './constants';
import { Radio } from 'components/primitives/form';
import { useSelector } from 'react-redux';

const AdditionalInfoForm = ({
  formikRef,
  initialValues,
  commentVisibility,
  deliveryDateVisibility,
  referenceNoVisibility,
  onSubmit,
  onBlur,
  submitOnBlur,
}) => {
  const isReferenceVisible = isVisible(referenceNoVisibility),
  isCommentVisible = isVisible(commentVisibility);

  const customer = useSelector(x => x.user.customer);

  // CHECK IF PRODUCTS IN OVERVIEW ARE OF TYPE SPARE
  const basketSummary = useSelector(x => x.basket.summary);
  let isSpareVisible = false;

  if (basketSummary.productLines && basketSummary.productLines.list && basketSummary.productLines.list.length > 0) {
    if (basketSummary.productLines.list[0].product && basketSummary.productLines.list[0].product.isSpare) {
      isSpareVisible = basketSummary.productLines.list[0].product.isSpare;
    }
  }
  
  let branchCodes = [];

  if (customer && customer !== undefined && customer.branches !== null && customer.branches !== undefined && customer.branches?.length > 0 && customer.branches[0] !== undefined) {
    branchCodes = customer.branches;
  }

  let assignedBranchCode = '';
  if (customer && customer !== undefined && customer.branchCode !== undefined && customer.branchCode !== undefined) {
    assignedBranchCode = customer.branchCode;
  }

  useEffect(() => {
    makeBranchCodeOptions();
  }, branchCodes);

  const makeBranchCodeOptions = () => {
    return branchCodes.map(branch => {
        return {
          name: (branch.description) ? branch.description : '',
          value: (branch.code) ? branch.code : '',
        };
      });
  };

  const showBranchFilter = (branchCodes.length > 0);

  const singleDelivery = 0;
  const phasedDelivery = 1;

  const [deliveryType, setDeliveryType] = useState(singleDelivery);
  const [isDateVisible, setIsDateVisible] = useState(isVisible(deliveryDateVisibility));

  const { texts: [referenceNoLbl, commentsLbl, dateLbl, singleDeliveryLbl, phasedDeliveryLbl, deliveryTypeLbl, branchesLbl, serialNoLbl, modelNoLbl, warrantyOrderLbl], loaded } = useSanaTexts([
    isReferenceVisible && 'ReferenceNumber',
    isCommentVisible && 'Comments',
    isDateVisible && 'RequestedDeliveryDate',
    'SingleDeliveryLabel',
    'PhasedDeliveryLabel',
    'DeliveryTypeCaption',
    'BranchesLabel',
    'SerialNumber',
    'ModelNumber',
    'WarrantyOrder',
  ], makeSimpleText);
  const prevValuesRef = useRef();

  const newInitialValues = useMemo(() => {
    const result = { ...initialValues };

    result.branchCode = assignedBranchCode;

    if (branchCodes !== null && branchCodes !== undefined && branchCodes.length === 1) {
      result.branchCode = branchCodes[0].code;
    }

    if (!isReferenceVisible)
      delete result.referenceNo;
    if (!isCommentVisible)
      delete result.comment;
    if (!isDateVisible)
      delete result.deliveryDate;

    return result;
  }, [initialValues, isReferenceVisible, isCommentVisible, isDateVisible]);

  if (!loaded)
    return DatePickerField.textKeys ? <UseSanaTexts options={DatePickerField.textKeys} /> : null;

  const handleSubmit = values => {
    prevValuesRef.current = values;
    onSubmit(values);
  };

  const handleDeliveryTypeChange = e => {
    const chosenValue = parseInt(e.target.value);
    const currentComment = formikRef.current.values.comment;
    let newComment = currentComment ? currentComment : '';

    if (chosenValue === phasedDelivery) {

      if (currentComment) {
        newComment += '\n';
      }

      newComment += phasedDeliveryLbl;
    } else {
      newComment = currentComment.replace(phasedDeliveryLbl, '');
    }

    formikRef.current.setFieldValue('comment', newComment);

    setDeliveryType(chosenValue);
    setIsDateVisible(chosenValue === singleDelivery);

  };

  const onFormBlur = submitOnBlur
    ? (e, formik) => {
      onBlur && onBlur();
      if (!formik.dirty || !formik.isValid || e.currentTarget.contains(e.relatedTarget || document.activeElement))
        return;

      if (prevValuesRef.current === formik.values)
        return;

      handleSubmit(formik.values);
    }
    : null;

	const getMinimumDeliveryDate = () => {
		const today = new Date();
		let offSet = 1;
		const thursday = 4;
		const friday = 5;
		const saturday = 6;
		const sunday = 7;
		const thisDay = today.getDay();
		const theHour = today.getHours();

		if (theHour>12)
		{
			switch (thisDay) {
				case thursday:
					offSet += 3;
					break;
				case saturday:
				case sunday:
					break;
				default:
					offSet++;
					break;
			}
		}

		switch (thisDay) {
			case friday:
				offSet += 2;
				break;
			case saturday:
				offSet += 2;
				break;
			case sunday:
				offSet += 1;
				break;
			default:
				break;
		}

		return new Date(today.setDate(today.getDate()+offSet));
	};

  return (
    <SanaForm
      name="additional"
      formikRef={formikRef}
      onBlur={onFormBlur}
      onSubmit={handleSubmit}
      initialValues={newInitialValues}
      className={styles.additional}
    >
      {isReferenceVisible &&
        <FormGroup
          fieldName="referenceNo"
          fieldTitle={referenceNoLbl}
          fieldComponent={TextBoxField}
          maxLength={20}
          required={referenceNoVisibility === FieldVisibility.Required}
          validation={{ required: referenceNoVisibility === FieldVisibility.Required }}
        />
      }
      {isSpareVisible &&
        <FormGroup
          fieldName="serialNo"
          fieldTitle={serialNoLbl}
          fieldComponent={TextBoxField}
          maxLength={7}
          required={(isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required}
          validation={{ required: (isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required, minLength: { min: 7 } }}
        />
      }
      {isSpareVisible &&
        <FormGroup
          fieldName="modelNo"
          fieldTitle={modelNoLbl}
          fieldComponent={TextBoxField}
          maxLength={20}
          required={(isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required}
          validation={{ required: (isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required }}
        /> 
      }
      {isSpareVisible &&
        <FormGroup
          fieldName="warrantyOrder"
          fieldTitle={warrantyOrderLbl}
          fieldComponent={DropdownField}
          items={[{
            name: 'No',
            value: false,
          },{
            name: 'Yes',
            value: true,
          }]}
          required={(isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required}
          validation={{ required: (isSpareVisible) ? 'REQUIRED' : 'OPTIONAL' === FieldVisibility.Required }}
        /> 
      }      
      {isCommentVisible &&
        <FormGroup
          fieldName="comment"
          fieldTitle={commentsLbl}
          fieldComponent={TextAreaField}
          maxLength={2000}
        />
      }
      {showBranchFilter && (
        <FormGroup
          fieldName="branchCode"
          fieldTitle={branchesLbl}
          fieldComponent={DropdownField}
          items={makeBranchCodeOptions()}
          validation={{ required: { allowWhitespace: false } }}
        />
      )}
      <div className="SanaForm_form-row">
        <div className={'SanaForm_control form-row-control'}>
          <div className={'SanaForm_label form-row-label'}>
            {deliveryTypeLbl}
          </div>
          <div role="presentation">
            <div className={'SanaForm_field form-row-field'}>
              <Radio name="deliveryType"
                value={singleDelivery} onChange={handleDeliveryTypeChange} checked={deliveryType === singleDelivery}
                className={`${styles.radio} delivery-type-radio`}
              >
                <span>{singleDeliveryLbl}</span>
              </Radio>

              <Radio name="deliveryType"
                value={phasedDelivery} onChange={handleDeliveryTypeChange} checked={deliveryType === phasedDelivery}
                className={`${styles.radio} delivery-type-radio`}
              >
                <span>{phasedDeliveryLbl}</span>
              </Radio>
            </div>
          </div>
        </div>
      </div>

      {isDateVisible &&
        <FormGroup
          fieldName="deliveryDate"
          fieldTitle={dateLbl}
          fieldComponent={DatePickerField}
          minDate={getMinimumDeliveryDate()}
          required={deliveryDateVisibility === FieldVisibility.Required}
          validation={{ required: deliveryDateVisibility === FieldVisibility.Required }}
        />
      }
      <HiddenSubmitButton />
    </SanaForm>
  );
};

AdditionalInfoForm.propTypes = {
  formikRef: PropTypes.object,
  initialValues: PropTypes.object,
  commentVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  deliveryDateVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  referenceNoVisibility: PropTypes.oneOf(Object.values(FieldVisibility)),
  onSubmit: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  submitOnBlur: PropTypes.bool,
};

export default React.memo(AdditionalInfoForm);
