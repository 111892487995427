import styles from './Paging.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { HashRoutingContext } from 'components/hash';
import { connectToContext } from 'utils/react';
import { Input } from 'components/primitives/form';
import { makeSimpleText } from 'utils/render';
import { Item, SelectedItem, SkippedItem, ArrowLink } from './pagingItems';

const pageIndexSpan = 2;
const defaultGetUrlFunc = () => null;

const Paging = ({
  pageIndex,
  pageSize,
  totalCount,
  onSelect,
  navigateTo,
  scrollToId,
  className = '',
  getUrl = defaultGetUrlFunc,
  withInput = false,
}) => {
  const lastIndex = Math.floor((totalCount - 1) / pageSize);

  const [
    previousLbl,
    nextLbl,
    pageLbl,
    pagingLbl,
  ] = useSanaTexts(['PreviousPage', 'NextPage', 'PageLabel', 'PaginationLabel'], makeSimpleText).texts;

  const listRef = useRef();

  if (lastIndex < 1)
    return null;

  const select = index => {
    if (!listRef.current)
      return;

    for (const item of listRef.current.children) {
      if (+item.dataset.index === index)
        !item.classList.contains('clicked') && item.classList.add('clicked');
      else
        item.classList.contains('clicked') && item.classList.remove('clicked');
    }
  };

  const onClick = (index, e) => {
    if (e.button === 1)
      return;

    e.preventDefault();
    const shouldChange = onSelect(index);
    if (shouldChange === false)
      return;

    select(index);

    if (scrollToId)
      setTimeout(() => navigateTo(`#${scrollToId}`), 10);
  };

  const onSubmit = withInput && (e => {
    e.preventDefault();

    const form = e.target;
    const input = form.elements['pageNumber'];
    let index = +input.value;
    if (!input.value || (!index && index !== 0)) {
      input.value = '';
      input.focus();
      return;
    }

    index--;
    if (index < 0)
      index = 0;
    if (index > lastIndex)
      index = lastIndex;

    const shouldChange = onSelect(index);
    if (shouldChange === false) {
      return;
    }

    input.value = '';

    if (scrollToId)
      navigateTo(`#${scrollToId}`, { timeout: 10 });
  });

  const onInputKeyDown = withInput && (e => {
    if (e.key.length === 1) {
      const charCode = e.key.charCodeAt(0);

      if (charCode < 47 || charCode > 57) {
        e.preventDefault();
      }
    }
  });

  const ariaPageLbl = pageLbl + ' ';

  const items = [];
  for (let i = 0; i <= lastIndex; i++) {
    if (i === pageIndex) {
      items.push(<SelectedItem key={i} index={i} pageLbl={ariaPageLbl} />);
      continue;
    }

    const inTheMiddle = i > 0 && i < lastIndex - 1;
    if (inTheMiddle && i < pageIndex - pageIndexSpan && i + 1 !== lastIndex - pageIndexSpan) {
      items.push(<SkippedItem key={i} />);
      i = Math.min(pageIndex, lastIndex - 1) - pageIndexSpan;
      continue;
    }
    if (inTheMiddle && i >= Math.max(pageIndex, 1) + pageIndexSpan) {
      items.push(<SkippedItem key={i} />);
      i = lastIndex - 1;
      continue;
    }

    items.push(<Item key={i} index={i} onClick={onClick} getUrl={getUrl} pageLbl={ariaPageLbl} />);
  }

  return (
    <nav className={`${styles.pager} ${withInput ? styles.withInput : ''} ${className}`} aria-label={pagingLbl}>
      <div className={`${styles.pagination} pagination`}>
        <ArrowLink
          index={pageIndex - 1}
          title={previousLbl}
          className={styles.left}
          rel="prev"
          lastIndex={NaN}
          getUrl={getUrl}
          onClick={onClick}
        />
        <ul ref={listRef}>
          {items}
        </ul>
        <ArrowLink
          index={pageIndex + 1}
          title={nextLbl}
          className={styles.right}
          rel="next"
          lastIndex={lastIndex}
          getUrl={getUrl}
          onClick={onClick}
        />
      </div>
      {withInput &&
        <form onSubmit={onSubmit}>
          <label htmlFor="pageNumber">{pageLbl + ': '}</label>
          <div className={styles.inputWrapper}>
            <Input id="pageNumber"
              name="pageNumber"
              type="text"
              placeholder={pageIndex + 1}
              className={styles.pageInput}
              onKeyDown={onInputKeyDown}
            />
          </div>
          <button type="submit" className={`${btnStyles.btnBase} ${btnStyles.btnSmall} ${styles.toPageBtn}`}>
            <span className="btn-cnt">
              <SimpleText textKey="ButtonText_GoToPage" />
            </span>
          </button>
        </form>
      }
    </nav>
  );
};

Paging.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  scrollToId: PropTypes.string,
  className: PropTypes.string,
  getUrl: PropTypes.func,
  withInput: PropTypes.bool,
};

export default connectToContext([HashRoutingContext], ({ navigateTo }) => ({ navigateTo }))(
  React.memo(Paging),
);