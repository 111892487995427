import { CalendarTypes, PeriodTypes } from './enums';

export const CalendarTypeLocalesMap = Object.freeze({
  [CalendarTypes.US]: [
    'en-CA',
    'en-US',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-SV',
    'es-VE',
    'pt-BR',
  ],
  [CalendarTypes.ARABIC]: [
    // ar-LB, ar-MA intentionally not included.
    'ar',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LY',
    'ar-OM',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SY',
    'ar-YE',
    'dv',
    'dv-MV',
    'ps',
    'ps-AR',
  ],
  [CalendarTypes.HEBREW]: [
    'he',
    'he-IL',
  ],
});

export const СalendarViews = Object.freeze(Object.values(PeriodTypes).slice(0, -1));

export const AllValueTypes = Object.freeze(Object.values(PeriodTypes).slice(-4));
