import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ProductDetailsButton from './ProductDetailsButton';

const SelectVariants = ({ product }) => (
  <ProductDetailsButton
    textKey="SelectVariants"
    className={`${btnStyles.btnSmall} btn-action`}
    product={product}
  />
);

SelectVariants.propTypes = {
  product: PropTypes.object.isRequired,
};

export default SelectVariants;
