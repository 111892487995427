import React from 'react';

const UpdateContext = React.createContext({
  quantities: null,
  setQuantity: null,
  delete: null,
  writeOnlySubmit: null,
  submit: null,
});

export default UpdateContext;