import styles from './SimpleNavigation.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BaseNavigationItemPropTypes } from './PropTypes';
import NavItem from './NavItem';

const SimpleNavigation = React.forwardRef(({
  groupCode: _groupCode,
  items,
  ariaLabel,
  className,
  children,
}, ref) => (
  <>
    {children}
    <nav aria-label={ariaLabel}>
      <ul className={className} ref={ref}>
        {items.map((item, index) => <NavItem key={index} item={item} className={styles.item} />)}
      </ul>
    </nav>
  </>
));

SimpleNavigation.propTypes = {
  items: PropTypes.arrayOf(BaseNavigationItemPropTypes),
  groupCode: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
};

export default connect(
  ({ navigation }, { groupCode }) => ({ items: navigation[groupCode] }),
  null,
  null,
  { forwardRef: true },
)(SimpleNavigation);