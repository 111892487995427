import styles from './ProductTiles.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProductTile from './ProductTile';

const ProductTiles = ({ products, noImage, itemsInRow, itemsInRowPrint }) => {
  if (!products.length)
    return null;

  return (
    <div className={styles.productTilesGrid} data-width={itemsInRow} data-print-width={itemsInRowPrint}>
      {products.map(product => <ProductTile key={product.id} product={product} noImage={noImage} />)}
    </div>
  );
};

ProductTiles.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  noImage: PropTypes.string,
  itemsInRow: PropTypes.number,
  itemsInRowPrint: PropTypes.number,
};

export default connect(({
  settings: { product: productSettings },
}) => ({
  noImage: productSettings && productSettings.noImage.small,
}))(ProductTiles);