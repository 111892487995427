import { Observable, concat } from 'rxjs';
import { tap } from 'rxjs/operators';

export default function concatToIfEmpty(to$) {
  return source$ => {
    let emited;
    return concat(
      tap(() => emited = true)(source$),
      new Observable(observer => {
        if (!emited)
          return to$.subscribe(observer);
        observer.complete();
      }),
    );
  };
}
