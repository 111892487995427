import styles from './MainNav.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { MenuOpenItemIcon, MenuMobileOpenItemIcon, MenuMobileCloseItemIcon } from 'components/primitives/icons';

const ListItemStateIcon = ({ vertical, active, className = '' }) => {
  const Icon = vertical
    ? MenuOpenItemIcon
    : active ? MenuMobileCloseItemIcon : MenuMobileOpenItemIcon;

  return (
    <div className={styles.iconWrapper}>
      <Icon className={className} />
    </div>
  );
};

ListItemStateIcon.propTypes = {
  vertical: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(ListItemStateIcon);