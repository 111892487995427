import { of } from 'rxjs';
import { mergeMap, catchError, tap, defaultIfEmpty } from 'rxjs/operators';
import { handlers, errorHandler } from './routeHandlers';
import { isIndexed, routeNames } from 'routes';
import { fallbackHandler } from './system';

export function handle(state$, dependencies, routeData) {
  const state = state$.value;
  if (state.error.initial || state.settings.error || routeData.routeName === routeNames.Error)
    return errorHandler();

  const handler = handlers[routeData.routeName] || fallbackHandler;

  return handler(routeData, state$, dependencies).pipe(
    defaultIfEmpty(null),
    mergeMap(result => {
      if (!result)
        return fallbackHandler(routeData, state$, dependencies);

      if (result.forwardTo)
        return handle(state$, dependencies, result.forwardTo);

      return of({ ...result, routeData });
    }),
    tap(({ page }) => {
      if (page) {
        if (!('index' in page))
          page.index = isIndexed(routeData.routeName);

        const { options } = routeData;
        const backTo = options && options.backTo;
        if (backTo)
          page.backTo = backTo;
      }
    }),
    catchError(e => errorHandler(e, dependencies.logger)),
  );
}
