import { useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { routeNames } from 'routes';
import { useIsTouchDevice, useIsTouchScreen, useIsMobileSafari } from 'utils/detections';
import { useIsMouse } from 'components/detection';
import {
  addHoverState,
  removeItemHoverState,
  toggleHoverState,
  handlePointerMove,
  handlePointerLeave,
  addItemHoverState,
  disableLinkInteraction,
  handleLinkClickOnCapture,
  handleLinkClick,
  setFocus,
} from './eventHandlers';

export const useMultiColumnDropdownSelector = () => useSelector(
  ({ settings }) => settings.loaded ? settings.navigation.multiColumnPresentation : false,
);

export const useFocusResetOnLocationChange = (cancelFocusReset, beforeFocusReset, afterFocusReset) => {
  const isFirstRender = useRef(true);
  const {
    location,
    previous,
    routeData,
  } = useSelector(({
    routing: {
      location,
      previous,
      routeData,
    },
  }) => ({
    location,
    previous,
    routeData,
  }), shallowEqual);

  useEffect(() => {
    // On location change set focus to layout element to trigger blur event on currently focused
    // menu item so the hover status on it and all ancestors up to the list root element will be unset
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!location || !routeData || cancelFocusReset)
      return;

    const { routeName } = routeData;

    if (previous.routeData && previous.routeData.params && routeName === routeNames.ProductList) {
      // If ProductList page with continuous scroll do not reset focus
      const prevData = previous.routeData;

      if (
        prevData.routeName === routeName
        && routeData.params.language === prevData.params.language
        && routeData.params.id === prevData.params.id
        // On touch devices you can scroll down with opened desktop menu submenu and then navigate to the same page from desktop menu.
        // To handle this rare case check for location.search should be also present as it is not present when initial navigation to page performed.
        && location.search
      )
        return;
    }

    beforeFocusReset && beforeFocusReset();
    document.getElementById('layout').focus();
    afterFocusReset && afterFocusReset();
  }, [location]);
};

export const useNavItemEventHandlers = (shouldRenderSubItems, withLinkHandlers = true) => {
  const isTouchDevice = useIsTouchDevice();
  const isTouchScreen = useIsTouchScreen();
  const isMobileSafari = useIsMobileSafari();
  const isMouse = useIsMouse();

  const isNotMousePointer = isMouse === false;
  const shouldHandleTouchInteraction = shouldRenderSubItems && (isTouchDevice || isTouchScreen) && isNotMousePointer;
  const shouldHandleTouchScreenInteraction = isTouchScreen && isNotMousePointer;
  const shouldHandleLinkTouchInteraction = shouldHandleTouchInteraction && !isMobileSafari;
  const shouldHandleSafariTouchInteraction = shouldRenderSubItems && isMobileSafari && isNotMousePointer;

  return useMemo(
    () => {
      const result = {
        navItemEventHandlers: {
          // onMouseOver event is supported by onKeyDown instead of onFocus. The links inside will be actual targets.
          // onMouseOver, onMouseLeave are not reliable when using touch screen on laptop - they are fired twice for touch position and last known mouse cursor position.
          onMouseOver: isTouchScreen && isNotMousePointer ? null : addHoverState,
          onPointerMove: shouldHandleTouchScreenInteraction ? handlePointerMove : null,
          onPointerLeave: shouldHandleTouchScreenInteraction ? handlePointerLeave : null,
          onPointerUp: shouldHandleTouchScreenInteraction ? addHoverState : null,
          onTouchCancel: shouldHandleSafariTouchInteraction ? addHoverState : null,
          onMouseLeave: isTouchScreen && isNotMousePointer ? null : removeItemHoverState,
          onBlur: removeItemHoverState,
          onKeyDown: toggleHoverState,
          onClick: shouldHandleTouchInteraction ? addItemHoverState : null,
          onContextMenu: shouldHandleTouchScreenInteraction ? addHoverState : null,
        },
      };

      if (withLinkHandlers) {
        result.navItemLinkEventHandlers = {
          onTouchCancel: shouldHandleSafariTouchInteraction ? disableLinkInteraction : null,
          // onMouseOver is used only in Mobile Safari for correcting menu touch interaction behavior. Link itself has default keyboard related behavior.
          onMouseOver: shouldHandleSafariTouchInteraction ? disableLinkInteraction : null,
          onContextMenu: shouldHandleLinkTouchInteraction
            ? disableLinkInteraction
            : shouldHandleTouchScreenInteraction ? setFocus : null,
          onClick: shouldHandleTouchInteraction ? handleLinkClick : null,
          onClickCapture: shouldHandleTouchInteraction ? handleLinkClickOnCapture : null,
        };
      }

      return result;
    },
    [
      shouldRenderSubItems,
      isTouchScreen,
      shouldHandleTouchScreenInteraction,
      isMouse,
      shouldHandleSafariTouchInteraction,
      shouldHandleTouchInteraction,
      shouldHandleLinkTouchInteraction,
      withLinkHandlers,
    ],
  );
};