export const ADDONS_METADATA_REQUESTED = 'ADDONS/METADATA/REQUESTED';
export const requestAddonsMetadata = () => ({
  type: ADDONS_METADATA_REQUESTED,
});

export const ADDONS_METADATA_LOADED = 'ADDONS/METADATA/LOADED';
export const addonsMetadataLoaded = metadata => ({
  type: ADDONS_METADATA_LOADED,
  payload: metadata,
});

export const ADDON_METADATA_CLEARED = 'ADDON/METADATA/CLEARED';
export const clearAddonMetadata = id => ({
  type: ADDON_METADATA_CLEARED,
  payload: { id },
});
