import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxYear } from '../datePicker/helpers';

const YearSelect = ({
  id,
  name,
  placeholderText,
  maxDate,
  minDate,
  ...selectProps
}) => {
  const { maxYear, minYear } = getMinMaxYear(maxDate, minDate);

  const items = useMemo(() => {
    const items = [{ name: placeholderText, value: null }];
    for (let i = maxYear; i >= minYear; i--)
      items.push({ name: i.toString(), value: i });

    return items;
  }, [maxYear, minYear, placeholderText]);

  return (
    <Select
      items={items}
      id={id && `${id}_year`}
      name={name && `${name}_year`}
      {...selectProps}
    />
  );
};

YearSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  maxDate: isMaxDate,
  minDate: isMinDate,
};

export default YearSelect;
