import React from 'react';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import Documents, { selectPropsFromPage } from '../Documents';
import CreditNotesList from './CreditNotesList';

const textKeys = {
  introduction: 'CreditNotesHistory_IntroductionText',
  listHeader: 'RecentCreditNotes',
  loadMore: 'ShowNextNOrders',
  noItems: 'Orders_NoOrderHistory',
};

const creditNotesRoute = routesBuilder.forCreditNotes();

const CreditNotes = React.memo(props => (
  <Documents
    loadMoreRoute={creditNotesRoute}
    textKeys={textKeys}
    documentType={DocumentType.CreditNote}
    {...props}
  >
    <CreditNotesList documents={props.documents} />
  </Documents>
));

CreditNotes.selectPropsFromPage = selectPropsFromPage;

export default CreditNotes;
