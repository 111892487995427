import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSliderControl, Slider } from 'components/primitives/slider';
import { FullScreenPopup } from 'components/objects/fullScreenPopup';

const SliderPopup = ({ hide, items, index = 0 }) => {
  const control = useSliderControl(index);
  const { prev, next } = control;
  const itemsLen = items.length;

  const handleKeyDown = useCallback(e => {
    if (itemsLen <= 1)
      return;

    // 37 - Left arrow button keycode
    if (e.key === 'ArrowLeft' || e.which === 37) {
      prev();
      return;
    }
    // 39 - Right arrow button keycode
    if (e.key === 'ArrowRight' || e.which === 39) {
      next();
      return;
    }
  }, [itemsLen]);

  return (
    <FullScreenPopup
      hide={hide}
      onKeyDown={handleKeyDown}
      aria-keyshortcuts={itemsLen > 1 ? 'ArrowRight ArrowLeft' : null}
    >
      {itemsLen > 1
        ? <Slider items={items} control={control} />
        : items[0]
      }
    </FullScreenPopup>);
};

SliderPopup.propTypes = {
  hide: PropTypes.func.isRequired,
  items: PropTypes.array,
  index: PropTypes.number,
};

export default SliderPopup;