import React from 'react';
import PropTypes from 'prop-types';
import { useIsMobileViewport } from 'utils/hooks';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const DesktopMobileImage = ({
  desktopImage,
  mobileImage,
  alt,
  className,
  afterLoad,
  imageLoadVisibleByDefault,
}) => {
  const isMobile = useIsMobileViewport();

  if (!isMobile && !desktopImage)
    return null;

  return (
    <ResponsiveLazyImage
      key={isMobile}
      src={isMobile ? mobileImage : desktopImage}
      className={className}
      afterLoad={afterLoad}
      visibleByDefault={imageLoadVisibleByDefault}
      alt={alt}
    />
  );
};

DesktopMobileImage.propTypes = {
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  afterLoad: PropTypes.func,
  imageLoadVisibleByDefault: PropTypes.bool,
};

export default React.memo(DesktopMobileImage);