import styles from '../BasketFooter.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import TotalsLine from './TotalsLine';

const TotalInclTax = ({ price, focus = true }) => {
  if (focus) {
    return (
      <TotalsLine
        textKey="Total"
        value={price}
        className={`${styles.priceInclTax} ${styles.emphasis} focused-total-line`}
      />);
  }
  return <TotalsLine textKey="TotalIncVat" value={price} className={styles.priceInclTax} />;
};

TotalInclTax.propTypes = {
  price: PropTypes.node.isRequired,
  focus: PropTypes.bool,
};

export default TotalInclTax;