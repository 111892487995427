import styles from './NonOrderable.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { LinesPropType } from './PropTypes';
import { makeRichText } from 'utils/render';
import { DangerAlert } from 'components/primitives/alerts';
import { Placeholder } from 'components/primitives/placeholders';
import Closable from './Closable';

const NonOrderableGroup = ({ headerText, lines }) => {
  if (lines !== null) {
    if (!lines || !lines.length)
      return null;
  }

  return (
    <Closable>
      <DangerAlert role="alert" className={styles.block}>
        {headerText
          ? makeRichText(headerText)
          : <Placeholder />}
        {lines && (
          <ul className={styles.lines}>
            {lines.map((line, index) => (
              <li key={index}>
                {line.description}
              </li>
            ))}
          </ul>
        )}
      </DangerAlert>
    </Closable>
  );
};

NonOrderableGroup.propTypes = {
  headerText: PropTypes.string,
  lines: LinesPropType,
};

export default NonOrderableGroup;