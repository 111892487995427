import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CheckoutPresets } from 'behavior/settings/constants';
import OneStepCheckout from './oneStep';
import MultiStepCheckout from './multiStep';

const presets = {
  [CheckoutPresets.OneStep]: OneStepCheckout,
  [CheckoutPresets.MultiStep]: MultiStepCheckout,
};

const Checkout = ({ info, preset }) => {
  const Page = presets[preset] || OneStepCheckout;

  return <Page info={info} />;
};

Checkout.propTypes = {
  info: PropTypes.shape({
    shippingAddresses: PropTypes.array,
    billingAddress: PropTypes.object,
    shippingAddress: PropTypes.object,
    shippingTemplateFields: PropTypes.array,
    isQuote: PropTypes.bool,
  }).isRequired,
  preset: PropTypes.oneOf(Object.values(CheckoutPresets)),
};

const connected = connect(({ settings: { checkout } }) => ({
  preset: checkout && checkout.pagePreset,
}))(Checkout);

connected.selectPropsFromPage = ({ info }) => ({ info });

export default connected;
