import styles from '../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useDownload } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Subject, timer } from 'rxjs';
import { switchMap, mapTo, tap } from 'rxjs/operators';

const DownloadReportLink = ({ document, documentType, setReportLoading, msgId }) => {
  const { reportUrl } = document;
  const options = [
    `Download${documentType}`,
    'DownloadThe',
    `OrderType_${documentType}`,
  ];
  options.skipReplacingEmptyText = true;

  const { texts: [
    downloadText,
    downloadTextTemplate,
    typeText,
  ], loaded } = useSanaTexts(options);

  const download = useDownload();
  const dispatch = useDispatch();

  const loadingStateSubjRef = useRef();
  if (!loadingStateSubjRef.current)
    loadingStateSubjRef.current = new Subject();

  useEffect(() => {
    let prev;
    const sub = loadingStateSubjRef.current.pipe(
      switchMap(v => timer(v === DownloadStates.Waiting || !prev || prev !== DownloadStates.Waiting ? 450 : 2000).pipe(
        mapTo(v),
        tap(v => prev = v),
      )),
    ).subscribe(setReportLoading);

    return () => sub.unsubscribe();
  }, [setReportLoading]);

  if (!loaded)
    return <Placeholder className={styles.btnActionPlaceholder} />;

  const downloadReportText = downloadText
    ? makeSimpleText(downloadText)
    : makeSimpleText(downloadTextTemplate, [typeText]);

  const handleClick = e => {
    e.preventDefault();
    loadingStateSubjRef.current.next(DownloadStates.Waiting);
    dispatch(setLoadingIndicator());

    download(reportUrl,
      () => void (loadingStateSubjRef.current.next(DownloadStates.Success), dispatch(unsetLoadingIndicator())),
      () => void (loadingStateSubjRef.current.next(DownloadStates.Failure), dispatch(unsetLoadingIndicator())),
    );
  };

  return (
    <a
      href={reportUrl}
      className={`${btnStyles.btn} ${btnStyles.btnSmall} ${styles.btnReport} ${styles.btnAction}`}
      rel="noopener noreferrer nofollow"
      onClick={handleClick}
      aria-describedby={msgId}
    >
      {downloadReportText}
    </a>
  );
};

DownloadReportLink.propTypes = {
  document: PropTypes.shape({
    reportUrl: PropTypes.string.isRequired,
  }).isRequired,
  documentType: PropTypes.string.isRequired,
  setReportLoading: PropTypes.func.isRequired,
  msgId: PropTypes.string,
};

export default DownloadReportLink;

export const DownloadStates = {
  Success: 0,
  Waiting: 1,
  Failure: 2,
};
