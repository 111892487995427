import styles from './Link.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Link from './base/Link';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { toPropTypes, createRouteData } from './base/helpers';
import { createUrl } from 'behavior/routing';
import { SmallArrowLeftIcon } from 'components/primitives/icons';

const defaultTexts = ['ButtonText_Back', 'ButtonAltText_Back'];

const BackTo = ({
  url,
  to,
  texts = defaultTexts,
  toTexts = texts,
  supportedRoutes,
  className = styles.hypBack,
}) => {
  const { routeData: prevRouteData, location: prevLocation } = useSelector(state => state.routing.previous);
  const toRouteData = useMemo(() => createRouteData(to), [to]);

  const previousRouteName = prevRouteData && prevRouteData.routeName;

  /* eslint-disable no-mixed-operators */
  const useFallbackRoute = !prevRouteData
    || !prevLocation
    || supportedRoutes && !supportedRoutes.has(previousRouteName)
    || !supportedRoutes && previousRouteName !== toRouteData.routeName;
  /* eslint-enable no-mixed-operators */

  const useFallbackTexts = useFallbackRoute || previousRouteName === toRouteData.routeName;

  const { texts: [text, title], loaded } = useSanaTexts(useFallbackTexts ? toTexts : texts, makeSimpleText);
  if (!loaded)
    return null;

  const icon = <SmallArrowLeftIcon alt={title} className="back-icon" aria-hidden />;

  if (useFallbackRoute) {
    return (
      <Link to={toRouteData} url={url} title={title} rel="nofollow" className={className}>
        {icon}
        {text}
      </Link>
    );
  }

  const backUrl = url || createUrl(prevLocation);

  return (
    <Link
      url={backUrl}
      to={{
        routeName: previousRouteName,
        params: toRouteData.params ? toRouteData.params : prevRouteData.params,
        options: toRouteData.options ? toRouteData.options : prevRouteData.options,
      }}
      title={title}
      rel="nofollow"
      className={className}
    >
      {icon}
      {text}
    </Link>
  );
};

BackTo.propTypes = {
  url: PropTypes.string,
  to: toPropTypes.isRequired,
  texts: PropTypes.arrayOf(PropTypes.string.isRequired),
  toTexts: PropTypes.arrayOf(PropTypes.string.isRequired),
  supportedRoutes: PropTypes.instanceOf(Set),
  className: PropTypes.string,
};

export default BackTo;