import styles from './Orders.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import isUrl from 'is-url';

const ShippingTracking = ({ tracking }) => {
  const { number, link } = tracking;
  const hasUrl = link && (isUrl(link) || isUrl(`://${link}`));
  const text = number || link;
  const title = number ? link : null;

  if (hasUrl)
    return (
      <a
        rel="noreferrer noopener"
        className={styles.trackingLink}
        target="_blank"
        href={link}
        title={text}
      >{text}</a>
    );

  if (number)
    return <span className={styles.trackingNumber} title={title}>{text}</span>;

  return null;
};

ShippingTracking.propTypes = {
  tracking: PropTypes.shape({
    number: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default React.memo(ShippingTracking);
