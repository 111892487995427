import styles from 'components/objects/product/Details.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { makeRichText } from 'utils/render';

const ProductSpecifications = ({ specifications }) => {
  if (!specifications || !specifications.length)
    return null;

  return (
    <dl className={styles.tableList}>
      {specifications.map(({ name, value }, index) => (
        <React.Fragment key={index}>
          <dt className={styles.name}>{name}</dt>
          <dd className={styles.value}>{makeRichText(value)}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

ProductSpecifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
};

export default React.memo(ProductSpecifications);