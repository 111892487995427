import styles from './ProductComparisonTile.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useProductRoute } from '../hooks';
import { Link } from 'components/primitives/links';
import Spinner from 'components/primitives/spinner/Spinner';
import { ProductPrice, ProductStock, ProductThumbnail } from 'components/primitives/product';
import ButtonBox from './buttons';
import RemoveButton from '../RemoveButton';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const ProductTile = ({ product, noImage, onRemove, compact = false }) => {
  const {
    id,
    title,
    url,
    image,
    price,
    listPrice,
    inventory,
    stockLevels,
  } = product;

  const route = useProductRoute(id);

  const link = (
    <WithProductClickTracking>
      {handleClick => (
        <Link onClick={handleClick} url={url} to={route} className={styles.productTitle}>
          {id}
        </Link>
      )}
    </WithProductClickTracking>
  );

  const imageLink = (
    <WithProductClickTracking>
      {handleClick => (
        <Link onClick={handleClick} draggable="false" className={styles.imageLink} url={url} to={route} aria-hidden>
          <ProductThumbnail
            title={title}
            src={image && (image.medium || image.small)}
            noImageSrc={noImage}
            className={styles.productImage}
            placeholder={<Spinner />}
            visibleByDefault
          />
        </Link>
      )}
    </WithProductClickTracking>
  );

  const priceBlock = (price !== null || listPrice !== null) && (
    <div className={styles.prices}>
      <ProductPrice salesPrice={price} basePrice={listPrice} />
    </div>
  );

  const button = <ButtonBox product={product} />;

  return (
    <ProductTrackingContext.Provider value={{ product, trackingSource : EVENT_SOURCES.compareProductsPage }}>
      <div className={`${styles.tile} ${compact ? styles.compactView : styles.fullView}`}>
        {compact
          ? (
            <>
              <div>
                {imageLink}
                {link}
                {priceBlock}
              </div>
              <div className={styles.productAction}>
                {button}
              </div>
            </>
          ) : (
            <>
              <div className={styles.thumbnail}>
                <div className={styles.thumbnailBox}>
                  {imageLink}
                  <RemoveButton className={styles.btnRemoveItem} onClick={() => onRemove(id)} />
                </div>
              </div>
              <div className={styles.productInfo}>
                <div className={styles.productDescription}>
                  {link}
                  <div className={styles.productId}>
                    {title}
                  </div>
                  {inventory !== null && stockLevels != null && product.isSpare && (
                    <div>
                      <ProductStock inventory={inventory} stockLevels={stockLevels} />
                    </div>
                  )}
                </div>
                <div className={styles.productAction}>
                  {priceBlock}
                  {button}
                </div>
              </div>
            </>
          )
        }
      </div>
    </ProductTrackingContext.Provider>
  );
};

ProductTile.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      medium: PropTypes.string,
      small: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    inventory: PropTypes.number,
    stockLevels: PropTypes.object,
  }).isRequired,
  noImage: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  compact: PropTypes.bool,
};

const mapStateToProps = ({ settings }) => ({
  noImage: settings && settings.product && settings.product.noImage && settings.product.noImage.medium,
});

export default connect(mapStateToProps)(ProductTile);