import styles from '../PLP.module.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RichText, SimpleText } from 'components/sanaText';
import { OfflinePriceWarning } from 'components/primitives/product';
import ProductLine from '../b2c/Line';
import ClearButton from './ClearButton';
import { requestPage } from 'behavior/pages/productList/lastViewed';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { useLoadEffect } from 'utils/hooks';
import { ProductTrackingContext } from 'components/objects/analytics';

const Page = ({ products, requestPage, trackProductListView }) => {
  useEffect(() => {
    if (!products)
      requestPage();
  }, [products]);

  useLoadEffect(() => {
    if (!products || !products.length)
      return;

    const calculated = products.every(product => typeof product.price !== 'undefined');
    if (!calculated)
      return;

    trackProductListView(products, EVENT_SOURCES.lastViewedProductsPage);
  }, [products]);

  if (!products)
    return null;

  if (!products.length) {
    return (
      <div className="msg-block">
        <RichText textKey="ProductList_NoResult" />
      </div>
    );
  }

  return (
    <>
      <OfflinePriceWarning />
      <div className={`${styles.panel} ${styles.pagePanel}`}>
        <div className={styles.panelHeader}>
          <div className={`clearfix ${styles.panelHeaderRow}`}>
            <div className={styles.counterInside}>
              <span>{products.length} <SimpleText textKey="ProductList_ProductHeader" /></span>
              <ClearButton />
            </div>
          </div>
        </div>
        <div className={styles.list} role="list">
          {products.map((product, index) => (
            <ProductTrackingContext.Provider
              key={product.id}
              value={{ product, trackingSource: EVENT_SOURCES.lastViewedProductsPage }}
            >
              <ProductLine
                product={product}
                showThumbnail
                index={index}
              />
            </ProductTrackingContext.Provider>
          ))}
        </div>
      </div>
    </>
  );
};

Page.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  requestPage: PropTypes.func.isRequired,
  trackProductListView: PropTypes.func.isRequired,
};

export default connect(
  ({ page: { lastViewed } }) => ({ products: lastViewed }),
  { requestPage, trackProductListView },
)(Page);
