import styles from './Spinner.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Spinner = React.forwardRef(({ className = '' }, ref) => (
  <span ref={ref} className={`${styles.spinner} ${className}`} />
));

Spinner.propTypes = {
  className: PropTypes.string,
};

export default React.memo(Spinner);