import React from 'react';
import PropTypes from 'prop-types';
import styles from './Placeholder.module.scss';

const Placeholder = ({ className, lineCount = 1, ...attributes }) => {
  const placeholders = [];

  for (let i = 0; i < lineCount; i++) {
    placeholders.push((
      <span
        key={i}
        className={`${className ? `${className} ` : ''}${styles.placeholderItem}`}
        {...attributes}
      />
    ));
  }

  return <>{placeholders}</>;
};

Placeholder.propTypes = {
  className: PropTypes.string,
  lineCount: PropTypes.number,
  attributes: PropTypes.object,
};

export default React.memo(Placeholder);