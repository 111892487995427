import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SanaForm, EntityTemplateFields, useFormLockerOnSubmit, extractEscapedTemplateFields,
} from 'components/objects/forms';
import { connect } from 'react-redux';
import { createProspect } from 'behavior/user';
import { toasts } from 'behavior/toasts';
import { FormLockKeys } from 'behavior/pages';
import { useHandlerLockerInPreview } from 'components/objects/preview';

const Form = ({ templateFields, createProspectResult, createProspect }) => {
  const [isSpinnerDisplayed, displaySpinner] = useState(false);

  const onSubmit = useFormLockerOnSubmit(formData => {
    displaySpinner(true);
    createProspect(createProspectData(formData, templateFields));
  }, FormLockKeys.CreateProspect, [templateFields]);

  useEffect(() => {
    if (createProspectResult == null)
      return;

    if (createProspectResult.isCreated)
      return;

    if (createProspectResult.failureText) {
      toasts.error(createProspectResult.failureText);
    }

    displaySpinner(false);
  }, [createProspectResult]);

  const anyCustomerDataFields = !!(templateFields && templateFields.length);

  return (
    <SanaForm
      onSubmit={useHandlerLockerInPreview(onSubmit)}
      name="CreateProspect"
    >
      {anyCustomerDataFields && (
        <>
          <EntityTemplateFields applyNamesEscaping fields={templateFields} />
          <SanaForm.SubmitRow
            textKey="Save"
            btnClass={isSpinnerDisplayed ? btnStyles.loading : null}
          />
        </>
      )}
    </SanaForm>
  );
};

Form.propTypes = {
  templateFields: PropTypes.array,
  createProspectResult: PropTypes.object,
  createProspect: PropTypes.func.isRequired,
};

export default connect(undefined, { createProspect })(Form);

function createProspectData(formData, templateFields) {
  const { ...customerData } = formData;
  return { customerData: extractEscapedTemplateFields(customerData, templateFields) };
}