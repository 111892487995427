import styles from '../Details.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { Select } from 'components/primitives/form';
import { usePrintMode } from 'utils/hooks';
import { focusById } from 'utils/dom';

const VariantDropdownRow = ({ id, value, groupName, variantComponents, onChange }) => {
  const isPrintMode = usePrintMode();
  const items = variantComponents.map(c => ({ name: c.name, value: c.id }));
  const selectedItem = items.find(item => item.value === value);

  const labelId = id + '_lb';

  return (
    <Row className={styles.row} crossAxisAlign="center">
      <Col xs={3} lg={2}>
        {/* onClick is used to simulate default label behavior. */}
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
        <label id={labelId} className={styles.ellipsis} onClick={focusById.bind(null, id)}>{groupName}</label>
      </Col>
      <Col xs={9} lg={10}>
        {isPrintMode
          ? <span className={styles.variant}>{selectedItem.name}</span>
          : (
            <Select
              items={items}
              onChange={onChange}
              value={value}
              id={id}
              labelId={labelId}
              className={styles.variant}
              optionClassName={styles.variantOption}
            />
          )
        }
      </Col>
    </Row>
  );
};

VariantDropdownRow.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  variantComponents: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default VariantDropdownRow;