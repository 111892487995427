import PropTypes from 'prop-types';

const TabItem = () => {
  throw new Error(
    'The `TabItem` component is not meant to be rendered! ' +
    'It\'s an abstract component that is only valid as a direct Child of the `Tabs` Component.',
  );
};

TabItem.propTypes = {
  children: PropTypes.node,
  tabKey: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default TabItem;