import styles from './VariantsMatrix.module.scss';
import React, { useMemo } from 'react';
import { useMatrixAbilities } from './useMatrixAbilities';
import VariantsOverview from './VariantsOverview';
import { AbilityTo } from 'behavior/user/constants';
import { SimpleText } from 'components/sanaText';
import { useProductContext } from '../hooks';
import VariantCellPrint from './VariantCellPrint';
import { getVariantKey, getCellKey } from './VariantsMatrix';
import { showMatrix } from '../showMatrix';
import { UomTitle } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';

const VariantsMatrixPrint = () => {
  const abilities = useMatrixAbilities();

  if (Object.values(abilities).some(ability => ability === undefined))
    return null;

  const { product, uomId } = useProductContext();

  const {
    variantComponentGroups,
    variants,
    uoms,
    stockLevels,
  } = product;

  const showUom = abilities[AbilityTo.ViewUnitOfMeasure] && uomId && uoms && uoms.length;
  const currentUom = useMemo(() => {
    if (showUom)
      return uoms.find(u => u.id === uomId) || {};
  }, [uomId, showUom]);

  if (!showMatrix(product))
    return null;

  if (!variantComponentGroups)
    return null;

  const [verticalGroup, horizontalGroup] = variantComponentGroups;
  const columnHeaders = verticalGroup
    ? verticalGroup.components
    : variants.map(({ id, title: name }) => ({ id, name }));
  const rowHeaders = horizontalGroup ? horizontalGroup.components : [];
  const variantsMap = new Map(variants.map(variant =>
    [getVariantKey(variant, verticalGroup, horizontalGroup), variant]));

  const showMatrixForm = abilities[AbilityTo.ViewPrices]
    || abilities[AbilityTo.OrderProducts]
    || abilities[AbilityTo.ViewStock];

  let rows;
  if (showMatrixForm) {
    const rowsCount = rowHeaders.length || 1;
    const colsCount = columnHeaders.length || 1;
    rows = Array(rowsCount);
    for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
      const cells = Array(colsCount);
      const row = rowHeaders[rowIndex];
      const includeRowHeader = rowHeaders.length > 0;

      for (let colIndex = 0; colIndex < colsCount; colIndex++) {
        const cellKey = getCellKey(columnHeaders[colIndex], row);
        const variant = variantsMap.get(cellKey);

        cells[colIndex] = !variant
          ? <td key={colIndex} />
          : (
            <VariantCellPrint
              key={colIndex}
              stockLevels={stockLevels}
              variant={variant}
              abilities={abilities}
              labelledBy={`VariantColHeader${colIndex}${includeRowHeader ? ` VariantRowHeader${rowIndex}` : ''}`}
            />
          );
      }

      rows[rowIndex] = (
        <tr key={rowIndex}>
          {rowHeaders.length > 0 && <th className={styles.rowHeader} id={`VariantRowHeader${rowIndex}`}>{row.name}</th>}
          {cells}
        </tr>
      );
    }
  }

  const uomTitlePlaceholder = <Placeholder className={styles.uomTitlePlaceholder} />;

  return (
    <div className={styles.variants}>
      <div className={styles.header}>
        <h2 className={styles.productTitle}><SimpleText textKey="Variants" /></h2>
      </div>
      {showMatrixForm &&
        <>
          {currentUom &&
            <div className={styles.uomWrapper}>
              <span className={styles.uomTitle}>
                <SimpleText textKey="UOM_Label" placeholder={uomTitlePlaceholder} />
              </span>
              <div className={styles.uomSelector}>
                <UomTitle allowHtmlTags={false} id={currentUom.id} description={currentUom.description} />
              </div>
            </div>
          }
          {product.variantComponentGroups &&
            <div className={styles.matrix}>
              <div className={styles.tableWrapper}>
                <table>
                  {columnHeaders.length && (
                    <thead>
                      <tr>
                        {rowHeaders.length > 0 && <th aria-hidden />}
                        {columnHeaders.map(({ name }, index) => <th className="th-col" key={index} id={`VariantColHeader${index}`}>{name}</th>)}
                      </tr>
                    </thead>
                  )}
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          }
        </>
      }
      {!showMatrixForm && <VariantsOverview />}
    </div>
  );
};

export default React.memo(VariantsMatrixPrint);
