exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".LargeGallery_slide{position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;max-height:95%;max-width:95%}.LargeGallery_spinner{display:flex;justify-content:center;align-items:center}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/mediaGallery/LargeGallery/LargeGallery.module.scss"],"names":[],"mappings":"AAAA,oBACI,kBACA,MACA,QACA,SACA,OACA,YACA,eACA,aACJ,CAEA,sBACI,aACA,uBACA,kBACJ,CAAA","file":"LargeGallery.module.scss","sourcesContent":[".slide {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    margin: auto;\n    max-height: 95%;\n    max-width: 95%;\n}\n\n.spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"slide": "LargeGallery_slide",
	"spinner": "LargeGallery_spinner"
};