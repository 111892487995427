import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, renderHTML } from 'utils/render';
import { DangerAlert } from 'components/primitives/alerts';
import { VerticalSliding } from 'components/primitives/transitions';
import scrollIntoView from 'scroll-into-view';

const emptySteps = [];

const IncompleteStepsMessage = ({ incompleteSteps }) => {
  const [expanded, setExpanded] = useState(true);
  const timeoutRef = useRef();

  const { texts: [template, ...sanaTextTitles], loaded } = useSanaTexts(['FillCheckoutSteps', ...incompleteSteps?.map(s => s.titleKey) ?? emptySteps]);
  const show = loaded && incompleteSteps?.length;

  useEffect(() => {
    if (!show)
      return;

    if (!expanded)
      setExpanded(true);

    const messageElement = document.getElementById('incompleteMsg');
    messageElement && scrollIntoView(messageElement, { time: 300, align: { top: 0.25 } });
    timeoutRef.current = setTimeout(() => setExpanded(false), 5000);

    return () => clearTimeout(timeoutRef.current);
  }, [incompleteSteps, loaded]);

  if (!show)
    return null;

  const titles = (sanaTextTitles[0] ? sanaTextTitles : incompleteSteps.map(s => s.title))
    .flatMap(title => [renderHTML(`'${title}'`), ', ']);
  titles.pop();

  return (
    <VerticalSliding
      expanded={expanded}
      role="alertdialog"
      aria-labelledby="incompleteMsg"
      duration={350}
      timingFunction="ease"
    >
      <DangerAlert>
        <span id="incompleteMsg">
          {makeRichText(template, [titles])}
        </span>
      </DangerAlert>
    </VerticalSliding>
  );
};

IncompleteStepsMessage.propTypes = {
  incompleteSteps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired),
};

export default React.memo(IncompleteStepsMessage);
