import styles from './Calendar.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getYearStart, getYearEnd } from '@wojtekmaj/date-utils';
import { viewPropTypes } from './propTypes';
import { PeriodTypes } from './enums';
import { getDecadeStartYear, getTileClassNames, getTilesGroup, isTileDisabled } from './helpers';

const DecadeView = ({
  activeStartDate,
  formatYear,
  hoveredDate,
  minDate,
  maxDate,
  onClick,
  onMouseOver,
  shouldDisableTile,
  tileContent,
  value,
  valueType,
}) => {
  const tilesGroup = useMemo(() => {
    const start = getDecadeStartYear(activeStartDate);
    const end = start + 9;

    return getTilesGroup(start, end).map(tile => new Date(tile, 0, 1));
  }, [activeStartDate]);

  const transformedMinDate = useMemo(() => minDate && getYearStart(minDate), [minDate]);
  const transformedMaxDate = useMemo(() => maxDate && getYearEnd(maxDate), [maxDate]);

  return (
    <div className={styles.decadeView}>
      {tilesGroup.map(date => {
        const isDisabled = isTileDisabled(
          date,
          activeStartDate,
          transformedMinDate,
          transformedMaxDate,
          PeriodTypes.DECADE,
          shouldDisableTile,
        );
        const classNames = getTileClassNames(date, PeriodTypes.YEAR, value, valueType, hoveredDate);
        const handleMouseOver = !isDisabled && onMouseOver ? event => onMouseOver(date, event) : null;

        return (
          <button
            key={+date}
            tabIndex={isDisabled ? -1 : null}
            className={`${styles.year} ${classNames}`}
            aria-disabled={isDisabled}
            aria-keyshortcuts="ArrowUp ArrowDown ArrowLeft ArrowRight Space Enter"
            onClick={!isDisabled && onClick ? event => onClick(date, event) : null}
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            type="button"
          >
            {formatYear(date)}
            {typeof tileContent === 'function' ? tileContent(activeStartDate, date, PeriodTypes.DECADE) : tileContent}
          </button>
        );
      })}
    </div>
  );
};

DecadeView.propTypes = {
  ...viewPropTypes,
  formatYear: PropTypes.func.isRequired,
};

export default DecadeView;
