import { resetPasswordPageQuery, userDataQuery } from './queries';
import { mergeMap } from 'rxjs/operators';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { routeNames } from 'routes';
import { getBackTo } from '../helpers';
import { switchMap, pluck, first } from 'rxjs/operators';
import { StoreType } from 'behavior/settings';

export default (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreResetPasswordPage(routeData, state$, api)
        : loadSystemResetPasswordPage(routeData, api),
    ),
  );
};

function loadClosedStoreResetPasswordPage(routeData, state$, api) {
  return api.graphApi(userDataQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile }) => {
      if (!profile.userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      return of({
        page: {
          emptyLayout: true,
          component: PageComponentNames.ClosedStoreResetPassword,
          userData: profile.userData,
          backTo: getBackTo(state$, [
            routeNames.ResetPassword,
          ], routeData.params && routeData.params.language),
        },
      });
    }),
  );
}

function loadSystemResetPasswordPage(routeData, api) {
  return api.graphApi(resetPasswordPageQuery, { token: routeData.params.token }).pipe(
    mergeMap(({ profile, pages }) => {
      if (!profile.userData)
        return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

      const page = pages.resetPassword;
      if (!page)
        return null;

      page.component = PageComponentNames.ResetPassword;
      page.userData = profile.userData;

      return of({ page });
    }),
  );
}
