/* eslint-disable react/no-multi-comp */
import React from 'react';
import {
  SimpleText as SimpleTextCore,
  RichText as RichTextCore,
} from 'components/sanaText';
import {
  UseSanaTexts as UseSanaTextsCore,
} from 'components/sanaText/addons';

export default function (addonId) {
  const makeTextKey = textKey => `${addonId}__${textKey}`;
  const SimpleText = ({ textKey, children, ...props }) => (
    React.createElement(SimpleTextCore, { textKey: makeTextKey(textKey), ...props }, children)
  );
  SimpleText.propTypes = SimpleTextCore.propTypes;

  const RichText = ({ textKey, children, ...props }) => (
    React.createElement(RichTextCore, { textKey: makeTextKey(textKey), ...props }, children)
  );
  RichText.propTypes = RichTextCore.propTypes;

  const UseSanaTexts = ({ textKeys, children, ...props }) => (
    React.createElement(UseSanaTextsCore, { textKeys: textKeys.map(makeTextKey), ...props }, children)
  );
  UseSanaTexts.propTypes = UseSanaTextsCore.propTypes;

  return {
    SimpleText,
    RichText,
    UseSanaTexts,
  };
}

