import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage as FormikErrorMessage, useFormikContext } from 'formik';
import ErrorMessage from './ErrorMessage';
import { createErrorMsgId } from './helpers';

const FieldError = ({ fieldName }) => {
  const formik = useFormikContext();
  const id = createErrorMsgId(formik.status.formName, fieldName);

  return (
    <FormikErrorMessage
      id={id}
      fieldName={fieldName}
      name={fieldName}
      component={ErrorMessage}
    />
  );
};

FieldError.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default FieldError;