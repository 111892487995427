import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useServices } from 'utils/services';
import { createUrl } from 'utils/url';

const AlternateLinks = () => {
  const { languages, alternateUrls, index } = useSelector(selector, _ => true);
  const { context: { origin } } = useServices();

  if (!index || !languages.length || alternateUrls.length <= 1)
    return null;

  const defaultLanguage = languages.find(language => language.isDefault);
  const id = defaultLanguage && defaultLanguage.id;
  const url = alternateUrls.find(({ key }) => +key === id);
  const defaultLanguageUrl = createUrl(url.path, origin);

  const getLanguageName = id => {
    const language = languages.find(language => language.id === id);
    return language && language.name;
  };

  return (
    <Helmet>
      {alternateUrls.map(({ key, path }, index) => (
        <link
          key={index}
          rel="alternate"
          hrefLang={getLanguageName(+key)}
          href={createUrl(path, origin)}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={defaultLanguageUrl} />
    </Helmet>
  );
};

export default React.memo(AlternateLinks);

function selector({ localization, page }) {
  return {
    languages: localization.languages,
    alternateUrls: localization.alternateUrls,
    index: page.index,
  };
}