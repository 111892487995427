import styles from './Reviews.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { connect } from 'react-redux';
import { StarsRating } from 'components/primitives/rating';
import { requestReviews } from 'behavior/pages/product';
import { HashRoutingContext } from 'components/hash';
import Form from './Form';
import { SimpleText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { toLocaleDate } from 'utils/format';

const ReviewsTab = ({ productId, reviews, total, culture, requestReviews }) => {
  const hashContext = useContext(HashRoutingContext);
  const showForm = useRef(false);
  const navigateToAdd = useCallback(
    () => hashContext.navigateTo('#reviews_add', { extraTopOffset: 30 }),
    [hashContext.navigateTo]);

  if (hashContext.hash && hashContext.hash.startsWith('#reviews')) {
    showForm.current = hashContext.hash === '#reviews_add';
  }
  if (showForm.current) {
    return <Form />;
  }

  if (total === 0) {
    return (
      <section className={styles.cnt}>
        <header>
          <SanaButton className={styles.btnAdd} textKey="WriteReview" onClick={navigateToAdd} />
        </header>
        <p className="msg-block">
          <SimpleText textKey="Product_ReviewsNoReviewsYetLabel" />
        </p>
      </section>
    );
  }

  const items = reviews.map((review, i) => (
    <li key={i} aria-label={review.title + ' - ' + review.rating}>
      <div className={styles.heading}>
        <span className={styles.rating}><StarsRating value={review.rating} /></span>
        <h4 className={styles.title}>{makeSimpleText(review.title)}</h4>
      </div>
      <time dateTime={review.date}>{toLocaleDate(review.date, culture)}</time>
      <div className={styles.description}>{makeSimpleText(review.description)}</div>
    </li>
  ));

  const nextIndex = reviews.length < total &&
    Math.floor(reviews.length / 5);

  return (
    <section className={styles.cnt}>
      <header className={styles.header}>
        <SanaButton className={`${btnStyles.btnSmall} ${styles.btnAdd}`} textKey="WriteReview" onClick={navigateToAdd} />
      </header>
      <ul className="clearfix">{items}</ul>
      {nextIndex &&
        <footer>
          <SanaButton textKey="ShowNextNReviews"
            className={`${btnStyles.btnBig} ${btnStyles.btnLoadMore}`}
            formatWith={[5]}
            onClick={() => requestReviews(productId, nextIndex)}
          />
        </footer>
      }
    </section>
  );
};

ReviewsTab.propTypes = {
  productId: PropTypes.string.isRequired,
  culture: PropTypes.string,
  requestReviews: PropTypes.func.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    rating: PropTypes.number.isRequired,
  })),
  total: PropTypes.number.isRequired,
};

export default connect(state => ({
  culture: state.localization.currentLanguage && state.localization.currentLanguage.cultureName,
}), { requestReviews })(ReviewsTab);
