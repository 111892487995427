import styles from '../Checkout.module.scss';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CheckoutContext from './CheckoutContext';
import { useDispatch } from 'react-redux';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import debounce from 'lodash/debounce';
import { submitCheckout, Steps } from 'behavior/pages/checkout';
import { trackCheckout } from 'behavior/analytics';
import { scrollToInvalidElement } from '../base/helpers';

export const CheckoutProvider = ({ info, setStepCompletion, children }) => {
  const registrations = useRegistrations();
  const loadingIdRef = useRef();
  const methodsRef = useRef();
  const dispatch = useDispatch();

  if (!methodsRef.current) {
    methodsRef.current = {
      setStepCompletion,
      registerStep(id, className, dependsOn, validate, onBeforeSubmit) {
        registrations.set(id, { className, dependsOn, validate, onBeforeSubmit });
        return () => methodsRef.current.unregisterStep(id);
      },
      unregisterStep(id) {
        registrations.delete(id);
      },
      setLoading(id) {
        dispatch(setLoadingIndicator());
        loadingIdRef.current = debounce(() => {
          if (loadingIdRef.current) {
            if (id) {
              for (const registration of registrations.values()) {
                if (registration.dependsOn && registration.dependsOn.includes(id))
                  document.querySelector('.' + registration.className).classList.add(styles.loading);
              }
            }
          } else {
            releaseLoadingSteps();
          }
        }, 150, { leading: false });

        loadingIdRef.current();

        return () => {
          loadingIdRef.current.cancel();
          loadingIdRef.current = null;
          dispatch(unsetLoadingIndicator());
          releaseLoadingSteps();
        };
      },
      async submit() {
        if (loadingIdRef.current) {
          await new Promise(resolve => {
            const intervalId = setInterval(() => {
              if (!loadingIdRef.current) {
                resolve();
                clearInterval(intervalId);
              }
            }, 150);
          });
        }

        let invalidStepClassSelector;
        for (const registration of registrations.values()) {
          if (registration.validate && !await registration.validate()) {
            if (!invalidStepClassSelector)
              invalidStepClassSelector = '.' + registration.className;
          }
        }

        if (invalidStepClassSelector) {
          const invalidElement = document.querySelector(invalidStepClassSelector + ' [aria-invalid=true]');
          const errorElement = document.querySelector(invalidStepClassSelector + ' .field-validation-error:not(:empty)');
          scrollToInvalidElement(invalidElement, errorElement);
          return false;
        }

        for (const registration of registrations.values()) {
          if (registration.onBeforeSubmit)
            await registration.onBeforeSubmit();
        }

        dispatch(submitCheckout());
        return true;
      },
    };
  }

  useEffect(() => {
    if (!loadingIdRef.current)
      return;

    loadingIdRef.current.cancel();
    dispatch(unsetLoadingIndicator());

    const unsetLoading = loadingIdRef.current;
    loadingIdRef.current = null;
    unsetLoading();
  }, [info]);

  useEffect(() => () => {
    if (loadingIdRef.current)
      loadingIdRef.current.cancel();
  }, []);

  const productsToTrack = info.analytics ? info.analytics.products : null;
  useEffect(() => {
    if (!productsToTrack)
      return;

    const stepsNames = Object.values(Steps);

    for (const step of registrations) {
      dispatch(trackCheckout(stepsNames.indexOf(step[0]) + 1, productsToTrack));
    }
  }, [productsToTrack]);

  return <CheckoutContext.Provider value={methodsRef.current}>{children}</CheckoutContext.Provider>;
};

CheckoutProvider.propTypes = {
  info: PropTypes.shape({
    analytics: PropTypes.shape({
      products: PropTypes.array,
    }),
  }).isRequired,
  setStepCompletion: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

/**
 * @typedef {Object} Registration
 * @property {string} className
 * @property {Array<string>} dependsOn
 * @property {Function} validate
 **/

/**
 * @returns {Map<string, Registration>}
 **/
function useRegistrations() {
  const ref = useRef();
  if (ref.current)
    return ref.current;

  return ref.current = new Map();
}

function releaseLoadingSteps() {
  for (const element of [...document.getElementsByClassName(styles.loading)]) {
    element.classList.remove(styles.loading);
  }
}
