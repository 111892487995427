import styles from '../checkout/Checkout.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { payOrder } from 'behavior/pages/orderPayment';
import OrderPaymentForm from './OrderPaymentForm';
import AdditionalCustomerDataForm from './AdditionalCustomerDataForm';
import { ExtraPaymentDataForm } from './extraPaymentData';
import { SanaButton } from 'components/primitives/buttons';
import scrollIntoView from 'scroll-into-view';
import { validateForm } from 'components/objects/forms';

const OrderPaymentFormsContainer = ({ orderPaymentInfo }) => {
  const { transactionId, order, paymentMethods, additionalCustomerData, extraPaymentData } = orderPaymentInfo;
  const orderPaymentFormRef = useRef();
  const additionalCustomerDataFormRef = useRef();
  const extraPaymentDataFormRef = useRef();
  const isLoading = useSelector(({ isLoading }) => isLoading);
  const dispatch = useDispatch();

  const hasPaymentMethods = paymentMethods.length > 0;
  const canSubmit = hasPaymentMethods && !isLoading;

  const onBeforeSubmitExtraPaymentDataRef = useRef();

  const onBeforeSubmit = async () => {
    if (onBeforeSubmitExtraPaymentDataRef.current)
      await onBeforeSubmitExtraPaymentDataRef.current();
  };

  const onSubmit = useCallback(async () => {
    let isFormValid = true;

    if (!await validateForm(orderPaymentFormRef)) {
      setTimeout(() => scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 300 }), 10);
      isFormValid = false;
    }

    if (additionalCustomerDataFormRef.current && !await additionalCustomerDataFormRef.current.validate()) {
      if (isFormValid) {
        const input = document.querySelector('form[name=customerData] [aria-invalid=true]');
        const error = document.querySelector('form[name=customerData] .field-validation-error :not(:empty)');
        setTimeout(() => scrollIntoView(input || error, { time: 300 }, input && input.focus && (() => input.focus())), 10);
      }
      isFormValid = false;
    }

    if (extraPaymentDataFormRef.current && !await extraPaymentDataFormRef.current.validate()) {
      isFormValid = false;
    }

    if (isFormValid) {
      await onBeforeSubmit();
      dispatch(payOrder(transactionId, orderPaymentFormRef.current.values, additionalCustomerDataFormRef.current?.values, extraPaymentDataFormRef.current?.values));
    }
  }, [transactionId]);

  return (
    <>
      <OrderPaymentForm
        transactionId={transactionId}
        order={order}
        paymentMethods={paymentMethods}
        formRef={orderPaymentFormRef}
      />
      <AdditionalCustomerDataForm
        additionalCustomerData={additionalCustomerData}
        formRef={additionalCustomerDataFormRef}
      />
      <ExtraPaymentDataForm
        transactionId={transactionId}
        extraPaymentData={extraPaymentData}
        formRef={extraPaymentDataFormRef}
        onBeforeSubmitRef={onBeforeSubmitExtraPaymentDataRef}
        onFormSubmit={onSubmit}
      />
      <div className={styles.payRow}>
        <SanaButton
          textKey="Pay"
          className={`${btnStyles.btn} ${btnStyles.btnAction}`}
          onClick={onSubmit}
          disabled={!canSubmit}
        />
      </div>
    </>
  );
};

OrderPaymentFormsContainer.propTypes = {
  orderPaymentInfo: PropTypes.shape({
    transactionId: PropTypes.string.isRequired,
    order: PropTypes.shape({
      id: PropTypes.string.isRequired,
      currency: PropTypes.object.isRequired,
      referenceNo: PropTypes.string,
      orderDate: PropTypes.string.isRequired,
      totalAmount: PropTypes.number,
    }).isRequired,
    paymentMethods: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
      }).isRequired,
    ).isRequired,
    additionalCustomerData: PropTypes.object,
    extraPaymentData: PropTypes.object,
  }),
};

export default React.memo(OrderPaymentFormsContainer);
