import React from 'react';
import styles from '../basket/b2b/BasketB2B.module.scss';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import { useSanaTexts } from 'components/sanaText';
import { SanaButton } from 'components/primitives/buttons';
import { Modal } from 'components/objects/modals';
import PropTypes from 'prop-types';

const ProductSelectionDialog = ({ opened, toggle }) => {

  const [
    productSelectionMsg,
    productSelectionMsgTitle,
    productSelectionMsgFooter,
  ] = useSanaTexts([
    'ProductSelectionMsg',
    'ProductSelectionMsg_Title',
    'ProductSelectionMsg_Footer',
  ]).texts;

  return (
    <Modal opened={opened} hide={toggle}>
      <div>
        <div><h1>{productSelectionMsgTitle}</h1></div>

        <div><span>{productSelectionMsg}</span></div>
      
        <div><span>{productSelectionMsgFooter}</span></div>
        <div className={styles.modalButtonsBlock}>
          <SanaButton
            textKey="Ok"
            className={`${buttonStyles.btnExtraSmall} ${buttonStyles.noIcon} ${styles.btnConfirm}`}
            onClick={toggle}
          />
        </div>
      </div>
    </Modal>
  );
};

ProductSelectionDialog.propTypes = {
  opened: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ProductSelectionDialog;