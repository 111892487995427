import React from 'react';
import { renderRows } from 'components/containers';
import { SimpleText } from 'components/sanaText';
import Breadcrumb from './Breadcrumb';
import Page from './Page';
import { Container } from 'components/primitives/grid';

function productPageRenderer(page) {
  let header, footer;
  if (page.content) {
    header = page.content.header;
    footer = page.content.footer;
  }

  return (
    <>
      <Container>
        <Breadcrumb />
        <h1><SimpleText textKey="ProductCompare_Header" /></h1>
      </Container>
      {header && !!header.length && renderRows(header)}
      <Container>
        <Page data={page} />
      </Container>
      {footer && !!footer.length && renderRows(footer)}
    </>
  );
}

export default {
  productComparison: productPageRenderer,
};