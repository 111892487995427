import { useEffect, useState } from 'react';
import { loadPasswordStrengthValidator } from 'utils/validation';

let validatePasswordStrength = null;

export const usePasswordStrengthValidator = () => {
  const [loaded, setLoaded] = useState(!!validatePasswordStrength);

  useEffect(() => {
    if (loaded)
      return;

    loadPasswordStrengthValidator().then(validator => {
      validatePasswordStrength = validator;
      setLoaded(true);
    });
  }, []);

  return validatePasswordStrength;
};