import { subAccountPageQuery, newSubAccountPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { arrayToObject } from 'utils/helpers';
import { createNotFoundPageResult } from '../helpers';
import { PageComponentNames } from '../componentNames';

export default ({ params }, _state$, { api }) => {
  const id = params && params.id;
  const request = id
    ? api.graphApi(subAccountPageQuery, { id })
    : api.graphApi(newSubAccountPageQuery);

  return request.pipe(
    map(({ pages, profile: { subAccounts, accountManagers } }) => {
      const page = pages.subAccount;

      if (!subAccounts)
        return createNotFoundPageResult(page);

      page.component = PageComponentNames.SubAccount;

      page.subAccount = subAccounts.search && subAccounts.search.list[0];
      page.authorizers = subAccounts.authorizers
        ? accountManagers.list.concat(subAccounts.authorizers.list)
        : accountManagers.list;
      page.authorizers = arrayToObject(page.authorizers, a => a.id);
      if (page.subAccount)
        delete page.authorizers[page.subAccount.id];

      page.options = subAccounts.options;

      if (id && !page.subAccount)
        return null;

      return { page };
    }),
    initSystemPageContent(),
  );
};
