import React, { useCallback } from 'react';
import Field, { fieldPropTypes } from '../Field';
import { getIn } from 'formik';

export default function (Primitive) {
  const componentBuilder = ({ field, form, className, ...rest }) => {
    const { name, value } = field;
    const { setFieldValue, setFieldTouched, errors, touched } = form;
    const isValid = !getIn(touched, name) || !getIn(errors, name);

    const onChange = useCallback(date => {
      setFieldTouched(name, true, false);
      setFieldValue(name, date);
    }, [name, setFieldValue, setFieldTouched]);

    return (
      <Primitive
        name={name}
        {...rest}
        value={value}
        onChange={onChange}
        isValid={isValid}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const DateField = ({ fieldName, fieldTitle, validation, initialValue, ...props }) => {
    const dateValidation = { ...validation, date: true };

    return (
      <Field
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={dateValidation}
        component={componentBuilder}
        initialValue={initialValue || null}
        {...props}
      />
    );
  };

  DateField.propTypes = fieldPropTypes;

  return DateField;
}