import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './NonOrderable.module.scss';
import { useOnLanguageChanged } from 'utils/hooks';

const Closable = ({ children }) => {
  const elemRef = useRef();

  useEffect(() => open(elemRef));
  useOnLanguageChanged(() => closeAndRemove(elemRef));

  return (
    <div className={styles.closable} ref={elemRef}>
      {children}
    </div>
  );
};

Closable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Closable;

function closeAndRemove(elemRef) {
  const elem = elemRef.current;
  if (!elem)
    return;
  elem.style.maxHeight = 1 + elem.clientHeight + 'px';
  setTimeout(() => {
    const elem = elemRef.current;
    if (!elem)
      return;

    elem.classList.add(styles.closed);

    setTimeout(() => {
      const elem = elemRef.current;
      if (!elem)
        return;
      elem.style.display = 'none';
    }, +styles.closeTransition + 10);
  }, 30);
}

function open(elemRef) {
  const elem = elemRef.current;
  elem.style.display = '';
  elem.style.maxHeight = '';
  elem.classList.remove(styles.closed);
}
