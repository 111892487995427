import styles from '../PLP.module.scss';
import React, { useContext } from 'react';
import { RichText, useSanaTexts } from 'components/sanaText';
import { OfflinePriceWarning } from 'components/primitives/product';
import ProductLine from './Line';
import Schema from '../Schema';
import { ViewModes } from 'behavior/pages/productList';
import Paging from '../paging';
import { FacetsContext } from '../facets';
import { LastViewedProducts } from 'components/objects/lastViewed';
import withPageProps, { pagePropTypes } from '../withPageProps';
import { useAriaStatusNotification, useTracking } from '../hooks';
import { HeaderRow } from '../HeaderRow';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { connect } from 'react-redux';
import { ProductTrackingContext } from 'components/objects/analytics';
import { useMatchMediaContext } from 'components/responsive';

const Page = ({
  products,
  totalCount,
  selectedSorting,
  sortingEnabled,
  selectedViewMode,
  viewModeSwitchEnabled,
  pagingType,
  showThumbnails,
  id,
  lastViewedEnabled,
  relevanceAvailable,
  pageTitle,
  trackProductListView,
}) => {
  const { hasFacets, availableFacets } = useContext(FacetsContext);
  const [headerLbl] = useSanaTexts(['Aria_PLP_Header']).texts;
  const trackingSource = id === 'search' ? EVENT_SOURCES.searchResult : pageTitle;
  useTracking(products,
    ({ products }) => trackProductListView(products, trackingSource),
    [products],
  );
  const gridMode = selectedViewMode === ViewModes.Grid;
  const { xl } = useMatchMediaContext();

  useAriaStatusNotification(products, selectedViewMode, selectedSorting);

  if (!products.length) {
    return (
      <div className="msg-block">
        <RichText textKey="ProductList_NoResult" />
      </div>
    );
  }

  return (
    <>
      <Schema products={products} />
      <OfflinePriceWarning />
      <div className={styles.panel}>
        <header className={styles.panelHeader} aria-label={headerLbl}>
          <HeaderRow
            key={id}
            totalCount={totalCount}
            selectedSorting={selectedSorting}
            sortingEnabled={sortingEnabled}
            relevanceAvailable={relevanceAvailable}
            hasFacets={hasFacets}
            availableFacets={availableFacets}
            viewModeSwitchEnabled={viewModeSwitchEnabled}
            selectedViewMode={selectedViewMode}
          />
        </header>
        <div className={`${gridMode ? `${styles.gridMode} ` : ''}${styles.list}`} role="list">
          {products.map((product, index) => (
            <ProductTrackingContext.Provider key={product.id} value={{ product, trackingSource }}>
              <ProductLine
                product={product}
                gridMode={gridMode}
                showThumbnail={showThumbnails}
                index={index}
              />
            </ProductTrackingContext.Provider>
          ))}
        </div>
        <div className={styles.panelFooter}>
          <Paging pagingType={pagingType} totalCount={totalCount} />
        </div>
        {lastViewedEnabled && <LastViewedProducts maxSlidesToShow={xl ? 4 : 3} />}
      </div>
    </>
  );
};

Page.propTypes = pagePropTypes;

export default connect(state =>
  ({ pageTitle: state.page.metaTitle }),
  { trackProductListView },
)(withPageProps(Page));

