/**
 * @typedef {Object} PagingInfo
 * @property {number} nextPage - the next page index (zero based).
 * @property {number} nextPageCount - the next page count.
 * @property {number} itemsToLoad - the amount of items to load.
 */

/**
 * Gets paging info for the next page.
 * @param {Number} loadedItemsAmount - total amount of products already loaded.
 * @param {Number} totalCount - total amount of products.
 * @param {Number} pageSize - the page size.
 * @returns {PagingInfo} - the next page paging info.
 */

export function getNextPageInfo(loadedItemsAmount, pageSize, totalCount) {
  const immediateLoad = loadedItemsAmount !== totalCount && loadedItemsAmount % pageSize !== 0;
  const pageToLoad = Math.floor(loadedItemsAmount / pageSize);
  const nextPageCount = (pageToLoad + 1) * pageSize;
  const itemsToLoad = Math.min(nextPageCount, totalCount) - loadedItemsAmount;

  return {
    immediateLoad,
    pageToLoad,
    nextPageCount,
    itemsToLoad,
  };
}
