import styles from './Filter.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'components/primitives/grid';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { TextBoxField, DatePickerField, DropdownField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { DocumentType } from 'behavior/documents';
import { useSimpleTexts } from 'components/sanaText';
import { OrderAuthorizationStatus } from 'behavior/pages/document';

const minDate = new Date(2001, 0, 1);
const maxDate = new Date(2100, 11, 31);

const Filter = ({
  documentType,
  applyFilter,
  filter,
  showAuthorizationStatusFilter,
  branchCodes = [],
  assignedBranchCode = '',
}) => {
  const isChildDocument = childDocumentTypes.includes(documentType);

  const [
    orderIdTitle,
    documentIdTitle,
    authorizationStatusTitle,
    pendingTitle,
    approvedTitle,
    declinedTitle,
    fromDateTitle,
    toDateTitle,
    branchCodeTitle,
    orEDN
  ] = useSimpleTexts([
    'OrderNumber',
    isChildDocument ? 'DocumentNo' : documentIdTitleKeys[documentType],
    showAuthorizationStatusFilter && 'Orders_AuthorizationStatusFilter',
    showAuthorizationStatusFilter && 'OrderAuthorizationStatus_Pending',
    showAuthorizationStatusFilter && 'OrderAuthorizationStatus_Approved',
    showAuthorizationStatusFilter && 'OrderAuthorizationStatus_Declined',
    'From',
    'Orders_ToFilter',
    'BranchCode',
    'OrEDN'
  ]).texts;

  let authorizationStatuses;
  if (showAuthorizationStatusFilter) {
    authorizationStatuses = [
      { name: pendingTitle, value: OrderAuthorizationStatus.Pending },
      { name: approvedTitle, value: OrderAuthorizationStatus.Approved },
      { name: declinedTitle, value: OrderAuthorizationStatus.Declined },
    ];
  }

  const dateFieldProps = {
    fieldComponent: DatePickerField,
    minDate,
    maxDate,
    className: styles.datePickerRow,
  };

  const showBranchFilter = (!assignedBranchCode || assignedBranchCode === '') && branchCodes && branchCodes.length;
  if (!showBranchFilter && assignedBranchCode !== '') {
    filter.branchCode = assignedBranchCode;
  }

  const makeBranchCodeOptions = () => branchCodes.map(x => {
    return {
      name: x.description,
      value: x.code,
    };
  });

  return (
    <SanaForm
      name="Filter"
      initialValues={filter}
      onSubmit={applyFilter}
      className={styles.filterForm}
    >
      <Container>
        <Row>
          <Col xl={7}>
            <Row>
              <Col xs={12} md={6}>
                {(isChildDocument || showAuthorizationStatusFilter) &&
                  <FormGroup
                    fieldName="orderId"
                    fieldTitle={orderIdTitle}
                    fieldComponent={TextBoxField}
                    maxLength={50}
                  />
                }
                {!showAuthorizationStatusFilter && (
                  <FormGroup
                    fieldName="documentId"
                    fieldTitle={`${documentIdTitle} ${orEDN}`}
                    fieldComponent={TextBoxField}
                    maxLength={50}
                  />
                )}
                {showAuthorizationStatusFilter && (
                  <FormGroup
                    fieldName="authorizationStatus"
                    fieldTitle={authorizationStatusTitle}
                    fieldComponent={DropdownField}
                    items={authorizationStatuses}
                  />
                )}
                {showBranchFilter && (
                  <FormGroup
                    fieldName="branchCode"
                    fieldTitle={branchCodeTitle}
                    fieldComponent={DropdownField}
                    items={makeBranchCodeOptions()}
                  />
                )}
              </Col>
              <Col xs={12} md={5} xl={6}>
                <FormGroup
                  fieldName="orderDate.from"
                  fieldTitle={fromDateTitle}
                  {...dateFieldProps}
                />
                <FormGroup
                  fieldName="orderDate.to"
                  fieldTitle={toDateTitle}
                  {...dateFieldProps}
                />
              </Col>
              <Col xs={12}>
                <div className={`${formStyles.formRow} ${styles.submitRow}`}>
                  <SanaButton type="submit" textKey="Search" className={`${btnStyles.btnSmall} ${styles.btnSubmit}`} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SanaForm>
  );
};

Filter.propTypes = {
  documentType: PropTypes.string.isRequired,
  applyFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  showAuthorizationStatusFilter: PropTypes.bool,
  branchCodes: PropTypes.array,
};

export default Filter;

const documentIdTitleKeys = {
  [DocumentType.Order]: 'OrderNumber',
  [DocumentType.Quote]: 'QuoteNumber',
  [DocumentType.ReturnOrder]: 'ReturnOrderNumber',
};

const childDocumentTypes = [
  DocumentType.Invoice,
  DocumentType.ReturnReceipt,
  DocumentType.CreditNote,
  DocumentType.Shipment,
];
