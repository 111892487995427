import { ADDON_ACTION_PREFIX, EVENT_ACTION_PREFIX } from './actions';

export default function createMiddleware() {
  return _store => next => action => {
    if (action.type && action.type.startsWith(ADDON_ACTION_PREFIX)) {
      const eventAction = action.payload.action;
      if (eventAction.type && eventAction.type.startsWith(EVENT_ACTION_PREFIX)) {
        return next(eventAction);
      }
    }

    return next(action);
  };
}
