import styles from './Select.module.scss';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getPageScrollBarWidth } from 'scroll-lock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { joinClasses } from 'utils/helpers';
import scrollIntoView from 'scroll-into-view';
import { useOnChange, useComputedValue, useEffectOnChange } from 'utils/hooks';
import { SimpleText } from 'components/sanaText';

const placeholderIndex = -1;
const INPUT_DELAY = 1000;
const SECOND_CHAR_INPUT_DELAY = 250;

const Select = ({
  id,
  name,
  items,
  value: initialValue,
  className,
  optionClassName = '',
  onChange,
  placeholderTextKey = null,
  labelId,
  autoComplete = null,
  isInvalid,
  shouldReset,
}) => {
  const memoizedItems = useComputedValue(() => items, items);
  const ref = useRef();
  const listBoxRef = useRef();
  const pageScrollBarWidthRef = useRef(0);
  const userInputData = useUserInputDataRef();
  const optionsAreHoveredRef = useRef(false);
  const iterateOptions = iterationFunction => {
    let index = 0;
    for (const element of listBoxRef.current.children) {
      iterationFunction(element, index);
      index++;
    }
  };

  const showPlaceholder = !!placeholderTextKey;

  let [selectedIndex, setSelected] = useState(() => getInitialIndex(memoizedItems, initialValue, showPlaceholder));
  let [activeIndex, setActive] = useState(selectedIndex);

  const resetSelectedIndex = index => {
    selectedIndex = activeIndex = index;
    setSelected(index);
    setActive(index);
    listBoxRef.current.style.width = '';
  };

  const handleReset = () => {
    const initialIndex = getInitialIndex(memoizedItems, initialValue, showPlaceholder);
    resetSelectedIndex(initialIndex);
    clearTimeout(userInputData.inputTimeout);
    clearTimeout(userInputData.secondCharInputTimeout);
    userInputData.typedValue = '';
  };

  useOnChange(handleReset, memoizedItems, false);
  useOnChange(handleReset, [shouldReset], false);

  useEffectOnChange(() => {
    const initialIndex = getInitialIndex(memoizedItems, initialValue, showPlaceholder);
    if (selectedIndex === initialIndex)
      return;

    resetSelectedIndex(initialIndex);
  }, [initialValue]);

  const [isExpanded, setExpanded] = useState(false);

  useOnChange(() => {
    onChange && onChange(selectedIndex !== placeholderIndex ? memoizedItems[selectedIndex].value : null);
  }, [selectedIndex], false);

  useEffect(() => {
    iterateOptions((option, i) => option.value = memoizedItems[i].value);

    const { clientHeight, scrollHeight } = listBoxRef.current;
    if (scrollHeight === clientHeight) {
      if (!listBoxRef.current.classList.contains(styles.scrollable))
        return;

      listBoxRef.current.classList.remove(styles.scrollable);
      listBoxRef.current.style.paddingRight = '';
      iterateOptions((optionElement => optionElement.style.paddingRight = ''));
    } else {
      const pageScrollBarWidth = getPageScrollBarWidth();
      if (listBoxRef.current.classList.contains(styles.scrollable && pageScrollBarWidth.current === pageScrollBarWidth))
        return;

      if (pageScrollBarWidthRef.current !== pageScrollBarWidth)
        pageScrollBarWidthRef.current = pageScrollBarWidth;

      const optionPaddingRight = `calc(${styles.optionRightPadding}em - ${pageScrollBarWidthRef.current}px)`;
      listBoxRef.current.classList.add(styles.scrollable);
      listBoxRef.current.style.paddingRight = pageScrollBarWidthRef.current + 'px';
      iterateOptions((optionElement => optionElement.style.paddingRight = optionPaddingRight));
    }
  }, [memoizedItems]);

  useEffect(() => {
    iterateOptions((option, i) => option.selected = i === activeIndex);
  }, [activeIndex]);

  useOnChange(() => {
    if (!listBoxRef.current.classList.contains(styles.scrollable))
      return;

    if (isExpanded) {
      if (!listBoxRef.current.style.width)
        listBoxRef.current.style.width = `${ref.current.getBoundingClientRect().width}px`;

      listBoxRef.current.style.paddingRight = '';
    } else {
      const pageScrollBarWidth = getPageScrollBarWidth();
      if (pageScrollBarWidthRef.current !== pageScrollBarWidth)
        pageScrollBarWidthRef.current = pageScrollBarWidth;

      listBoxRef.current.style.paddingRight = pageScrollBarWidthRef.current + 'px';
    }
  }, [isExpanded], false);

  useEffect(() => {
    if (!isExpanded) {
      if (!listBoxRef.current.style.width)
        return;

      const handleResize = () => {
        listBoxRef.current.style.width = '';
        window.removeEventListener('resize', handleResize);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    const handleResize = () => void (setExpanded(false), listBoxRef.current.style.width = '');
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isExpanded]);

  useEffect(() => () => {
    clearTimeout(userInputData.inputTimeout);
    clearTimeout(userInputData.secondCharInputTimeout);
  }, []);

  const toggle = e => {
    e.preventDefault();
    ref.current.firstElementChild.focus();
    setExpanded(!isExpanded);
  };

  const select = index => {
    if (isExpanded)
      setExpanded(false);

    setSelected(index);
    setActive(index);
  };

  const handleKeyDown = e => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      if (isExpanded) {
        setExpanded(false);
        setSelected(activeIndex);
      }

      return;
    }

    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (isExpanded)
          select(activeIndex);
        else
          setExpanded(true);
        return;

      case ' ':
      case 'Spacebar':
        e.preventDefault();
        setExpanded(true);
        return;

      case 'ArrowUp':
      case 'Up':
        if (e.altKey) {
          toggle(e);
          return;
        }
        e.preventDefault();
        if (activeIndex > 0) {
          if (isExpanded) {
            const prevIndex = activeIndex - 1;
            setActive(prevIndex);
            scrollOptionIntoView(listBoxRef.current, prevIndex);
            if (optionsAreHoveredRef.current)
              handleKeyboardSelectionOnMouseOver(listBoxRef.current);
          } else
            select(selectedIndex - 1);
        }
        return;

      case 'ArrowDown':
      case 'Down':
        if (e.altKey) {
          toggle(e);
          return;
        }
        e.preventDefault();
        if (activeIndex < memoizedItems.length - 1) {
          if (isExpanded) {
            const nextIndex = activeIndex + 1;
            setActive(nextIndex);
            scrollOptionIntoView(listBoxRef.current, nextIndex);
            if (optionsAreHoveredRef.current)
              handleKeyboardSelectionOnMouseOver(listBoxRef.current);
          } else
            select(selectedIndex + 1);
        }
        return;

      case 'PageUp':
      case 'Home':
        e.preventDefault();
        if (isExpanded) {
          setActive(0);
          scrollOptionIntoView(listBoxRef.current, 0);
          if (optionsAreHoveredRef.current)
            handleKeyboardSelectionOnMouseOver(listBoxRef.current);
        } else
          select(0);
        return;

      case 'PageDown':
      case 'End':
        e.preventDefault();
        const lastIndex = memoizedItems.length - 1;
        if (isExpanded) {
          setActive(lastIndex);
          scrollOptionIntoView(listBoxRef.current, lastIndex);
          if (optionsAreHoveredRef.current)
            handleKeyboardSelectionOnMouseOver(listBoxRef.current);
        } else
          select(lastIndex);
        return;

      default:
        if (e.key.length > 1)
          return;

        clearTimeout(userInputData.inputTimeout);
        if (!userInputData.keyIsHolding)
          userInputData.typedValue += e.key.toLowerCase();

        const currentIndex = isExpanded ? activeIndex : selectedIndex;

        const clearTypedValue = () => {
          userInputData.inputTimeout = setTimeout(
            () => void (userInputData.typedValue = '', userInputData.inputTimeout = null),
            INPUT_DELAY,
          );
        };

        const handleUserInput = () => {
          let targetIndex = findMatchingOptionIndex(memoizedItems, userInputData.typedValue, currentIndex + 1);
          // If user typed two identical symbols and there are no options matched, treat typed chars as one char and rerun search.
          if (
            targetIndex === -1
            && userInputData.typedValue.length === 2
            && userInputData.typedValue[0] === userInputData.typedValue[1]
          ) {
            // Symbols repeating, so user is probably holding down some key, set appropriate flag
            userInputData.keyIsHolding = true;
            userInputData.typedValue = userInputData.typedValue[0];
            targetIndex = findMatchingOptionIndex(memoizedItems, userInputData.typedValue, currentIndex + 1);
          }

          if (targetIndex === -1) {
            clearTypedValue();
            return;
          }

          if (isExpanded) {
            setActive(targetIndex);
            scrollOptionIntoView(listBoxRef.current, targetIndex);
            if (optionsAreHoveredRef.current)
              handleKeyboardSelectionOnMouseOver(listBoxRef.current);
          }
          else
            select(targetIndex);

          clearTypedValue();
        };

        if (currentIndex !== placeholderIndex) {
          const currentName = memoizedItems[currentIndex].name;
          if (!userInputData.keyIsHolding && nameStartsWith(currentName, userInputData.typedValue)) {
            // When some option has been already selected or preselected, user typed one character and it matched selected
            // (or preselected) option, wait for SECOND_CHAR_INPUT_DELAY ms for user typing more characters, if there are no
            // additional characters - handle user input for one character, if they are present - handle whole user input.
            if (userInputData.typedValue.length === 1) {
              userInputData.secondCharInputTimeout = setTimeout(
                () => void (userInputData.secondCharInputTimeout = null, handleUserInput()),
                SECOND_CHAR_INPUT_DELAY,
              );
              return;
            }

            clearTimeout(userInputData.secondCharInputTimeout);
            userInputData.secondCharInputTimeout = null;
            clearTypedValue();
            return;
          }
        }

        // Do not handle user input if waiting for typing of second character was triggered.
        if (userInputData.secondCharInputTimeout)
          return;

        handleUserInput();
    }
  };

  const handleKeyUp = () => {
    userInputData.keyIsHolding = false;
  };

  const handleBlur = e => {
    const relatedTarget = e.relatedTarget || document.activeElement;
    if (ref.current.contains(relatedTarget))
      return;

    select(activeIndex);
  };

  const handleListBoxFocus = e => {
    // All browsers except Legacy MS Edge and IE11 keep focus on combobox element and do not set it on
    // listbox element when user interacts with its scrollbar (if present) in some way. This handler is used
    // to keep this behavior for above listed browsers to avoid various issues with select closing functionality.
    if (e.target !== listBoxRef.current)
      return;

    ref.current.firstElementChild.focus();
  };

  const handleMouseOver = e => {
    if (!optionsAreHoveredRef.current)
      optionsAreHoveredRef.current = true;

    if (e.target === e.currentTarget) {
      listBoxRef.current.classList.add(styles.hovered);
    } else {
      listBoxRef.current.classList.remove(styles.hovered);
    }
  };

  const handleMouseLeave = () => optionsAreHoveredRef.current = false;

  const handleClick = ({ target, currentTarget }) => {
    if (currentTarget === target)
      return;

    select(+target.dataset.index);
  };

  const rootElementId = `${id}_select`;
  const elementId = `${id}_listbox`;
  const activeItem = memoizedItems[activeIndex];

  const isPlaceholderIndex = activeIndex === placeholderIndex;
  const activeDescendant = `${elementId}_${activeIndex}`;
  const value = isPlaceholderIndex ? '' : activeItem ? activeItem.value : null;
  return (
    <span id={rootElementId} className={joinClasses(styles.select, className, isExpanded && 'expanded')} ref={ref}>
      <span
        id={id}
        className={styles.combobox}
        onMouseDown={toggle}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls={elementId}
        aria-owns={elementId}
        aria-expanded={isExpanded}
        aria-labelledby={labelId ? `${labelId} ${activeDescendant}` : activeDescendant}
        aria-activedescendant={isPlaceholderIndex ? null : activeDescendant}
        aria-keyshortcuts="Space Enter Alt+ArrowUp Alt+ArrowDown ArrowUp ArrowDown Escape PageUp PageDown Home End"
        aria-invalid={isInvalid ? true : null}
        tabIndex="0"
      >
        <span className={`${styles.label}${isPlaceholderIndex ? ` ${styles.placeholder}` : ''}`}
          aria-hidden={isExpanded}
          id={isPlaceholderIndex ? activeDescendant : null}
        >
          {isPlaceholderIndex
            ? showPlaceholder && <SimpleText textKey={placeholderTextKey} />
            : activeItem ? activeItem.name : value
          }
        </span>
        <span aria-hidden className={styles.arrow}><FontAwesomeIcon className={styles.icon} icon={faCaretDown} /></span>
      </span>
      {/* keyDown is handled on combobox element */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <span
        ref={listBoxRef}
        className={styles.options}
        id={elementId}
        role="listbox"
        aria-labelledby={id}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onMouseDown={e => e.preventDefault()}
        onFocus={handleListBoxFocus}
        onClick={handleClick}
        tabIndex="-1"
      >
        {memoizedItems.map((option, index) => (
          <span
            key={index}
            data-index={index}
            className={`${optionClassName}${index === activeIndex ? ' active' : ''}`}
            role="option"
            id={`${elementId}_${index}`}
            aria-selected={index === activeIndex}
            tabIndex="-1"
          >
            {option.name}
          </span>
        ))}
      </span>
      {(name != null || autoComplete) && autoComplete !== 'off' && (
        <input
          name={name}
          value={value || ''}
          onChange={e => {
            const newValue = e.target.value;
            const indexToSelect = memoizedItems.findIndex(i => i.value === newValue || i.name === newValue);
            select(indexToSelect);
          }}
          aria-hidden
          className="visually-hidden"
          autoComplete={autoComplete}
          tabIndex={-1}
        />
      )}
    </span>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  className: PropTypes.string,
  optionClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholderTextKey: PropTypes.string,
  labelId: PropTypes.string,
  autoComplete: PropTypes.string,
  isInvalid: PropTypes.bool,
  shouldReset: PropTypes.any,
};

const memoizedSelect = React.memo(Select);
memoizedSelect.displayName = 'Select';

export default memoizedSelect;

function useUserInputDataRef() {
  const userInputDataRef = useRef();

  if (!userInputDataRef.current) {
    userInputDataRef.current = {
      typedValue: '',
      inputTimeout: null,
      secondCharInputTimeout: null,
      keyIsHolding: false,
    };
  }

  return userInputDataRef.current;
}

function handleKeyboardSelectionOnMouseOver(optionsListBoxElement) {
  // Applies active styles for item selected from keyboard instead of hovered by pointer until pointer will not change its position.
  if (optionsListBoxElement.classList.contains(styles.keyboardSelectionActive))
    return;

  const handleMouseMove = () => {
    optionsListBoxElement.classList.remove(styles.keyboardSelectionActive);
    optionsListBoxElement.removeEventListener('mousemove', handleMouseMove);
    optionsListBoxElement.removeEventListener('mouseleave', handleMouseMove);
  };
  optionsListBoxElement.classList.add(styles.keyboardSelectionActive);
  optionsListBoxElement.addEventListener('mousemove', handleMouseMove);
  optionsListBoxElement.addEventListener('mouseleave', handleMouseMove);
}

function getInitialIndex(items, initialValue, displayPlaceholder) {
  const defaultIndex = displayPlaceholder ? placeholderIndex : 0;
  if (initialValue === '')
    return defaultIndex;

  const result = items.findIndex(i => i.value === initialValue);
  return result === -1 ? defaultIndex : result;
}

function scrollOptionIntoView(optionsListBoxElement, optionIndex) {
  if (!optionsListBoxElement.classList.contains(styles.scrollable))
    return;

  const targetOptionElement = optionsListBoxElement.children[optionIndex];
  scrollIntoView(targetOptionElement, {
    time: 0,
    isScrollable: target => {
      if (target !== optionsListBoxElement)
        return false;

      return target.scrollHeight !== target.clientHeight;
    },
  });
}

function nameStartsWith(name, string) {
  return name.toLowerCase().startsWith(string);
}

function findMatchingOptionIndex(items, typedString, startIndex = 0) {
  for (let index = startIndex; index < items.length; index++) {
    if (nameStartsWith(items[index].name, typedString))
      return index;
  }

  for (let index = 0; index < startIndex; index++) {
    if (nameStartsWith(items[index].name, typedString))
      return index;
  }

  return -1;
}
