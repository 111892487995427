import styles from './LoginInfo.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { LoginLink, WishlistLink } from 'components/primitives/links';
import { connect } from 'react-redux';
import { StoreType } from 'behavior/settings';

const LoginInfoAnonymous = ({ publicStore }) => {
  if (publicStore == null)
    return null;

  return (
    <>
      <LoginLink className={styles.item} />
      {publicStore && <WishlistLink className={styles.item} />}
    </>
  );
};

LoginInfoAnonymous.propTypes = {
  publicStore: PropTypes.bool,
};

export default connect(({ settings: { storeType } }) => ({ publicStore: storeType && storeType === StoreType.Public }))(LoginInfoAnonymous);