import styles from './ProductSelector.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const SuggestionItem = ({ text }) => (
  <span className={styles.suggestItem}>{text}</span>
);

SuggestionItem.propTypes = {
  text: PropTypes.node.isRequired,
};

export default SuggestionItem;
