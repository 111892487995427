import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getFontShorthand, measureText } from 'update-input-width';

/* eslint-disable jsx-a11y/no-autofocus */
const Input = ({
  ariaLabel,
  autoFocus,
  className,
  disabled,
  itemRef,
  max,
  min,
  id,
  name,
  nameForClass,
  onChange,
  onKeyDown,
  onKeyUp,
  placeholder = '--',
  required,
  step = 1,
  value,
  allowEmpty,
}) => {
  const maxLength = max.toString().length;
  const [displayValue, setDisplayValue] = useState(createDisplayValue(value, maxLength));
  const inputRef = useRef();
  const selectAfterFocusRef = useRef(false); // Required to apply same selection behavior in Legacy MS Edge as in other browsers.
  const selectionPresentRef = useRef(false);

  const handleFocus = event => {
    selectAfterFocusRef.current = true;
    handleTextSelection(event);
  };

  const handleBlur = () => selectAfterFocusRef.current = false;

  const handleClick = event => {
    if (!selectAfterFocusRef.current)
      return;

    selectAfterFocusRef.current = false;
    handleTextSelection(event);
  };

  const handleKeyDown = event => {
    selectionPresentRef.current = event.target.selectionStart !== event.target.selectionEnd;
    selectAfterFocusRef.current = false;
    const isArrowUpKey = event.key === 'ArrowUp' || event.key === 'Up';
    const isArrowDownKey = event.key === 'ArrowDown' || event.key === 'Down';
    if (isArrowUpKey || isArrowDownKey) {
      event.preventDefault();
      const value = +event.target.value;

      if (isArrowUpKey && value < max)
        onChange(null, name, value < min ? min : value + step);

      if (isArrowDownKey && value > min)
        onChange(null, name, value - step);
    }

    onKeyDown && onKeyDown(event);
  };

  const handleChange = event => {
    const targetValue = event.target.value.trim();
    if (targetValue === '' && displayValue === '')
      return;

    const value = +targetValue;

    if (
      isNaN(value)
      || targetValue.length > maxLength
      // Additional check for Android devices with Samsung virtual keyboard which ignores maxlength attribute on input and randomly
      // replaces already typed digits if cursor been set to fully filled input. Added for consistent behavior across all devices/browsers.
      || !selectionPresentRef.current && displayValue && event.target.value.length === maxLength && displayValue.length === maxLength
    ) {
      selectionPresentRef.current = false;
      event.target.value = displayValue;
      return;
    }

    selectionPresentRef.current = false;
    setDisplayValue(targetValue);

    onChange && onChange(event);
  };

  const inputClassName = `${className}__input ${className}__${nameForClass || name}`;

  useEffect(() => {
    if (itemRef)
      itemRef(inputRef.current, name);

    updateInputWidth(inputRef.current);
    if (!document.fonts)
      return;

    const font = getFontShorthand(inputRef.current);
    if (!font)
      return;

    const isFontLoaded = document.fonts.check(font);
    if (isFontLoaded)
      return;

    const fontLoadingDoneHandler = updateInputWidth.bind(null, inputRef.current);
    document.fonts.addEventListener('loadingdone', fontLoadingDoneHandler);

    return () => {
      document.fonts.removeEventListener('loadingdone', fontLoadingDoneHandler);
    };
  }, []);

  useEffect(() => {
    if (value === +inputRef.current.value)
      return;

    setDisplayValue(createDisplayValue(value, maxLength));
  }, [value]);

  useEffect(() => {
    setInputValidity(inputRef.current, displayValue, min, max, allowEmpty);
    updateInputWidth(inputRef.current);
  }, [displayValue, min, max, allowEmpty]);

  useEffect(() => {
    updateInputWidth(inputRef.current);
  }, [placeholder]);

  return (
    <input
      key="input"
      aria-label={ariaLabel}
      autoComplete="off"
      autoFocus={autoFocus}
      className={inputClassName}
      disabled={disabled}
      maxLength={maxLength}
      name={name}
      id={id}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={onKeyUp}
      onClick={handleClick}
      onCut={preventDefault}
      onPaste={preventDefault}
      onDragStart={preventDefault}
      placeholder={placeholder}
      ref={inputRef}
      required={required}
      type="text"
      data-type="number"
      value={displayValue}
    />
  );
};

Input.propTypes = {
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  nameForClass: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
  allowEmpty: PropTypes.bool,
};

export default Input;

function handleTextSelection(event) {
  event.target.select();
}

function updateInputWidth(element) {
  const font = getFontShorthand(element);
  const text = element.value || element.placeholder;
  const width = measureText(text, font);

  if (width === null) {
    return null;
  }
  // Width should be equal to computed width + 3px to fix non-smooth increase of input width and Legacy MS Edge inputs rendering issues.
  element.style.width = `${width + 3}px`;
  return width;
}

function setInputValidity(inputElement, value, min, max, allowEmpty) {
  const isValueEmpty = value == null || value === '';

  if (allowEmpty && isValueEmpty) {
    setInputAsValid(inputElement);
    return;
  }

  if (isValueEmpty || value < min)
    setInputAsInvalid(inputElement, 'Value is below specified range.');
  else if (value > max)
    setInputAsInvalid(inputElement, 'Value is under specified range.');
  else
    setInputAsValid(inputElement);
}

function setInputAsValid(inputElement) {
  inputElement.setCustomValidity('');
  inputElement.removeAttribute('aria-invalid');
}

function setInputAsInvalid(inputElement, errorMessage) {
  inputElement.setCustomValidity(errorMessage);
  inputElement.setAttribute('aria-invalid', 'true');
}

function preventDefault(event) {
  event.preventDefault();
}

function createDisplayValue(value, maxLength) {
  if (!value)
    return '';

  let prefix = '';
  value = value.toString();
  for (let i = maxLength - value.length; i > 0; i--)
    prefix += '0';

  return prefix + value;
}