import styles from './ContinuousScroll.module.scss';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getViewPortIntersection } from 'utils/helpers';
import { SimpleText } from 'components/sanaText';
import Spinner from 'components/primitives/spinner/Spinner';

const ContinuousScroll = ({ updateInterval = 500, onVisibleUpdated }) => {
  const loadingElementRef = useRef(null);

  useEffect(() => {
    const updateVisibility = () => {
      const { partial } = getViewPortIntersection(loadingElementRef.current, window, 300);
      onVisibleUpdated(partial);
    };

    const intervalId = setInterval(updateVisibility, updateInterval);
    return () => clearInterval(intervalId);
  }, [updateInterval]);

  return (
    <div ref={loadingElementRef} className={styles.loadingIndicator}>
      <span className={styles.loader}><Spinner /></span>
      <span><SimpleText textKey="Loading" /></span>
    </div>
  );
};

ContinuousScroll.propTypes = {
  updateInterval: PropTypes.number,
  onVisibleUpdated: PropTypes.func.isRequired,
};

export default React.memo(ContinuousScroll);