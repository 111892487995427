import styles from '../../Order.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { loadQuoteStock, promoteQuote } from 'behavior/pages/document';
import { CustomerTypes } from 'behavior/user';
import { useSanaButton, Button } from 'components/primitives/buttons';
import {
  PromotionType,
  DocumentType,
  isDocumentPromotableQuote,
  PromoteQuoteStatus,
} from 'behavior/documents';
import { formatText } from 'utils/formatting';
import { useSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import useConfirmation from 'components/objects/confirmation';
import { makeRichText } from 'utils/render';
import CreditLimitExceededMessage from './CreditLimitExceededMessage';

const PromoteButton = ({ quote, documentNotifications }) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const dispatch = useDispatch();
  const {
    checkStock,
    promotionType,
    isImpersonationRequired,
    promotionResult,
  } = useSelector(optionsSelector, shallowEqual);

  const texts = useTexts(quote, isImpersonationRequired);
  const [promotionPending, setPromotionPending] = useState(false);

  const { id: quoteInternalId, hasStock } = quote;
  const promoteWithCheckout = promotionType === PromotionType.WithCheckout;
  const disabled = !isDocumentPromotableQuote(quote, DocumentType.Quote);
  const promotionStatus = promotionResult && promotionResult.status;

  const ignoreWarningsRef = useRef(false);

  const showPopup = (titleKey, description, className) => {
    const popupPromoteButtonHandler = () => {
      dispatchPromoteQuote(ignoreWarningsRef.current);
      clearConfirmation();
    };

    showConfirmation(
      popupPromoteButtonHandler,
      titleKey,
      description,
      {
        okText: 'ButtonText_Continue',
        okTextAlt: 'ButtonAltText_Continue',
      },
      undefined,
      className,
    );
  };

  useEffect(() => {
    if (disabled)
      return;

    if (promoteWithCheckout && hasStock === undefined)
      dispatch(loadQuoteStock(quoteInternalId));
  }, [quote, promoteWithCheckout, disabled]);

  useEffect(() => {
    if (!promotionResult)
      return;

    setPromotionPending(false);

    if (promotionResult.error) {
      documentNotifications.cannotBePromoted();
      return;
    }

    switch (promotionStatus) {
      case PromoteQuoteStatus.ContainsNonOrderableProducts:
      case PromoteQuoteStatus.OutOfStock:
        documentNotifications.cannotBePromoted();
        break;
      case PromoteQuoteStatus.CreditLimitError:
        documentNotifications.creditLimitExceeded();
        break;
      case PromoteQuoteStatus.CreditLimitForceOnlinePayment:
      case PromoteQuoteStatus.CreditLimitWarning:
        ignoreWarningsRef.current = true;
        showPopup('Info', <CreditLimitExceededMessage isWarning />, styles.creditLimitWarning);
        break;
      default:
        clearConfirmation();
        return;
    }
  }, [promotionResult]);

  if (!texts.loaded || (!disabled && promoteWithCheckout && hasStock === undefined))
    return <Placeholder className={styles.btnActionPlaceholder} />;

  const dispatchPromoteQuote = ignoreCreditLimitWarnings => {
    dispatch(promoteQuote(quoteInternalId, ignoreCreditLimitWarnings));
    setPromotionPending(true);
  };

  const promoteButtonHandler = () => {
    ignoreWarningsRef.current = false;
    documentNotifications.hidePromoteNotifications();

    if (!promoteWithCheckout)
      showPopup('QuotePromotionConfirmationPopup_Title', makeRichText(texts.confirmationDescription), styles.promotionConfirmationPopup);
    else if (hasStock)
      dispatchPromoteQuote(false);
    else if (checkStock)
      setTimeout(() => documentNotifications.cannotBePromoted(), 100);
    else
      showPopup('Info', makeRichText(texts.insufficientQuantityDescription), styles.insufficientQuantityPopup);
  };

  return (
    <>
      <Button
        title={texts.promoteTitle}
        disabled={disabled || promotionPending}
        className={`${btnStyles.btn} ${btnStyles.btnAction} ${btnStyles.btnSmall} ${styles.btnAction} ${styles.btnPromote}`}
        onClick={promoteButtonHandler}
      >
        {texts.promoteText}
      </Button>
      {confirmationModal}
    </>
  );
};

PromoteButton.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.string.isRequired,
    hasStock: PropTypes.bool,
    sellToAddress: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  documentNotifications: PropTypes.shape({
    cannotBePromoted: PropTypes.func.isRequired,
    creditLimitExceeded: PropTypes.func.isRequired,
    hidePromoteNotifications: PropTypes.func.isRequired,
  }),
};

export default PromoteButton;

function optionsSelector({ settings, user, page }) {
  const promotionType = settings.documents.promotionType;
  const isImpersonationRequired = promotionType === PromotionType.WithCheckout
    && user.shopAccountType === CustomerTypes.Sales && !user.isImpersonating;

  return {
    checkStock: settings.product.checkStock,
    promotionType,
    isImpersonationRequired,
    promotionResult: page.promotionResult,
  };
}

function useTexts(quote, isImpersonationRequired) {
  const [promoteText, promoteTitle] = useSanaButton(isImpersonationRequired ? 'RepresentAndPromoteToOrder' : 'PromoteToOrder').texts;

  const {
    texts: [
      confirmationDescription,
      insufficientQuantityDescription,
    ],
    loaded,
  } = useSanaTexts([
    'QuotePromotionConfirmationPopup_Description',
    'ProductsInInsufficientQuantity',
  ]);

  return {
    promoteText,
    promoteTitle: isImpersonationRequired
      ? formatText(promoteTitle, [quote.sellToAddress && quote.sellToAddress.name])
      : promoteTitle,
    confirmationDescription,
    insufficientQuantityDescription,
    loaded,
  };
}