export const visualDesignerReady = () => ({
  type: 'VD/WEBSTORE/READY',
});

export const updateHeight = height => ({
  type: 'VD/WEBSTORE/UPDATE_HEIGHT',
  payload: { height },
});

export const addColumn = index => ({
  type: 'VD/ADD_COLUMN',
  payload: { index },
});

export const deleteColumn = id => ({
  type: 'VD/DELETE_COLUMN',
  payload: { id },
});

export const duplicateColumn = id => ({
  type: 'VD/DUPLICATE_COLUMN',
  payload: { id },
});

export const editColumnSettings = id => ({
  type: 'VD/EDIT_COLUMN_SETTINGS',
  payload: { id },
});

export const addRow = rowInfo => ({
  type: 'VD/ADD_ROW',
  payload: { rowInfo },
});

export const deleteRow = index => ({
  type: 'VD/DELETE_ROW',
  payload: { index },
});

export const moveRow = (indexBefore, indexAfter) => ({
  type: 'VD/MOVE_ROW',
  payload: {
    indexBefore,
    indexAfter,
  },
});

export const duplicateRow = index => ({
  type: 'VD/DUPLICATE_ROW',
  payload: { index },
});

export const editRowSettings = (rowIndex, activeTabId) => ({
  type: 'VD/EDIT_ROW_SETTINGS',
  payload: { rowIndex, activeTabId },
});

export const setRowLayout = rowInfo => ({
  type: 'VD/SET_ROW_LAYOUT',
  payload: { rowInfo },
});

export const addContentBlock = (id, blockInfo) => ({
  type: 'VD/ADD_CONTENT_BLOCK',
  payload: { id, blockInfo },
});

export const editContentBlock = id => ({
  type: 'VD/EDIT_CONTENT_BLOCK',
  payload: { id },
});

export const deleteContentBlock = id => ({
  type: 'VD/DELETE_CONTENT_BLOCK',
  payload: { id },
});

export const saveContentBlock = (id, blockInfo) => ({
  type: 'VD/SAVE_CONTENT_BLOCK',
  payload: { id, blockInfo },
});

export const moveContentElement = (indexBefore, indexAfter, sourceElementId, targetElementId) => ({
  type: 'VD/MOVE_CONTENT_ELEMENT',
  payload: {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  },
});

export const UPDATE_VIEW_PAGE = 'VD/UPDATE_PAGE';
export const UPDATE_TITLE = 'VD/UPDATE_TITLE';

export const modifyPage = modifiedProps => ({
  type: 'VD/MODIFY_PAGE',
  payload: { modifiedProps },
});

export const SET_PAGE_ERRORS = 'VD/SET_PAGE_ERRORS';
export const setPageErrors = pageErrors => {
  return {
    type: SET_PAGE_ERRORS,
    payload: { pageErrors },
  };
};

export const INSITE_EDITOR_SANA_TEXTS_REQUESTED = 'VD/SANA_TEXTS_REQUESTED';
export const sanaTextRequested = textKeys => ({
  type: INSITE_EDITOR_SANA_TEXTS_REQUESTED,
  payload: { textKeys },
});

export const INSITE_EDITOR_SET_TEXT_ONLY_MODE = 'VD/SET_TEXT_ONLY_MODE';
export const setTextOnlyMode = textKeys => ({
  type: INSITE_EDITOR_SET_TEXT_ONLY_MODE,
  payload: { textKeys },
});

export const INSITE_EDITOR_SAVE_SANA_TEXT = 'VD/SAVE_SANA_TEXT';
export const saveSanaText = (languageId, { key, text }) => ({
  type: INSITE_EDITOR_SAVE_SANA_TEXT,
  payload: { languageId, sanaText: { key, text } },
});

export const INSITE_EDITOR_REVERT_SANA_TEXT = 'VD/REVERT_SANA_TEXT';
export const revertSanaText = (languageId, key) => ({
  type: INSITE_EDITOR_REVERT_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_DELETE_SANA_TEXT = 'VD/DELETE_SANA_TEXT';
export const deleteSanaText = (languageId, key) => ({
  type: INSITE_EDITOR_DELETE_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_EDIT_SANA_TEXT = 'VD/EDIT_SANA_TEXT';
export const editSanaText = (languageId, key, isCustom, isEmptyText, isTextOnlyMode) => ({
  type: INSITE_EDITOR_EDIT_SANA_TEXT,
  payload: { key, languageId, isCustom, isEmptyText, isTextOnlyMode },
});

export const INSITE_EDITOR_SANA_TEXTS_LOADED = 'VD/SANA_TEXTS_LOADED';
export const sanaTextsLoaded = (languageId, textKeys) => ({
  type: INSITE_EDITOR_SANA_TEXTS_LOADED,
  payload: { languageId, textKeys },
});

export const INSITE_EDITOR_LANGUAGE_CHANGED = 'VD/INSITE_EDITOR_LANGUAGE_CHANGED';
export const languageChanged = languageId => ({
  type: INSITE_EDITOR_LANGUAGE_CHANGED,
  payload: { languageId },
});

export const INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS = 'VD/INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS';
export const addConstructedTexts = texts => ({
  type: INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS,
  payload: { texts },
});

export const INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT = 'VD/INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT';
export const updateConstructedText = ({ key, deleted }) => ({
  type: INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT,
  payload: { text: { key, deleted } },
});

export const FLUSH_VISUAL_DESIGNER = 'VD/FLUSH_DATA';

export const visualDesignerFlushed = () => ({
  type: 'VD/DATA_FLUSHED',
});

export const emitVisualDesignerEvent = (eventType, eventPayload) => ({
  type: 'VD/EMIT_EVENT',
  payload: {
    eventType,
    eventPayload,
  },
});

export const requestVisualDesignerDelete = (title, callbackAction, frontendCallbackAction) => ({
  type: 'VD/DELETE_REQUESTED',
  payload: { title, callbackAction, frontendCallbackAction },
});

export const VISUAL_DESIGNER_TEXTS_RECEIVED = 'VD/TEXTS_RECEIVED';
export const VISUAL_DESIGNER_LANGUAGE_CHANGED = 'VD/LANGUAGE_CHANGED';
export const SET_CONTENT_BLOCKS_INFO = 'VD/SET_CONTENT_BLOCKS_INFO';
export const SET_SETTINGS = 'VD/SET_SETTINGS';
export const SET_SANA_TEXTS_INFO = 'VD/SET_SANA_TEXTS_INFO';
export const SANA_TEXT_REVERT_COMPLETED = 'VD/SANA_TEXT_REVERT_COMPLETED';
export const SANA_TEXT_DELETE_COMPLETED = 'VD/SANA_TEXT_DELETE_COMPLETED';
export const SANA_TEXT_CHANGED = 'VD/SANA_TEXT_CHANGED';
export const sanaTextChanged = ({ key, text }) => ({
  type: SANA_TEXT_CHANGED,
  payload: { key, text },
});

export const VISUAL_DESIGNER_LOADED = 'APP/VISUAL_DESIGNER/LOADED';
export const visualDesignerLoaded = () => ({
  type: VISUAL_DESIGNER_LOADED,
});

export const INSITE_EDITOR_LOADED = 'APP/INSITE_EDITOR/LOADED';
export const insiteEditorLoaded = () => ({
  type: INSITE_EDITOR_LOADED,
});

export const VISUAL_DESIGNER_RESET_FLUSH_DATA_INVOCATION = 'APP/VISUAL_DESIGNER/FLUSH_DATA';
export const resetFlushDataInvocation = () => ({
  type: VISUAL_DESIGNER_RESET_FLUSH_DATA_INVOCATION,
});

export const VISUAL_DESIGNER_PREVIEW_ADD_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_ADD_ROW';
export const previewAddRow = (index, id) => ({
  type: VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_MOVE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_MOVE_ROW';
export const previewMoveRow = (indexBefore, indexAfter) => ({
  type: VISUAL_DESIGNER_PREVIEW_MOVE_ROW,
  payload: {
    indexBefore,
    indexAfter,
  },
});

export const VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DUPLICATE_ROW';
export const previewDuplicateRow = (index, id) => ({
  type: VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  payload: { index, id },
});

export const VISUAL_DESIGNER_PREVIEW_DELETE_ROW = 'APP/VISUAL_DESIGNER/PREVIEW_DELETE_ROW';
export const previewDeleteRow = index => ({
  type: VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  payload: { index },
});

export const VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT = 'APP/VISUAL_DESIGNER/PREVIEW_MOVE_CONTENT_ELEMENT';
export const previewMoveContentElement = (indexBefore, indexAfter, sourceElementId, targetElementId) => ({
  type: VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
  payload: {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  },
});