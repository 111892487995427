import styles from './LoadingIndicator.module.scss';
import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

let animationTimeoutId;

const LoadingIndicator = ({ isLoading }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isLoading == null)
      return;

    if (isLoading) {
      clearTimeout(animationTimeoutId);
      ref.current.style.opacity = 1;
      ref.current.classList.add(styles.animate);
    } else {
      animationTimeoutId = setTimeout(() => {
        ref.current.style.opacity = 0;
        ref.current.classList.remove(styles.animate);
      }, 500);
    }
  }, [isLoading]);

  useEffect(() => () => clearTimeout(animationTimeoutId), []);

  return (
    <div role="progressbar" style={{ opacity: 0 }} ref={ref} className={styles.loadingIndicator}>
      <div className={styles.indicatorLine} />
    </div>
  );
};

LoadingIndicator.propTypes = {
  isLoading: PropTypes.bool,
};

export default connect(({ isLoading }) => ({ isLoading }))(LoadingIndicator);