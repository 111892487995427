import styles from './SalesAgreements.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';
import { toLocaleDate, getCurrency } from 'utils/format';
import { renderHTML } from 'utils/render';
import { applyAgreement, cancelAgreement } from 'behavior/salesAgreements/actions';
import { connect } from 'react-redux';

const AgreementDetails = ({ agreement, culture, selectedAgreementId, dispatch }) => {

  const apply = itemId => dispatch(applyAgreement(itemId));
  const cancel = itemId => dispatch(cancelAgreement(itemId));
  const isSelected = selectedAgreementId === agreement.id;

  const isCompleted = useMemo(() => {
    if (!agreement || !agreement.lines || !agreement.lines.length)
      return null;
    for (const line of agreement.lines) {
      if (line.quantities.remaining > 0)
        return false;
      if (line.amounts.remaining > 0)
        return false;
    }
    return true;
  }, [agreement]);

  return (
    <>
      <Row className={styles.details}>
        <Col xs={12} sm={8} xl={7}>
          <table className={`${tableStyles.table} ${tableStyles.nameValue}`}>
            <tbody>
              <tr>
                <th scope="row"><SimpleText textKey="Id" /></th>
                <td>{agreement.id}</td>
              </tr>
              <tr>
                <th scope="row"><SimpleText textKey="Status" /></th>
                <td><SimpleText textKey={'Status_' + agreement.status} /></td>
              </tr>
              {isCompleted != null &&
                <tr className={styles.completed}>
                  <th scope="row"><SimpleText textKey="CompletedFieldName" /></th>
                  <td><SimpleText textKey={isCompleted ? 'Yes' : 'No'} /></td>
                </tr>}
              {agreement.effectiveDate &&
                <tr className={styles.effectiveDate}>
                  <th scope="row"><SimpleText textKey="EffectiveDate" /></th>
                  <td>{toLocaleDate(agreement.effectiveDate, culture)}</td>
                </tr>}
              {agreement.expirationDate &&
                <tr className={styles.expirationDate}>
                  <th scope="row"><SimpleText textKey="ExpirationDate" /></th>
                  <td>{toLocaleDate(agreement.expirationDate, culture)}</td>
                </tr>}
              <tr className={styles.currencyId}>
                <th scope="row"><SimpleText textKey="CurrencyField" /></th>
                <td>{getCurrency(agreement.currency.id, agreement.currency.cultureName)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        {agreement.address && (
          <Col xs={12} sm={4} xl={3} className={styles.shippingAddress}>
            <h2 className={`h4 ${styles.shipToAddressTitle}`}><SimpleText textKey="ShipToAddress" /></h2>
            {renderHTML(agreement.address)}
          </Col>
        )}
      </Row>
      {agreement.status === 'Active' &&
        <Row>
          <Col className={styles.action} xs={12} sm={8} xl={7}>
            {isSelected
              ? <SanaButton textKey="Cancel" className={`${btnStyles.btnSmall}`} onClick={() => cancel(agreement.id)} />
              : <SanaButton textKey="Apply" className={`${btnStyles.btnSmall} ${btnStyles.btnAction}`} onClick={() => apply(agreement.id)} />
            }
          </Col>
        </Row>
      }
    </>
  );
};

AgreementDetails.propTypes = {
  agreement: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lines: PropTypes.arrayOf(PropTypes.shape({
      quantities: PropTypes.shape({ remaining: PropTypes.number }).isRequired,
      amounts: PropTypes.shape({ remaining: PropTypes.number }).isRequired,
    }).isRequired),
    status: PropTypes.string.isRequired,
    effectiveDate: PropTypes.any,
    expirationDate: PropTypes.any,
    currency: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    address: PropTypes.string,
  }),
  culture: PropTypes.string,
  selectedAgreementId: PropTypes.string,
  dispatch: PropTypes.func,
};

export default connect(({ salesAgreements }) => ({
  selectedAgreementId: salesAgreements.appliedSalesAgreementId,
}))(AgreementDetails);