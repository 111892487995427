import styles from '../BasketFooter.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import TotalsLine from './TotalsLine';

const TaxLine = ({ amount, taxes, detailed = true }) => {
  if (!detailed)
    return <TotalsLine textKey="TotalVAT" value={amount} className={styles.taxAmount} />;

  if (!taxes)
    return null;

  return taxes.map((tax, i) => <TotalsLine label={tax.description} value={tax.amount} key={i} className={styles.taxLine} />);
};

TaxLine.propTypes = {
  amount: PropTypes.number,
  taxes: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
    }),
  ),
  detailed: PropTypes.bool,
};

export default TaxLine;
