import styles from './PageTemplate.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import HeaderContent from './HeaderContent';
import { PageContent } from 'components/objects/pages';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Facets, FacetsProvider, facetUrlBuilder } from '../facets';
import { useIsMobileViewport } from 'utils/hooks';
import { plpId } from '../constants';

const PageTemplate = ({ facets, id, isEmpty, headerContent, footerContent, children }) => {
  const isMobile = useIsMobileViewport();
  const { texts: [leftColumnProductList], loaded } = useSanaTexts(['LeftColumn_ProductList'], makeSimpleText);
  const shouldHideLeftColumn = isEmpty && loaded && !leftColumnProductList;

  const content = (
    <Container className={`${styles.plp} ${shouldHideLeftColumn ? styles.hideLeftColumn : ''}`}>
      <Row>
        <Col xs={12} md={9} id={plpId} className={styles.productList}>
          {children}
        </Col>
        {isMobile
          ? <Col>{leftColumnProductList}</Col>
          : (
            <Col md={{ size: 3, order: 'first' }} className={styles.leftColumn}>
              <Facets key={id} />
              <div>{leftColumnProductList}</div>
            </Col>
          )}
      </Row>
    </Container>
  );
  const renderFacets = !!facets && facets.facets.length > 0;

  return (
    <>
      {renderFacets
        ? (
          <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}>
            <HeaderContent headerContent={headerContent} />
            {content}
          </FacetsProvider>
        )
        : (
          <>
            <HeaderContent headerContent={headerContent} />
            {content}
          </>
        )}
      <PageContent content={footerContent} position="footer" />
    </>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  facets: PropTypes.object,
  headerContent: PropTypes.any,
  footerContent: PropTypes.array,
  id: PropTypes.string,
  isEmpty: PropTypes.bool,
};

const memoizedPageTemplate = React.memo(PageTemplate);

memoizedPageTemplate.selectProps = (({
  facets,
  id,
  headerContent,
  footerContent,
  products,
}) => ({
  facets,
  id,
  headerContent,
  footerContent,
  isEmpty: !products.length,
})
);

export default memoizedPageTemplate;