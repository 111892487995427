import { Subject, asyncScheduler } from 'rxjs';
import { share, observeOn } from 'rxjs/operators';
import { normalizeAddonId } from './helpers';

export default function createRegistry() {
  const entries = new Map();
  const events = new Subject();
  const updates = events.pipe(
    observeOn(asyncScheduler),
    share(),
  );

  return {
    get(id, hash) {
      const entry = entries.get(normalizeAddonId(id));
      if (hash === undefined)
        return entry && entry.addon;

      return entry && entry.hash === hash ? entry.addon : undefined;
    },
    getEntries() {
      return [...entries.entries()]
        .map(([id, { hash, addon }]) => ({ id, hash, addon }));
    },
    add(id, hash, addon) {
      const addonId = normalizeAddonId(id);

      entries.set(addonId, { hash, addon });
      events.next({ id: addonId, hash, addon });
    },
    get updates() {
      return updates;
    },
    getIds() {
      return [...entries.keys()];
    },
  };
}
