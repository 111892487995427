import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import BackStepLink from './BackStepLink';
import CheckoutContext from './CheckoutContext';
import OverviewStepBase, { OverviewStepBody } from '../base/OverviewStep';

const OverviewStep = ({ className, isActive, info }) => {
  const showFooter = useRef(!info.productLines.list).current;
  const additionalInfoFormikRef = useRef();
  const termsAndAgreementsFormikRef = useRef();

  return (
    <OverviewStepBase className={className} isQuote={info.isQuote || !!info.quote}>
      {isActive &&
        <OverviewStepBody
          info={info}
          context={CheckoutContext}
          extraButton={<BackStepLink />}
          showFooter={showFooter}
          summaryOnMobileOnly
          saveOnSubmitOnly
          additionalInfoFormikRef={additionalInfoFormikRef}
          termsAndAgreementsFormikRef={termsAndAgreementsFormikRef}
        />
      }
    </OverviewStepBase>
  );
};

OverviewStep.propTypes = {
  className: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    productLines: PropTypes.shape({
      list: PropTypes.any,
    }).isRequired,
    quote: PropTypes.object,
  }).isRequired,
};

export default OverviewStep;
