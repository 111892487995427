import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ToastProvider as ToastProviderBase } from 'react-toast-notifications';
import Toast from './Toast';
import { onAdd, toasts } from 'behavior/toasts';
import { useIsMobileViewport } from 'utils/hooks';
import { useLayoutShifter } from 'utils/layout';
import { TRANSITION_DURATION } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { createErrorToast } from 'components/objects/error';
import { closeErrorToast, errorToasts } from 'behavior/errorHandling';
import { PageComponentNames } from 'behavior/pages';

class ExtendedToastProvider extends ToastProviderBase {
  constructor(props) {
    super(props);
    onAdd(this.add);

    if (!props.methodsRef.current) {
      props.methodsRef.current = {
        remove: this.remove,
      };
    }
  }

  remove = (id, callback) => {
    if (!this.has(id))
      return;

    this.setState(state => {
      return { toasts: state.toasts.filter(t => t.id !== id) };
    }, callback);
  };
}

const ToastProvider = ({ children }) => {
  const isMobile = useIsMobileViewport();
  const { bottomFixedElementsHeight } = useLayoutShifter();
  const dispatch = useDispatch();
  const methodsRef = useRef();
  const errorToast = useSelector(({ error }) => error.toast);
  const isErrorPage = useSelector(({ routing, page }) => routing.navigatingTo ? '' : page.component === PageComponentNames.Error);

  useEffect(() => {
    const toastsContainer = document.querySelector('.react-toast-notifications__container');
    if (!toastsContainer)
      return;

    toastsContainer.style.marginBottom = isMobile ? bottomFixedElementsHeight + 'px' : 0;
  }, [isMobile, bottomFixedElementsHeight]);

  useEffect(() => {
    if (!errorToast)
      return;

    const id = getErrorId(errorToast.type);
    toasts.errorDialog(
      createErrorToast(id, errorToast.type, errorToast.data),
      {
        id,
        autoDismiss: isErrorPage,
        onDismiss: () => dispatch(closeErrorToast(errorToast.type)),
      },
    );
  }, [errorToast]);

  useEffect(() => {
    if (isErrorPage) {
      methodsRef.current.remove(getErrorId(errorToasts.Reload), () => dispatch(closeErrorToast(errorToasts.Reload)));
      methodsRef.current.remove(getErrorId(errorToasts.Retry), () => dispatch(closeErrorToast(errorToasts.Retry)));
    }
  }, [isErrorPage]);

  return useMemo(() => (
    <ExtendedToastProvider
      methodsRef={methodsRef}
      autoDismiss
      autoDismissTimeout={10000}
      transitionDuration={TRANSITION_DURATION}
      components={{ Toast }}
      placement={isMobile ? 'bottom-center' : 'top-right'}
    >
      {children}
    </ExtendedToastProvider>
  ), [isMobile, children]);
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(ToastProvider);

const getErrorId = type => 'error-' + type;
