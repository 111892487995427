import styles from './NewsOverview.module.scss';
import React from 'react';
import { useLoadEffect } from 'utils/hooks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Heading from 'components/primitives/headings/Heading';
import SidebarNewsItem from './SidebarNewsItem';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';
import { loadNewsHeadlines } from 'behavior/newsHeadlines';

const SidebarNews = ({ news, loaded, loadNewsHeadlines }) => {
  useLoadEffect(() => {
    loaded || loadNewsHeadlines(5);
  });

  if (!news || !news.length)
    return null;

  return (
    <nav className={styles.headlines} aria-labelledby="headlinesHeader">
      <Heading className={styles.sideHeader}>
        <h3 id="headlinesHeader"><SimpleText textKey="Newslist_Title" /></h3>
      </Heading>
      <ul className={styles.sideList}>
        {
          news.map(item =>
            (<SidebarNewsItem key={item.id} {...item} />),
          )
        }
      </ul>
      <Link
        to={routesBuilder.forNewsOverview}
        className={styles.linkMore}
      >
        <SimpleText textKey="News_More" />
      </Link>
    </nav>
  );
};

SidebarNews.propTypes = {
  news: PropTypes.array,
  loaded: PropTypes.bool.isRequired,
  loadNewsHeadlines: PropTypes.func.isRequired,
};

export default connect(
  ({ newsHeadlines }) => ({ news: newsHeadlines.items, loaded: newsHeadlines.loaded }),
  { loadNewsHeadlines },
)(SidebarNews);