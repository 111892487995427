import styles from './SalesAgreements.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, useSanaTexts, RichText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { Link, LinkButton } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { UomTitle } from 'components/primitives/product';
import { getToLocaleDate, getFormatPrice, getFormatPercentage, getFormatNumber } from 'utils/format';
import { useIsIE } from 'utils/detections';
import { InfoAlert } from 'components/primitives/alerts';
import { Table } from 'components/primitives/table';
import { isExceeded } from './helpers';
import { useIsMobileViewport, useCurrentRouteAsBackTo } from 'utils/hooks';
import { WithProductClickTracking, ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';
import { ContactStoreLink } from 'components/primitives/links';
import OrderBox from './OrderBox';
import ProductProvider from './ProductProvider';

const Lines = ({ lines, culture, currency }) => {
  const canViewUom = useAbilities([AbilityTo.ViewUnitOfMeasure]).abilities;
  const backTo = useCurrentRouteAsBackTo();
  const showUomColumn = canViewUom && lines.some(l => l.uomId);

  const [, setState] = useState();
  const expandedLines = getExpandedLinesSet();

  useMsGridFix(lines);

  const formatDate = getToLocaleDate(culture);
  const formatPrice = getFormatPrice(currency);
  const formatPercentage = getFormatPercentage(culture);
  const formatNumber = getFormatNumber(culture);

  const [
    uomText,
    uomTitle,
    effectiveText,
    effectiveTitle,
    expirationText,
    expirationTitle,
  ] = useSanaTexts([
    'UOM',
    'UnitOfMeasure',
    'EffectiveDate_Shorter',
    'EffectiveDate',
    'ExpirationDate_Shorter',
    'ExpirationDate',
    'AgreementTerm_Contact',
  ], makeSimpleText).texts;

  const contactLink = <ContactStoreLink linkTextKey="AgreementTerm_Contact" />;

  return (
    <Table className={styles.lines}>
      <thead>
        <tr>
          <th><SimpleText textKey="Id" /></th>
          <th><SimpleText textKey="ProductOrCategory" /></th>
          {!!showUomColumn && <th title={uomTitle}>{uomText}</th>}
          <th className={styles.discount}><SimpleText textKey="Discount" /></th>
          <th title={effectiveTitle}>{effectiveText}</th>
          <th title={expirationTitle}>{expirationText}</th>
          <th />
        </tr>
      </thead>
      {lines.map(line => {
        const { id, product, category, discountPercent, uomId, uoms, quantities, amounts, location, isLineOrderable } = line;
        const uom = uomId && uoms ? getUom(uoms, uomId) : null;
        const idId = id + '_id',
          nameId = id + '_name';

        const uomTitle = showUomColumn && uom ? <UomTitle id={uom.id} description={uom.description} /> : ' '; // id+descr
        const locationTitleText = location && (location.title || location.id);
        const isExpanded = expandedLines.has(id);

        const lineDetailsIsEmpty = !product
          && line.price == null
          && Object.values(amounts).every(field => field == null)
          && Object.values(quantities).every(field => field == null)
          && !locationTitleText;

        let body = null;

        const categoryRoute = category && category.exists && routesBuilder.forProductsWithCategory(category.id);

        if (isLineOrderable) {
          body = (
            <ProductProvider product={product}>
              <OrderBox isMaxEnforced={line.isMaxEnforced} quantityRemaining={quantities.remaining} />
            </ProductProvider>
          );
        }
        else if (isExceeded(line)) {
          body = (
            <InfoAlert className={`${styles.bodyInfoBlock} ${styles.termExceeds}`}>
              <div className={styles.infoBlockContent}>
                <RichText textKey="AgreementTermExceedsRemainingQuantityOrAmount" />
              </div>
            </InfoAlert>
          );
        } else if (category) {
          body = category.exists
            ? (
              <div className={styles.viewProducts}>
                <LinkButton to={categoryRoute} className={btnStyles.btnAction}>
                  <SimpleText textKey="ViewProducts" />
                </LinkButton>
              </div>
            )
            : (
              <InfoAlert className={`${styles.bodyInfoBlock} ${styles.categoryNotAvailable}`}>
                <div className={styles.infoBlockContent}>
                  <RichText textKey="CategoryOfAgreementTermIsNotAvailable" formatWith={[contactLink]} />
                </div>
              </InfoAlert>
            );
        } else {
          body = (
            <InfoAlert className={`${styles.bodyInfoBlock} ${styles.termCannotBeUsed}`}>
              <div className={styles.infoBlockContent}>
                <RichText textKey="AgreementTermCannotBeUsedInWebShop" formatWith={[contactLink]} />
              </div>
            </InfoAlert>
          );
        }

        let productTitle = null;
        if (product) {
          productTitle = product.exists
            ? (
              <ProductTrackingContext.Provider value={{ product, trackingSource: EVENT_SOURCES.salesAgreement }}>
                <WithProductClickTracking>
                  {handleClick => (
                    <Link
                      onClick={handleClick}
                      url={product.url}
                      to={routesBuilder.forProduct.bind(null, product.id, backTo)}
                    >
                      {product.title || product.id}
                    </Link>
                  )}
                </WithProductClickTracking>
              </ProductTrackingContext.Provider>
            )
            : <span>{product.title || product.id}</span>;
        }

        return (
          <tbody key={id} aria-labelledby={idId + ' ' + nameId}>
            <tr>
              <td id={idId}>{id}</td>
              <td id={nameId}>
                {productTitle}
                {' '}
                {category && (
                  category.exists
                    ? (
                      <Link to={categoryRoute}>
                        <SimpleText textKey={'Facet_Product category_' + category.id}>{category.name || category.id}</SimpleText>
                      </Link>
                    )
                    : <span><SimpleText textKey={'Facet_Product category_' + category.id}>{category.name || category.id}</SimpleText></span>
                )}
              </td>
              {showUomColumn && <td className={styles.uom}>{uomTitle}</td>}
              <td className={styles.discount}>{discountPercent ? formatPercentage(discountPercent, true) : '%'}</td>
              <td className={styles.effectiveDate}>{formatDate(line.effectiveDate)}</td>
              <td className={styles.expirationDate}>{formatDate(line.expirationDate)}</td>
              <td className={styles.togglerWrapper}>
                <button className={`${linkStyles.link} ${isExpanded ? styles.minus : styles.plus}`} data-id={id} onClick={toggle}>
                  <span className={isExpanded ? styles.hide : null}><SimpleText textKey="SalesAgreementLine_ShowMore" /></span>
                  <span className={isExpanded ? null : styles.hide}><SimpleText textKey="SalesAgreementLine_Hide" /></span>
                </button>
              </td>
            </tr>
            <tr className={`${styles.hiddenInfo} ${isExpanded ? '' : styles.hidden}`} data-related-agreement-line={id}>
              <td colSpan={showUomColumn ? 7 : 6}>
                {line.isMaxEnforced &&
                  <p><RichText textKey="ThisAgreementLineNotAllowExceedingValue" /></p>
                }
                {!lineDetailsIsEmpty &&
                  <dl className={styles.lineDetails}>
                    {product && innerLine('ProductId', product.id, styles.productId)}
                    {quantities.value != null &&
                      innerLine('ProductQuantityCommitment', <>{formatNumber(quantities.value)} {uomTitle}</>, styles.qtyCommitment)
                    }
                    {amounts.value != null && innerLine('CommitmentAmount', formatPrice(amounts.value), styles.commitmentAmount)}
                    {line.price != null && innerLine('Price', formatPrice(line.price), styles.price)}

                    {amounts.min != null && amounts.min !== 0 && innerLine('SalesAgreementMinAmountPerTransaction', formatPrice(amounts.min), styles.minAmount)}
                    {amounts.max != null && amounts.max !== 0 && innerLine('SalesAgreementMaxAmountPerTransaction', formatPrice(amounts.max), styles.maxAmount)}

                    {amounts.remaining != null && innerLine('RemainingAmount', formatPrice(amounts.remaining), styles.remainingAmount)}
                    {quantities.remaining != null &&
                      innerLine('RemainingQuantity', <>{formatNumber(quantities.remaining)} {uomTitle}</>, styles.remainingQty)
                    }

                    {amounts.shipped != null && innerLine('AmountShipped', formatPrice(amounts.shipped), styles.shipped)}
                    {quantities.shipped != null &&
                      innerLine('OrderDetail_QuantityShipped', <>{formatNumber(quantities.shipped)} {uomTitle}</>, styles.shippedQty)
                    }

                    {amounts.invoiced != null && innerLine('AmountInvoiced', formatPrice(amounts.invoiced), styles.amountInvoiced)}
                    {quantities.invoiced != null &&
                      innerLine('OrderDetail_QuantityInvoiced', <>{formatNumber(quantities.invoiced)} {uomTitle}</>, styles.qtyInvoiced)
                    }

                    {amounts.released != null && innerLine('AmountReleased', formatPrice(amounts.released), styles.amountReleased)}
                    {quantities.released != null && innerLine('QuantityReleased', <>{formatNumber(quantities.released)} {uomTitle}</>, styles.qtyReleased)}

                    {locationTitleText &&
                      innerLine('LocationTitle', <SimpleText textKey={'LocationTitle_' + locationTitleText}>{locationTitleText}</SimpleText>, styles.locationTitle)
                    }
                  </dl>
                }
                {body}
              </td>
            </tr>
          </tbody>
        );
      })}
    </Table>
  );

  function toggle(e) {
    const button = e.currentTarget;
    const lineId = button.dataset['id'];
    expandedLines.has(lineId)
      ? expandedLines.delete(lineId)
      : expandedLines.add(lineId);
    setState({});
  }
};

Lines.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      exists: PropTypes.boolean,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    category: PropTypes.shape({
      id: PropTypes.string.isRequired,
      exists: PropTypes.boolean,
      name: PropTypes.string,
    }),
    discountPercent: PropTypes.number,
    uomId: PropTypes.string,
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })),
    quantities: PropTypes.shape({
      value: PropTypes.number,
      remaining: PropTypes.number,
      shipped: PropTypes.number,
      invoiced: PropTypes.number,
      released: PropTypes.number,
    }).isRequired,
    amounts: PropTypes.shape({
      value: PropTypes.number,
      min: PropTypes.number,
      max: PropTypes.number,
      remaining: PropTypes.number,
      shipped: PropTypes.number,
      invoiced: PropTypes.number,
      released: PropTypes.number,
    }).isRequired,
    location: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  }).isRequired).isRequired,
  culture: PropTypes.string,
  currency: PropTypes.object,
};

export default Lines;

function useMsGridFix() {
  const isIE = useIsIE();
  const isMobile = useIsMobileViewport();

  useEffect(() => {
    if (!isIE)
      return;

    const listElements = document.getElementsByClassName(styles.lineDetails);
    const colAmount = isMobile ? 2 : 4;

    for (const listElement of listElements) {
      let col = 0, row = 1;
      for (const element of listElement.children) {
        if (col === colAmount) {
          col = 1;
          row++;
        }
        else {
          col++;
        }
        element.style.setProperty('-ms-grid-row', row);
      }
    }
  }, [isIE, isMobile]);
}

function getExpandedLinesSet() {
  const expandedLinesRef = useRef();
  if (!expandedLinesRef.current)
    expandedLinesRef.current = new Set();

  return expandedLinesRef.current;
}

function innerLine(titleKey, value, valueClassName = null) {
  return (
    <>
      <dt><SimpleText textKey={titleKey} /></dt>
      <dd className={valueClassName}>{value}</dd>
    </>
  );
}

function getUom(uoms, uomId) {
  const uom = uoms.find(u => u.id.toUpperCase() === uomId.toUpperCase());
  return uom ? { id: uomId, description: uom.description } : null;
}