import { routeNames } from 'routes/routeNames';

const allowedRoutes = new Set([
  routeNames.ProductDetails,
  routeNames.LastViewedProducts,
  routeNames.ProductList,
  routeNames.Search,
  routeNames.ProductsWithCategory,
]);

export function isWidgetAllowed(routeName, params) {
  return allowedRoutes.has(routeName) && params?.previewToken == null;
}
