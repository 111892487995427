import React from 'react';
import { ContentBlock } from 'components/contentBlocks';
import { VdContentBlockContainer } from 'components/visualDesigner';

export default function createContentBlock(props, index) {
  return (
    <VdContentBlockContainer key={props.id} id={props.id} index={index}>
      <ContentBlock {...props} />
    </VdContentBlockContainer>
  );
}