import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { loadAlternateUrls } from 'behavior/localization';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import LanguageSelectorContent from './LanguageSelectorContent';
import MobileLanguageSelector from './MobileLanguageSelector';

const LanguageSelector = ({
  languages,
  alternateUrls,
  loadAlternateUrls,
  currentLanguage,
  isMobile,
}) => {
  const handleLoadAlternateUrls = useCallback(() => {
    if (alternateUrls == null || !alternateUrls.length)
      loadAlternateUrls();
  }, [alternateUrls]);

  const selectedLanguage = languages.find(l => l.id === currentLanguage.id) || languages[0];
  const sortedLanguages = useMemo(() => {
    return [selectedLanguage, ...languages.filter(language => language !== selectedLanguage)];
  }, [languages, selectedLanguage]);

  if (languages == null || languages.length <= 1)
    return null;

  if (isMobile)
    return (
      <MobileLanguageSelector
        selectedLanguage={selectedLanguage}
        sortedLanguages={sortedLanguages}
        alternateUrls={alternateUrls}
        handleLoadAlternateUrls={handleLoadAlternateUrls}
      />
    );

  const header = <Header selectedLanguage={selectedLanguage} />;

  return (
    <Dropdown className="language" onDropDownShow={handleLoadAlternateUrls} header={header}>
      <LanguageSelectorContent
        alternateUrls={alternateUrls}
        sortedLanguages={sortedLanguages}
        selectedLanguage={selectedLanguage}
      />
    </Dropdown>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    twoLetterISOName: PropTypes.string.isRequired,
    imagePath: PropTypes.string,
  })),
  alternateUrls: PropTypes.array,
  loadAlternateUrls: PropTypes.func.isRequired,
  currentLanguage: PropTypes.shape({
    id: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
};

export default connect(
  ({ localization: { languages, alternateUrls, currentLanguage } }) => ({ languages, alternateUrls, currentLanguage }),
  { loadAlternateUrls },
)(LanguageSelector);
