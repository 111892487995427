import styles from '../Calendar.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { activeStartDateCalculatedDataPropType } from '../propTypes';
import { getWeekStartDate, getWeekNumber } from '../helpers';

const WeekNumbers = ({
  activeStartDateCalculatedData,
  firstDayOfWeek,
  onClickWeekNumber,
  onMouseOver,
  showFixedNumberOfWeeks,
}) => {
  const numberOfWeeks = getNumberOfWeeks(
    activeStartDateCalculatedData.daysInMonth,
    activeStartDateCalculatedData.dayOfWeekIndex,
    showFixedNumberOfWeeks,
  );

  const dates = useMemo(() => {
    const { year, monthIndex, day, calendarType } = activeStartDateCalculatedData;
    const result = [];
    for (let i = 0; i < numberOfWeeks; i++)
      result.push(getWeekStartDate(new Date(year, monthIndex, day + (i * 7)), firstDayOfWeek, calendarType));

    return result;
  }, [activeStartDateCalculatedData, firstDayOfWeek, numberOfWeeks]);

  return (
    <section className={styles.weekNumbers} onFocus={onMouseOver} onMouseOver={onMouseOver}>
      {dates.map(date => {
        const weekNumber = getWeekNumber(date, firstDayOfWeek, activeStartDateCalculatedData.calendarType);

        return (
          <button
            key={weekNumber}
            tabIndex={!onClickWeekNumber ? -1 : null}
            className={`${styles.tile} ${styles.weekNumber}`}
            aria-disabled={!onClickWeekNumber}
            aria-keyshortcuts="Space Enter ArrowUp ArrowDown"
            onClick={onClickWeekNumber ? event => onClickWeekNumber(weekNumber, date, event) : null}
            type="button"
          >
            {weekNumber}
          </button>
        );
      })
      }
    </section>
  );
};

WeekNumbers.propTypes = {
  activeStartDateCalculatedData: activeStartDateCalculatedDataPropType,
  firstDayOfWeek: PropTypes.number,
  onClickWeekNumber: PropTypes.func,
  onMouseOver: PropTypes.func,
  showFixedNumberOfWeeks: PropTypes.bool,
};

export default WeekNumbers;

function getNumberOfWeeks(daysInMonth, dayOfWeekIndex, showFixedNumberOfWeeks) {
  if (showFixedNumberOfWeeks)
    return 6;

  const days = daysInMonth - (7 - dayOfWeekIndex);
  return Math.ceil(days / 7) + 1;
}
