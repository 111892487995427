import styles from './PLP.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { sanitizeId } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { SimpleText } from 'components/sanaText';
import Stock from './Stock';
import Specifications from './Specifications';
import { Placeholder } from 'components/primitives/placeholders';
import { useProductRouteBuilder } from './hooks';

const Description = ({ id, url, title, isSpare, discontinued, obsolete }) => {
  const routeBuilder = useProductRouteBuilder(id);

  return (
    <div className={styles.productDescription}>
      <WithProductClickTracking>
        {handleClick => (
          <Link onClick={handleClick} url={url} to={routeBuilder} className={styles.productTitle}>
            <h2 id={sanitizeId('title' + id)}>
              <span className={styles.productIdName}>
                <SimpleText
                  textKey="General_Product_Id"
                  placeholder={<Placeholder className={styles.productIdNamePlaceholder} />}
                />
              </span>
              <span className={styles.productIdValue}>{id}</span>
            </h2>
          </Link>
        )}
      </WithProductClickTracking>
      <div className={styles.productIdStock}>
        <span className={styles.productId}>
          {title}
          {/*Use dot for screen readers to add pause after the title.*/}
          <i className="visually-hidden">.</i>          
          {' '}
        </span>
        { !obsolete && <Stock /> }
        <span className={styles.productId}>&nbsp;&nbsp;&nbsp;|&nbsp; {(isSpare ? <SimpleText textKey="General_Spare" /> : <SimpleText textKey="General_Product" />)}</span>
      </div>
      <Specifications />
    </div>
  );
};

Description.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isSpare: PropTypes.bool.isRequired,
};

export default connectToContext([ProductContext],
  ({ id, url, title, isSpare, discontinued, obsolete }) => ({ id, url, title, isSpare, discontinued, obsolete }),
)(
  React.memo(Description),
);