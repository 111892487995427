import styles from './Login.module.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { SanaLinkButton } from 'components/primitives/links';
import { RichText, SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { routeNames } from 'routes';

const GuestCheckoutBlock = () => {
  const [hasAbility] = useHasAbilities(AbilityTo.CheckoutAsGuest);

  const dontShowGuestBlock = useSelector(s => {
    if (!hasAbility)
      return true;

    if (s.user.isAuthenticated || !s.basket.summary.totalCount)
      return true;

    const backToRouteData = s.page?.backTo?.routeData;

    return !!backToRouteData
      && backToRouteData.routeName === routeNames.Checkout
      && backToRouteData.params?.asQuote;
  });

  if (dontShowGuestBlock)
    return null;

  return (
    <div>
      <h3 className={styles.title}><SimpleText textKey="Guest_DoNotWantToRegister" /></h3>
      <div className={styles.block}><RichText textKey="Guest_Description" /></div>
      <div>
        <SanaLinkButton to={routesBuilder.forCheckout(false, null, true)} textKey="Login_GuestCheckoutButton" className={styles.guestCheckout} />
      </div>
    </div>
  );
};

export default GuestCheckoutBlock;
