import styles from './Facets.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Placeholder } from 'components/primitives/placeholders';

const textToLoad = ['Filters'];

const MobileFacetsToggler = ({ expanded, toggle, disabled }) => (
  <UseSanaTexts
    options={textToLoad}
    placeholder={<Placeholder className={styles.mobileTogglerPlaceholder} />}
  >
    {([filtersLbl]) => (
      <button
        onClick={toggle}
        className={`${expanded ? `${styles.expanded} ` : ''}${styles.mobileToggler}`}
        disabled={disabled}
      >
        <span className={styles.togglerLbl}>{filtersLbl}</span>
        <span className={styles.expandIcon}>
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
    )}
  </UseSanaTexts>
);

MobileFacetsToggler.propTypes = {
  expanded: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(MobileFacetsToggler);
