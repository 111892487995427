exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".StyleWrapper_wrapper{flex:0 0 auto}.StyleWrapper_overflow{overflow:hidden}.StyleWrapper_stretch-height{height:100%;flex:0 1 auto}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/contentBlocks/StyleWrapper.module.scss"],"names":[],"mappings":"AAAA,sBACI,aACJ,CAEA,uBACI,eACJ,CAEA,6BACI,YACA,aACJ,CAAA","file":"StyleWrapper.module.scss","sourcesContent":[".wrapper {\n    flex: 0 0 auto;\n}\n\n.overflow {\n    overflow: hidden;\n}\n\n.stretch-height {\n    height: 100%;\n    flex: 0 1 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "StyleWrapper_wrapper",
	"overflow": "StyleWrapper_overflow",
	"stretch-height": "StyleWrapper_stretch-height",
	"stretchHeight": "StyleWrapper_stretch-height"
};