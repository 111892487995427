import React from 'react';
import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import { throttle, debounce } from 'lodash';
import { getPageScroll } from './helpers';
import getScrollElement from 'react-lazy-load-image-component/src/utils/get-scroll-element';

// This HOC were copied from react-lazy-load-image-component library and only necessary code lines added/changed
// to allow simple code comparison in the future if it will be needed.
const trackWindowScroll = BaseComponent => {
  class ScrollAwareComponent extends React.Component {
    constructor(props) {
      super(props);

      // Check for Intersection Observer API availability moved to LazyLoadComponentWrapper and useIntersectionObserver prop
      // here shows whether this API can be used in general combining above mentioned check and appropriate prop set by developer on
      // the higher level. This applies also for all other places in the code below.
      if (props.useIntersectionObserver)
        return;

      const onChangeScroll = this.onChangeScroll.bind(this);

      if (props.delayMethod === 'debounce') {
        this.delayedScroll = debounce(onChangeScroll, props.delayTime);
      } else if (props.delayMethod === 'throttle') {
        this.delayedScroll = throttle(onChangeScroll, props.delayTime);
      }

      // Combined getPageScroll function is used instead of separate getScrollX and getScrollY functions in the original library.
      // This also applies to setState call in onChangeScroll handler below.
      this.state = { scrollPosition: getPageScroll() };

      this.baseComponentRef = React.createRef();
    }

    componentDidMount() {
      this.addListeners();
    }

    componentDidUpdate() {
      if (typeof window === 'undefined' || this.props.useIntersectionObserver)
        return;

      const scrollElement = getScrollElement(ReactDOM.findDOMNode(this.baseComponentRef.current));

      if (scrollElement !== this.scrollElement) {
        this.removeListeners();
        this.addListeners();
      }
    }

    componentWillUnmount() {
      this.removeListeners();
    }

    addListeners() {
      if (typeof window === 'undefined' || this.props.useIntersectionObserver)
        return;

      this.scrollElement = getScrollElement(ReactDOM.findDOMNode(this.baseComponentRef.current));

      this.scrollElement.addEventListener('scroll', this.delayedScroll, { passive: true });
      window.addEventListener('resize', this.delayedScroll, { passive: true });

      if (this.scrollElement !== window)
        window.addEventListener('scroll', this.delayedScroll, { passive: true });
    }

    removeListeners() {
      if (typeof window == 'undefined' || this.props.useIntersectionObserver)
        return;

      this.scrollElement.removeEventListener('scroll', this.delayedScroll);
      window.removeEventListener('resize', this.delayedScroll);

      if (this.scrollElement !== window)
        window.removeEventListener('scroll', this.delayedScroll);
    }

    onChangeScroll() {
      if (this.props.useIntersectionObserver)
        return;

      // Do nothing if component was unmounted (in this case there will be no baseComponentRef).
      if (!this.baseComponentRef.current)
        return;

      this.setState({ scrollPosition: getPageScroll() });
    }

    render() {
      const { delayMethod, delayTime, ...props } = this.props;
      const scrollPosition = this.props.useIntersectionObserver
        ? null
        : this.state.scrollPosition;

      return <BaseComponent ref={this.baseComponentRef} scrollPosition={scrollPosition} {...props} />;
    }
  }

  ScrollAwareComponent.propTypes = {
    delayMethod: PropTypes.oneOf(['debounce', 'throttle']),
    delayTime: PropTypes.number,
    useIntersectionObserver: PropTypes.bool,
  };

  ScrollAwareComponent.defaultProps = {
    delayMethod: 'throttle',
    delayTime: 300,
  };

  return ScrollAwareComponent;
};

export default trackWindowScroll;