import PropTypes from 'prop-types';
import { OrderStatus, PaymentStatus, DocumentType, QuoteStatus } from 'behavior/documents';
import { TaxFocusMode, TaxAmountPosition, TaxDisplayMode } from 'behavior/settings/constants';

export const orderStatusPropType = PropTypes.oneOf(Object.values(OrderStatus));

export const quoteStatusPropType = PropTypes.oneOf(Object.values(QuoteStatus));

export const documentTypePropType = PropTypes.oneOf(Object.values(DocumentType));

export const paymentStatusPropType = PropTypes.oneOf(Object.values(PaymentStatus));

export const taxFocusModePropType = PropTypes.oneOf(Object.values(TaxFocusMode));

export const taxAmountPositionPropType = PropTypes.oneOf(Object.values(TaxAmountPosition));

export const taxDisplayModePropType = PropTypes.oneOf(Object.values(TaxDisplayMode));
