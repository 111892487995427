exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TermsAndAgreements_terms-container{text-align:right}.TermsAndAgreements_terms-container .TermsAndAgreements_chb{margin-left:.6em;margin-right:0}.TermsAndAgreements_btn-close{display:block;margin:30px 0 0 auto}.TermsAndAgreements_popup{\n  /*!*/}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/checkout/base/termsAndAgreements/TermsAndAgreements.module.scss"],"names":[],"mappings":"AAAA,oCACI,gBACJ,CACI,4DACI,iBACA,cACR,CAGA,8BACI,cACA,oBAAJ,CAIA;EACI,KAAJ,CAAA","file":"TermsAndAgreements.module.scss","sourcesContent":[".terms-container {\n    text-align: right;\n\n    .chb {\n        margin-left: 0.6em;\n        margin-right: 0;\n    }\n}\n\n.btn-close {\n    display: block;\n    margin: 30px 0 0 auto;\n}\n\n/* Empty classes for tests & customizations */\n.popup {\n    /*!*/\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"terms-container": "TermsAndAgreements_terms-container",
	"termsContainer": "TermsAndAgreements_terms-container",
	"chb": "TermsAndAgreements_chb",
	"btn-close": "TermsAndAgreements_btn-close",
	"btnClose": "TermsAndAgreements_btn-close",
	"popup": "TermsAndAgreements_popup"
};