import styles from '../../SanaForm.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { createLabelId } from '../../helpers';
import { useFormikContext } from 'formik';
import FormGroupTemplate from '../../formGroups/FormGroupTemplate';
import FieldError from '../../FieldError';

const RadioFormGroup = ({ field, className }) => {
  const formik = useFormikContext();
  const { fieldName, fieldTitle, required } = field.props;
  const formName = formik.status.formName;
  const labelId = createLabelId(formName, fieldName);

  return (
    <FormGroupTemplate
      label={(
        <div id={labelId} className={styles.radioLabel}>
          {fieldTitle}{fieldTitle && required && '*' || null}
        </div>
      )}
      field={field}
      error={<FieldError fieldName={fieldName} />}
      role="group"
      aria-labelledby={labelId}
      className={className}
    />
  );
};

RadioFormGroup.propTypes = {
  field: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default RadioFormGroup;
