import styles from './Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import BackTo from './BackTo';
import { Container } from 'components/primitives/grid';

const BackToContainer = ({ options, pageBackTo }) => {
  let to, url, supportedRoutes, toTexts;

  if (pageBackTo) {
    to = pageBackTo.routeData;
    url = pageBackTo.url;
  } else if (options && options.route) {
    to = options.route;
    url = options.url;
    toTexts = options && options.texts;
    supportedRoutes = options && options.supportedRoutes;
  }

  if (!to)
    return null;

  return (
    <Container className={styles.backTo}>
      <BackTo to={to} url={url} supportedRoutes={supportedRoutes} toTexts={toTexts} />
    </Container>
  );
};

BackToContainer.propTypes = {
  options: PropTypes.shape({
    route: PropTypes.any,
    url: PropTypes.string,
    supportedRoutes: PropTypes.any,
    texts: PropTypes.any,
  }),
  pageBackTo: PropTypes.shape({
    routeData: PropTypes.object.isRequired,
    url: PropTypes.string,
  }),
};

export default React.memo(BackToContainer);