import styles from '../Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { UseSanaTexts } from 'components/sanaText';
import Popup from './Popup';
import { Modal, useModal } from 'components/objects/modals';
import { Placeholder } from 'components/primitives/placeholders';
import { makeSimpleText } from 'utils/render';

const ViewBomButton = ({
  bomComponents,
  variants,
  variantId,
}) => {
  const { opened, show, hide } = useModal();

  const components = useMemo(() => {
    if (!variantId)
      return bomComponents;

    const variant = variants.find(v => v.id === variantId);
    if (!variant)
      return null;

    if (variant.bomComponents)
      return variant.bomComponents;

    return bomComponents;
  }, [variantId, bomComponents, variants]);

  if (variantId && !components?.length)
    return null;

  const hasVariants = components && components.some(component => component.variant);
  const hypBomPlaceholder = <Placeholder className={`${styles.hypBomPlaceholder} ${styles.hypBom}`} />;

  return (
    <>
      <UseSanaTexts options={['ViewPackageContents']} placeholder={hypBomPlaceholder}>
        {([ViewPackageContents]) => (
          <button className={`${styles.hypBom} ${linkStyles.arrowed} ${linkStyles.link}`} onClick={show}>
            {makeSimpleText(ViewPackageContents)}
          </button>
        )}
      </UseSanaTexts>
      <Modal hide={hide} opened={opened} size={hasVariants ? 'medium' : null} className={styles.bomPopup}>
        <Popup components={components} />
      </Modal>
    </>
  );
};

ViewBomButton.propTypes = {
  bomComponents: PropTypes.array,
  variants: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    bomComponents: PropTypes.array,
  })),
  variantId: PropTypes.string,
};

export default React.memo(ViewBomButton);
