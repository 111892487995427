import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { SmallArrowLeftIcon } from 'components/primitives/icons';

const texts = ['BackToOverview'];

const Back = ({ node }) => {
  const { texts: [backToText] } = useSanaTexts(texts, makeSimpleText);

  if (!node || !node.link)
    return null;

  return (
    <Link {...node.link} title={node.title} className={linkStyles.hypBack} rel="nofollow">
      <SmallArrowLeftIcon alt={node.title} className="back-icon" aria-hidden />
      {backToText}
    </Link>
  );
};

Back.propTypes = {
  node: PropTypes.shape({
    link: PropTypes.object,
    title: PropTypes.string,
  }),
};

Back.texts = texts;

export default Back;
