import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UserTypes } from 'behavior/user';
import Message from './AccountImpersonationMessage';

const AccountImpersonationBar = ({ isPrintMode, userType }) => {
  if (isPrintMode || userType !== UserTypes.Admin)
    return null;

  return <Message />;
};

AccountImpersonationBar.propTypes = {
  isPrintMode: PropTypes.bool,
  userType: PropTypes.string,
};

export default connect(s => ({ userType: s.user.type, isPrintMode: s.page.isPrintMode }))(AccountImpersonationBar);
