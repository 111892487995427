import React from 'react';
import PropTypes from 'prop-types';
import { SanaTextContainer } from 'components/sanaText';

const ErrorMessageText = ({ message, fieldMetadata }) => {
  const rule = fieldMetadata.validationRules
    .find(r => r.errorMessage === message);

  if (!rule?.errorMessageKey)
    return message;

  return (
    <SanaTextContainer textKey={rule.errorMessageKey}>{message}</SanaTextContainer>
  );
};

ErrorMessageText.propTypes = {
  message: PropTypes.string.isRequired,
  fieldMetadata: PropTypes.shape({
    validationRules: PropTypes.arrayOf(PropTypes.shape({
      errorMessage: PropTypes.string,
      errorMessageKey: PropTypes.string,
    })).isRequired,
  }).isRequired,
};

export default React.memo(ErrorMessageText);
