import React from 'react';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';

const LoginLink = attributes => (
  <Link to={routesBuilder.forLogin} {...attributes}>
    <SimpleText textKey="ButtonText_Login" />
  </Link>
);

export default LoginLink;