import styles from '../../Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const InnerLine = ({ value, lineTexts }) => {
  if (!value)
    return null;

  return (
    <tr>
      <td>
        <span className={styles.name}>{lineTexts.title}</span>
        <span className={styles.value}>{value}</span>
      </td>
    </tr>
  );
};

InnerLine.propTypes = {
  value: PropTypes.any,
  lineTexts: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default InnerLine;
