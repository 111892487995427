import styles from './ActionButtons.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { SanaLinkButton } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { useUpdateContext } from '../updateContext';

const CheckoutButton = ({ disabled, className = '' }) => {
  const { writeOnlySubmit } = useUpdateContext();

  return (
    <SanaLinkButton
      textKey="ProceedToCheckout"
      to={routesBuilder.forCheckout}
      onClick={() => writeOnlySubmit()}
      disabled={disabled}
      className={`${btnStyles.btnBig} ${btnStyles.btnAction} ${styles.btnCheckout} ${className}`}
      placeholder={<Placeholder className={styles.btnCheckoutPlaceholder} />}
    />
  );
};

CheckoutButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(CheckoutButton);