import React from 'react';
import PropTypes from 'prop-types';
import { PeriodTypes } from './enums';
import { getRangeStart, getNextRangeStart } from './helpers';
import CenturyView from './CenturyView';
import DecadeView from './DecadeView';
import YearView from './YearView';
import MonthView from './MonthView';

const Body = ({
  currentActiveStartDate,
  calendarType,
  firstDayOfWeek,
  formatLongDate,
  formatMonth,
  formatMonthYear,
  formatShortWeekday,
  formatWeekday,
  formatYear,
  isSecondView,
  locale,
  onClickWeekNumber,
  onMouseLeave,
  showFixedNumberOfWeeks,
  showNeighboringMonth,
  showWeekNumbers,
  view,
  ...commonProps
}) => {
  commonProps.activeStartDate = (isSecondView ? getNextRangeStart : getRangeStart)(view, currentActiveStartDate);

  switch (view) {
    case PeriodTypes.CENTURY:
      return <CenturyView {...commonProps} formatYear={formatYear} />;
    case PeriodTypes.DECADE:
      return <DecadeView {...commonProps} formatYear={formatYear} />;
    case PeriodTypes.YEAR:
      return <YearView {...commonProps} formatMonth={formatMonth} formatMonthYear={formatMonthYear} />;
    case PeriodTypes.MONTH:
      return (
        <MonthView
          {...commonProps}
          calendarType={calendarType}
          firstDayOfWeek={firstDayOfWeek}
          formatLongDate={formatLongDate}
          formatShortWeekday={formatShortWeekday}
          formatWeekday={formatWeekday}
          isSecondView={isSecondView}
          locale={locale}
          onClickWeekNumber={onClickWeekNumber}
          onMouseLeave={onMouseLeave}
          showFixedNumberOfWeeks={showFixedNumberOfWeeks}
          showNeighboringMonth={showNeighboringMonth}
          showWeekNumbers={showWeekNumbers}
        />
      );
    default:
      throw new Error(`Invalid view: ${view}.`);
  }
};

Body.propTypes = {
  currentActiveStartDate: PropTypes.instanceOf(Date).isRequired,
  calendarType: PropTypes.string,
  firstDayOfWeek: PropTypes.number,
  formatLongDate: PropTypes.func,
  formatMonth: PropTypes.func,
  formatMonthYear: PropTypes.func,
  formatShortWeekday: PropTypes.func,
  formatWeekday: PropTypes.func,
  formatYear: PropTypes.func,
  isSecondView: PropTypes.bool,
  locale: PropTypes.string,
  onClickWeekNumber: PropTypes.func,
  onMouseLeave: PropTypes.func,
  showFixedNumberOfWeeks: PropTypes.bool,
  showNeighboringMonth: PropTypes.bool,
  showWeekNumbers: PropTypes.bool,
  view: PropTypes.oneOf(Object.values(PeriodTypes)).isRequired,
};

export default Body;