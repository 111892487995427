import styles from './Basket.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { VerticalSliding } from 'components/primitives/transitions';
import { InfoAlert, alertStyles } from 'components/primitives/alerts';
import { useUpdateContext, usePendingChangesContext } from './updateContext';
import { makeSimpleText, makeRichText } from 'utils/render';
import { StockAlertDialog } from 'components/objects/stockAlertDialog';
import { useModal } from 'components/objects/modals';

const RecalculationSuggestion = ({
  recalcMessageTextKey = 'RecalculateBasketMessage',
  messageId = 'recalcMsg',
  isQuickOrderMode,
}) => {
  const pendingChanges = usePendingChangesContext();
  const { submit, getModifiedLines } = useUpdateContext();

  const { texts: [recalcMsg, recalcBtnText] }
    = useSanaTexts([recalcMessageTextKey, 'Recalculate']);

  const { opened, toggle, show } = useModal();
  const [overInv, setOverInv] = useState([]);

  const handleSubmitClicked = () => {

    const tooMany = [];

    setOverInv([]);

    for (const ml of getModifiedLines()) {
      const { id, quantity } = ml;
      const invId = `${id}-inv`;
      const productIdId = `${id}-id`;

      const invSpan = document.getElementById(invId);
      const productIdSpan = document.getElementById(productIdId);

      const inventory = invSpan ? parseInt(invSpan.innerHTML) : null;
      const productId = productIdSpan ? productIdSpan.innerHTML : null;

      if (inventory !== null && quantity > inventory) {
        tooMany.push({ id: productId, quantity, inv: inventory });
      }
    }

    setOverInv(tooMany);
    if (tooMany.length) {
      show();
    }

    submit();
  };

  const handleKeyDown = e => {
    const { key, which } = e;
    const isSpaceKey = key === ' ' || which === 32;
    const isEnterKey = key === 'Enter' || which === 13;

    if (!isSpaceKey && !isEnterKey)
      return;

    e.preventDefault();
    submit();
  };
  const recalcBtn = (
    <span
      role="button"
      className={`${alertStyles.link} ${styles.btnRecalculate}`}
      onClick={handleSubmitClicked}
      onKeyDown={handleKeyDown}
      tabIndex="0"
    >
      {makeSimpleText(recalcBtnText)}
    </span>
  );

  return (
    <>
    <VerticalSliding
      expanded={pendingChanges || isQuickOrderMode}
      role="alertdialog"
      aria-labelledby={messageId}
      duration={350}
      timingFunction="ease"
      className={styles.recalcSuggestionWrapper}
    >
      <InfoAlert className={styles.recalcMsg} id={messageId}>
        {makeRichText(recalcMsg, [recalcBtn])}
      </InfoAlert>
    </VerticalSliding>
    <StockAlertDialog opened={opened} toggle={toggle} elements={overInv} />
    </>
  );
};

RecalculationSuggestion.propTypes = {
  recalcMessageTextKey: PropTypes.string,
  messageId: PropTypes.string,
  isQuickOrderMode: PropTypes.bool,
};

export default React.memo(RecalculationSuggestion);
