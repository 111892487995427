import styles from './OfflineMode.module.scss';
import React from 'react';
import { Container } from 'components/primitives/grid';
import { Helmet } from 'react-helmet';
import { useSanaTexts } from 'components/sanaText';
import Logo from 'components/primitives/logo/Logo';
import { useSelector } from 'react-redux';
import PromotionalText from 'components/objects/promotionalTexts/PromotionalText';
import { makeSimpleText } from 'utils/render';
import { useVerticalOverflowFix } from 'utils/hooks';

const Offline = () => {
  const shopName = useSelector(({ settings: { shopName } }) => shopName);
  const [title] = useSanaTexts(['ConnectionOfflinePage_PageTitle'], makeSimpleText).texts;

  useVerticalOverflowFix();

  return (
    <div id="unavailablePage">
      <Helmet>
        <title>{title}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Logo className={styles.logo} />
        <div className={styles.content}>
          <h1>{title}</h1>
          <div className={styles.message}>
            <PromotionalText textKey="ConnectionOffline_Text" />
          </div>
          <span className={styles.shopName}>{shopName}</span>
        </div>
      </Container>
    </div>
  );
};

export default Offline;