import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const ShippingStatus = ({ status }) => (
  <SimpleText textKey={`ShippingStatus_${status}`} placeholder={status}>
    {status}
  </SimpleText>
);

ShippingStatus.propTypes = {
  status: PropTypes.string,
};

export default ShippingStatus;
