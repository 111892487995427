import { useSimpleTexts } from 'components/sanaText';

export function useBooleanOptions(fieldName) {
  const [yesText, noText, defaultYesText, defaultNoText] = useSimpleTexts([
    `OptionText_${fieldName}_Yes`,
    `OptionText_${fieldName}_No`,
    'Yes',
    'No',
  ]).texts;

  const options = [
    { name: yesText || defaultYesText, value: true },
    { name: noText || defaultNoText, value: false },
  ];

  return options;
}