import React from 'react';
import { withAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import ButtonRenderer from './ButtonRenderer';

const ButtonBox = ({ abilities: [orderProductAbility], ...props }) => {
  if (orderProductAbility !== AbilityState.Available)
    return null;

  return <ButtonRenderer {...props} />;
};

ButtonBox.propTypes = {
  abilities: abilitiesPropType,
};

export default withAbilities(ButtonBox, [AbilityTo.OrderProducts]);
