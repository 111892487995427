import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigateTo } from 'behavior/events';
import { useDispatch } from 'react-redux';
import { createRouteData, toPropTypes } from './helpers';
import { isModifierKeyPressed } from 'utils/helpers';
import { logger } from 'utils/logs';
import { isAbsoluteUrl } from 'utils/url';

const NavigationLink = ({
  createLink,
  to,
  url,
  onClick,
  omitScroll,
  replaceHistory,
  options,
  disabled,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!url)
      logBrokenLinksWarning();
  }, [url]);

  const onLinkClick = event => {
    if (event.button === 1 || isModifierKeyPressed(event))
      return;

    const { currentTarget: { target } } = event;
    if (!url || isAbsoluteUrl(url) || (target && target !== '_self'))
      return;

    event.preventDefault();
    if (disabled)
      return;

    const routeData = getRouteData(to, options);
    const ignore = (onClick && onClick(event, routeData)) === false;
    if (!ignore)
      dispatch(navigateTo(routeData, url, { omitScroll, replaceHistory }));
  };

  return createLink(url, onLinkClick);
};

NavigationLink.propTypes = {
  createLink: PropTypes.func.isRequired,
  url: PropTypes.string,
  to: toPropTypes,
  onClick: PropTypes.func,
  omitScroll: PropTypes.bool,
  replaceHistory: PropTypes.bool,
  options: PropTypes.object,
  disabled: PropTypes.bool,
};

export default NavigationLink;

function getRouteData(to, options) {
  const routeData = createRouteData(to);
  if (!options)
    return routeData;

  return { ...routeData, options };
}

function logBrokenLinksWarning() {
  logger.warn('There are broken links on the website. Please use document.querySelectorAll("a[data-broken]") to find them.');
}