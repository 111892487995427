import styles from '../../Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedPrice } from 'components/primitives/price';

const ServiceLine = ({
  line: {
    chargeId,
    title,
    price,
    subtotal,
  },
  options: {
    currencyInfo,
    showPrices,
  },
  lineTexts,
}) => (
    <tr>
      <td>
        <span className={styles.name}>{lineTexts.id}</span>
        <span className={styles.value}>{chargeId}</span>
      </td>
      <td>
        <span className={styles.name}>{lineTexts.title}</span>
        <span className={styles.value}>{title}</span>
      </td>

      {showPrices &&
        <td className={styles.priceCell}>
          <span className={styles.name}>{lineTexts.price}</span>
          <span className={styles.value}>
            <FormattedPrice price={price} currencyInfo={currencyInfo} />
          </span>
        </td>
      }
      {showPrices &&
        <td className={styles.totalCell}>
          <span className={styles.name}>{lineTexts.totals}</span>
          <span className={styles.value}>
            <FormattedPrice price={subtotal} currencyInfo={currencyInfo} />
          </span>
        </td>
      }
    </tr>
  );

ServiceLine.propTypes = {
  options: PropTypes.shape({
    showPrices: PropTypes.bool.isRequired,
    currencyInfo: PropTypes.object,
  }).isRequired,
  line: PropTypes.shape({
    chargeId: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    subtotal: PropTypes.number,
  }).isRequired,
  lineTexts: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    totals: PropTypes.string,
  }),
};

export default ServiceLine;
