import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { TextAreaField as TextAreaFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const TextAreaField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    disabled,
    maxLength,
    placeholder,
  }) => (
      <TextAreaFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
      />
    ),
  TextAreaFieldCore,
);

TextAreaField.propTypes = {
  ...TextAreaFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  validation: validationFieldPropType,
};

export default TextAreaField;
