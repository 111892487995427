import styles from './PLP.module.scss';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { makeRichText } from 'utils/render';
import { stripFormatting } from 'utils/helpers';

const Specifications = ({ specifications }) => {
  if (!specifications || !specifications.length)
    return null;

  return (
    <div className={styles.productAttributes}>
      {specifications.map((specification, index) => (
        <Fragment key={index}>
          <span>
            {makeRichText(stripFormatting(specification.value.replace(/<br\s*\/?>/, '')))}
            {index !== specifications.length - 1 && (
              <br />
            )}
          </span>
          {' '}
        </Fragment>
      ))}
    </div>
  );
};

Specifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
  })),
};

export default connectToContext([ProductContext], ({ specifications }) => ({ specifications }))(
  React.memo(Specifications),
);