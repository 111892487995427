import styles from './MainNav.module.scss';
import React, { createContext, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useOnChange } from 'utils/hooks';

const sublistWidth = +styles.subListWidth;

export const DIRECTIONS = {
  FORWARDS: 'FORWARDS',
  BACKWARDS: 'BACKWARDS',
};

export const SublistPositionContext = createContext(null);

export const SublistPositionProvider = ({
  navWidth,
  startOffset,
  startDirection = DIRECTIONS.FORWARDS,
  children,
}) => {
  const initialParams = useRef({}).current;

  useOnChange(() => {
    const isStartDirectionForwards = isForwardsDirection(startDirection);
    const startPosition = startOffset
      ? isStartDirectionForwards
        ? startOffset
        : navWidth - startOffset
      : isStartDirectionForwards
        ? 0
        : navWidth;
    const leftPositioningAvailable = startPosition + sublistWidth <= navWidth;
    const rightPositioningAvailable = startPosition - sublistWidth >= 0;
    let defaultPositioning = null;

    if (!leftPositioningAvailable && !rightPositioningAvailable) {
      defaultPositioning = styles.leftPos;
    } else {
      if (isStartDirectionForwards) {
        if (!leftPositioningAvailable && rightPositioningAvailable)
          defaultPositioning = styles.rightPos;
      } else {
        if (!rightPositioningAvailable && leftPositioningAvailable)
          defaultPositioning = styles.leftPos;
      }
    }

    initialParams.startDirection = startDirection;
    initialParams.startPosition = startPosition;
    initialParams.defaultPositioning = defaultPositioning;
    initialParams.navWidth = navWidth;
  }, [navWidth, startOffset, startDirection]);

  const contextValue = useMemo(() => {
    const { leftPos, rightPos } = styles;

    return {
      getSublistPositionParams: (direction = initialParams.startDirection, position = initialParams.startPosition) => {
        if (initialParams.defaultPositioning)
          return { posClass: initialParams.defaultPositioning, direction, position };

        let posClass = isForwardsDirection(direction) ? leftPos : rightPos;

        if (direction === DIRECTIONS.FORWARDS) {
          const currentPosition = position + sublistWidth;
          if (currentPosition <= initialParams.navWidth) {
            position = currentPosition;
            posClass = leftPos;
          } else {
            position = position - sublistWidth * 2;
            direction = DIRECTIONS.BACKWARDS;
            posClass = rightPos;
          }
        } else {
          const currentPosition = position - sublistWidth;
          if (currentPosition >= 0) {
            position = currentPosition;
            posClass = rightPos;
          } else {
            position = position + sublistWidth * 2;
            direction = DIRECTIONS.FORWARDS;
            posClass = leftPos;
          }
        }
        return { posClass, direction, position };
      },
    };
  }, []);

  return (
    <SublistPositionContext.Provider value={contextValue}>
      {children}
    </SublistPositionContext.Provider>
  );
};

export default React.memo(SublistPositionProvider);

SublistPositionProvider.propTypes = {
  navWidth: PropTypes.number,
  startOffset: PropTypes.number,
  startDirection: PropTypes.string,
  children: PropTypes.node,
};

function isForwardsDirection(direction) {
  return direction === DIRECTIONS.FORWARDS;
}