import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paging from 'components/primitives/paging';
import { useUrlBuilder } from '../hooks';
import { getViewModeProductAmount } from 'behavior/pages/productList';
import { navigateTo } from 'behavior/events';

const LinksPaging = ({ pageIndex, pageSize, totalCount, navigateTo, hasPendingChanges }) => {
  const buildRoute = useUrlBuilder(pagingMiddleware);
  const buildUrlForPage = (page, options) => buildRoute({
    page: page + 1,
    count: page.size,
  }, options);

  if (!pageSize)
    return null;

  const onSelect = page => {
    const { to, url } = buildUrlForPage(page, { productsOnly: true });
    navigateTo(to, url, { omitScroll: true });

    if (hasPendingChanges)
      return false;
  };

  const getUrl = page => {
    const { url } = buildUrlForPage(page);
    return url;
  };

  return (
    <Paging
      pageIndex={pageIndex}
      pageSize={pageSize}
      totalCount={totalCount}
      onSelect={onSelect}
      scrollToId="productListPage"
      getUrl={getUrl}
    />
  );
};

LinksPaging.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  navigateTo: PropTypes.func.isRequired,
  hasPendingChanges: PropTypes.bool,
};

export default connect(({
  routing: { routeData: { params } },
  settings: { productList },
  page: { selectedViewMode, defaultViewMode },
}) => ({
  pageIndex: params.page ? params.page - 1 : 0,
  pageSize: params.count || getViewModeProductAmount(params.viewMode || selectedViewMode || defaultViewMode, productList),
}), { navigateTo })(LinksPaging);

function pagingMiddleware(query, params) {
  if (params.page > 0)
    query.page = params.page;
}