import React from 'react';
import PropTypes from 'prop-types';
import { abilityPropType } from 'behavior/user';
import SummaryLine from './SummaryLine';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const ConfigurationResultLine = ({ line, currencyInfo, viewUomAbility }) => {
  const { configuration, uom, price, quantity } = line;
  const product = configuration.products[0];
  const { id, title, url, variantTitle, image } = product;
  const uomId = uom && uom.id;
  const uomDescription = uom && uom.description;

  const trackingData = {
    product: { ...product, price, uom },
    trackingSource: EVENT_SOURCES.shoppingCartMiniature,
  };

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <SummaryLine
        productId={id}
        productTitle={title}
        productUrl={url}
        productImage={image}
        variantTitle={variantTitle}
        quantity={quantity}
        price={price}
        uomId={uomId}
        uomDescription={uomDescription}
        currencyInfo={currencyInfo}
        viewUomAbility={viewUomAbility}
      />
    </ProductTrackingContext.Provider>
  );
};

ConfigurationResultLine.propTypes = {
  line: PropTypes.shape({
    configuration: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        url: PropTypes.string,
        variantTitle: PropTypes.string,
        image: PropTypes.shape({
          small: PropTypes.string,
        }),
      })),
    }).isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    quantity: PropTypes.number.isRequired,
    price: PropTypes.number,
  }),
  currencyInfo: PropTypes.object,
  viewUomAbility: abilityPropType.isRequired,
};

export default React.memo(ConfigurationResultLine);