import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addProducts } from 'behavior/basket';
import { SanaButton } from 'components/primitives/buttons';
import { createQuantityModel } from 'components/primitives/product';
import { generateKey } from 'utils/helpers';
import ButtonPlaceholder from './Placeholder';

const AddToBasket = ({
  product: { id, uom },
  basketModifiedDate,
  basketUpdatedById,
  addProducts,
}) => {
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    const quantity = createQuantityModel(uom).initial;
    const uomId = uom && uom.id;

    setDisabled(true);
    addProducts([{
        productId: id,
        uomId,
        quantity,
      }],
      componentId);
  }, []);

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  return (
    <SanaButton className={`${btnStyles.btnAction} ${btnStyles.btnSmall}`}
      textKey="AddToBasket"
      onClick={addToBasket}
      placeholder={<ButtonPlaceholder />}
    />
  );
};

AddToBasket.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  basketModifiedDate: PropTypes.number,
  basketUpdatedById: PropTypes.string,
  addProducts: PropTypes.func.isRequired,
};

export default connect(
  ({ basket }) => ({
    basketModifiedDate: basket.modifiedDate,
    basketUpdatedById: basket.updated.updaterId,
  }),
  { addProducts },
)(AddToBasket);