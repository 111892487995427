import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import { RichText } from 'components/sanaText';

const Page = ({ templateFields, registrationResult }) => (
  <>
    <div>
      <RichText textKey="CreateAccount_MainIntroductionLabel" />
    </div>
    <Form templateFields={templateFields} registrationResult={registrationResult} />
  </>
);

Page.propTypes = {
  templateFields: PropTypes.array.isRequired,
  registrationResult: PropTypes.object,
};

Page.selectPropsFromPage = ({ templateFields, registrationResult }) => ({ templateFields, registrationResult });

export default Page;