import styles from '../Orders.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CustomerTypes } from 'behavior/user';
import { SimpleText } from 'components/sanaText';
import { Table } from 'components/primitives/table';
import { toLocaleDate } from 'utils/format';
import ShippingTracking from '../ShippingTracking';
import ViewDetailsLink from '../ViewDetailsLink';
import { DocumentType } from 'behavior/documents';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';

const ReturnReceiptsList = ({
  documents,
  isB2CCustomer,
  culture,
}) => {
  const isPreview = useIsPreview();

  return (
    <Table className={styles.ordersList}>
      <thead>
        <tr>
          <th id="headerId" className={styles.documentIdCell}><SimpleText textKey="DocumentNo" /></th>
          <th id="headerOrderId" className={styles.documentIdCell}><SimpleText textKey="OrderNumber" /></th>
          <th id="headerDate" className={styles.dateCell}><SimpleText textKey="OrderDate" /></th>
          <th id="headerBillTo" className={styles.billToCell}>
            <SimpleText textKey="OrderDetail_BillToName" />
          </th>
          <th id="headerShipTo" className={styles.billToCell}>
            <SimpleText textKey="OrderDetail_ShipToName" />
          </th>
          {isB2CCustomer && <th id="headerTracking" className={styles.trackingCell}><SimpleText textKey="ShippingTracking" /></th>}
          <th className={styles.viewMoreCell} />
        </tr>
      </thead>
      <tbody>
        {!isPreview
          ? documents.map((order, index) => {
            const {
              id,
              documentId,
              orderId,
              orderDate,
              url,
              billingAddress,
              shipping: { tracking, address: shippingAddress },
            } = order;

            return (
              <tr key={index}>
                <th scope="row" aria-labelledby="headerId">{documentId}</th>
                <td aria-labelledby="headerOrderId">{orderId}</td>
                <td aria-labelledby="headerDate">{toLocaleDate(orderDate, culture)}</td>
                <td aria-labelledby="headerBillTo">{billingAddress && billingAddress.name}</td>
                <td aria-labelledby="headerShipTo">{shippingAddress && shippingAddress.name}</td>
                {isB2CCustomer &&
                  <td aria-labelledby="headerTracking" className={styles.trackingCell}><ShippingTracking tracking={tracking} /></td>
                }
                <td><ViewDetailsLink url={url} id={id} orderId={orderId} documentType={DocumentType.ReturnReceipt} /></td>
              </tr>
            );
          })
          : Array.from(Array(5)).map((_, index) => (
            <tr key={index}>
              <th scope="row"><Placeholder /></th>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              <td><Placeholder /></td>
              {isB2CCustomer && <td className={styles.trackingCell}><Placeholder /></td>}
              <td><Placeholder /></td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
};

ReturnReceiptsList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    orderId: PropTypes.string,
    orderDate: PropTypes.string.isRequired,
    url: PropTypes.string,
    billingAddress: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    shipping: PropTypes.shape({
      tracking: PropTypes.object,
      address: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }).isRequired,
  })),
  isB2CCustomer: PropTypes.bool.isRequired,
  culture: PropTypes.string,
};

const mapStateToProps = ({
  user: { customerType },
  localization: { currentLanguage },
}) => ({
  isB2CCustomer: customerType === CustomerTypes.B2C,
  culture: currentLanguage && currentLanguage.cultureName,
});

export default connect(mapStateToProps)(ReturnReceiptsList);
