import styles from '../../Checkout.module.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SimpleText, RichText } from 'components/sanaText';
import { ShippingAddressOption, Steps } from 'behavior/pages/checkout';
import { Radio } from 'components/primitives/form';
import AddressSelection from './AddressSelection';
import AddressForm from './AddressForm';
import LoadingIndicator from '../StepLoadingIndicator';
import { renderHTML } from 'utils/render';
import scrollIntoView from 'scroll-into-view';
import { useLayoutShifter } from 'utils/layout';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import StepDoneMark from '../StepDoneMark';

const AddressStep = ({ className, isQuote, isPromotion, asLink, isCompleted, isGuest, children }) => {
  const linkTextKey = isGuest
    ? 'CheckoutStep_GuestAddress'
    : isQuote ? 'QuoteCheckoutStep_ShippingAddress' : 'CheckoutStep_ShippingAddress';

  const linkText = <SimpleText textKey={linkTextKey} />;

  return (
    <section className={`${className} ${isCompleted ? styles.completed : ''}`}>
      <div className={styles.header}>
        <h2>
          {asLink
            ? (
              <Link to={isPromotion ? routesBuilder.forQuotePromotion : routesBuilder.forCheckout(isQuote, Steps.Address, isGuest)}>
                {linkText}
              </Link>
            )
            : linkText
          }
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

AddressStep.propTypes = {
  className: PropTypes.string,
  isQuote: PropTypes.bool,
  isCompleted: PropTypes.bool,
  asLink: PropTypes.bool,
  children: PropTypes.node,
  isGuest: PropTypes.bool,
  isPromotion: PropTypes.bool,
};

export default AddressStep;

// eslint-disable-next-line react/no-multi-comp
export const AddressStepBody = ({
  shippingOptionState,
  selectedAddressId,
  shippingAddresses,
  billingAddress,
  templateFields,
  customFields,
  onOptionChange,
  onAddressSelection,
  onAddressFormSubmit,
  submitFormOnBlur = false,
  formikRef,
  showLoading = false,
  showSelectionValidation,
  onAddressFormValidate,
}) => {
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();

  const [shippingOption, setShippingOption] = shippingOptionState;

  const isBillingAddressAvailable = useHasAbilities(AbilityTo.ShipToBillingAddress)[0] && !!billingAddress,
    isShippingAddressesAvailable = shippingAddresses && shippingAddresses.length > 0,
    isCustomAddressAvailable = !!(templateFields && templateFields.length);

  const isBilling = shippingOption === ShippingAddressOption.Billing;
  const isExisting = shippingOption === ShippingAddressOption.Existing;
  const isCustom = shippingOption === ShippingAddressOption.Custom;

  useEffect(() => {
    const element = document.querySelector('input[name=shippingChoice]:checked');
    if (!element)
      return;

    const rect = element.getBoundingClientRect();

    if (rect.top < topFixedElementsHeight || rect.bottom > window.innerHeight - bottomFixedElementsHeight)
      scrollIntoView(element);

  }, [shippingOption]);

  const onChange = e => {
    const option = e.target.value;
    setShippingOption(option);

    onOptionChange && onOptionChange(option);
  };

  return (
    <div className={styles.addressStepWrapper}>
      <div className={styles.description}>
        <RichText textKey="OrderAddress_ShippingAddressSelectHeader" />
      </div>
      <div>
        {isBillingAddressAvailable && (
          !isShippingAddressesAvailable && !isCustomAddressAvailable
            ? renderAddress(billingAddress)
            : (
              <>
                <Radio name="shippingChoice"
                  checked={isBilling}
                  onChange={onChange}
                  value={ShippingAddressOption.Billing}
                  className={styles.option}
                >
                  <SimpleText textKey="DeliverOrderToBillingAddress" />
                </Radio>
                {isBilling && renderAddress(billingAddress)}
              </>
            )
        )}
        {isShippingAddressesAvailable && (
          !isBillingAddressAvailable && !isCustomAddressAvailable
            ? shippingAddresses.length > 1
              ? (
                <AddressSelection
                  addresses={shippingAddresses}
                  currentId={selectedAddressId}
                  onSelection={onAddressSelection}
                  showValidation={showSelectionValidation}
                />
              )
              : renderAddress(shippingAddresses[0])
            : (
              <>
                <Radio name="shippingChoice"
                  checked={isExisting}
                  onChange={onChange}
                  value={ShippingAddressOption.Existing}
                  className={styles.option}
                >
                  <SimpleText textKey="DeliverOrderToAddressFromBook" />
                </Radio>
                {isExisting &&
                  <AddressSelection
                    addresses={shippingAddresses}
                    currentId={selectedAddressId}
                    onSelection={onAddressSelection}
                    showValidation={showSelectionValidation}
                  />
                }
              </>
            )
        )}
        {isCustomAddressAvailable &&
          <>
            <Radio name="shippingChoice"
              checked={isCustom}
              onChange={onChange}
              value={ShippingAddressOption.Custom}
              className={styles.option}
            >
              <SimpleText textKey="DeliverOrderToCustomAddress" />
            </Radio>
            {isCustom &&
              <AddressForm name="address"
                templateFields={templateFields}
                values={customFields}
                onSubmit={onAddressFormSubmit}
                onFormValidate={onAddressFormValidate}
                submitOnBlur={submitFormOnBlur}
                formikRef={formikRef}
              />
            }
          </>
        }
      </div>
      {showLoading && <LoadingIndicator />}
    </div>
  );
};

AddressStepBody.propTypes = {
  shippingOptionState: PropTypes.array,
  selectedAddressId: PropTypes.string,
  shippingAddresses: PropTypes.array,
  billingAddress: PropTypes.object,
  templateFields: PropTypes.array,
  customFields: PropTypes.object,
  onOptionChange: PropTypes.func,
  onAddressSelection: PropTypes.func,
  onAddressFormSubmit: PropTypes.func,
  submitFormOnBlur: PropTypes.bool,
  formikRef: PropTypes.object.isRequired,
  showLoading: PropTypes.bool,
  isQuote: PropTypes.bool,
  showSelectionValidation: PropTypes.bool,
  onAddressFormValidate: PropTypes.func,
};

// eslint-disable-next-line react/no-multi-comp
function renderAddress(address) {
  return <div className={styles.formatted}>{renderHTML(address.formatted)}</div>;
}
