import { ROUTES_LOADED } from './actions';
import { LANGUAGE_CHANGED } from 'behavior/events';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case ROUTES_LOADED:
      return { ...state, ...action.payload };
    case LANGUAGE_CHANGED:
      const newState = {};

      for (const key of Object.keys(state))
        newState[key] = { ...state[key], expired: true };

      return newState;
    default:
      return state;
  }
}