exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".DateSelect_container .DateSelect_select{margin-bottom:.3em}.DateSelect_container .DateSelect_select:not(:last-child){margin-right:.3em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/form/dateSelect/DateSelect.module.scss"],"names":[],"mappings":"AAAA,yCACI,kBACJ,CACI,0DACI,iBACR,CAAA","file":"DateSelect.module.scss","sourcesContent":[".container .select {\n    margin-bottom: 0.3em;\n\n    &:not(:last-child) {\n        margin-right: 0.3em;\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "DateSelect_container",
	"select": "DateSelect_select"
};