import styles from './BasketB2B.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { taxFocusModePropType, taxAmountPositionPropType, taxDisplayModePropType } from 'behavior/settings/propTypes';
import { TaxFocusMode, TaxAmountPosition, TaxDisplayMode } from 'behavior/settings/constants';
import { SimpleText } from 'components/sanaText';
import ShippingCostLabel from '../ShippingCostLabel';
import { makeRichText } from 'utils/render';
import { getCurrency, getFormatPrice } from 'utils/format';
import { useIsQuickOrderMode } from '../hooks';
import { EmptyPriceSymbol } from 'utils/product';
import { useIsPreview } from 'components/objects/preview';

const Totals = ({ basket, currencyInfo, taxSettings }) => {
  const isQuickOrderMode = useIsQuickOrderMode();
  const { totals, shippingCost, paymentCost, discount, tax } = basket;
  const isPreview = useIsPreview();

  const totalUnit = basket.totalCount && (
    <div>
      <dt className={styles.totalUnit}>
        <SimpleText textKey="Basket_UnitTotal" formatWith={[basket.totalCount, basket.productLines.totalCount]} />
      </dt>
      <dd style={{ display: 'none' }} />
    </div>
  );

  if (!totals || !basket.totalCount) {
    if (totalUnit)
      return <dl className={styles.totals}>{totalUnit}</dl>;

    return null;
  }

  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);
  const formatAsPrice = getFormatPrice(currencyInfo, false);

  const itemsCountLbl = <SimpleText textKey="Basket_ItemsCount" formatWith={[basket.productLines.totalCount]} />;

  return (
    <dl className={styles.totals}>
      {item(itemsCountLbl, totals.sub, styles.items)}

      {shippingCost && !!shippingCost.price && item(<ShippingCostLabel />, shippingCost.price, styles.shippingCost)}

      {paymentCost && !!paymentCost.price && item(<SimpleText textKey="PaymentCosts" />, paymentCost.price, styles.paymentCost)}

      {discount.promotion && !!discount.promotion.amount && item(makeRichText(discount.promotion.title), -discount.promotion.amount, styles.promotion)}

      {!!discount.invoice && item(<SimpleText textKey="InvoiceDiscount" />, -discount.invoice, styles.invoiceDiscount)}

      {!!totals.roundOff && item(<SimpleText textKey="RoundOff" />, totals.roundOff, styles.roundOff)}

      {taxSettings.totalExclTax && total(totals.priceExcludingTax, true)}

      {taxSettings.amountPosition === TaxAmountPosition.Below && total(totals.price)}

      {taxSettings.mode !== TaxDisplayMode.Hide && (
        taxSettings.mode === TaxDisplayMode.Detailed
          ? tax.taxes && tax.taxes.map((tax, i) => item(tax.description, tax.amount, styles.taxLine, i))
          : item(<SimpleText textKey="TotalVAT" />, tax.amount, styles.tax)
      )}
      {!!discount.payment && item(<SimpleText textKey="PaymentDiscount" />, -discount.payment, styles.paymentDiscount)}

      {taxSettings.amountPosition === TaxAmountPosition.Above && total(totals.price)}

      {totalUnit}
    </dl>
  );

  // eslint-disable-next-line react/no-multi-comp
  function item(lbl, price, className = null, key = undefined) {
    const negative = price < 0;
    return (
      <div className={className} key={key}>
        <dt>{lbl}</dt>
        <dd>
          <span className="visually-hidden">{negative && '-'}{currencySymbol + (negative ? -price : price)}</span>
          <span aria-hidden>{currencyInfo.symbol}</span>
          <span className={styles.totalsValue} aria-hidden>
            {isQuickOrderMode || isPreview ? EmptyPriceSymbol : formatAsPrice(price)}
          </span>
        </dd>
      </div>
    );
  }

  function total(price, exclTax) {
    const focus = taxSettings.focus === (exclTax ? TaxFocusMode.Excl : TaxFocusMode.Incl);
    const textKey = focus
      ? 'Total'
      : exclTax ? 'Basket_TotalExVat' : 'TotalIncVat';

    const className = styles[exclTax ? 'priceExclTax' : 'priceInclTax'] + ' ';
    return item(<SimpleText textKey={textKey} />, price, className + (focus ? styles.emphasis : ''));
  }

};

Totals.propTypes = {
  basket: PropTypes.shape({
    totals: PropTypes.shape({
      sub: PropTypes.number.isRequired,
      roundOff: PropTypes.number.isRequired,
      priceExcludingTax: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
    }),
    discount: PropTypes.shape({
      invoice: PropTypes.number.isRequired,
      payment: PropTypes.number.isRequired,
    }),
    productLines: PropTypes.shape({
      totalCount: PropTypes.number,
    }).isRequired,
    shippingCost: PropTypes.shape({ price: PropTypes.number }),
    paymentCost: PropTypes.shape({ price: PropTypes.number }),
    tax: PropTypes.shape({
      amount: PropTypes.number,
      taxes: PropTypes.arrayOf(PropTypes.shape({
        amount: PropTypes.number,
        description: PropTypes.string,
      }).isRequired),
    }),
  }).isRequired,
  currencyInfo: PropTypes.shape({
    id: PropTypes.string,
    cultureName: PropTypes.string,
  }).isRequired,
  taxSettings: PropTypes.shape({
    totalExclTax: PropTypes.bool.isRequired,
    focus: taxFocusModePropType.isRequired,
    amountPosition: taxAmountPositionPropType.isRequired,
    mode: taxDisplayModePropType.isRequired,
  }).isRequired,
};

export default connect(({ basket, settings }) => ({
  basket: basket.model,
  currencyInfo: settings.currency,
  taxSettings: settings.tax,
}))(Totals);
