import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { createLabelId } from '../../helpers';

const ReadonlyFieldLabel = ({ fieldName, children, className }) => {
  const formik = useFormikContext();
  const labelId = createLabelId(formik.status.formName, fieldName);

  return (
    <span id={labelId} className={className}>{children}</span>
  );
};

ReadonlyFieldLabel.propTypes = {
  fieldName: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default React.memo(ReadonlyFieldLabel);
