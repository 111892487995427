import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const OrderAuthorizers = ({ authorizers, className }) => {
  if (!authorizers || !authorizers.length)
    return null;

  return (
    <div className={className}>
      <SimpleText textKey="OrderAuthorizers_Intro" />
      <div>{authorizers.join(', ')}</div>
    </div>
  );
};

OrderAuthorizers.propTypes = {
  authorizers: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string,
};

export default React.memo(OrderAuthorizers);
