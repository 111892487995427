import styles from './ShoppingCartSuggestions.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UseSanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { AbilityTo } from 'behavior/user/constants';
import { routeNames } from 'routes';
import { CartSuggestionIcon } from 'components/primitives/icons';

const productSuggestionsAbilityKey = [AbilityTo.ViewProductSuggestions];

const ShoppingCartSuggestions = ({ shoppingCartSuggestionPage, hideLabel, className = '' }) => {
  if (shoppingCartSuggestionPage == null)
    return null;

  const hideLabelClassName = hideLabel ? `${styles.hideLabel} ` : '';

  return (
    <UseSanaButton textKey="GetProductSuggestions">
      {(text, title) => (
        <Link
          to={shoppingCartSuggestionPage.to}
          title={title || text}
          className={`${hideLabelClassName}${styles.suggestionsBtn} ${btnStyles.btnWhite} ${className}`}
          role="button"
        >
          <CartSuggestionIcon alt={title || text} className={styles.icon} aria-hidden />
          {!hideLabel && (
            <span className="btn-cnt">{text}</span>
          )}
        </Link>
      )}
    </UseSanaButton>
  );
};

ShoppingCartSuggestions.propTypes = {
  shoppingCartSuggestionPage: PropTypes.shape({
    to: PropTypes.shape({
      routeName: PropTypes.string,
      params: PropTypes.object,
    }),
  }),
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

const ignoredRoutes = [
  routeNames.BasketPage,
  routeNames.Checkout,
];

const wrappedShoppingCartSuggestions = withAbilitiesAndRouteChecker(
  connect(({ settings: { shoppingCartSuggestionPage } }) => ({ shoppingCartSuggestionPage }))(ShoppingCartSuggestions),
  ignoredRoutes,
  productSuggestionsAbilityKey,
);

export default wrappedShoppingCartSuggestions;
