import styles from '../PLP.module.scss';
import React, { useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import ListContext from './ListContext';
import { useOmitNavigation } from 'behavior/routing';
import { noop } from 'rxjs';
import { connect } from 'react-redux';
import { addToBasket } from './AddToBasketButton';
import { addProducts } from 'behavior/basket';
import { PagingTypes } from 'behavior/pages/productList';
import { useOnChange, useOnCustomerChanged } from 'utils/hooks';
import { HashRoutingContext } from 'components/hash';
import scrollIntoView from 'scroll-into-view';
import useConfirmation, { HideReason } from 'components/objects/confirmation';
import { plpId, searchPageId } from '../constants';
import { routeNames } from 'routes';
import { useSelector } from 'react-redux';

const SelectionLostPopup = ({ routeName, routeParams, pagingType, addProducts, location, restoreSorting }) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const { navigateTo } = useContext(HashRoutingContext);
  const { quantities, resetQuantities } = useContext(ListContext);
  const scrollToTopRef = useRef(false);
  const restoreSortingRef = useRef(false);

  function navigateToTop() {
    if (!scrollToTopRef.current)
      return;

    scrollToTopRef.current = false;

    const id = routeName === routeNames.Search ? searchPageId : plpId;
    navigateTo('#' + id, { time: 10 });
  }

  const shouldPostponeNavigation = useMemo(() => {
    if (!quantities.size)
      return noop;

    return routeData => {
      const changed = checkIfPageIsChanged(routeData.params, routeParams, pagingType);
      if (changed) {
        const onCancel = reason => {
          discardAndContinue();
        };

        //GF OP18938 Do not allow the user to add items to basket as it could be a mix of spares and products.
        showConfirmation(
          onCancel,
          'ProductListSelectionLostPopup_Title',
          <RichText textKey="ProductListSelectionLostPopup_DescriptionNew" placeholder={<Placeholder />} />,
          {
            okText: 'ButtonText_ProductListSelectionLostClearSelection',
            okTextAlt: 'ButtonAltText_ProductListSelectionLostClearSelection',
          },
          undefined,
          styles.selectionLostPopup,
        );
        scrollToTopRef.current = shouldScrollToTop(routeData.params, routeParams, pagingType);
        if (shouldRestoreSorting(routeData.params, routeParams))
          restoreSortingRef.current = true;
      }

      return changed;
    };
  }, [routeParams, pagingType, !!quantities.size]);

  const { resume, discard } = useOmitNavigation(shouldPostponeNavigation);

  useOnCustomerChanged(resetQuantities);
  useOnChange(([navigatingParams]) => {
    if (checkIfPageIsChanged(navigatingParams, routeParams, pagingType))
      resetQuantities();

  }, [routeParams, location], false);

  const close = () => {
    discard();

    if (restoreSortingRef.current)
      restoreSorting();

    scrollToTopRef.current = false;
    restoreSortingRef.current = false;
  };

  return confirmationModal;

  function discardAndContinue() {
    navigateToTop();
    resetQuantities();
    resume();
    clearConfirmation();
    restoreSortingRef.current = false;
  }

  function confirmAndContinue() {
    const invalidProductId = getFirstInvalidQuantityProductId(quantities);
    if (invalidProductId) {
      close();
      const invalidQuantityInput = document.getElementById(`${invalidProductId}_qty`);
      if (invalidQuantityInput)
        scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

      return;
    }

    navigateToTop();
    addToBasket(quantities, resetQuantities, addProducts, pageProducts);
    setTimeout(resume, 0);
    restoreSortingRef.current = false;
  }
};

SelectionLostPopup.propTypes = {
  routeName: PropTypes.string,
  routeParams: PropTypes.shape({
    viewMode: PropTypes.string,
    language: PropTypes.number,
    facets: PropTypes.object,
    sort: PropTypes.object,
    page: PropTypes.number,
  }).isRequired,
  location: PropTypes.object,
  addProducts: PropTypes.func.isRequired,
  pagingType: PropTypes.string,
  restoreSorting: PropTypes.func.isRequired,
};

const mapStateToProps = ({ routing: { routeData, location } }) => ({
  routeName: routeData.routeName,
  routeParams: routeData.params,
  location,
});

export default connect(mapStateToProps, { addProducts })(SelectionLostPopup);

function checkIfPageIsChanged(navigatingParams, currentParams, pagingType) {
  if (!navigatingParams)
    return true;

  const pageIsChanged = pagingType === PagingTypes.Links
    ? navigatingParams.page !== currentParams.page
    : navigatingParams.page < currentParams.page;

  if (pageIsChanged)
    return true;

  return (
    navigatingParams.id !== currentParams.id
    || navigatingParams.viewMode !== currentParams.viewMode
    || navigatingParams.language !== currentParams.language
    || navigatingParams.facets !== currentParams.facets
    || navigatingParams.sort !== currentParams.sort
  );
}

function shouldScrollToTop(navigatingParams, currentParams, pagingType) {
  if (!navigatingParams)
    return false;

  if (pagingType !== PagingTypes.Links)
    return false;

  return navigatingParams.page !== currentParams.page;
}

function shouldRestoreSorting(navigatingParams, currentParams) {
  if (!navigatingParams)
    return false;

  return navigatingParams.sort !== currentParams.sort;
}

function getFirstInvalidQuantityProductId(quantities) {
  for (const [productId, value] of quantities) {
    if (value instanceof Map)
      continue;

    if (!value.quantity.isValid)
      return productId;
  }

  return null;
}
