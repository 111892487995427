import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Field, { fieldPropTypes } from '../Field';
import { Input as Primitive } from 'components/primitives/form';

const decimalRegEx = /^-?\d*(?:[.,]\d*)?$/;

const componentBuilder = getDecimalPrimitiveBuilder(Primitive);

const DecimalField = ({ fieldName, fieldTitle, validation, asCurrency, ...attributes }) => (
  <Field
    type="text"
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    asCurrency={asCurrency}
    component={componentBuilder}
    {...attributes}
  />
);

DecimalField.propTypes = {
  ...fieldPropTypes,
  asCurrency: PropTypes.bool,
};

export default DecimalField;

function getDecimalPrimitiveBuilder(Primitive) {
  // eslint-disable-next-line react/no-multi-comp
  return ({ field, form, asCurrency, ...rest }) => {
    const fieldStatus = useRef({}).current;
    const decimalSeparator = form.status[asCurrency ? 'currencySeparator' : 'separator'];

    if (!fieldStatus.separator && decimalSeparator !== '.')
      fieldStatus.separator = decimalSeparator;

    const displayValue = getDisplayValue(fieldStatus, field.value);
    const onChange = parseAndHandleChange(parseValue.bind(null, fieldStatus), form.setFieldValue);

    return <Primitive {...field} {...rest} value={displayValue} onChange={onChange} />;
  };
}

const parseAndHandleChange = (handleParse, setFieldValue) => e => {
  if (!decimalRegEx.test(e.target.value))
    return;

  const value = handleParse(e.target.value);
  setFieldValue(e.target.name, value);
};

function parseValue(fieldStatus, value) {
  if (!value || !value.replace)
    return value;
  fieldStatus.separator = null;
  const replaced = value.replace(',', () => {
    fieldStatus.separator = ',';
    return '.';
  });
  fieldStatus.display = value;
  return fieldStatus.value = replaced.slice(-1) === '.' ? NaN : +replaced;
}

function getDisplayValue(fieldStatus, value) {
  if (fieldStatus.value === value || (isNaN(value) && isNaN(fieldStatus.value)))
    value = fieldStatus.display || '';
  else if (fieldStatus.separator && fieldStatus.separator !== '.')
    value = value.toString().replace('.', fieldStatus.separator);
  return value;
}