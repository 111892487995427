import styles from './MainNav.module.scss';
import React, { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { useLayoutShifter } from 'utils/layout';
import { NavigationKeys } from 'behavior/navigation/constants';
import { useIsMobileSafari } from 'utils/detections';

const iOSBottomIndent = +styles.iOSBottomIndent;
const resizeRefreshOptions = { leading: false, trailing: true };

export const NavDimensionsContext = createContext(null);

const NavDimensionsProvider = ({ containerRef, navRef, children }) => {
  const [navDimensions, setNavDimensions] = useState(null);
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();
  const shouldSetDimensions = !!containerRef.current && !!navRef.current;
  const mainNavItems = useSelector(({ navigation }) => navigation[NavigationKeys.Main], shallowEqual);
  const isMobileSafari = useIsMobileSafari();

  const handleResize = useCallback(() => {
    setCurrentNavDimensions(
      containerRef.current,
      navRef.current,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      setNavDimensions,
      isMobileSafari,
    );
  }, [topFixedElementsHeight, bottomFixedElementsHeight, isMobileSafari]);

  useEffect(() => {
    if (!shouldSetDimensions)
      return;

    setCurrentNavDimensions(
      containerRef.current,
      navRef.current,
      topFixedElementsHeight,
      bottomFixedElementsHeight,
      setNavDimensions,
      isMobileSafari,
    );
  }, [shouldSetDimensions, mainNavItems, topFixedElementsHeight, bottomFixedElementsHeight, isMobileSafari]);

  return (
    <NavDimensionsContext.Provider value={navDimensions}>
      {shouldSetDimensions &&
        <ReactResizeDetector
          handleWidth
          handleHeight
          refreshMode="debounce"
          refreshRate={500}
          refreshOptions={resizeRefreshOptions}
          onResize={handleResize}
          targetDomEl={containerRef.current.parentElement}
        />
      }
      {children}
    </NavDimensionsContext.Provider>
  );
};

export default React.memo(NavDimensionsProvider);

NavDimensionsProvider.propTypes = {
  containerRef: PropTypes.object,
  navRef: PropTypes.object,
  children: PropTypes.node,
};

function setCurrentNavDimensions(containerElement, navElement, topShift, bottomShift, setNavDimensions, isMobileSafari) {
  const [listRootElement] = navElement.children;
  const { clientHeight } = containerElement;
  const { offsetWidth, clientWidth } = listRootElement;
  const listRootWidth = offsetWidth - (offsetWidth - clientWidth) / 2;
  let menuMaxHeight = window.innerHeight - bottomShift - topShift;

  if (isMobileSafari)
    menuMaxHeight = menuMaxHeight + bottomShift - iOSBottomIndent;

  setNavDimensions({
    navContainerWidth: containerElement.parentElement.offsetWidth,
    navWidth: navElement.clientWidth,
    listRootWidth,
    menuMaxHeight: clientHeight > menuMaxHeight ? menuMaxHeight : clientHeight,
  });
}