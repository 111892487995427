import React from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'utils/render';
import { TotalsLine } from './TotalsLine';

const TaxLines = ({ lines, currencyInfo }) => {
  if (!lines)
    return null;

  return lines.map(({ description, amount }, index) => (
    <TotalsLine
      key={index}
      heading={renderHTML(description)}
      currencyInfo={currencyInfo}
      value={amount}
    />
  ));
};

TaxLines.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    amount: PropTypes.number.isRequired,
  })),
  currencyInfo: PropTypes.object.isRequired,
};

export default TaxLines;
