import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import {
  ProductPrice,
  PricePer,
  ProductStock,
  OfflinePriceWarning,
  useCanViewUom,
  UomSelector,
} from 'components/primitives/product';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import OrderBoxForm from './OrderBoxForm';
import { SanaButton } from 'components/primitives/buttons';
import { VariantDropdowns } from './variantDropdowns';
import { VolumePricesButton } from './volumePrices';
import { showMatrix } from './showMatrix';
import { Placeholder } from 'components/primitives/placeholders';
import { usePrintMode } from 'utils/hooks';
import ConfigureButton from '../productConfigurator/ConfigureButton';
import { BomComponents } from './bomComponents';
import { VariantsMatrixPopup } from './variantsMatrix';
import { AddToWishlist } from './wishlist';
import { useModal } from 'components/objects/modals';
import { StockAlertDialog } from 'components/objects/stockAlertDialog';
import { Modal } from 'components/objects/modals';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import Page from '../productList/modal/Page';
import { DangerAlert } from '../../../../adk/webstore/elements';

const OrderBox = ({ matrixPreset, abilities: [orderProductsAbility], wishListEnabled, allowUOMSelection }) => {
  const { product, calculatedInfo, pricesInclTax, updateUomId, uomId: selectedUomId } = useProductContext();
  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();

  if (!product)
    return null;

  const basketSummary = useSelector(x => x.basket.summary);

  //GF OP18938 Work out if the basket contains a spare or a product. It might be empty.
  let basketContainsSpare = false;
  let basketContainsProduct = false;

  if (basketSummary !== null && basketSummary !== undefined && basketSummary.productLines !== null && basketSummary.productLines !== undefined && basketSummary.productLines.list?.length > 0 && basketSummary.productLines.list[0] !== undefined) {
    if (basketSummary.productLines.list[0].product.isSpare) {
      basketContainsSpare = true;
    } else {
      basketContainsProduct = true;
    }
  }
  else {
  }

  const addingSpareToProductBasket = product.isSpare && basketContainsProduct;
  const addingProductToSpareBasket = (product.isSpare === false) && basketContainsSpare;

  const { price, listPrice, inventory, isOrderable } = calculatedInfo;

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    productConfiguratorInfo,
    hasVolumePrices,
  } = product;

  const uomId = canViewUom && product.uom && product.uom.id;
  const canOrderProducts = orderProductsAbility === AbilityState.Available;
  const discontinuedSoCantOrder = (product.discontinued && product.inventory <= 0);
	const obsoleteSoCantOrder = product.obsolete;

  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  const taxSuffixPlaceholder = <Placeholder className={styles.taxSuffixPlaceholder} />;
  const availabilityTextPlaceholder = <Placeholder className={styles.availabilityTextPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const priceRowPlaceholder = <Placeholder className={styles.priceRowPlaceholder} />;
  const availabilityPlaceholder = <Placeholder className={styles.availabilityPlaceholder} />;

  const { opened, toggle } = useModal();
  const [overInv, setOverInv] = useState([]);
  const [modalStatus, setModalStatus] = useState({
    show: false,
    onClose: false,
    closeable: true,
    disabled: false,
  });

  // LINKED ITEMS
  const [onModalPage, setOnModalPage] = useState(false); // MAKE SURE PRODUCTS SELECTED ONLY EFFECTED ON MODAL PAGE
  const [closeError, setCloseError] = useState(false); // DISPLAY ERROR IF MODAL STILL HAS PRODUCTS SELECTED BEFORE HIDING MODAL
  const [modalProductsSelected, setModalProductsSelected] = useState([]); // KEEP TRACK OF ALL PRODUCTS SELETED ON EACH TAB
  const [currentModalPageSelected, setCurrentModalPageSelected] = useState(0); // KEEP TRACK OF CURRENT MODAL TAB PAGE INDEX
  const [currentModalQuantitySelected, setCurrentModalQuantitySelected] = useState(0); // KEEP TRACK OF CURRENT MODAL TAB QUANITY OF PRODUCTS SELECTED  

  useEffect(() => {
    if (onModalPage) {
      if (!modalProductsSelected[currentModalPageSelected] || currentModalQuantitySelected !== modalProductsSelected[currentModalPageSelected]) {
        setModalProductsSelected({
          ...modalProductsSelected,
          [currentModalPageSelected]: currentModalQuantitySelected,
        });            
      }
      if (currentModalQuantitySelected === 0) {
        setCloseError(false);
      }
    }
  }, [currentModalPageSelected, currentModalQuantitySelected]);    

  const handleAddToBasketClick = e => {
    const qtyInput = document.getElementById('quantity_Input');
    const quantity = parseInt(qtyInput.value);

    if (quantity > inventory) {
      setOverInv([{ id: product.id, quantity, inv: inventory }]);
      toggle();
    }

    // NEED TO CHECK IF PRODUCT HAS LINKED ITEMS THEN DISPLAY MODAL
    if (product.linkedItemsGroups && product.linkedItemsGroups.length > 0) {
      setModalStatus({
        show: true,
        onClose: false,
        closeable: true,
        disabled: false,
      });    
    }
  };

  if (matrixPreset && product.hasVariants) {
    const matrixSupported = product.variantComponentGroups?.length <= 2;
    if (isPrintMode && matrixSupported)
      return null;

    const calculationInfoIsLoaded = product.variants.some(v => v.isOrderable !== undefined);

    if (matrixSupported && !calculationInfoIsLoaded && productOrderable)
      return btnPlaceholder;

    if (productOrderable && showMatrix(product)) {
      const showBomComponents = !!product.bomComponents?.length;
      return (
        <>
          <VariantsMatrixPopup />
          {showBomComponents && <BomComponents />}
          {hasVolumePrices && <VolumePricesButton showCompleteList canOrderProducts={canOrderProducts} />}
        </>
      );
    }
  }

  const shouldShowOrderBox = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && isOrderable && productOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowOrderBox)
    return null;

  const isProductOrderable = isOrderable && productOrderable && !discontinuedSoCantOrder && !obsoleteSoCantOrder;
  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);

  let orderBox = null;
  if (!isPrintMode) {
    if (!isPrintMode && canOrderProducts && isProductOrderable && !addingSpareToProductBasket && !addingProductToSpareBasket) {
      orderBox = (
        <OrderBoxForm>
          <StockAlertDialog  opened={opened} toggle={toggle} elements={overInv} />
          <QuantityBox disabled={!isProductOrderable} />
          {isProductOrderable &&
            <Row className={styles.row} crossAxisAlign="center">
              <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
                {productConfiguratorInfo.isProductConfigurable
                  ? <ConfigureButton />
                  : (
                    <SanaButton
                      textKey="AddToBasket"
                      type="submit"
                      className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.addToBasketBtn}`}
                      placeholder={btnPlaceholder}
                      onClick={handleAddToBasketClick}
                    />
                  )
                }
              </Col>
              <Col xs={12} className={styles.gutter} />
              <Col xs={12} sm="auto" className={styles.limitedWidth}>
                {showAddToWishList &&
                  <div className={styles.afterBtnLinks}>
                    <AddToWishlist />
                  </div>
                }
              </Col>
            </Row>
          }
        </OrderBoxForm>
      );
    }
    else if (uomId && allowUOMSelection && product.uoms?.length > 1) {
      orderBox = (
        <Row>
          <Col xs={{ size: 9, offset: 3 }} lg={{ size: 10, offset: 2 }}>
            <UomSelector
              className={styles.uom}
              productId={product.id}
              allowUOMSelection={allowUOMSelection}
              uomId={selectedUomId || uomId}
              uoms={product.uoms}
              onUomChange={updateUomId}
              isOrderable={isOrderable}
            />
          </Col>
        </Row>
      );
    }
  }

  const productsSelected = selected => {
    setOnModalPage(true);
    setCurrentModalPageSelected(selected.pageIndex);
    setCurrentModalQuantitySelected(selected.quantity);
  };

  const handleLinkedItemsHide= e => {
    if (modalProductsSelected[currentModalPageSelected] && modalProductsSelected[currentModalPageSelected] > 0) {
      setCloseError(true);
    } else {
      setOnModalPage(false);
      setCloseError(false);
      setModalStatus({
        show: false,
        onClose: false,
        closeable: true,
        disabled: false,
      });
    }
  };

  const linkedItemProducts = product.linkedItemsGroups.map(linkedItem => {
    const standardFields = {
      url: '',
      uom: {
        id: 'PCS',
      },
      uoms: [
        {
            id: 'PCS',
            description: 'Pieces',
            minimumQuantity: null,
            defaultQuantity: null,
            quantityStep: 1,
        },
      ],      
      productConfiguratorInfo: {
        isProductConfigurable: false,
      },
      calculated: true,
      calculatedInfoChanged: true,
      specifications: [],
      variantComponentGroups: [],
      hideCompare: true,
    };

    linkedItem = {
      ...linkedItem,
      stockLevels,
      ...standardFields,
    };

    return linkedItem;
  });

  // GENERATE LINKED PRODUCTS MODAL PAGE PROPS
  const LinkedObjects = {
    products: linkedItemProducts,
    totalCount: linkedItemProducts.length,
    selectedSorting: { field: 'Id', ascending: true },
    sortingEnabled: false,
    selectedViewMode: 'List',
    viewModeSwitchEnabled: false,
    pagingType: 'LoadMore',
    showThumbnails: false,
    lastViewedEnabled: false,
    pageTitle: 'Linked Products',
  };

  // FETCH BASKET TOTALS FOR CURRENT PROJECT
  const projectTotalBasketOrders = (basketSummary && basketSummary.productLines) ? basketSummary.productLines.list.filter(item => {
    return item.product.id === product.id;
  }) : [];

  return (
    <>
      {!isPrintMode && <BomComponents />}
      <div className={styles.orderBox}>
        {price !== null && !isPrintMode && (
          typeof price !== 'undefined'
            ? (
              <Row className={styles.row} crossAxisAlign="center">
                {!product.obsolete && <Col xs={3} lg={2} className={styles.ellipsis} id="price_Label">
                  <SimpleText textKey="Price" placeholder={pricePlaceholder} />
                </Col>}
                { !product.obsolete && <Col xs={9} lg={10} className={styles.field}>
                  <Row className={styles.prices}>
                    <ProductPrice salesPrice={price} basePrice={listPrice} />
                    <Col xs={12} sm="auto" className={styles.afterPrice}>
                      {uomId && <PricePer uomId={uomId} uoms={product.uoms} />}
                      <div>
                        {pricesInclTax != null && (
                          <SimpleText
                            textKey={pricesInclTax ? 'InclTaxSuffix' : 'ExclTaxSuffix'}
                            placeholder={taxSuffixPlaceholder}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  {!isPrintMode && hasVolumePrices && <VolumePricesButton canOrderProducts={canOrderProducts} />}
                </Col> }
                {!isPrintMode && (
                  <Col>
                    <OfflinePriceWarning />
                  </Col>
                )}
              </Row>
            )
            : priceRowPlaceholder
        )}
        {inventory !== null && stockLevels != null && !isPrintMode && !product.obsolete &&(
          <>
            {
              typeof inventory === 'number'
                ? (
                  <Row className={styles.row} crossAxisAlign="center">
                    <Col xs={3} lg={2} className={styles.ellipsis} id="availability_Label">
                      <SimpleText textKey="Availability" placeholder={availabilityTextPlaceholder} />
                    </Col>
                    <Col xs={9} lg={10} aria-labelledby="availability_Label">
                      { <ProductStock inventory={inventory} stockLevels={stockLevels} lowStockThreshold={product.lowStockThreshold} /> }
                    </Col>
                  </Row>
                )
                : availabilityPlaceholder
            }
          </>
        )}
        {productOrderable && hasVariants && <VariantDropdowns />}
        {!isPrintMode && addingSpareToProductBasket && (
          <div className={styles.cannotOrder}>
            <SimpleText textKey="Cannot_Add_Spare_To_ProductBasket_ProductPage" placeholder={availabilityTextPlaceholder} />
          </div>
        )}
        {!isPrintMode && addingProductToSpareBasket && (
          <div className={styles.cannotOrder}>
            <SimpleText textKey="Cannot_Add_Product_To_SpareBasket_ProductPage" placeholder={availabilityTextPlaceholder} />
          </div>
        )}
        { orderBox }
        {canOrderProducts && isProductOrderable === false && (
          <div className={styles.cannotOrder}>
            <RichText textKey={hasVariants ? 'ProductVariantCannotBeOrderedAtThisTime' : 'ProductCannotBeOrderedAtThisTime'} />
          </div>
        )}
      </div>
      <Modal opened={modalStatus.show} hide={handleLinkedItemsHide} className="wish-list-confirmation-modal">
        <div className={styles.modalContainer}>
          {closeError && (
            <DangerAlert role="alert" scrollOnAppear>
              <RichText textKey="LinkedItemsAlternateTabsWarning" />
            </DangerAlert>
          )}
          <RichText textKey="LinkedItemsModalTopText" /><br />
          {projectTotalBasketOrders && projectTotalBasketOrders.length > 0 && projectTotalBasketOrders[0].quantity} x {product.id}<br />
          <RichText textKey="LinkedItemsModalBottomText" /> 
          <br /><br />
          <Page {...LinkedObjects} key={'page_99'} pageIndex={99} productsSelected={productsSelected} />
          <div className={styles.modalButtonsBlock} style={{ marginTop: '-6%' }}>
            <SanaButton
              textKey="LinkedItemsCancel"
              className={`${buttonStyles.noIcon}`}
              onClick={handleLinkedItemsHide}
            />            
          </div>
        </div>
      </Modal>
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  matrixPreset: PropTypes.bool,
  wishListEnabled: PropTypes.bool,
  allowUOMSelection: PropTypes.bool,
};

const mapStateToProps = ({
  settings: {
    wishListEnabled,
    product,
  },
}) => ({
  wishListEnabled,
  allowUOMSelection: product && product.allowUOMSelection,
});

export default withAbilities(
  connect(mapStateToProps)(OrderBox),
  [AbilityTo.OrderProducts],
);
