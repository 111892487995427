import styles from './Basket.module.scss';
import React from 'react';
import BreadcrumbPrimitive from 'components/primitives/breadcrumb';
import { routesBuilder } from 'routes';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const BasketBreadcrumb = () => {
  const { texts: [ShoppingBasketHeaderText] } = useSanaTexts(['ShoppingBasket_Header'], makeSimpleText);

  const basketNode = {
    title: ShoppingBasketHeaderText || '',
    link: { to: routesBuilder.forBasket() },
  };

  return <BreadcrumbPrimitive items={[basketNode]} className={styles.breadcrumb} />;
};

export default BasketBreadcrumb;