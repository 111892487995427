import styles from './RemoveButton.module.scss';
import React from 'react';
import { useSanaTexts } from 'components/sanaText';
import { CrossBigIcon } from 'components/primitives/icons';

const RemoveButton = props => {
  const [title] = useSanaTexts(['ButtonAltText_ProductCompareSummary_RemoveItem']).texts;

  return (
    <button {...props} title={title}>
      <CrossBigIcon alt={title} className={styles.removeIcon} />
    </button>
  );
};

export default React.memo(RemoveButton);