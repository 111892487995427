import React from 'react';
import { Container } from 'components/primitives/grid';
import { Presets } from 'behavior/pages/basket';
import DetailsB2C from './b2c';
import DetailsB2B from './b2b';
import BasketTitle from './BasketTitle';
import { PageContent } from 'components/objects/pages';
import { PageComponentNames } from 'behavior/pages';
import BasketBreadcrumb from './Breadcrumb';
import CheckoutOffers from './checkoutOffers';

const presets = {
  [Presets.B2C]: DetailsB2C,
  [Presets.B2B]: DetailsB2B,
};

function basketRenderer(page) {
  const Preset = page.preset in presets
    ? presets[page.preset]
    : presets[Presets.B2C];
  let header, footer;
  if (page.content) {
    header = page.content.header;
    footer = page.content.footer;
  }
  return (
    <>
      <Container>
        <BasketBreadcrumb />
        <BasketTitle />
      </Container>
      <PageContent content={header} position="header" />
      <Container>
        <Preset />
      </Container>
      <CheckoutOffers />
      <PageContent content={footer} position="footer" />
    </>
  );
}

export default {
  [PageComponentNames.Basket]: basketRenderer,
};