import styles from './Error.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useRef } from 'react';
import { useSimpleTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useFocusActionBtn } from './hooks';

const ErrorToastWithReload = () => {
  const onClick = () => window.location.reload();

  const options = ['ReloadErrorMessageText', 'ButtonText_Reload', 'ButtonAltText_Reload'];
  options.disableInsiteEditor = true;

  const { texts: [msgText, btnText, btnAltText], loaded } = useSimpleTexts(options);

  const retryBtnRef = useRef();
  useFocusActionBtn(loaded && retryBtnRef);

  return (
    <div className={styles.body}>
      <p>
        {loaded
          ? msgText || 'Unfortunately there was an issue with retrieving the information for this page.'
          : <Placeholder className={styles.loading} />
        }
      </p>
      {loaded
        ? (
          <button
            ref={retryBtnRef}
            className={`${linkStyles.link} ${styles.actionBtn}`}
            title={btnAltText || 'Reload'}
            onClick={onClick}
          >
            {btnText || 'Reload'}
          </button>
        )
        : <Placeholder className={styles.loading} />
      }
    </div>
  );
};

export default ErrorToastWithReload;

export const preload = {
  routes: [],
  texts: [
    'ReloadErrorMessageText',
    'ButtonText_Reload',
    'ButtonAltText_Reload',
  ],
};
