import { orderTemplatesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { orderTemplatesReceived } from './actions';

export default (_data, _state$, { api }) =>
  api.graphApi(orderTemplatesPageQuery).pipe(
    map(({ pages: { orderTemplates: page }, orderTemplates }) => {
      const templatesAvailable = orderTemplates != null;

      if (!templatesAvailable) {
        page.component = PageComponentNames.NotFound;
        return { page };
      }

      page.component = PageComponentNames.OrderTemplates;

      return { page, action$: of(orderTemplatesReceived({ orderTemplates })) };
    }),
    initSystemPageContent(),
  );