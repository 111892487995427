import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { toLocaleTime } from 'utils/format';
import { useCultureName } from 'utils/hooks';

const ExpirationMessage = ({ modifiedDate, pendingChanges }) => {
  const culture = useCultureName();

  return (
    <p>
      <RichText textKey="BasketExpired_Message" formatWith={[toLocaleTime(modifiedDate, culture)]} />
      {pendingChanges
        ? <>{' '}<RichText textKey="BasketExpired_Pending" /></>
        : null
      }
    </p>
  );
};

ExpirationMessage.propTypes = {
  modifiedDate: PropTypes.number,
  pendingChanges: PropTypes.bool,
};

export default ExpirationMessage;