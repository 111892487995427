import styles from './Order.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useMemo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { joinClasses } from 'utils/helpers';
import { stringifyQuery, parseQuery } from 'utils/url';
import { makeSimpleText } from 'utils/render';

const PrintPreviewLink = ({
  isPrintMode,
  pathname,
  search,
  routeData,
}) => {
  const {
    texts: [linkText, linkAltText],
    loaded,
  } = useSanaTexts(['ButtonText_PrintPreview', 'ButtonAltText_PrintPreview'], makeSimpleText);

  const { to, url } = useMemo(() => {
    const query = parseQuery(search);
    query.print = '1';
    const url = pathname + stringifyQuery(query);

    return {
      to: routeData,
      url,
    };
  }, [pathname, routeData]);

  if (loaded)
    return (
      <Link to={to} url={url} rel="nofollow" omitScroll replaceHistory
        className={joinClasses(
          styles.printPreviewLink,
          linkStyles.link,
          linkStyles.arrowed,
          isPrintMode && styles.hidden,
        )}
        // tabIndex allows link to be focusable programmatically in Safari browser.
        tabIndex="0"
        title={linkAltText}
      >
        {linkText}
      </Link>
    );

  return <Placeholder className={`${styles.printPreviewPlaceholder} ${isPrintMode ? styles.hidden : ''}`} />;
};

PrintPreviewLink.propTypes = {
  isPrintMode: PropTypes.bool,
  pathname: PropTypes.any,
  search: PropTypes.string,
  routeData: PropTypes.any,
};

const mapStateToProps = ({
  routing: { location: { pathname, search }, routeData },
}) => ({
  search,
  pathname,
  routeData,
});

export default connect(mapStateToProps)(PrintPreviewLink);
