import React from 'react';
import PropTypes from 'prop-types';

const StepSummary = ({ className, title, value, link }) => {
  return (
    <div className={className}>
      <dt>{title}</dt>
      <dd>{value}</dd>
      {link && <dd>{link}</dd>}
    </div>
  );
};

StepSummary.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.node,
  link: PropTypes.node,
};

export default StepSummary;
