import styles from './VariantsMatrix.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { ProductStock, ProductPrice } from 'components/primitives/product';
import { AbilityTo } from 'behavior/user/constants';
import { matrixAbilitiesProps } from './useMatrixAbilities';

const VariantCell = ({ stockLevels, variant, abilities, labelledBy }) => {
  const viewPrices = abilities[AbilityTo.ViewPrices];
  const canOrder = abilities[AbilityTo.OrderProducts];

  const { isOrderable, inventory, price } = variant;

  return (
    <td className={styles.cell} aria-labelledby={labelledBy}>
      {canOrder && !isOrderable &&
        <span className={styles.notAvailable}>
          <SimpleText textKey="Product_NotAvailable" />
        </span>
      }
      <div>
        {inventory !== null && stockLevels != null &&
          <div className={styles.stockIndicator}>
            <ProductStock inventory={inventory} stockLevels={stockLevels} forVariant />
          </div>
        }
        {viewPrices && <ProductPrice salesPrice={price} />}
      </div>
    </td>
  );
};

VariantCell.propTypes = {
  stockLevels: PropTypes.object,
  variant: PropTypes.shape({
    isOrderable: PropTypes.bool,
    inventory: PropTypes.number,
    price: PropTypes.number,
  }).isRequired,
  abilities: matrixAbilitiesProps,
  labelledBy: PropTypes.string.isRequired,
};

export default React.memo(VariantCell);