import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { LastViewedProducts } from 'components/objects/lastViewed';
import { useMatchMediaContext } from 'components/responsive';

const LastViewed = ({ lastViewedEnabled }) => {
  const { xl } = useMatchMediaContext();

  if (!lastViewedEnabled)
    return null;

  return (
    <Container>
      <LastViewedProducts skipCurrent maxSlidesToShow={xl ? 5 : 4} />
    </Container>
  );
};

LastViewed.propTypes = {
  lastViewedEnabled: PropTypes.bool,
};

export default connect(({ settings: { lastViewedEnabled } }) => ({ lastViewedEnabled }))(LastViewed);