import styles from './DocBasedReturnOrder.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FieldArray } from 'formik';
import { SimpleText } from 'components/sanaText';
import { Button } from 'components/primitives/buttons';
import { DangerAlert } from 'components/primitives/alerts';
import ReturnOrderSubline from './ReturnOrderSubline';

const ReturnOrderSublines = React.memo(({ name, lines, invoiceLine, returnReasons, push, remove }) => {
  const splitLinesSupported = useSelector(({ page }) => page.splitLinesSupported);
  const sumLinesQuantity = lines.map(line => line.quantity).reduce((a, b) => a + b, 0);
  const canAddNewLine = splitLinesSupported && sumLinesQuantity < invoiceLine.availableReturnQuantity;

  const onLineAdd = useCallback(() => push({
    lineNo: invoiceLine.lineNo,
    quantity: invoiceLine.uom.minimumQuantity,
    reasonId: '',
    comment: '',
  }), [invoiceLine, push]);

  if (lines.length === 0)
    return null;

  return (
    <>
      {sumLinesQuantity > invoiceLine.availableReturnQuantity && (
        <DangerAlert role="alert" scrollOnAppear>
          <SimpleText
            textKey="ProductDetails_MaximumQuantityValidation"
            formatWith={[invoiceLine.availableReturnQuantity]}
          />
        </DangerAlert>
      )}
      {lines.map((_, index) => (
        <div className={styles.sublineBlock} key={index}>
          <ReturnOrderSubline
            index={index}
            name={`${name}[${index}]`}
            invoiceLine={invoiceLine}
            returnReasons={returnReasons}
            onDelete={remove}
          />
        </div>
      ))}
      {canAddNewLine && (
        <div className={styles.sublineAdd}>
          <Button
            type="button"
            className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.btnPlus}`}
            onClick={onLineAdd}
          >
            <span className="btn-cnt"><SimpleText textKey="ButtonText_Add" /></span>
          </Button>
        </div>
      )}
    </>
  );
});

ReturnOrderSublines.propTypes = {
  name: PropTypes.string.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired).isRequired,
  invoiceLine: PropTypes.shape({
    lineNo: PropTypes.string.isRequired,
    uom: PropTypes.shape({
      minimumQuantity: PropTypes.number.isRequired,
    }).isRequired,
    availableReturnQuantity: PropTypes.number.isRequired,
  }).isRequired,
  returnReasons: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const ReturnOrderSublinesFieldArray = ({ name, ...props }) => (
  <FieldArray name={name}>
    {({ push, remove }) => <ReturnOrderSublines name={name} push={push} remove={remove} {...props} />}
  </FieldArray>
);

ReturnOrderSublinesFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(ReturnOrderSublinesFieldArray);