import styles from '../OrderBox.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText } from 'components/sanaText';
import QuantityBox from './QuantityBox';
import VariantsButton from 'components/objects/productList/b2b/VariantsButton';
import ViewProductButton from '../b2c/ViewProductButton';
import { useSelector } from 'react-redux';

const OrderBox = ({
  product,
  className = '',
  abilities,
  gridMode,
}) => {
  const {
    hasVariants,
    productConfiguratorInfo,
  } = product;

  //GF OP18938 Work out if the basket contains a spare or a product. It might be empty.
  const basketSummary = useSelector(x => x.basket.summary);

  let basketContainsSpare = false;
  let basketContainsProduct = false;

  if (basketSummary !== null && basketSummary !== undefined && basketSummary.productLines !== undefined && basketSummary.productLines.list?.length > 0 && basketSummary.productLines.list[0] !== undefined) {
    if (basketSummary.productLines.list[0].product.isSpare) {
      basketContainsSpare = true;
    } else {
      basketContainsProduct = true;
    }
  }
  else {
    //console.log('undefined objects');
  }

	const discontinuedNotOrderable = (product.discontinued && product.inventory<1);
  const isOrderable = product.isOrderable && (!product.discontinued || product.inventory > 0) && !product.obsolete && !discontinuedNotOrderable;
  const addingSpareToProductBasket = product.isSpare && basketContainsProduct;
  const addingProductToSpareBasket = (product.isSpare === false) && basketContainsSpare;

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null) {
    content = placeholder;
  } else if (addingProductToSpareBasket) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Cannot_Add_Product_To_SpareBasket" placeholder={placeholder} />
      </span>
    );
  } else if (addingSpareToProductBasket) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Cannot_Add_Spare_To_ProductBasket" placeholder={placeholder} />
      </span>
    );
  } else if (isOrderable === false) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className={`${btnStyles.btnSmall} btn-configure`}
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
      />
    );
  } else if (hasVariants) {
    content = <VariantsButton abilities={abilities} product={product} />;
  } else {
    content = <QuantityBox abilities={abilities} product={product} gridMode={gridMode} />;
  }

  return (
    <div className={`${styles.orderBox} ${className}`}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    productConfiguratorInfo: PropTypes.object,
  }),
  className: PropTypes.string,
  abilities: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
};

export default OrderBox;
