import styles from './BasketB2B.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Basket from './Basket';
import PageTemplate from '../PageTemplate';
import Details from './Details';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import { Row, Col } from 'components/primitives/grid';
import QuickOrder from './QuickOrder';
import EditOrderInfo from '../EditOrderInfo';
import { DocumentType } from 'behavior/documents';

const Template = ({ abilities, model }) => {
  let [canCreateQuote, canCreateOrder] = abilities.map(ability => ability !== AbilityState.NotAvailable);
  const editMsg = model && model.editDocumentId ? <EditOrderInfo documentId={model.editDocumentId} isB2BLayout /> : null;

  if (model && model.editDocumentType) {
    if (model.editDocumentType === DocumentType.Quote) {
      canCreateQuote = true;
      canCreateOrder = false;
    } else if (model.editDocumentType === DocumentType.Order) {
      canCreateQuote = false;
      canCreateOrder = true;
    }
  }

  return (
    <PageTemplate
      headerTextKey="BasketPage_B2B_Header"
      footerTextKey="BasketPage_B2B_Footer"
    >
      <Row>
        <Col lg={9}>
          <QuickOrder />
        </Col>
      </Row>
      {editMsg}
      <Row className={styles.basket}>
        <Col xs={12} lg={9}>
          <Basket canCreateQuote={canCreateQuote} canCreateOrder={canCreateOrder} />
        </Col>
        <Col xs={12} lg={3} className={styles.detailsWrapper}>
          <Details canCreateQuote={canCreateQuote} canCreateOrder={canCreateOrder} />
        </Col>
      </Row>
    </PageTemplate>
  );
};

Template.propTypes = {
  abilities: abilitiesPropType,
  model: PropTypes.shape({
    editDocumentId: PropTypes.string,
    editDocumentType: PropTypes.string,
  }),
};

export default connect(({ basket: { model } }) => ({ model }),
)(withAbilities(Template, [AbilityTo.CreateQuote, AbilityTo.CreateOrder]));
