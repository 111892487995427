exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ContinuousScroll_loading-indicator{display:flex;justify-content:center;align-content:center;line-height:2em;padding-top:.38em}.ContinuousScroll_loader{margin-right:.76em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/continuousScroll/ContinuousScroll.module.scss"],"names":[],"mappings":"AAAA,oCACE,aACA,uBACA,qBACA,gBACA,iBACF,CAEA,yBACE,kBACF,CAAA","file":"ContinuousScroll.module.scss","sourcesContent":[".loading-indicator {\n  display: flex;\n  justify-content: center;\n  align-content: center;\n  line-height: 2em;\n  padding-top: .38em;\n}\n\n.loader {\n  margin-right: .76em;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loading-indicator": "ContinuousScroll_loading-indicator",
	"loadingIndicator": "ContinuousScroll_loading-indicator",
	"loader": "ContinuousScroll_loader"
};