import styles from '../../Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ServiceLine from './ServiceLine';
import ProductLine from './ProductLine';
import LinesFooter from '../LinesFooter';
import { useIsPreview } from 'components/objects/preview';
import PreviewLine from './PreviewLine';

const MobileTable = ({
  lines,
  loadLines,
  options,
  itemsFolded,
  initialLinesLoaded,
  lineTexts,
  cancelled,
}) => {
  const isPreview = useIsPreview();

  return (
    <div className={styles.lines}>
      <div className={styles.linesTableWrapper}>
        <table className={`${styles.linesTable} ${styles.mobileLines}`}>
          {!isPreview
            ? !itemsFolded && (
              <tbody>
                {lines.itemLines && lines.itemLines.map((item, index) => (
                  <ProductLine
                    key={index}
                    line={item}
                    options={options}
                    lineTexts={lineTexts}
                    cancelled={cancelled || item.isCancelled}
                  />
                ))}
                {lines.serviceLines && lines.serviceLines.map((item, index) => (
                  <ServiceLine key={index} line={item} options={options} lineTexts={lineTexts} />
                ))}
              </tbody>
            )
            : (
              <tbody>
                <PreviewLine options={options} lineTexts={lineTexts} />
                <PreviewLine options={options} lineTexts={lineTexts} />
                <PreviewLine options={options} lineTexts={lineTexts} />
              </tbody>
            )
          }
          {}
        </table>
      </div>
      {!initialLinesLoaded && (
        <LinesFooter
          lines={lines}
          itemsFolded={itemsFolded}
          loadLines={loadLines}
          lineTexts={lineTexts}
        />
      )}
    </div>
  );
};

MobileTable.propTypes = {
  lines: PropTypes.shape({
    serviceLines: PropTypes.array,
    itemLines: PropTypes.array,
  }).isRequired,
  loadLines: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  itemsFolded: PropTypes.bool.isRequired,
  initialLinesLoaded: PropTypes.bool.isRequired,
  lineTexts: PropTypes.object,
  cancelled: PropTypes.bool.isRequired,
};

export default MobileTable;
