import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelector';
import { EVENT_SOURCES } from 'behavior/analytics';
import { useOnCustomerChanged } from 'utils/hooks';
import { quickOrderAddProducts, clearQuickOrder } from 'behavior/basket';
import { useUpdateContext } from '../updateContext';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { useModal } from 'components/objects/modals';
import { StockAlertDialog } from 'components/objects/stockAlertDialog';

const QuickOrder = () => {
  const texts = useQuickOrderTexts();
  const dispatch = useDispatch();
  const { quickOrderMaxLines, modifiedDate } = useSelector(({ settings, basket: { modifiedDate } }) => ({
    quickOrderMaxLines: settings.basket && settings.basket.quickOrderMaxLines,
    modifiedDate,
  }), shallowEqual);
  const { getModifiedLines, reset: resetUpdateContext } = useUpdateContext();
  const [productAddDisabled, setProductAddDisabled] = useState(false);

  const { opened, toggle, show } = useModal();
  const [overInv, setOverInv] = useState([]);

  const onProductAdd = useCallback((line, product) => {
    if (productAddDisabled)
      return;

    if (line.quantity > product.inventory) {
      setOverInv([{ id: product.id, quantity: line.quantity, inv: product.inventory }]);
      show();
    }

    dispatch(quickOrderAddProducts([line], getModifiedLines(false), quickOrderMaxLines));
    resetUpdateContext();
    setProductAddDisabled(true);
  }, [productAddDisabled, quickOrderMaxLines]);

  useOnCustomerChanged(() => {
    dispatch(clearQuickOrder());
  });

  useEffect(() => () => {
    dispatch(clearQuickOrder());
  }, []);

  useEffect(() => void (setProductAddDisabled(false)), [modifiedDate]);

  return (
    <>
    <StockAlertDialog  opened={opened} toggle={toggle} elements={overInv} />
    <ProductSelector
      texts={texts}
      onProductAdd={useHandlerLockerInPreview(onProductAdd)}
      productAddDisabled={productAddDisabled}
      productClickTrackingSource={EVENT_SOURCES.quickOrder}
    />
    </>
  );
};

export default React.memo(QuickOrder);

function useQuickOrderTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'ButtonText_AddToBasket',
    'ButtonAltText_AddToBasket',
    'Loading',
    'QuickOrder_ProductNotFound',
    'ProductCannotBeOrderedAtThisTime',
  ]).texts;

  return useMemo(() => ({
    searchBoxPlaceholder: makeSimpleText(texts[0]),
    addBtn: makeSimpleText(texts[1]),
    addBtnAlt: makeSimpleText(texts[2]),
    loadingText: makeSimpleText(texts[3]),
    productNotFoundText: makeRichText(texts[4]),
    productCannotBeOrderedMessage: makeRichText(texts[5]),
  }), [texts]);
}