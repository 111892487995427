import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { addProducts } from 'behavior/basket';
import ListContext from './ListContext';
import StickyAddToBasketButton from './StickyAddToBasketButton';
import { connect } from 'react-redux';
import { addToBasket } from './AddToBasketButton';

const StickyAddToBasketPanel = ({ showFloatingAddToCart, addProducts }) => {
  const { quantities, resetQuantities } = useContext(ListContext);

  const dontShowStickyAddToCart = quantities.size === 0 || !showFloatingAddToCart;
  const onMiniatureBtnClick = useMemo(() => {
    if (dontShowStickyAddToCart)
      return null;

    return () => addToBasket(quantities, resetQuantities, addProducts);
  }, [dontShowStickyAddToCart, quantities.size]);

  if (dontShowStickyAddToCart)
    return null;

  return <StickyAddToBasketButton size={quantities.size} onClick={onMiniatureBtnClick} />;
};

StickyAddToBasketPanel.propTypes = {
  showFloatingAddToCart: PropTypes.bool,
  addProducts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ settings: { productList } }) => ({
  showFloatingAddToCart: productList.showFloatingAddToCart,
});

export default connect(mapStateToProps, { addProducts })(StickyAddToBasketPanel);
