exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".RadioButtonGroup_item{display:block}.RadioButtonGroup_item:not(:last-of-type){margin-bottom:.5em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/form/RadioButtonGroup.module.scss"],"names":[],"mappings":"AAAA,uBACI,aACJ,CACI,0CACI,kBACR,CAAA","file":"RadioButtonGroup.module.scss","sourcesContent":[".item {\n    display: block;\n\n    &:not(:last-of-type) {\n        margin-bottom: .5em;\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"item": "RadioButtonGroup_item"
};