import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { requestAuthorizations } from './actions';
import { orderAuthorizationsPageQuery } from './queries';
import { normalizeFilter, createOptions } from '../documents';

export const size = 10;

export default function ({ params, options: pageOptions }, state$, { api }) {
  const onlyItems = pageOptions && pageOptions.onlyItems;
  const backTo = pageOptions && pageOptions.backTo;
  const filter = normalizeFilter(params && params.filter);

  if (!filter.authorizationStatus)
    filter.authorizationStatus = null;

  const input = createOptions(params, filter, onlyItems);

  if (onlyItems)
    return of({
      action$: of(requestAuthorizations(input)),
      page: {
        ...state$.value.page,
        filter,
      },
    });

  return api.graphApi(orderAuthorizationsPageQuery, { input }).pipe(
    map(({ pages: { orderAuthorizations: page }, profile: { orderAuthorizations } }) => {
      if (!page)
        return null;

      if (!orderAuthorizations) {
        page.component = PageComponentNames.NotFound;
        return { page };
      }

      page.component = PageComponentNames.OrderAuthorizations;
      page.orderAuthorizations = orderAuthorizations;
      page.size = size;
      page.filter = filter;
      page.backTo = backTo;

      return { page };
    }),
    initSystemPageContent(),
  );
}
