import { OrderStatus, QuoteStatus, DocumentType } from './constants';
import { logger } from 'utils/logs';

export const isDocumentCancelled = document =>
  document.status === QuoteStatus.Cancelled || document.status === OrderStatus.Cancelled;

export const isDocumentPromotableQuote = (document, documentType) =>
  documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

const fieldPerDocumentType = {
  [DocumentType.Order]: 'orders',
  [DocumentType.Quote]: 'quotes',
  [DocumentType.Invoice]: 'invoices',
  [DocumentType.ReturnOrder]: 'returnOrders',
  [DocumentType.CreditNote]: 'creditNotes',
  [DocumentType.ReturnReceipt]: 'returnReceipts',
  [DocumentType.Shipment]: 'shipments',
};

export function getDocumentField(documentType) {
  const field = fieldPerDocumentType[documentType];

  if (!field) {
    logger.error(`graphQL field for ${documentType} is not registered!`);
  }

  return field;
}

const documentDetailsFieldMap = {
  [DocumentType.Order]: 'orderDetails',
  [DocumentType.Quote]: 'quoteDetails',
  [DocumentType.Invoice]: 'invoiceDetails',
  [DocumentType.ReturnOrder]: 'returnOrderDetails',
  [DocumentType.CreditNote]: 'creditNoteDetails',
  [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
  [DocumentType.Shipment]: 'shipmentDetails',
};

export function getDocumentDetailsField(documentType) {
  const field = documentDetailsFieldMap[documentType];

  if (!field) {
    logger.error(`graphQL field for ${documentType} is not registered!`);
  }

  return field;
}