import { ON_CAPTCHA_RESET } from './actions';
import { NAVIGATED } from 'behavior/routing';

export const initialSyncObj = {};
export const initialState = {
  syncObj: initialSyncObj,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ON_CAPTCHA_RESET:
      return { ...state, syncObj: {} };
    case NAVIGATED:
      return initialState;
    default:
      return state;
  }
}