import styles from './NotFound.module.scss';
import React from 'react';
import { Link, BackLink } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Helmet } from 'react-helmet';
import { InfoAlert } from 'components/primitives/alerts';
import { Placeholder } from 'components/primitives/placeholders';
import { usePageTitle } from '../hooks';
import { makeSimpleText, makeRichText } from 'utils/render';

const NotFound = () => {
  const {
    loaded,
    texts: [title, backLinkText, homeLinkText, msg, checkAddress, backTemplate, homeTemplate],
  } = useSanaTexts([
    'NotFound',
    'PageNotFound_BackLink',
    'ErrorPages_HomepageLink',
    'PageNotFound_IntroText',
    'PageNotFound_CheckAddress',
    'PageNotFound_BackLinkTemplate',
    'ErrorPages_HomepageLinkTemplate',
  ]);

  const pageTitle = usePageTitle(makeSimpleText(title));

  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  const backLink = loaded && <BackLink>{backLinkText}</BackLink>;
  const homePageLink = loaded && <Link to={routesBuilder.forHome}>{homeLinkText}</Link>;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <InfoAlert className={styles.block}>
        <div>
          {placeholder || makeRichText(msg)}
        </div>
        <ul className={styles.actionList}>
          <li>
            {placeholder || makeSimpleText(checkAddress)}
          </li>
          <li>
            {placeholder || makeSimpleText(backTemplate, [backLink], true)}
          </li>
          <li>
            {placeholder || makeSimpleText(homeTemplate, [homePageLink], true)}
          </li>
        </ul>
      </InfoAlert>
    </>
  );
};

export default React.memo(NotFound);
