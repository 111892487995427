import { useState, useRef } from 'react';
import { anyEqual, intersect } from 'utils/helpers';
import { useOnChange } from 'utils/hooks';

const useVariantDropdownsBlock = (
  variantComponentGroups,
  updateVariantId,
  productId,
  defaultVariantId,
) => {
  const selectionRef = useRef();
  const [, forceUpdate] = useState();

  if (!selectionRef.current) {
    const [selectedVariantId, selection] = getSelection(variantComponentGroups, undefined, defaultVariantId);

    selectionRef.current = selection;
    updateVariantId(selectedVariantId, selection);
  }

  // Reset current selection if product is changed.
  useOnChange(() => {
    const [selectedVariantId, selection] = getSelection(variantComponentGroups, undefined, defaultVariantId);

    selectionRef.current = selection;

    updateVariantId(selectedVariantId, selection);
  }, [productId, variantComponentGroups == null], false);

  const filteredVariantComponentGroups = function () {
    let availableVariants = null;

    return variantComponentGroups.map(group => {
      let { id, components } = group;
      const selectedComponent = selectionRef.current[id];

      // Filtering components to display for selection based on available variants from parent group.
      if (!availableVariants) {
        availableVariants = selectedComponent.variants;
      } else {
        components = components.filter(availableComponentFilter.bind(null, availableVariants));
        availableVariants = intersect(selectedComponent.variants, availableVariants);
      }

      return { ...group, components };
    });
  }();

  const onSelectionChange = newSelection => {
    const [selectedVariantId, validSelection] = getSelection(variantComponentGroups, newSelection);
    selectionRef.current = validSelection;
    forceUpdate({});
    updateVariantId(selectedVariantId, validSelection);
  };

  return [
    filteredVariantComponentGroups,
    selectionRef.current,
    onSelectionChange,
  ];
};

export default useVariantDropdownsBlock;

function availableComponentFilter(availableVariants, component) {
  return anyEqual(component.variants, availableVariants);
}

function getSelection(variantComponentGroups, selection, defaultVariantId) {
  const newSelection = {};
  let availableVariants;

  for (const group of variantComponentGroups) {
    let selectedComponent = null;

    if (selection) {
      selectedComponent = selection[group.id] || group.components[0];
    } else {
      const querySelectedComponent = defaultVariantId && group.components.find(c => anyEqual(c.variants, [defaultVariantId]));
      selectedComponent = querySelectedComponent || group.components[0];
    }

    if (!availableVariants) {
      availableVariants = selectedComponent.variants;
    } else {
      if (!anyEqual(selectedComponent.variants, availableVariants)) {
        selectedComponent = group.components.find(availableComponentFilter.bind(null, availableVariants));
      }

      availableVariants = intersect(selectedComponent.variants, availableVariants);
    }

    newSelection[group.id] = selectedComponent;
  }
  return [
    availableVariants[0],
    newSelection,
  ];
}
