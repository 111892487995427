exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProductComparisonRating_label-placeholder{display:inline-block;vertical-align:middle}.ProductComparisonRating_goto-review{margin-left:.75em}.ProductComparisonRating_title{margin-bottom:0}.ProductComparisonRating_field-value{word-wrap:break-word;padding:.31em .75em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/page/rating/ProductComparisonRating.module.scss","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/page/_comparisonPageMixins.scss"],"names":[],"mappings":"AAGA,2CACI,qBACA,qBAFJ,CAKA,qCACI,iBAFJ,CAKA,+BCXI,eDUJ,CAKA,qCCXI,qBACA,mBDUJ,CAAA","file":"ProductComparisonRating.module.scss","sourcesContent":["/// <reference path=\"../_comparisonPageMixins.scss\" />\n@import '../_comparisonPageMixins.scss';\n\n.label-placeholder {\n    display: inline-block;\n    vertical-align: middle;\n}\n\n.goto-review {\n    margin-left: .75em;\n}\n\n.title {\n    @include row-header;\n}\n\n.field-value {\n    @include row-cell;\n}","﻿@mixin row-header {\n    margin-bottom: 0;\n}\n\n@mixin row-cell {\n    word-wrap: break-word;\n    padding: 0.31em 0.75em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"label-placeholder": "ProductComparisonRating_label-placeholder",
	"labelPlaceholder": "ProductComparisonRating_label-placeholder",
	"goto-review": "ProductComparisonRating_goto-review",
	"gotoReview": "ProductComparisonRating_goto-review",
	"title": "ProductComparisonRating_title",
	"field-value": "ProductComparisonRating_field-value",
	"fieldValue": "ProductComparisonRating_field-value"
};