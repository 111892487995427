import styles from './MediaBackground.module.scss';
import gridStyles from 'components/primitives/grid/Grid.module.scss';
import { videoFrameAspectRatio } from 'components/primitives/video/Video.module.scss';
import React, { useRef, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import DesktopMobileImage from './DesktopMobileImage';
import { BackgroundVideo } from 'components/primitives/video';
import { scroll$, resize$, orientationChange$, useEventObservable } from 'utils/rxjs';
import { merge, of } from 'rxjs';
import { usePrintMode } from 'utils/hooks';

const PARENT_TYPES = {
  row: 'row',
  col: 'column',
};

const effectsMap = {
  NONE: '',
  KEN_BURN: styles.kenBurn,
  BLACK_AND_WHITE: styles.blackAndWhite,
};

const MediaBackground = ({
  parentType = PARENT_TYPES.row,
  fullWidth,
  color,
  desktopImage,
  mobileImage,
  hideImageOnMobile,
  isMobile,
  imageAltText,
  video,
  borderWidth,
  borderStyle,
  borderColor,
  cornerRadius,
  imageLoadVisibleByDefault,
  mutedSound,
  imageEffect,
}) => {
  const isPrintMode = usePrintMode();
  const containerClass = getContainerClass(parentType);
  const containerStyles = {
    backgroundColor: color || null,
    borderWidth: borderStyle !== 'NONE' ? (borderWidth || 0) : null,
    borderStyle: borderStyle || null,
    borderColor: borderColor || null,
    borderRadius: cornerRadius || null,
  };
  const wrapperRef = useRef(null);
  const imageEffectClassName = effectsMap[imageEffect];
  const wrapperClass = `${getWrapperClass(parentType, fullWidth)} ${isPrintMode || !imageEffectClassName ? '' : imageEffectClassName}`;

  const handleResize = useCallback(() => {
    if (desktopImage || mobileImage)
      adjustImage(wrapperRef.current.querySelector('.' + styles.image));

    if (video)
      adjustVideo(wrapperRef.current.querySelector('.' + styles.video));
  }, [desktopImage, mobileImage, video]);

  useEffect(handleResize, [handleResize, isPrintMode]);
  useEventObservable(resize$, handleResize, true, [handleResize]);
  useEventObservable(orientationChange$, handleResize, true, [handleResize]);

  useEffect(() => {
    if (isPrintMode || !imageEffectClassName)
      return;

    const eventSubscription = merge(of(1), scroll$).subscribe(() => {
      const wrapper = wrapperRef.current;
      if (wrapper)
        wrapper.classList[isInView(wrapper) ? 'add' : 'remove'](styles.playAnimation);
    });

    return () => eventSubscription.unsubscribe();
  }, [isPrintMode, imageEffectClassName]);

  const backgroundImage = useMemo(() => {
    if ((!desktopImage && !mobileImage) || (isMobile && hideImageOnMobile))
      return null;

    if (mobileImage)
      return (
        <DesktopMobileImage
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          className={styles.image}
          afterLoad={handleResize}
          visibleByDefault={imageLoadVisibleByDefault}
          alt={imageAltText || ''}
        />
      );

    return (
      <ResponsiveLazyImage
        src={desktopImage || mobileImage}
        className={styles.image}
        afterLoad={handleResize}
        visibleByDefault={imageLoadVisibleByDefault}
        alt={imageAltText || ''}
      />
    );
  }, [desktopImage, mobileImage, isMobile, hideImageOnMobile]);

  return (
    <div className={containerClass} style={containerStyles}>
      {(desktopImage || mobileImage || video) && <ReactResizeDetector handleHeight onResize={handleResize} />}
      <div className={wrapperClass} ref={wrapperRef}>
        <div className={styles.mediaBox}>
          {backgroundImage}
          {video && <BackgroundVideo src={video} className={styles.video} mutedSound={mutedSound} />}
        </div>
      </div>
    </div>
  );
};

MediaBackground.propTypes = {
  parentType: PropTypes.oneOf(Object.values(PARENT_TYPES)),
  fullWidth: PropTypes.bool,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  hideImageOnMobile: PropTypes.bool,
  isMobile: PropTypes.bool,
  imageAltText: PropTypes.string,
  video: PropTypes.string,
  color: PropTypes.string,
  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColor: PropTypes.string,
  cornerRadius: PropTypes.string,
  imageLoadVisibleByDefault: PropTypes.bool,
  mutedSound: PropTypes.bool,
  imageEffect: PropTypes.oneOf(Object.keys(effectsMap)),
};

export default React.memo(MediaBackground);

function getContainerClass(parentType) {
  return parentType === PARENT_TYPES.row ? styles.container : `${styles.container} ${styles.column}`;
}

function getWrapperClass(parentType, fullWidth) {
  if (parentType === PARENT_TYPES.col)
    return styles.mediaWrapper;

  return fullWidth ? styles.mediaWrapper : `${styles.mediaWrapper} ${gridStyles.container}`;
}

function adjustImage(element) {
  if (!element)
    return;

  const originRatio = element.naturalHeight / element.naturalWidth;
  const parentRatio = element.parentElement.offsetHeight / element.parentElement.offsetWidth;
  const isFullHeight = element.classList.contains(styles.fullHeight);
  if (originRatio < parentRatio) {
    !isFullHeight && element.classList.add(styles.fullHeight);
  } else {
    isFullHeight && element.classList.remove(styles.fullHeight);
  }
}

function adjustVideo(element) {
  if (!element)
    return;

  element.style.width = Math.ceil(element.parentElement.offsetHeight / +videoFrameAspectRatio) + 'px';
}

function isInView(block) {
  if (!block)
    return false;

  const { top, bottom } = block.getBoundingClientRect();

  return top > 0 && top < window.innerHeight || bottom > 0 && bottom < window.innerHeight;
}