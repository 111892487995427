exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".EditProfile_form{padding-top:15px;max-width:46.75em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/profile/editProfile/EditProfile.module.scss"],"names":[],"mappings":"AAAA,kBACI,iBACA,iBACJ,CAAA","file":"EditProfile.module.scss","sourcesContent":[".form {\n    padding-top: 15px;\n    max-width: 46.75em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"form": "EditProfile_form"
};