import { insiteEditorUseRegisteredSanaTexts } from './insiteEditorUseRegisteredSanaTexts';
import { useSelector } from 'react-redux';
import { useRef, useMemo, useEffect, useReducer } from 'react';

const useInsiteEditorTexts = (items, process, params) => {
  if (!useSelector(state => state.visualDesigner.insiteEditor))
    return items;

  const [, rerender] = useReducer(() => ({}), null);
  const optionsRef = useRef([]);
  const sanaTextsRef = useRef([]);

  const findText = (key, text) => {
    const option = optionsRef.current.find(option => option.key === key);

    if (sanaTextsRef.current.length > 0 && option) {
      const isTextUpdated = option.value === text;
      const optionIndex = optionsRef.current.findIndex(option => option.key === key);

      if (!isTextUpdated)
        optionsRef.current = optionsRef.current.map((option, index) => {
          if (index === optionIndex)
            option.value = text;

          return option;
        });

      return sanaTextsRef.current[optionIndex];
    }
    else {
      optionsRef.current.push({ key, value: text });
      return text;
    }
  };

  useEffect(() => {
    if (optionsRef.current.length === 0)
      return;

    rerender();
  }, []);

  if (params && params.textOnly)
    optionsRef.current.textOnly = true;

  if (params && params.isConstructedTexts)
    optionsRef.current.isConstructedTexts = true;

  const insiteEditorTexts = insiteEditorUseRegisteredSanaTexts(optionsRef.current);
  if (insiteEditorTexts)
    sanaTextsRef.current = insiteEditorTexts.texts;

  return useMemo(() => items?.map(item => process(item, findText)), [items, sanaTextsRef.current]);
};

export default useInsiteEditorTexts;