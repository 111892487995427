import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { QuantityTextBox, createQuantityModel } from 'components/primitives/product';
import { getNumberDecimalsSeparator } from 'utils/format';
import Field, { fieldPropTypes } from 'components/objects/forms/fields/Field';

const QuantityBox = ({ field: { name, value }, form: { setFieldValue }, uom }) => {
  const culture = useSelector(({ localization }) => localization.currentLanguage.cultureName);
  const quantity = useMemo(() => createQuantityModel(uom, false), [uom]);
  const onChange = useCallback(({ value }) => setFieldValue(name, value, false), [name, setFieldValue]);

  useEffect(() => {
    onChange({ value: quantity.initial });
  }, [quantity]);
  
  return (
    <QuantityTextBox
      name={name}
      value={value}
      quantity={quantity}
      onChange={onChange}
      separator={getNumberDecimalsSeparator(culture)}
      hideControlsOnBlur
    />
  );
};

QuantityBox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  uom: PropTypes.shape({
    id: PropTypes.string,
    maximumQuantity: PropTypes.number.isRequired,
    minimumQuantity: PropTypes.number,
    quantityStep: PropTypes.number,
  }),
};

// eslint-disable-next-line react/no-multi-comp
const QuantityBoxField = ({ ...props }) => <Field component={QuantityBox} {...props} />;

QuantityBoxField.propTypes = fieldPropTypes;

export default React.memo(QuantityBoxField);