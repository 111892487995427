import styles from './Specifications.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { RowRails } from '../swiper';

const GridRow = ({ data }) => (
  <>
    <div className={styles.fieldName}>
      {data.name}
    </div>

    <RowRails items={data.values} highlightColumns>
      {value => (
        <div className={styles.fieldValue}>
          {value || <SimpleText textKey="ProductCompare_EmptySpecification" />}
        </div>
      )}
    </RowRails>
  </>
);

GridRow.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default GridRow;