import React from 'react';
import PropTypes from 'prop-types';

const BackLinkWrapper = ({ children, className }) => (
  <div className={'d-print-none ' + className || ''}>
    {children}
  </div>
);

BackLinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default BackLinkWrapper;