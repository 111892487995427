import styles from 'components/objects/product/Details.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Types } from 'behavior/attachments';
import { linkable } from './linkable';
import { nonDownloadable } from './nonDownloadable';

const Attachments = ({ attachments, offlineMode }) => {
  const sortedAttachments = attachments.slice().sort(compareTitles);

  const items = sortedAttachments.map((attachment, index) => {
    let nameComponent;

    if (attachment.type === Types.File && offlineMode) {
      nameComponent = nonDownloadable(attachment);
    } else if (attachment.url) {
      nameComponent = linkable(attachment);
    } else {
      nameComponent = attachment.title;
    }

    return (
      <li key={index}>
        <span className={styles.name}>{nameComponent}</span>
        <span className={styles.value}>{attachment.variant && attachment.variant.title}</span>
      </li>
    );
  });

  return (
    <ul className={`${styles.attachmentsList} ${styles.tableList}`}>
      {items}
    </ul>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(Types)),
    url: PropTypes.string,
    variant: PropTypes.shape({
      title: PropTypes.string,
    }),
  })).isRequired,
  offlineMode: PropTypes.bool,
};

export default connect(
  state => ({ offlineMode: state.app.offlineMode }),
)(Attachments);

function compareTitles(a, b) {
  return a.title.localeCompare(b.title);
}