import styles from './Reviews.module.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { Placeholder } from 'components/primitives/placeholders';
import { useSimpleTexts } from 'components/sanaText';

const DescriptionMessage = () => {
  const { texts: [message], loaded } = useSimpleTexts(['WriteReview_MessageNote']);

  if (!loaded)
    return <Placeholder />;

  return (
    <div className={styles.descriptionMessage}>
      <span className={styles.icon}><FontAwesomeIcon icon={faInfo} /></span>
      {message}
    </div>
  );
};

export default DescriptionMessage;