exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".HtmlBlock_html{margin:0;word-wrap:break-word;max-width:100%}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/htmlBlock/HtmlBlock.module.scss"],"names":[],"mappings":"AAAA,gBACI,SACA,qBACA,cACJ,CAAA","file":"HtmlBlock.module.scss","sourcesContent":[".html {\n    margin: 0;\n    word-wrap: break-word;\n    max-width: 100%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"html": "HtmlBlock_html"
};