import styles from './BasketB2B.module.scss';
import basketStyles from '../Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { UseSanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import SaveOrderTemplate from './SaveOrderTemplate';
import { AddToWishlist } from './wishlist';
import { useUpdateContext } from '../updateContext';
import { useDispatch } from 'react-redux';
import { clearBasket } from 'behavior/basket';
import { FolderOpenIcon, TrashCanIcon, CartRefreshIcon } from 'components/primitives/icons';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { StockAlertDialog } from 'components/objects/stockAlertDialog';
import { useModal } from 'components/objects/modals';

const Actions = ({ isEmpty }) => {
  const [canUseWishlist, canUseOrderTemplates] = useHasAbilities(AbilityTo.UseWishlist, AbilityTo.UseOrderTemplates);
  const { submit } = useUpdateContext();
  const dispatch = useDispatch();
  const previewHandlerLocker = useHandlerLockerInPreview();
  
  const disabledClass = isEmpty ? ' disabled' : '';

  const { opened, toggle, show } = useModal();
  const [overInv, setOverInv] = useState([]);

  const handleSubmitClick = () => {

    const tooMany = [];

    setOverInv([]);

    for (const ml of getModifiedLines()) {
      const { id, quantity } = ml;
      const invId = `${id}-inv`;
      const productIdId = `${id}-id`;

      const invSpan = document.getElementById(invId);
      const productIdSpan = document.getElementById(productIdId);

      const inventory = invSpan ? parseInt(invSpan.innerHTML) : null;
      const productId = productIdSpan ? productIdSpan.innerHTML : null;

      if (inventory !== null && quantity > inventory) {
        tooMany.push({ id: productId, quantity, inv: inventory });
      }
    }

    setOverInv(tooMany);
    if (tooMany.length) {
      show();
    }

    submit();
  };

  const placeholder = <Placeholder />;
  return (
    <div className={styles.buttons}>
      <StockAlertDialog opened={opened} toggle={toggle} elements={overInv} />
      <UseSanaButton textKey="RecalculateBasket" placeholder={placeholder}>
        {(text, title) => (
          <button
            className={`${linkStyles.link} ${styles.recalculateBtn}${disabledClass}`}
            type="button"
            title={title}
            aria-disabled={isEmpty}
            onClick={isEmpty ? null : handleSubmitClick}
            tabIndex={isEmpty ? -1 : null}
          >
            <span className={styles.iconWrapper}>
              <CartRefreshIcon className={styles.icon} alt={title} />
            </span>
            {text}
          </button>
        )}
      </UseSanaButton>
      {canUseWishlist &&
        <AddToWishlist placeholder={placeholder} isEmpty={isEmpty} />
      }
      {canUseOrderTemplates &&
        <>
          <SaveOrderTemplate placeholder={placeholder} isEmpty={isEmpty} />
          <UseSanaButton textKey="LoadTemplate" placeholder={placeholder}>
            {(text, title) => (
              <Link
                to={routesBuilder.forOrderTemplates} options={{ backTo: { routeData: routesBuilder.forBasket() } }}
                className={`${linkStyles.link} ${styles.loadTemplateBtn}`}
                title={title}
                onClick={previewHandlerLocker}
              >
                <span className={styles.iconWrapper}>
                  <FolderOpenIcon alt={title} className={styles.icon} />
                </span>
                {text}
              </Link>
            )}
          </UseSanaButton>
        </>
      }
      <UseSanaButton textKey="ClearBasket" placeholder={placeholder}>
        {(text, title) => (
          <button
            className={`${linkStyles.link} ${basketStyles.clearBtn}${disabledClass}`}
            title={title}
            onClick={isEmpty ? null : dispatch.bind(null, clearBasket())}
            aria-disabled={isEmpty}
            tabIndex={isEmpty ? -1 : null}
          >
            <span className={styles.iconWrapper}>
              <TrashCanIcon className={styles.icon} alt={title} />
            </span>
            {text}
          </button>
        )}
      </UseSanaButton>
    </div>
  );
};

Actions.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
};

export default Actions;
