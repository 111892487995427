import styles from '../Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import AddressColumn from './AddressColumn';

const DocumentAddresses = ({ isB2BCustomer, addresses }) => {
  const { sellTo, billing, payer, shipping } = addresses;

  return (
    <div className={styles.columnsGeneral}>
      {isB2BCustomer && <AddressColumn headerTextKey={'OrderDetail_SellToAddress'} address={sellTo} className={styles.sellingAddress} />}
      <AddressColumn headerTextKey={'BillToAddress'} address={billing} className={styles.billingAddress} />
      <AddressColumn headerTextKey={'PayerAddress'} address={payer} className={styles.payerAddress} />
      <AddressColumn headerTextKey={'ShipToAddress'} address={shipping} className={styles.shippingAddress} />
    </div>
  );
};

export default React.memo(DocumentAddresses);

DocumentAddresses.propTypes = {
  isB2BCustomer: PropTypes.bool.isRequired,
  addresses: PropTypes.shape({
    sellTo: PropTypes.string,
    billing: PropTypes.string,
    payer: PropTypes.string,
    shipping: PropTypes.string,
  }).isRequired,
};
