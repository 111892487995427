import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import { tap, map, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { receiveTiming, PROFILER_REQUEST_CLOSE, closeProfiler, profilerClosed } from './actions';
import { toolDisableMutation } from '../queries';
import { Tool } from 'utils/tools';

const toolName = Tool.PerformanceProfiler;

export default function (action$, _, { api, toolsStorage }) {
  const track$ = api.extensions$.pipe(
    map(extensions => extensions && extensions.profiler),
    distinctUntilChanged(),
    filter(profiler => !profiler || !profiler.root.isTrivial),
    map(profiler => profiler ? receiveTiming(profiler) : closeProfiler()),
  );

  const close$ = action$.pipe(
    ofType(PROFILER_REQUEST_CLOSE),
    filter(_ => toolsStorage.toolEnabled(toolName)),
    switchMap(_ => api.graphApi(toolDisableMutation, { name: toolName }, { useCookies: true }).pipe(
      tap(_ => toolsStorage.disableTool(toolName)),
      map(_ => profilerClosed()),
    )),
  );

  return merge(track$, close$);
}