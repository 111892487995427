/* eslint-disable react/no-multi-comp */
import React from 'react';
import { iconPropTypes } from './propTypes';

export const OkIcon = React.forwardRef(({ className, title, ...props }, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    className={className}
    ref={ref}
    {...props}
  >
    {title && <title>{title}</title>}
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
));

OkIcon.propTypes = iconPropTypes;

export const PendingIcon = React.forwardRef(({ className, title, ...props }, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    className={className}
    ref={ref}
    {...props}
  >
    {title && <title>{title}</title>}
    <path d="M24 24H0V0h24v24z" fill="none" />
    <circle cx="12" cy="12" r="8" />
  </svg>
));

PendingIcon.propTypes = iconPropTypes;