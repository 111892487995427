import PropTypes from 'prop-types';
import { CalendarTypes } from './enums';
import { СalendarViews, AllValueTypes } from './constants';

const supportedViews = Object.keys(СalendarViews).join(', ');

export const calendarTypePropType = PropTypes.oneOf(Object.values(CalendarTypes));

export const valuePropType = PropTypes.oneOfType([
  PropTypes.instanceOf(Date),
  PropTypes.arrayOf(PropTypes.instanceOf(Date)),
]);

export const viewValidator = (props, propName, componentName) => {
  const view = props[propName];
  const allowedViews = props.views || СalendarViews;

  if (view !== undefined && !allowedViews.includes(view))
    return new Error(`Invalid prop \`${propName}\` of value \`${view}\` supplied to \`${componentName}\`, expected one of: ${supportedViews}.`);

  return null;
};

viewValidator.isRequired = (props, propName, componentName) => {
  const view = props[propName];
  if (!view)
    return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\`, but its value is \`${view}\`.`);

  return valuePropType(props, propName, componentName);
};

export const edgeDateValueValidator = (props, propName, componentName) => {
  const value = props[propName];
  if (!value)
    return null;

  if (!(value instanceof Date))
    return new Error(`Invalid prop \`${propName}\` of type \`${typeof value}\` supplied to \`${componentName}\`, expected instance of \`Date\`.`);

  if (propName === 'minDate' && props.maxDate && value > props.maxDate)
    return new Error(`Invalid prop \`${propName}\` of type \`${typeof minDate}\` supplied to \`${componentName}\`, minDate cannot be larger than maxDate.`);

  if (propName === 'maxDate' && props.minDate && value < props.minDate)
    return new Error(`Invalid prop \`${propName}\` of type \`${typeof maxDate}\` supplied to \`${componentName}\`, maxDate cannot be smaller than minDate.`);

  return null;
};

export const activeStartDateCalculatedDataPropType = PropTypes.shape({
  calendarType: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  day: PropTypes.number.isRequired,
  dayOfWeekIndex: PropTypes.number.isRequired,
  daysInMonth: PropTypes.number.isRequired,
  monthIndex: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
}).isRequired;

export const valueTypePropType = PropTypes.oneOf(AllValueTypes).isRequired;

export const tileContentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
]);

export const viewPropTypes = {
  activeStartDate: PropTypes.instanceOf(Date).isRequired,
  hoveredDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func,
  shouldDisableTile: PropTypes.func,
  tileContent: tileContentPropType,
  value: valuePropType,
  valueType: valueTypePropType,
};
