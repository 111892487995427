import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  useProductTrackingOptions,
  createProductListStructure,
} from 'utils/structureData';
import renderOnce from 'components/objects/renderOnce';

const Schema = ({ products }) => {
  const options = useProductTrackingOptions();

  if (!products || !products.length || products.some(product => !product.calculated))
    return null;

  const schema = createProductListStructure(products, options);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

Schema.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    calculated: PropTypes.bool,
  })),
};

export default renderOnce(React.memo(Schema, _ => true));