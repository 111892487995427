import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import EventEmitter from 'eventemitter3';

export const AccordionStateContext = createContext();

export const AccordionStateProvider = ({ children, deps }) => {
  const contextValue = useMemo(() => {
    const emitter = new EventEmitter();
    const resetEventName = 'MENU/ITEM/RESETSTATE';
    let prevId = undefined;

    return {
      emitter,
      resetEventName,
      resetMenuState: itemId => {
        if (prevId === itemId)
          return;

        emitter.emit(resetEventName, itemId);
        prevId = itemId;
      },
    };
  }, []);

  useMemo(() => {
    contextValue.resetMenuState();
  }, [deps]);

  return (
    <AccordionStateContext.Provider value={contextValue}>
      {children}
    </AccordionStateContext.Provider>
  );
};

AccordionStateProvider.propTypes = {
  deps: PropTypes.array,
  children: PropTypes.node,
};