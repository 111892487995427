import { makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { useMemo } from 'react';

export const ValidationTexts = Object.freeze({
  Required: 'Validation_RequiredField',
  RequiredPlural: 'Validation_RequiredFieldPlural',
  RequiredCaptcha: 'Validation_Captcha_Required',
  Invalid: 'Validation_InvalidField',
  InvalidReadableField: 'Validation_InvalidReadableField',
  MaxLengthExceeded: 'Validation_MaxLengthExceeded',
  MinLengthExceeded: 'Validation_MinLengthExceeded',
  OutOfRange: 'Validation_OutOfRange',
  Range: 'Validation_Range',
  Precise: 'Validation_SumValue',
  MaxLength: 'Validation_MaxLength',
  PasswordStrength: 'Validation_PasswordStrength',
  UniqueEmail: 'CreateAccount_ExistingShopAccountEmail',
  PerCountryPatterns: 'Validation_GenericPatterns',
  UniqueOrderTemplate: 'OrderTemplate_SaveFailedInUse',
  MinAge: 'Validation_DatePicker_MinAgeMessage',
  FileNameMaxLength: 'FileUploader_MaxNameLengthValidation',
  FilesMaxSize: 'FileUploader_MaxSizeValidation',
});

const validationTextKeys = Object.values(ValidationTexts);

export const useValidationTexts = () => {
  const sanaTexts = useSanaTexts(validationTextKeys, makeSimpleText).texts;

  return useMemo(() => {
    return validationTextKeys.reduce((acc, textKey, index) => {
      const sanaText = sanaTexts[index];
      if (sanaText)
        acc[textKey] = sanaText;

      return acc;
    }, {});
  }, [sanaTexts]);
};

export const ValidationTextsLoader = () => {
  useSanaTexts(validationTextKeys);
  return null;
};