import { CONNECTION_CHANGED } from './actions';

const initialState = {
  onLine: true,
  accessible: true,
};
export default function connectionReducer(state = initialState, action) {
  if (action.type === CONNECTION_CHANGED) {
    return action.payload;
  }
  return state;
}