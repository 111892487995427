import styles from './PLP.module.scss';
import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestSortableFields } from 'behavior/products/search/sortableFields';
import { UseSanaTexts, SimpleText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Select } from 'components/primitives/form';
import { navigateTo } from 'behavior/events';
import { getSortOptionKey, parseSortOption } from 'behavior/pages/productList';
import { Placeholder } from 'components/primitives/placeholders';
import { useUrlBuilder } from './hooks';

const Sorting = ({
  sortableFields,
  selectedSorting,
  requestSortableFields,
  relevanceAvailable,
  navigateTo,
}) => {
  const sortingFields = sortableFields.fields;

  useEffect(() => {
    if (!sortingFields)
      requestSortableFields();
  }, [sortableFields]);

  const labelId = 'listSorting';
  const builderRef = useRef();
  builderRef.current = useUrlBuilder();

  const value = useMemo(() => {
    if (!selectedSorting)
      return;

    const { field, ascending } = selectedSorting;
    return getSortOptionKey(field, ascending);
  }, [selectedSorting]);

  const onSortingChange = useCallback(value => {
    const buildRoute = builderRef.current;
    const options = { sort: value && parseSortOption(value), page: 0 };
    const { url, to } = buildRoute(options, { productsOnly: true });

    navigateTo(to, url, { omitScroll: true });
  }, []);

  const sanaTextsToLoad = useMemo(() => {
    if (!sortingFields || !sortingFields.length)
      return null;

    const defaultTexts = [{ key: 'Ascending' }, { key: 'Descending' }];
    defaultTexts.disableInsiteEditor = true;

    if (relevanceAvailable)
      defaultTexts.push({ key: 'Relevance' });

    return sortingFields.reduce((result, { name, title }) => {
      const titleKey = 'General_Product_' + name;
      result.push({ key: titleKey, fallback: title || name });
      result.push({ key: titleKey + '_Asc' });
      result.push({ key: titleKey + '_Desc' });

      return result;
    }, defaultTexts);
  }, [sortingFields, relevanceAvailable]);

  if (!sortingFields)
    return <Placeholder className={styles.sortingPlaceholder} />;

  if (sortingFields && !sortingFields.length)
    return null;

  const sortingLabelPlaceholder = <Placeholder className={styles.sortingLabelPlaceholder} />;
  const selectPlaceholder = <Placeholder className={styles.selectPlaceholder} />;

  return (
    <div className={`text-right ${styles.sorting}`}>
      <span id={labelId} className={styles.sortingLabel}>
        <SimpleText textKey="SortBy_Label" placeholder={sortingLabelPlaceholder} />
      </span>
      <div className={styles.sortingDropDown}>
        <UseSanaTexts options={sanaTextsToLoad} placeholder={selectPlaceholder}>
          {([ascending, descending, ...fieldTitles]) => {
            const items = [];
            const ascendingText = (ascending || '').toLowerCase();
            const descendingText = (descending || '').toLowerCase();
            const shift = relevanceAvailable ? 1 : 0;

            if (relevanceAvailable)
              items.push({ name: fieldTitles[0] });

            sortingFields.forEach(({ name }, index) => {
              const fieldIndex = index * 3 + shift;
              const title = fieldTitles[fieldIndex];
              const titleAsc = fieldTitles[fieldIndex + 1];
              const titleDesc = fieldTitles[fieldIndex + 2];

              items.push({
                name: titleAsc
                  ? makeSimpleText(titleAsc)
                  : makeSimpleText(`${title} - ${ascendingText}`),
                value: getSortOptionKey(name, true),
              });
              items.push({
                name: titleDesc
                  ? makeSimpleText(titleDesc)
                  : makeSimpleText(`${title} - ${descendingText}`),
                value: getSortOptionKey(name, false),
              });
            });

            return (
              <Select
                items={items}
                onChange={onSortingChange}
                value={value}
                id="sorting"
                labelId={labelId}
              />
            );
          }}
        </UseSanaTexts>
      </div>
    </div>
  );
};

Sorting.propTypes = {
  sortableFields: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
    })),
  }),
  selectedSorting: PropTypes.shape({
    field: PropTypes.string.isRequired,
    ascending: PropTypes.bool.isRequired,
  }),
  relevanceAvailable: PropTypes.bool,
  navigateTo: PropTypes.func.isRequired,
  requestSortableFields: PropTypes.func.isRequired,
};

export default connect(
  ({ sortableFields }) => ({ sortableFields }),
  { requestSortableFields, navigateTo },
)(Sorting);