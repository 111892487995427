exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Impersonate_bar{border-bottom:1px solid #a81937;padding:8px 0;background:#c91f42;color:#fff;line-height:1.15;font-size:13px}.Impersonate_cnt{display:flex;justify-content:space-between}.Impersonate_cnt button{background:no-repeat;border:none;color:inherit;cursor:pointer;text-decoration:underline}.Impersonate_cnt button:hover{text-decoration:none}.Impersonate_cnt button:focus{-webkit-text-decoration-style:double;text-decoration-style:double}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/adminImpersonation/Impersonate.module.scss"],"names":[],"mappings":"AAAA,iBACI,gCACA,cACA,mBACA,WACA,iBACA,cACJ,CAEA,iBACI,aACA,6BACJ,CACI,wBACI,qBACA,YACA,cACA,eACA,yBACR,CACQ,8BACI,oBACZ,CAEQ,8BACI,qCAAA,4BAAZ,CAAA","file":"Impersonate.module.scss","sourcesContent":[".bar {\n    border-bottom: 1px solid #a81937;\n    padding: 8px 0;\n    background: #c91f42;\n    color: #fff;\n    line-height: 1.15;\n    font-size: 13px;\n}\n\n.cnt {\n    display: flex;\n    justify-content: space-between;\n\n    button {\n        background: no-repeat;\n        border: none;\n        color: inherit;\n        cursor: pointer;\n        text-decoration: underline;\n\n        &:hover {\n            text-decoration: none;\n        }\n\n        &:focus {\n            text-decoration-style: double;\n        }\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"bar": "Impersonate_bar",
	"cnt": "Impersonate_cnt"
};