import styles from 'components/objects/product/Details.module.scss';
import React from 'react';
import { SimpleText } from 'components/sanaText';
export function nonDownloadable(attachment) {
  return (
    <>
      {attachment.title}
      <span className={styles.unableLoadTitle}>
        <SimpleText textKey="UnableLoadAttachmentInOfflineMode" />
      </span>
    </>
  );
}
