import styles from './Product.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import UomTitle from './UomTitle';
import { Placeholder } from 'components/primitives/placeholders';

const PricePer = ({ uomId, uoms }) => {
  if (!uomId || !uoms)
    return null;

  const uom = uoms.find(u => u.id === uomId);
  if (!uom || !uom.description)
    return null;

  const pricePerPlaceholder = <Placeholder className={styles.pricePerPlaceholder} />;
  const uomTitlePlaceholder = <Placeholder className={styles.uomTitlePlaceholder} />;

  return (
    <div>
      <SimpleText textKey="Product_PricePerUnitOfMeasure" placeholder={pricePerPlaceholder} />
      {' '}
      <UomTitle id={uom.id} description={uom.description} placeholder={uomTitlePlaceholder} />
    </div>
  );
};

PricePer.propTypes = {
  uomId: PropTypes.string,
  uoms: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
  })),
};

export default React.memo(PricePer);