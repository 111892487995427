import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SanaForm } from 'components/objects/forms';
import { ReadOnlyFormGroup } from 'components/objects/forms/formGroups';
import { useDispatch, useSelector } from 'react-redux';
import { toLocaleDate } from 'utils/format';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { FormattedPrice } from 'components/primitives/price';
import PaymentMethodsListField from 'components/objects/orderPayment/PaymentMethodsListField';
import { PromotionalText } from 'components/objects/promotionalTexts';
import { WarningAlert } from 'components/primitives/alerts';
import { requestPaymentMethodExtraData } from 'behavior/pages/invoicePayment';

const InvoicePaymentForm = ({ order, paymentMethods, formRef }) => {
  const dispatch = useDispatch();
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const culture = useSelector(({ localization }) => localization.currentLanguage?.cultureName);

  const hasPaymentMethods = paymentMethods.length > 0;
  const defaultPaymentMethod = paymentMethods.find(pm => pm.isDefault);

  const onPaymentMethodChange = useCallback(paymentMethodId => {
    dispatch(requestPaymentMethodExtraData(order.id, paymentMethodId));
  }, [order.id]);

  useEffect(() => {
    if (defaultPaymentMethod?.id)
      onPaymentMethodChange(defaultPaymentMethod.id);
  }, [defaultPaymentMethod]);

  const [paymentMethodErrorMessage] = useSimpleTexts(['PaymentMethods_ValidationMessage']).texts;

  return (
    <SanaForm name="InvoicePayment" formikRef={formRef}>
      {order.referenceNo && (
        <ReadOnlyFormGroup
          fieldName="referenceNo"
          fieldTitle={<SimpleText textKey="Payment_ReferenceNumber" />}
          displayValue={order.referenceNo}
        />
      )}
      <ReadOnlyFormGroup
        fieldName="orderDate"
        fieldTitle={<SimpleText textKey="Payment_OrderDate" />}
        displayValue={toLocaleDate(order.orderDate, culture)}
      />
      {showPrices && (
        <ReadOnlyFormGroup
          fieldName="totalAmount"
          fieldTitle={<SimpleText textKey="Payment_TotalAmount" />}
          displayValue={<FormattedPrice price={order.totalAmount} currencyInfo={order.currency} />}
        />
      )}
      {hasPaymentMethods ? (
        <PaymentMethodsListField
          fieldName="paymentMethodId"
          paymentMethods={paymentMethods}
          onChange={onPaymentMethodChange}
          currency={order.currency}
          initialValue={defaultPaymentMethod?.id}
          validation={{ required: { message: paymentMethodErrorMessage } }}
        />
      ) : <WarningAlert><PromotionalText textKey="PaymentMethodsNotAvailable" withPlaceholder /></WarningAlert>}
    </SanaForm>
  );
};

InvoicePaymentForm.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isDefault: PropTypes.bool.isRequired,
    }).isRequired,
  ).isRequired,
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currency: PropTypes.object.isRequired,
    referenceNo: PropTypes.string,
    orderDate: PropTypes.string.isRequired,
    totalAmount: PropTypes.number,
  }).isRequired,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

export default React.memo(InvoicePaymentForm);
