import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import FacetsContext from './FacetsContext';
import { useOnChange } from 'utils/hooks';
import { alphanumSort } from './helpers';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useFacetUrlsBuilder } from './hooks';

const FacetsProvider = ({ children, urlBuilder, facets: { facets, multiSelect } }) => {
  const availableFacets = useRef([]);
  const selectedFacets = useRef([]);

  useOnChange(() => {
    if (!facets) {
      selectedFacets.current = [];
      availableFacets.current = [];
      return;
    }

    const facetsModel = createFacetsModel(facets);

    if (availableFacets.current.length !== 0 || facetsModel.availableFacets.length !== 0)
      availableFacets.current = facetsModel.availableFacets;

    if (selectedFacets.current.length !== 0 || facetsModel.selectedFacets.length !== 0)
      selectedFacets.current = facetsModel.selectedFacets;
  }, [facets]);

  const facetUrlsBuilder = useFacetUrlsBuilder(availableFacets.current, urlBuilder);

  const context = useMemo(() => {
    const getUrl = (facet, value) => value.selected
      ? facetUrlsBuilder.remove(facet, value)
      : facetUrlsBuilder.add(facet, value);

    return {
      multiSelect,
      hasFacets: facets && facets.length > 0,
      availableFacets: availableFacets.current,
      selectedFacets: selectedFacets.current,
      getUrl: facetUrlsBuilder && getUrl,
      urlWithoutFacets: facetUrlsBuilder && facetUrlsBuilder.removeAll,
    };
  }, [facets, multiSelect, availableFacets.current, selectedFacets.current, facetUrlsBuilder]);

  return (
    <FacetsContext.Provider value={context}>
      {children}
    </FacetsContext.Provider>
  );
};

FacetsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  urlBuilder: PropTypes.func.isRequired,
  facets: PropTypes.shape({
    facets: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      sortDescending: PropTypes.bool.isRequired,
      values: PropTypes.array,
    })),
    multiSelect: PropTypes.bool.isRequired,
  }),
};

export default FacetsProvider;

function createFacetsModel(facets) {
  const selectedFacets = [];
  const availableFacets = [];

  for (const facet of facets) {
    const facetModel = {
      title: facet.title,
      textTitle: stripHtmlTagsAndFormatting(facet.title),
      name: facet.name,
      anySelected: false,
      values: [...facet.values],
    };

    const selectedFacetModel = {
      title: facet.title,
      name: facet.name,
      values: [],
    };

    for (const value of facet.values) {
      if (value.selected) {
        facetModel.anySelected = true;
        selectedFacetModel.values.push(value);
      }
    }

    if (selectedFacetModel.values.length) {
      selectedFacetModel.values = alphanumSort(selectedFacetModel.values, facet.sortDescending);
      selectedFacets.push(selectedFacetModel);
    }

    alphanumSort(facetModel.values, facet.sortDescending);
    availableFacets.push(facetModel);
  }

  return {
    selectedFacets,
    availableFacets,
  };
}
