import styles from './Error.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { useSimpleTexts } from 'components/sanaText';
import { retryFailedRequests } from 'behavior/errorHandling/actions';
import { useToasts } from 'react-toast-notifications';
import { Placeholder } from 'components/primitives/placeholders';
import { useFocusActionBtn } from './hooks';

const ErrorToastWithRetry = ({ retryFailedRequests, id, connection }) => {
  const { removeToast } = useToasts();
  const connectionStatusRef = useRef(connection.onLine);

  const onClick = useCallback(() => {
    retryFailedRequests();
    removeToast(id);
  }, [retryFailedRequests, id]);

  const options = ['ButtonText_Retry', 'ButtonAltText_Retry'];
  options.disableInsiteEditor = true;

  const { texts: [btnText, btnAltText], loaded } = useSimpleTexts(options);

  const retryBtnRef = useRef();
  useFocusActionBtn(loaded && retryBtnRef);

  return (
    <div className={styles.body}>
      <p>
        {connectionStatusRef.current
          ? (
            <SimpleText textKey="RetryErrorMessageText" disableInsiteEditor>
              Unfortunately something went wrong during the execution of your action.
            </SimpleText>
          )
          : (
            <SimpleText textKey="OfflineErrorMessageText" disableInsiteEditor>
              Unfortunately there was an issue with retrieving the information for this page.
              Please check your internet connection.
            </SimpleText>
          )}
      </p>
      {loaded
        ? (
          <button className={`${linkStyles.link} ${styles.actionBtn}`} title={btnAltText || 'Retry'} onClick={onClick} ref={retryBtnRef}>
            {btnText || 'Retry'}
          </button>
        )
        : <Placeholder className={styles.loading} />
      }
    </div>
  );
};

ErrorToastWithRetry.propTypes = {
  retryFailedRequests: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  connection: PropTypes.shape({
    onLine: PropTypes.bool.isRequired,
    accessible: PropTypes.bool.isRequired,
  }),
};

const mapStateToProps = ({ connection }) => ({ connection });

export default connect(mapStateToProps, { retryFailedRequests })(ErrorToastWithRetry);

export const preload = {
  routes: [],
  texts: [
    'RetryErrorMessageText',
    'OfflineErrorMessageText',
    'ButtonText_Retry',
    'ButtonAltText_Retry',
  ],
};
