import styles from './Tabs.module.scss';
import React, { useState, useMemo, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TabItem from './TabItem';
import { HashRoutingContext } from 'components/hash';
import { forEach } from 'utils/react';
import { resize$, useEventObservable } from 'utils/rxjs';
import { usePrintMode, useOnChange } from 'utils/hooks';
import { BigArrowLeftIcon, BigArrowRightIcon } from 'components/primitives/icons';

let scrollTimeout;

const Tabs = ({
  name,
  children,
  resetDependency,
  areScrollable,
  preventTransitionDependency,
  afterChangedTab,
}) => {
  const [activeKey, setActiveKey] = useState();
  const hashContext = useContext(HashRoutingContext);
  const tabPrefix = `${name}_tab_`;
  const panelPrefix = `${name}_`;
  const tabsWrapperRef = useRef(null);
  const prevBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  const isPrintMode = usePrintMode();

  if (!children || children.length === 0)
    return null;

  const keys = useMemo(getTabKeys, [children]);

  useOnChange(() => {
    if (!keys.includes(activeKey))
      setActiveKey(keys[0]);
  }, [keys]);
  useOnChange(() => setActiveKey(keys[0]), [resetDependency || children]);
  useOnChange(() => {
    const hash = hashContext.hash && hashContext.hash.slice(1);
    for (let i = 0; i < keys.length; i++) {
      const tabKey = keys[i];
      if (tabKey === hash) {
        setActiveKey(tabKey);
        return;
      }
    }
    if (!hash)
      return;
    for (let i = 0; i < keys.length; i++) {
      const tabKey = keys[i];
      if (hash.startsWith(tabKey)) {
        setActiveKey(tabKey);
        return;
      }
    }
  }, [hashContext]);

  const onKeyDown = ({ altKey, shiftKey, ctrlKey, which }) => {
    //Keyboard codes for Right arrow - 39 and Left arrow - 37
    if ((altKey || shiftKey || ctrlKey) || (which !== 37 && which !== 39))
      return;

    const isRightArrow = which === 39;
    const newIndex = getSubsequentTabIndex(activeKey, keys, areScrollable, isRightArrow);
    const newActiveKey = keys[newIndex];
    setActiveKey(newActiveKey);
    const newActiveTabEl = document.getElementById(tabPrefix + newActiveKey);
    // for scrollable tabs focus should be set on tabs wrapper transition end to prevent
    // missplacing of control buttons
    if (areScrollable) {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        newActiveTabEl.focus();
      }, styles.scrollTransitionTime);
      return;
    }

    newActiveTabEl.focus();
  };

  let navButtonsBlock = null;

  if (areScrollable) {
    const changeTab = ({ currentTarget }) => {
      const nextTab = currentTarget === nextBtnRef.current;
      const newIndex = getSubsequentTabIndex(activeKey, keys, areScrollable, nextTab);
      const newActiveKey = keys[newIndex];
      setActiveKey(newActiveKey);

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        document.getElementById(tabPrefix + newActiveKey).focus();
      }, styles.scrollTransitionTime);
    };

    navButtonsBlock = (
      <>
        <button className={styles.prevBtn} onClick={changeTab} tabIndex="-1" ref={prevBtnRef}>
          <span className={styles.icon}>
            <BigArrowLeftIcon />
          </span>
        </button>
        <button className={styles.nextBtn} onClick={changeTab} tabIndex="-1" ref={nextBtnRef}>
          <span className={styles.icon}>
            <BigArrowRightIcon />
          </span>
        </button>
      </>
    );
  }

  useEventObservable(
    resize$,
    () => configureNav(activeKey, keys, tabsWrapperRef, prevBtnRef, nextBtnRef),
    true,
    [activeKey, keys, tabsWrapperRef.current, prevBtnRef.current, nextBtnRef.current],
  );

  useEffect(() => {
    const { current } = tabsWrapperRef;
    if (afterChangedTab) {
      afterChangedTab(activeKey);
    }
    if (!areScrollable || !current)
      return;

    configureNav(activeKey, keys, tabsWrapperRef, prevBtnRef, nextBtnRef);
  });

  useEffect(() => {
    const { current } = tabsWrapperRef;
    if (!areScrollable || !current)
      return;

    // Prevents scrollable tabs transition on preventTransitionDependency change,
    // transition restored after a timeout
    setTimeout(() => current.style.transition = '', 500);
    return () => {
      current.style.transition = 'none';
    };
  }, [areScrollable, preventTransitionDependency]);

  const { tabs, content } = useMemo(getTabsContent, [activeKey, children, name]);

  return (
    <article
      className={`${styles.container} ${areScrollable ? styles.scrollable : ''}`}
      onKeyDown={!isPrintMode ? onKeyDown : null}
    >
      {!isPrintMode && (
        <div className={styles.nav} role="tablist">
          {areScrollable
            ? <div className={styles.wrapper} ref={tabsWrapperRef}>{tabs}</div>
            : tabs
          }
          {navButtonsBlock}
        </div>
      )}
      <div className={styles.body}>
        {content}
      </div>
    </article>
  );

  function getTabsContent() {
    const tabs = [], content = [];
    forEach(children, child => {
      const { tabKey, title, children, disabled } = child.props;
      const disabledCheck = (disabled) ? disabled : false;
      const active = tabKey === activeKey;
      const className = styles.item + (active ? ' active' : '');
      const tabId = tabPrefix + tabKey, panelId = panelPrefix + tabKey;

      tabs.push((
        <div className={className} key={tabKey}>
          <button
            onClick={() => setActiveKey(tabKey)}
            id={tabId}
            role="tab"
            aria-selected={active}
            aria-controls={panelId}
            tabIndex={active ? 0 : -1}
            disabled={disabledCheck}
          >
            {title}
          </button>
        </div>
      ));

      content.push((
        <div className={className} key={tabKey} id={tabKey}>
          {!areScrollable && (
            <div className={styles.heading}>
              {title}
            </div>
          )}
          <div
            tabIndex={isPrintMode ? null : '0'}
            id={panelId}
            role="tabpanel"
            aria-labelledby={isPrintMode ? null : tabId}
            className={styles.bodyContent}
          >
            {children}
          </div>
        </div>
      ));

    });
    return { tabs, content };
  }

  function getTabKeys() {
    const keys = [];
    forEach(children, child => {
      const { tabKey } = child.props;
      keys.push(tabKey);
    });
    return keys;
  }
};

Tabs.Item = TabItem;

Tabs.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  resetDependency: PropTypes.any,
  areScrollable: PropTypes.bool,
  preventTransitionDependency: PropTypes.any,
  afterChangedTab: PropTypes.func,
};

export default Tabs;

function getActiveTabIndex(activeKey, tabKeys) {
  return tabKeys.indexOf(activeKey);
}

function getSubsequentTabIndex(activeKey, tabKeys, areScrollable, nextTab = false) {
  const activeTabIndex = getActiveTabIndex(activeKey, tabKeys);
  const lastTabKeyIndex = tabKeys.length - 1;
  return nextTab
    ? activeTabIndex < lastTabKeyIndex
      ? activeTabIndex + 1
      : areScrollable
        ? activeTabIndex
        : 0
    : activeTabIndex > 0
      ? activeTabIndex - 1
      : areScrollable
        ? activeTabIndex
        : lastTabKeyIndex;
}

function configureNav(activeKey, tabKeys, tabsWrapperRef, prevBtnRef, nextBtnRef) {
  const { current: tabsWrapperEl } = tabsWrapperRef;
  const { current: prevBtnEl } = prevBtnRef;
  const { current: nextBtnEl } = nextBtnRef;
  if (!tabsWrapperEl || !prevBtnEl || !nextBtnEl)
    return;

  // additional shift for first tab outline to fit tabs nav visible area
  const leftOutlineShift = 1;
  // additional shift for last tab outline to fit tabs nav visible area
  const rightOutlineShift = 2;
  const activeTabIndex = getActiveTabIndex(activeKey, tabKeys);
  const { offsetWidth } = tabsWrapperEl;
  const { offsetWidth: navOffsetWidth } = tabsWrapperEl.parentElement;
  const navItems = tabsWrapperEl.children;
  const isFirstTabActive = activeTabIndex === 0;
  const isLastTabActive = activeTabIndex === navItems.length - 1;
  const { offsetWidth: activeTabWidth, offsetLeft: activeTabOffsetLeft } = navItems[activeTabIndex];
  const areTabsScrollable = offsetWidth > navOffsetWidth;
  const activeTabShift = navOffsetWidth / 2 - activeTabOffsetLeft - activeTabWidth / 2;

  const leftShift = areTabsScrollable
    ? isFirstTabActive
      ? leftOutlineShift
      : isLastTabActive
        ? navOffsetWidth - activeTabOffsetLeft - activeTabWidth - rightOutlineShift
        : activeTabShift < leftOutlineShift
          ? activeTabShift
          : leftOutlineShift
    : leftOutlineShift;

  tabsWrapperEl.style.transform = `translateX(${leftShift}px)`;
  prevBtnEl.classList[
    areTabsScrollable && !isFirstTabActive && leftShift !== leftOutlineShift
      ? 'add'
      : 'remove'
  ](styles.show);
  nextBtnEl.classList[
    areTabsScrollable && !isLastTabActive
      ? 'add'
      : 'remove'
  ](styles.show);
}