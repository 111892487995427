import { of, Subject, merge, EMPTY } from 'rxjs';
import { mergeMap, map, tap, ignoreElements, first } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { loadRoutesQuery } from './queries';
import { arrayToObject, generateKey } from 'utils/helpers';
import { routesLoaded, ROUTES_REQUESTED } from './actions';
import { mapRouteToInput, getRouteKey } from './helpers';
import { LANGUAGE_CHANGED } from 'behavior/events';
import { APP_INIT, APP_INIT_HYDRATE } from 'behavior/app';
import { bufferBatchForLoading, handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { PageComponentNames } from 'behavior/pages';
import { retryWithToast } from 'behavior/errorHandling';

function routeEpic(action$, state$, { api, logger, completePendingActions$ }) {
  const routesLoadedSubject = new Subject();
  routeEpic.pushRoute = (key, path) => routesLoadedSubject.next(routesLoaded([{ [key]: { path } }]));

  const usedRouteKeys = new Set();

  function mapActionsToRequestState(actions) {
    const routes = [];
    const keysMap = new Map();

    for (const action of actions)
      for (const route of action.payload) {
        const key = getRouteKey(route);

        if (usedRouteKeys.has(key))
          continue;

        usedRouteKeys.add(key);
        const data = mapRouteToInput(route);
        data.key = generateKey();

        routes.push(data);
        keysMap.set(data.key, key);
      }

    return { routes, keysMap };
  }

  const loadRoutes$ = action$.pipe(
    ofType(ROUTES_REQUESTED),
    bufferBatchForLoading(completePendingActions$),
    mergeMap(actions => {
      const { routes, keysMap } = mapActionsToRequestState(actions);
      if (!routes.length)
        return EMPTY;

      const state = state$.value;
      const errorHandler = state.page.component !== PageComponentNames.Error && Object.keys(state.routes).length > 0
        ? retryWithToast(action$, logger)
        : handleError(ERROR_PRIORITIES.HIGH, 'Sana texts');

      return api.graphApi(loadRoutesQuery, { routes }).pipe(
        map(({ routing }) => {
          for (const key of keysMap.values())
            usedRouteKeys.delete(key);

          return routesLoaded(arrayToObject(routing.routePaths, getKeySelector(keysMap), selectPath));
        }),
        errorHandler,
      );
    }),
  );

  const resetKeys$ = action$.pipe(
    ofType(LANGUAGE_CHANGED),
    tap(() => usedRouteKeys.clear()),
    ignoreElements(),
  );

  const init$ = action$.pipe(
    ofType(APP_INIT, APP_INIT_HYDRATE),
    first(),
    tap(() => Object.keys(state$.value.routes).forEach(usedRouteKeys.add.bind(usedRouteKeys))),
    ignoreElements(),
  );

  return merge(routesLoadedSubject, loadRoutes$, resetKeys$, init$);
}

export function requestRoute(route, state$, { api }) {
  const key = getRouteKey(route);
  const existingRoute = state$.value.routes[key];
  if (existingRoute && !existingRoute.expired)
    return of(existingRoute.path);

  const routeInput = mapRouteToInput(route);
  routeInput.key = '1';

  return api.graphApi(loadRoutesQuery, { routes: [{ ...routeInput }] }).pipe(
    map(({ routing }) => {
      const path = routing.routePaths[0].path;
      routeEpic.pushRoute && routeEpic.pushRoute(key, path);
      return path;
    }),
  );
}

export default routeEpic;

function getKeySelector(keysMap) {
  return routeData => keysMap.get(routeData.key);
}

function selectPath({ path }) {
  return { path };
}
