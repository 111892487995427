import styles from './Video.module.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { generateKey } from 'utils/helpers';

const YOUTUBE_API_SRC = 'https://www.youtube.com/player_api';

let asyncLoadYoutubeAPI;

const YoutubePlayer = ({ videoId, className = '', events, ...playerVars }) => {
  const [playerId] = useState(() => `yt_player_${generateKey()}`);

  useEffect(() => {
    let player;
    if (!asyncLoadYoutubeAPI) {
      asyncLoadYoutubeAPI = new Promise(resolve => {
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
        const tag = document.createElement('script');
        tag.src = YOUTUBE_API_SRC;
        document.body.appendChild(tag);
      });
    }
    asyncLoadYoutubeAPI.then(YT => {
      player = new YT.Player(playerId, {
        videoId,
        playerVars: {
          origin: window.location.origin,
          rel: 0,
          showinfo: 0,
          ...playerVars,
        },
        events,
      });
    });
    return () => player?.destroy();
  }, [videoId, events, playerVars]);

  return (
    <div className={`${styles.frame} ${className}`}>
      <div id={playerId} />
    </div>
  );
};

YoutubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  className: PropTypes.string,
  events: PropTypes.shape({
    onReady: PropTypes.func,
    onStateChange: PropTypes.func,
    onPlaybackQualityChange: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onError: PropTypes.func,
    onApiChange: PropTypes.func,
  }),
};

export default React.memo(YoutubePlayer);