import styles from './ActionButtons.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { routesBuilder } from 'routes';
import { SanaLinkButton } from 'components/primitives/links';
import { useUpdateContext } from '../updateContext';

const QuoteButton = ({ disabled, className, canCreateOrder }) => {
  const { writeOnlySubmit } = useUpdateContext();
  return (
    <SanaLinkButton
      textKey="GetQuote"
      to={routesBuilder.forCheckout(true)}
      onClick={() => writeOnlySubmit()}
      className={joinClasses(
        btnStyles.btn,
        styles.btnQuote,
        canCreateOrder
          ? btnStyles.btnSmall
          : btnStyles.btnBig + ' ' + btnStyles.btnAction,
        className,
      )}
      disabled={disabled}
    />
  );
};

QuoteButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  canCreateOrder: PropTypes.bool,
};

export default React.memo(QuoteButton);