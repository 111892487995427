import React from 'react';
import PropTypes from 'prop-types';
import { StarsRating } from 'components/primitives/rating';
import { WithProductClickTracking } from 'components/objects/analytics';
import ReviewsLink from './ReviewsLink';

const RatingDisplay = ({ reviews, to, url = '' }) => {
  if (!reviews)
    return null;

  url += '#reviews';

  return (
    <>
      <StarsRating value={reviews.avg} />
      <WithProductClickTracking>
        {handleClick => <ReviewsLink reviewsTotal={reviews.total} onClick={handleClick} to={to} url={url} />}
      </WithProductClickTracking>
    </>
  );
};

RatingDisplay.propTypes = {
  reviews: PropTypes.shape({
    avg: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  to: PropTypes.any.isRequired,
  url: PropTypes.any.isRequired,
};

export default React.memo(RatingDisplay);