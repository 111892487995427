import type { StyleModule } from './types';
import React, { useContext, useRef, useEffect, ComponentType } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import StyleContext from './StyleContext';

// To detect if it's in SSR process or in browser. 
const isBrowser = typeof window !== 'undefined' && !!window.document;

function withStyles(...styles: StyleModule[]) {
  return function wrapWithStyles<T, P>(ComposedComponent: ComponentType<P>) {
    const WithStyles = React.forwardRef<T, P>((props, ref) => {

      const { insertCss, version, isReactDOMServer } = useContext(StyleContext);

      if (isBrowser && !isReactDOMServer) {
        const loadedVersion = useRef<boolean | string | undefined>(false);
        let removeCss: (() => void) | void;

        if (loadedVersion.current !== version) {
          loadedVersion.current = version;
          removeCss = insertCss(styles);
        }

        useEffect(() => removeCss && (() => void setTimeout(removeCss as () => void, 0)), [version]);
      } else {
        insertCss(styles);
      }

      return React.createElement(ComposedComponent, { ref, ...props });
    });

    const displayName = ComposedComponent.displayName || ComposedComponent.name || 'Component';

    WithStyles.displayName = `Styled${displayName}`;

    return hoistStatics(WithStyles, ComposedComponent);
  };
}

export default withStyles;
