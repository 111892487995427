exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PageContent_footer,.PageContent_header,.PageContent_middle{\n  /*!*/}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/pages/PageContent.module.scss"],"names":[],"mappings":"AACA;EAGE,KACF,CAAA","file":"PageContent.module.scss","sourcesContent":["/* Empty classes for tests & customizations */\n.footer,\n.header,\n.middle {\n  /*!*/\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "PageContent_footer",
	"header": "PageContent_header",
	"middle": "PageContent_middle"
};