import {
  ADDON_LOADED,
  ADDON_ACTION_PREFIX,
  EVENT_ACTION_PREFIX,
  ADDON_SCOPE_CLEARED,
} from './actions';
import { ADDONS_METADATA_LOADED, ADDON_METADATA_CLEARED } from './metadata/actions';
import { combineReducers } from 'redux';
import { normalizeAddonId } from './helpers';

const INIT_ACTION = { type: '@@INIT' };

const metadataReducer = (state = null, action) => {
  if (action.type === ADDONS_METADATA_LOADED) {
    return action.payload.reduce((result, addon) => {
      result[normalizeAddonId(addon.id)] = addon.metadata;
      return result;
    }, { ...state });
  }

  if (action.type === ADDON_METADATA_CLEARED) {
    const { id } = action.payload;
    return omitAddonData(normalizeAddonId(id), state);
  }

  return state;
};

const scopesReducer = addonRegistry => (state = {}, action) => {
  if (action.type === ADDON_LOADED) {
    const { id, hash, addon } = action.payload;
    addonRegistry.add(id, hash, addon);
    return reduceAddonState(state, INIT_ACTION, id, addon);
  }

  if (action.type === ADDON_SCOPE_CLEARED) {
    const { id } = action.payload;
    return omitAddonData(normalizeAddonId(id), state);
  }

  if (action.type.startsWith(ADDON_ACTION_PREFIX)) {
    const { addonId, addonHash, action: addonAction } = action.payload;

    if (addonId === '*') {
      return addonRegistry.getEntries().reduce((state, { id, addon }) =>
        reduceAddonState(state, addonAction, id, addon), state);
    }
    else {
      const addon = addonRegistry.get(addonId, addonHash);
      return addon ? reduceAddonState(state, addonAction, addonId, addon) : state;
    }
  }
  else if (action.type.startsWith(EVENT_ACTION_PREFIX)) {
    return addonRegistry.getEntries().reduce((state, { id, addon }) =>
      reduceAddonState(state, action, id, addon), state);
  }

  return state;
};

export default (addonRegistry, extraReducers = undefined) => {
  const reducer = combineReducers({
    ...extraReducers,
    metadata: metadataReducer,
    scopes: scopesReducer(addonRegistry),
  });

  return (state, action) => {
    if (action.type.startsWith(ADDON_ACTION_PREFIX)) {
      const metadata = state.metadata && state.metadata[action.payload.addonId];
      return reducer(state, {
        ...action,
        payload: {
          ...action.payload,
          addonHash: metadata && metadata.bundle ? metadata.bundle.hash : undefined,
        },
      });
    }
    else {
      return reducer(state, action);
    }
  };
};

function reduceAddonState(state, action, addonId, addon) {
  if (!addon || !addon.reducer)
    return state;

  const addonState = state[normalizeAddonId(addonId)];
  const newAddonState = addon.reducer(addonState, action);

  if (addonState === newAddonState)
    return state;

  return { ...state, [normalizeAddonId(addonId)]: newAddonState };
}

function omitAddonData(id, { [id]: _, ...addon } = {}) {
  return addon;
}