import { loadSubAccountsPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { createNotFoundPageResult } from '../helpers';
import { PageComponentNames } from '../componentNames';

export default (_route, _state$, { api }) => {
  return api.graphApi(loadSubAccountsPageQuery).pipe(
    map(({ pages, profile: { subAccounts } }) => {
      const page = pages.subAccounts;

      if (!subAccounts)
        return createNotFoundPageResult(page);

      page.component = PageComponentNames.SubAccounts;
      page.subAccounts = subAccounts.search;
      page.options = subAccounts.options;
      return { page };
    }),
    initSystemPageContent(),
  );
};