import { THEME_LOADED } from './actions';
import { ErrorVersion } from './constants';

export const initialState = {
  values: null,
  version: null,
  loaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case THEME_LOADED:
      return { ...action.payload, loaded: action.payload?.version !== ErrorVersion };
    default:
      return state;
  }
}