import React from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const UseSanaButton = ({
  textKey,
  titleTextKey = textKey,
  defaultText,
  placeholder,
  dontWait,
  children,
}) => {
  const { texts: [text, title], loaded } = useSanaButton(textKey, titleTextKey, defaultText);
  if (!dontWait && !loaded)
    return placeholder || null;

  return children(text, title);
};

UseSanaButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  defaultText: PropTypes.string,
  dontWait: PropTypes.bool,
  children: PropTypes.func.isRequired,
  placeholder: PropTypes.node,
};

export default React.memo(UseSanaButton);

export function useSanaButton(textKey, titleTextKey = textKey, defaultText, formatWith) {
  const textsState = useSanaTexts([
    {
      key: `ButtonText_${textKey}`,
      fallback: defaultText,
    }, {
      key: `ButtonAltText_${titleTextKey}`,
      fallback: defaultText,
    },
  ], formatWith ? undefined : makeSimpleText);

  if (!formatWith || !textsState.loaded)
    return textsState;

  const [text, altText] = textsState.texts;
  const texts = [
    makeSimpleText(text, formatWith),
    makeSimpleText(altText),
  ];

  return { texts, loaded: textsState.loaded };
}
