import React from 'react';
import { InfoAlert } from 'components/primitives/alerts';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const BasketUnavailable = () => {
  const [msg, linkText] = useSanaTexts(['BasketUnavailable', 'BasketUnavailable_Reload']).texts;

  return (
    <InfoAlert>
      {makeRichText(msg, [<Link to={routesBuilder.forBasket()}>{makeRichText(linkText)}</Link>])}
    </InfoAlert>
  );
};

export default BasketUnavailable;
