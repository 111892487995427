import styles from './Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import { renderHTML, makeSimpleText } from 'utils/render';
import { SimpleText, UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { HashRoutingContext } from 'components/hash';
import { connectToContext } from 'utils/react';
import PropTypes from 'prop-types';
import ProductContext from './ProductContext';
import PrintPreviewLink from './PrintPreviewLink';
import { Placeholder } from 'components/primitives/placeholders';
import { usePrintMode } from 'utils/hooks';

const maxDescriptionLength = 300;

const ProductDescription = ({ navigateTo, description, matrixPreset }) => {
  const isPrintMode = usePrintMode();
  const shortenDescription = stripHtmlTagsAndFormatting(description).substr(0, maxDescriptionLength);
  const showReadFullDescriptionLink = !isPrintMode && description && shortenDescription !== description.trim();

  const descriptionBlock = description
    ? (
      <div className={styles.shortenDescription}>
        {renderHTML(shortenDescription)}
      </div>
    )
    : (
      <SimpleText
        textKey="Product_NoDescriptionMessage"
        placeholder={<Placeholder className={styles.noDescriptionPlaceholder} />}
      />
    );

  const fullDescriptionPlaceholder = <Placeholder className={`${styles.fullDescriptionPlaceholder} ${styles.link}`} />;

  return (
    <>
      {descriptionBlock}
      <div className={styles.controls}>
        {showReadFullDescriptionLink && (
          <UseSanaTexts options={['ReadFullDescription']} placeholder={fullDescriptionPlaceholder}>
            {([ReadFullDescription]) => (
              <button
                onClick={() => navigateTo('#description', { extraTopOffset: 60, time: 400 })}
                className={`${styles.link} ${linkStyles.link} ${linkStyles.arrowed}`}
              >
                {makeSimpleText(ReadFullDescription)}
              </button>
            )}
          </UseSanaTexts>
        )}
        <PrintPreviewLink isPrintMode={isPrintMode} matrixPreset={matrixPreset} />
      </div>
    </>
  );
};

ProductDescription.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  description: PropTypes.string,
  matrixPreset: PropTypes.bool,
};

export default connectToContext([HashRoutingContext, ProductContext],
  ({ navigateTo }, { product: { description } }) => ({
    navigateTo,
    description,
  }),
)(React.memo(ProductDescription));
