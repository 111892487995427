import styles from './Facets.module.scss';
import React from 'react';
import Facets from './Facets';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';

const MobileFacets = ({ expanded, className = '' }) => (
  <VerticalSliding expanded={expanded} containerClass={`${styles.mobileFacetsSlider} ${className}`}>
    <Facets isMobile />
  </VerticalSliding>
);

MobileFacets.propTypes = {
  expanded: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(MobileFacets);