import styles from './ProductSelector.module.scss';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useOnCustomerChanged } from 'utils/hooks';
import { searchProducts, requestProduct, clearProduct } from 'behavior/productSelector';
import ProductPanel from './ProductPanel';
import SearchBox from './SearchBox';
import LoadingIndicator from './LoadingIndicator';
import { SimpleText } from 'components/sanaText';

const SEARCH_INPUT_ID = 'productSelectorSearch';

const ProductSelector = ({
  texts,
  onProductAdd,
  productAddDisabled,
  allowAddUnorderableProduct,
  productClickTrackingSource,
}) => {
  const dispatch = useDispatch();
  const { suggestions, selectedProduct } = useSelector(({ productSelector }) => ({
    suggestions: productSelector.suggestions,
    selectedProduct: productSelector.selectedProduct,
  }), shallowEqual);
  const productLoadingState = useState(false);
  const [productLoading, setProductLoading] = productLoadingState;

  const onProductSearch = useCallback((keywords, count) => dispatch(searchProducts(keywords, count)), []);
  const onProductSelect = useCallback(id => dispatch(requestProduct(id)), []);
  const onProductClear = useCallback(() => dispatch(clearProduct()), []);
  
  //GF OP18938 Find out if the basket contains spares or products.
  const basket = useSelector(x => x.basket);

  var basketContainsSpare = false;
  var basketContainsProduct = false;

  if (basket !== undefined && basket.model !== null && basket.model.productLines.list !== undefined && basket.model.productLines.totalCount > 0 && basket.model.productLines.list[0] !== undefined) {
    if (basket.model.productLines.list[0].product.isSpare) {
      basketContainsSpare = true;
    }
    else {
      basketContainsProduct = true;
    }
  }
  else {
    //console.log('undefined objects');
  }

  var addingProductToSparesBasket = false;
  var addingSpareToProductBasket = false;
  
  if (selectedProduct !== null) {
    if (selectedProduct.isSpare && basketContainsProduct) {
      addingSpareToProductBasket = true;
    }
    else if (selectedProduct.isSpare === false && basketContainsSpare) {
      addingProductToSparesBasket = true;
    }
  }

  var mixedBasketInfo;
  if (addingProductToSparesBasket) {
    mixedBasketInfo = <div> <SimpleText textKey="Cannot_Add_Product_To_SpareBasket" /> </div>
  }
  else if (addingSpareToProductBasket) {
    mixedBasketInfo = <div> <SimpleText textKey="Cannot_Add_Spare_To_ProductBasket" /> </div>
  }
  
  useEffect(() => {
    setProductLoading(false);
  }, [selectedProduct]);

  useOnCustomerChanged(() => {
    onProductClear();
  });

  useEffect(() => () => {
    onProductClear();
  }, []);

  return (
    <div className={styles.box}>
      <SearchBox
        texts={texts}
        id={SEARCH_INPUT_ID}
        productLoadingState={productLoadingState}
        suggestions={suggestions}
        selectedProduct={selectedProduct}
        onProductSearch={onProductSearch}
        onProductSelect={onProductSelect}
        onProductClear={onProductClear}
      />
      <div className={styles.productWrapper}>
        {productLoading
          ? <LoadingIndicator text={texts.loadingText} />
          : !selectedProduct ? null :
            addingSpareToProductBasket || addingProductToSparesBasket ?
              mixedBasketInfo
                :
          (
            <ProductPanel
              texts={texts}
              product={selectedProduct}
              onProductAdd={onProductAdd}
              productAddDisabled={productAddDisabled}
              allowAddUnorderableProduct={allowAddUnorderableProduct}
              productClickTrackingSource={productClickTrackingSource}
              searchInputId={SEARCH_INPUT_ID}
            />
          )}
      </div>
    </div>
  );
};

ProductSelector.propTypes = {
  texts: PropTypes.shape({
    searchBoxPlaceholder: PropTypes.string,
    addBtn: PropTypes.string,
    addBtnAlt: PropTypes.string,
    productNotFoundText: PropTypes.node,
    productCannotBeOrderedMessage: PropTypes.node,
    loadingText: PropTypes.string,
  }).isRequired,
  onProductAdd: PropTypes.func.isRequired,
  productAddDisabled: PropTypes.bool,
  allowAddUnorderableProduct: PropTypes.bool,
  productClickTrackingSource: PropTypes.string.isRequired,
};

export default React.memo(ProductSelector);