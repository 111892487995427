import React from 'react';
import styles from '../basket/b2b/BasketB2B.module.scss';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import { useSanaTexts } from 'components/sanaText';
import { SanaButton } from 'components/primitives/buttons';
import { Modal } from 'components/objects/modals';
import PropTypes from 'prop-types';

const StockAlertDialog = ({ opened, toggle, elements }) => {

  const [
    stockAlertMsg,
    stockAlertMsgTitle,
    stockAlertMsgFooter,
  ] = useSanaTexts([
    'StockAlertMsg',
    'StockAlertMsg_Title',
    'StockAlertMsg_Footer',
  ]).texts;

  return (
    <Modal opened={opened} hide={toggle} className="wish-list-confirmation-modal">
      <div className={styles.modalContainer}>
        <div><span>{stockAlertMsgTitle}</span></div>
        {elements.map(x => (
          <div key={x.id}>
            <span>{stockAlertMsg.replace('[ORDEREDQTY]', x.quantity).replace('[ORDEREDITEM]', x.id).replace('[STOCKQTY]', x.inv)}</span>
          </div>
          ))}
        <div><span>{stockAlertMsgFooter}</span></div>
        <div className={styles.modalButtonsBlock}>
          <SanaButton
            textKey="Ok"
            className={`${buttonStyles.btnExtraSmall} ${buttonStyles.noIcon} ${styles.btnConfirm}`}
            onClick={toggle}
          />
        </div>
      </div>
    </Modal>
  );
};

StockAlertDialog.propTypes = {
  opened: PropTypes.bool,
  toggle: PropTypes.func,
  elements: PropTypes.array,
};

export default StockAlertDialog;