import styles from './Impersonate.module.scss';
import React from 'react';
import { Container } from 'components/primitives/grid';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { stop } from 'behavior/tools/impersonation';
import { useAdminTexts } from 'utils/hooks';
import { makeSimpleText } from 'utils/render';
import { ShopAccountTypes } from 'behavior/user';

const AccountImpersonationMessage = () => {
  const [name, type, role, id] = useSelector(s => [s.user.name, s.user.shopAccountType, s.user.role, s.user.referenceId], shallowEqual);
  const dispatch = useDispatch();

  const { texts: [template, typeText, roleText, stopText], loaded } = useAdminTexts([
    'ImpersonatingShopAccount',
    type === ShopAccountTypes.SalesAgent ? 'SalesAgent' : type,
    type === ShopAccountTypes.Customer ? getAccountRoleTextKey(role) : null,
    'StopShopAccountImpersonation',
  ]);

  if (!loaded)
    return null;

  const onCloseClick = () => {
    dispatch(stop());
  };

  return (
    <div className={styles.bar}>
      <Container className={styles.cnt}>
        <span>
          {makeSimpleText(template, [name, typeText, roleText ? ' - ' + roleText : '', id ? ' - ' + id : ''])}
        </span>
        <button onClick={onCloseClick}>{stopText}</button>
      </Container>
    </div>
  );
};

export default AccountImpersonationMessage;

function getAccountRoleTextKey(role) {
  let postfix;
  if (role === AccountRoles.Manager)
    postfix = 'AccountManager';
  else if (role === AccountRoles.SubAccount)
    postfix = 'SubAccount';
  else
    postfix = 'RegularAccount';

  return 'AccountRole_' + postfix;
}

const AccountRoles = {
  Regular: 'REGULAR',
  SubAccount: 'SUB',
  Manager: 'MANAGER',
};
