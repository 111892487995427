import styles from './Calendar.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { viewValidator } from './propTypes';
import { PeriodTypes } from './enums';
import {
  getCenturyLabel, getDecadeLabel,
  getNextRangeStart, getNextUpperRangeStart,
  getPreviousRangeStart, getPreviousUpperRangeStart,
  getPreviousRangeEnd, getPreviousUpperRangeEnd,
} from './helpers';

const Navigation = ({
  activeStartDate,
  formatMonthYear,
  formatYear,
  isDrillUpAvailable,
  locale,
  maxDate,
  minDate,
  navigationAriaLabel = '',
  nextUpperRangeAriaLabel = '',
  nextUpperRangeLabel = '»',
  nextAriaLabel = '',
  nextLabel = '›',
  onDrillUp,
  prevUpperRangeAriaLabel = '',
  prevUpperRangeLabel = '«',
  prevAriaLabel = '',
  prevLabel = '‹',
  setActiveStartDate,
  setNavigationLabel,
  showDoubleView,
  view,
}) => {
  const shouldShowUpperRangeButtons = view !== PeriodTypes.CENTURY;
  const previousActiveStartDate = getPreviousRangeStart(view, activeStartDate);
  const prevUpperRangeActiveStartDate = shouldShowUpperRangeButtons && getPreviousUpperRangeStart(view, activeStartDate);
  const nextActiveStartDate = getNextRangeStart(view, activeStartDate);
  const nextUpperRangeActiveStartDate = shouldShowUpperRangeButtons && getNextUpperRangeStart(view, activeStartDate);
  const prevButtonDisabled = isPreviousButtonDisabled(previousActiveStartDate, activeStartDate, minDate, view);
  const prevUpperRangeBtnDisabled = shouldShowUpperRangeButtons && isPreviousButtonDisabled(prevUpperRangeActiveStartDate, activeStartDate, minDate, view, true);
  const nextButtonDisabled = maxDate && maxDate <= nextActiveStartDate;
  const nextUpperRangeBtnDisabled = shouldShowUpperRangeButtons && maxDate && maxDate <= nextUpperRangeActiveStartDate;

  let activeStartDateLabel = getLabel(activeStartDate, view, formatYear, formatMonthYear);
  let nextActiveStartDateLabel;
  if (showDoubleView)
    nextActiveStartDateLabel = getLabel(nextActiveStartDate, view, formatYear, formatMonthYear);

  if (setNavigationLabel) {
    activeStartDateLabel = setNavigationLabel(activeStartDate, activeStartDateLabel, locale, view);
    if (showDoubleView)
      nextActiveStartDateLabel = setNavigationLabel(nextActiveStartDate, nextActiveStartDateLabel, locale, view);
  }

  const handleClickOnPrevious = () => setActiveStartDate(previousActiveStartDate);
  const handleClickOnPreviousUpperRange = () => setActiveStartDate(prevUpperRangeActiveStartDate);
  const handleClickOnNext = () => setActiveStartDate(nextActiveStartDate);
  const handleClickOnNextUpperRange = () => setActiveStartDate(nextUpperRangeActiveStartDate);

  return (
    <div className={styles.nav}>
      {shouldShowUpperRangeButtons && prevUpperRangeLabel !== null &&
        <button
          tabIndex={prevUpperRangeBtnDisabled ? -1 : null}
          aria-label={prevUpperRangeAriaLabel}
          className={`${styles.arrow} ${styles.prevUpperRangeBtn}`}
          aria-disabled={prevUpperRangeBtnDisabled}
          onClick={!prevUpperRangeBtnDisabled ? handleClickOnPreviousUpperRange : null}
          type="button"
        >
          {prevUpperRangeLabel}
        </button>
      }
      {prevLabel !== null &&
        <button
          tabIndex={prevButtonDisabled ? -1 : null}
          aria-label={prevAriaLabel}
          className={`${styles.arrow} ${styles.prevBtn}`}
          aria-disabled={prevButtonDisabled}
          onClick={!prevButtonDisabled ? handleClickOnPrevious : null}
          type="button"
        >
          {prevLabel}
        </button>
      }
      <button
        tabIndex={!isDrillUpAvailable ? -1 : null}
        aria-label={navigationAriaLabel}
        className={styles.label}
        aria-disabled={!isDrillUpAvailable}
        onClick={isDrillUpAvailable ? onDrillUp : null}
        type="button"
        aria-live="polite"
      >
        <span className={styles.navTextFrom}>{activeStartDateLabel}</span>
        {showDoubleView &&
          <> – <span className={styles.navTextTo}>{nextActiveStartDateLabel}</span></>
        }
      </button>
      {nextLabel !== null &&
        <button
          tabIndex={nextButtonDisabled ? -1 : null}
          aria-label={nextAriaLabel}
          className={`${styles.arrow} ${styles.nextBtn}`}
          aria-disabled={nextButtonDisabled}
          onClick={!nextButtonDisabled ? handleClickOnNext : null}
          type="button"
        >
          {nextLabel}
        </button>
      }
      {nextUpperRangeLabel !== null && shouldShowUpperRangeButtons && (
        <button
          tabIndex={nextUpperRangeBtnDisabled ? -1 : null}
          aria-label={nextUpperRangeAriaLabel}
          className={`${styles.arrow} ${styles.nextUpperRangeBtn}`}
          aria-disabled={nextUpperRangeBtnDisabled}
          onClick={!nextUpperRangeBtnDisabled ? handleClickOnNextUpperRange : null}
          type="button"
        >
          {nextUpperRangeLabel}
        </button>
      )}
    </div>
  );
};

Navigation.propTypes = {
  activeStartDate: PropTypes.instanceOf(Date).isRequired,
  formatMonthYear: PropTypes.func.isRequired,
  formatYear: PropTypes.func.isRequired,
  isDrillUpAvailable: PropTypes.bool,
  locale: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  navigationAriaLabel: PropTypes.string,
  nextUpperRangeAriaLabel: PropTypes.string,
  nextUpperRangeLabel: PropTypes.node,
  nextAriaLabel: PropTypes.string,
  nextLabel: PropTypes.node,
  onDrillUp: PropTypes.func.isRequired,
  prevUpperRangeAriaLabel: PropTypes.string,
  prevUpperRangeLabel: PropTypes.node,
  prevAriaLabel: PropTypes.string,
  prevLabel: PropTypes.node,
  setActiveStartDate: PropTypes.func.isRequired,
  setNavigationLabel: PropTypes.func,
  showDoubleView: PropTypes.bool,
  view: viewValidator,
};

export default React.memo(Navigation);

function isPreviousButtonDisabled(previousActiveStartDate, activeStartDate, minDate, view, isPreviousUpperRange = false) {
  if (previousActiveStartDate.getFullYear() < 1000)
    return true;

  let previousActiveEndDate;
  if (isPreviousUpperRange)
    previousActiveEndDate = getPreviousUpperRangeEnd(view, activeStartDate);
  else
    previousActiveEndDate = getPreviousRangeEnd(view, activeStartDate);

  return minDate && minDate >= previousActiveEndDate;
}

function getLabel(date, view, formatYear, formatMonthYear) {
  switch (view) {
    case PeriodTypes.CENTURY:
      return getCenturyLabel(date, formatYear);
    case PeriodTypes.DECADE:
      return getDecadeLabel(date, formatYear);
    case PeriodTypes.YEAR:
      return formatYear(date);
    case PeriodTypes.MONTH:
      return formatMonthYear(date);
    default:
      throw new Error(`Invalid view: ${view}.`);
  }
}