import { routeNames } from 'routes';
import { redirect } from './helpers';

const canonicalRoutes = new Set([
  routeNames.ContentPage,
  routeNames.NewsDetail,
  routeNames.FaqPage,
  routeNames.ProductList,
  routeNames.Home,
]);

export function canonical(canonicalRoutes, route, location, dependencies) {
  const { routeName, canonicalUrl } = route;

  if (!canonicalUrl || !canonicalRoutes.has(routeName))
    return;

  return redirect(canonicalUrl, location, route, dependencies);
}

export default canonical.bind(null, canonicalRoutes);