import { ARIA_STATUS_SET } from './actions';

const initialState = {};
export default function ariaReducer(state = initialState, action) {
  switch (action.type) {
    case ARIA_STATUS_SET:
      return action.payload;
    default:
      return state;
  }
}
