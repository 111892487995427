import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

/**
 * Modal options.
 * @typedef {Object} ModalOptions
 * @property {boolean} fullscreen - Indicates whether modal should be shown in full screen.
 * @property {boolean} fullWidth - Indicates whether modal should be shown in full width.
 * @property {boolean} hideCloseButton - Indicates whether close button should be hidden.
 * @property {string} closeBtnTitle - Close button title.
 * @property {string} className - Modal class name.
 * @property {boolean} resetContentOnClose - Indicates whether modal should reset content after closing.
 * @property {string} size - size for content container in modal. May be 'medium' or 'large'.
 * @property {function} onClosed - handler which is invoked when modal was closed.
 */

/**
 * Modal container.
 * @typedef {Object} ModalContainer
 * @property {function} show - Function which can be used to show modal.
 * @property {function} hide - Function which can be used to hide modal.
 * @property {boolean} opened - Indicates whether modal is opened.
 * @property {function} Modal - Modal React component.
 */

/**
 * @param {ModalOptions} options The modal options.
 * @returns {ModalContainer} Modal React component with controls.
 */
export default function (options) {
  const [opened, setOpened] = useState(false);

  const show = useCallback(() => setOpened(true), []);
  const hide = useCallback(() => {
    setOpened(false);
    if (options?.onClosed)
      options.onClosed();
  }, [options?.onClosed]);

  const ModalComponent = ({ children }) =>
    opened
      ? React.createElement(Modal, { ...options, hide, opened }, children)
      : null;

  ModalComponent.propTypes = {
    children: PropTypes.node,
  };

  return {
    show,
    hide,
    Modal: ModalComponent,
  };
}