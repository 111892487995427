import styles from './SubscribeInlineColumn.module.scss';
import footerStyles from 'components/sections/footer/Footer.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { connect } from 'react-redux';
import { SimpleText, RichText, useSimpleTexts } from 'components/sanaText';
import { subscribeToNewsletter } from 'behavior/newsletter';
import { SanaForm, FieldError } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { useSuccessMessage } from './hooks';
import { generateKey } from 'utils/helpers';

const SubscribeInlineColumn = ({
  lastUpdated,
  updatedById,
  subscribeToNewsletter,
  hasSitemap,
}) => {
  const [componentId] = useState(generateKey);
  const subscribedByComponent = componentId === updatedById;

  const onSubmit = useCallback(formData => {
    subscribeToNewsletter(formData, componentId);
  }, []);
  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;

  return (
    <Col xs={12} sm={hasSitemap ? 6 : 8} md={5} lg={4} xl={3} className={styles.col}>
      <h2 className={`${footerStyles.title} h4`}>
        <SimpleText textKey="NewsletterContainer_Title" />
      </h2>
      <div className={styles.caption}>
        <RichText textKey="NewsletterContainer_Description" />
      </div>
      <SanaForm
        name="NewsletterInline"
        onSubmit={onSubmit}
        shouldReset={subscribedByComponent}
        className={styles.form}
        validateOnBlur={false}
        onBlur={(e, formik) => {
          if (!e.currentTarget.contains(e.relatedTarget || document.activeElement))
            formik.setFieldError('email', undefined);
        }}
      >
        <div className={styles.formFieldWrapper}>
          <EmailAddressField
            fieldName="email"
            fieldTitle={emailTitle}
            placeholder={emailTitle}
            maxLength={80}
            required
            validation={{
              required: true,
            }}
          />
          <div className={styles.validation}>
            <FieldError fieldName="email" />
          </div>
        </div>
        <SanaButton textKey="Subscribe" type="submit" className={`${btnStyles.btnSmall} ${styles.btnSubmit}`} />
      </SanaForm>
    </Col>
  );
};

SubscribeInlineColumn.propTypes = {
  subscribeToNewsletter: PropTypes.func.isRequired,
  lastUpdated: PropTypes.instanceOf(Date),
  updatedById: PropTypes.string,
  hasSitemap: PropTypes.bool,
};

export default connect(
  ({ newsletter }) => ({
    lastUpdated: newsletter.lastUpdated,
    updatedById: newsletter.updatedById,
  }),
  { subscribeToNewsletter },
)(SubscribeInlineColumn);