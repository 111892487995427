const compareAdapter = rule => ({
  equalsTo: {
    to: rule.otherProperty,
    message: getErrorMessage(rule),
  },
});

const maxLengthAdapter = rule => ({
  maxLength: {
    max: rule.maximumLength,
    message: getErrorMessage(rule),
  },
});

const minLengthAdapter = rule => ({
  minLength: {
    min: rule.minimumLength,
    message: getErrorMessage(rule),
  },
});

const rangeAdapter = rule => ({
  range: {
    max: rule.maximum,
    min: rule.minimum,
    message: getErrorMessage(rule),
  },
});

const regexAdapter = rule => ({
  regex: {
    pattern: rule.pattern,
    message: getErrorMessage(rule),
  },
});

const requiredAdapter = rule => ({
  required: {
    allowWhitespace: rule.allowEmptyStrings,
    message: getErrorMessage(rule),
  },
});

const stringLengthAdapter = rule => ({
  stringLength: {
    min: rule.minimumLength,
    max: rule.maximumLength,
    message: getErrorMessage(rule),
  },
});

const minAgeAdapter = rule => ({
  minAge: {
    minAge: rule.minAge,
    message: getErrorMessage(rule),
  },
});

function getErrorMessage(rule) {
  if (!rule.errorMessageKey)
    return;

  return rule.errorMessage;
}

const validatorAdapters = Object.freeze({
  compare: compareAdapter,
  maxLength: maxLengthAdapter,
  range: rangeAdapter,
  regex: regexAdapter,
  required: requiredAdapter,
  stringLength: stringLengthAdapter,
  minAge: minAgeAdapter,
	minLength: minLengthAdapter,
});

export const createValidator = rule => {
  const adapter = validatorAdapters[rule.name];
  if (!adapter)
    throw new Error(`Validator ${rule.name} is not supported.`);

  return adapter(rule);
};
