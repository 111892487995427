import styles from './ProductComparisonWidget.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useSanaTexts, SimpleText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { SanaButton } from 'components/primitives/buttons';
import { SanaLinkButton } from 'components/primitives/links';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { Placeholder } from 'components/primitives/placeholders';
import { useLayoutShifter } from 'utils/layout';
import { MediumArrowDownIcon, MediumArrowUpIcon } from 'components/primitives/icons';

import { buildRouteForProductComparison } from '../util';
import ComparisonListItem from './ComparisonListItem';
import NumberSwitcher from './NumberSwitcher';

const WidgetContent = ({
  products,
  isCollapsed,
  handleChangeCollapsedState,
  handleRemoveAllProducts,
}) => {
  const containerRef = useRef(null);
  const { topFixedElementsHeight, bottomFixedElementsHeight } = useLayoutShifter();
  const [expandedTitle, collapsedTitle] = useSanaTexts([
    'ButtonAltText_ProductCompareSummary_Collapse',
    'ButtonAltText_ProductCompareSummary_Expand',
  ], makeSimpleText).texts;

  const route = buildRouteForProductComparison(products);
  const options = {
    backTo: useCurrentRouteAsBackTo(),
  };
  const wrapperClasses = `${styles.wrapper} ${isCollapsed ? styles.collapsed : ''}`;

  const placeholder = <Placeholder className={styles.placeholder} />;

  useEffect(() => {
    const bottomShift = bottomFixedElementsHeight + parseInt(styles.containerBottomShift || 0, 10);
    const overallShift = topFixedElementsHeight + bottomShift + parseInt(styles.containerTopShift || 0, 10);

    containerRef.current.style.bottom = `${bottomShift}px`;
    containerRef.current.style.maxHeight = `calc(100vh - ${overallShift}px)`;
  }, [topFixedElementsHeight, bottomFixedElementsHeight]);

  const HeaderIcon = isCollapsed ? MediumArrowUpIcon : MediumArrowDownIcon;
  const headerButtonTitle = isCollapsed ? expandedTitle : collapsedTitle;

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={wrapperClasses}>
        <button
          className={styles.header}
          title={headerButtonTitle}
          onClick={handleChangeCollapsedState}
        >
          <SimpleText
            textKey="ProductCompareSummary_Title"
            formatWith={[(<NumberSwitcher number={products.length} />)]}
            formatAsHtml
          />
          <span className={styles.iconWrapper}>
            <HeaderIcon alt={headerButtonTitle} className={styles.icon} />
          </span>
        </button>
        <div className={styles.content}>
          <ul className={styles.list}>
            {products.map(item => <ComparisonListItem key={item.id} item={item} />)}
          </ul>
        </div>
        <div className={styles.footer}>
          <SanaButton
            textKey="ProductCompare_ClearSelection"
            titleTextKey="ProductCompareSummary_ClearSelection"
            onClick={handleRemoveAllProducts}
            className={`${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${styles.btnClear}`}
            placeholder={placeholder}
          />
          <SanaLinkButton
            textKey="ProductCompare_Compare"
            titleTextKey="ProductCompareSummary_Compare"
            to={route}
            options={options}
            className={`${btnStyles.btnExtraSmall} ${btnStyles.btnAction}`}
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  );
};

WidgetContent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  handleChangeCollapsedState: PropTypes.func.isRequired,
  handleRemoveAllProducts: PropTypes.func.isRequired,
};

export default WidgetContent;