import styles from './PageContent.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { renderRows } from 'components/containers';

const PageContent = ({ content, position }) => {
  if (!content)
    return null;

  if (!position || !styles[position])
    return renderRows(content);

  return (
    <div className={styles[position]}>
      {renderRows(content)}
    </div>
  );
};

PageContent.propTypes = {
  content: PropTypes.array,
  position: PropTypes.oneOf(['header', 'middle', 'footer']),
};

export default React.memo(PageContent);