import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { TotalsLine } from './TotalsLine';

const PromotionLine = ({ promotion, currencyInfo, className }) => {
  const { title, price } = promotion;
  const heading = title || <SimpleText textKey="PromotionDiscount" />;

  return (
    <TotalsLine
      heading={heading}
      currencyInfo={currencyInfo}
      value={price || null}
      className={className}
    />
  );
};

PromotionLine.propTypes = {
  promotion: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
  currencyInfo: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default PromotionLine;
