import React from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import SearchBox from './SearchBox';
import { routeNames } from 'routes';
import { useRoutes } from 'components/primitives/route';
import { defaultSearchParams } from './constants';
import { useAbilities } from 'components/objects/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import Schema from './Schema';
import { makeSimpleText } from 'utils/render';
import DisabledSearchBox from './DisabledSearchBox';

const searchRoute = { routeName: routeNames.Search, params: { q: defaultSearchParams } };

const Search = ({ className, onFocus, onBlur, initialModel, id, restoreQuery, designMode }) => {
  const ability = useSearchAbility();
  const [routePath] = useRoutes([searchRoute]);
  const [placeholder, searchTitle] = useSanaTexts([!initialModel?.placeholder && 'Search_SearchPrefix', 'ButtonAltText_Search']).texts;

  if (ability.isLoading || !routePath)
    return null;

  if (!ability.available)
    return <DisabledSearchBox className={className} />;

  return (
    <>
      <Schema routePath={routePath} />
      <SearchBox
        id={id}
        placeholder={initialModel?.placeholder || makeSimpleText(placeholder)}
        routePath={routePath}
        searchTitle={searchTitle}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        restoreQuery={restoreQuery}
        designMode={designMode}
      />
    </>
  );
};

Search.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  initialModel: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  restoreQuery: PropTypes.bool,
  id: PropTypes.string.isRequired,
  designMode: PropTypes.bool,
};

export default React.memo(Search);

const abilityKeys = [AbilityTo.ViewCatalog];

function useSearchAbility() {
  const { isLoading, abilities } = useAbilities(abilityKeys);
  return {
    isLoading,
    available: !isLoading && abilities[0] === AbilityState.Available,
  };
}
