import { AbilityTo } from 'behavior/user/constants';

export const OrderStatus = Object.freeze({
  Open: 'OPEN',
  Released: 'RELEASED',
  Cancelled: 'CANCELLED',
});

export const PaymentStatus = Object.freeze({
  Empty: '',
  New: 'new',
  InProgress: 'inprogress',
  Paid: 'paid',
  Cancelled: 'cancelled',
  Unpaid: 'unpaid',
  Error: 'error',
});

export const DocumentType = Object.freeze({
  Order: 'Order',
  Quote: 'Quote',
  Invoice: 'Invoice',
  InvoiceOrder: 'InvoiceOrder',
  ReturnOrder: 'ReturnOrder',
  ReturnReceipt: 'ReturnReceipt',
  Shipment: 'Shipment',
  CreditNote: 'CreditNote',
});

export const SupportedDocumentTypes = new Set(Object.keys(DocumentType));

export const DocumentAbilitiesMap = Object.freeze({
  [DocumentType.Order]: AbilityTo.ViewOrders,
  [DocumentType.Quote]: AbilityTo.ViewQuotes,
  [DocumentType.Invoice]: AbilityTo.ViewOrders,
  [DocumentType.InvoiceOrder]: AbilityTo.ViewInvoices,
  [DocumentType.ReturnOrder]: AbilityTo.ViewReturnOrders,
  [DocumentType.ReturnReceipt]: AbilityTo.ViewReturnReceipts,
  [DocumentType.Shipment]: AbilityTo.ViewShipments,
  [DocumentType.CreditNote]: AbilityTo.ViewCreditNotes,
});

export const ReorderResult = Object.freeze({
  Success: 'SUCCESS',
  BasketNotEmpty: 'BASKET_NOT_EMPTY',
  NoProducts: 'NO_PRODUCTS',
  OrderNotFound: 'ORDER_NOT_FOUND',
});

export const EditResult = Object.freeze({
  Success: 'SUCCESS',
  BasketNotEmpty: 'BASKET_NOT_EMPTY',
  NoDocument: 'NO_DOCUMENT',
});

export const QuoteStatus = Object.freeze({
  Expired: 'Expired',
  InReview: 'InReview',
  Orderable: 'Orderable',
  Cancelled: 'Cancelled',
});

export const PromotionType = Object.freeze({
  Disabled: 'DISABLED',
  WithCheckout: 'WITH_CHECKOUT',
  Unattended: 'UNATTENDED',
});

export const PromoteQuoteStatus = Object.freeze({
  Succeed: 'SUCCEED',
  CreditLimitForceOnlinePayment: 'CREDIT_LIMIT_FORCE_ONLINE_PAYMENT',
  CreditLimitWarning: 'CREDIT_LIMIT_WARNING',
  CreditLimitError: 'CREDIT_LIMIT_ERROR',
  OutOfStock: 'OUT_OF_STOCK',
  ContainsNonOrderableProducts: 'CONTAINS_NON_ORDERABLE_PRODUCTS',
  QuoteNotFound: 'QUOTE_NOT_FOUND',
});

export const ChildDocumentTypes = Object.freeze([
  DocumentType.Invoice,
  DocumentType.ReturnReceipt,
  DocumentType.CreditNote,
  DocumentType.Shipment,
]);
