import styles from './SimpleNavigation.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { BaseNavigationItemPropTypes } from './PropTypes';
import { renderHTML } from 'utils/render';

const NavItem = ({ item: { link, title, cssClass, children: childNodes }, className }) => (
  <li className={`${className || ''} ${cssClass || ''}`}>
    <Link {...link}>{title && renderHTML(title)}</Link>
    {childNodes && (
      <ul>
        {childNodes.map((child, index) => <NavItem key={index} item={child} className={styles.item} />)}
      </ul>
    )}
  </li>
);

NavItem.propTypes = {
  item: BaseNavigationItemPropTypes,
  className: PropTypes.string,
};

export default NavItem;