import styles from './PLP.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { UseSanaTexts, SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useUrlBuilder } from './hooks';
import { ViewModes } from 'behavior/pages/productList';
import { Placeholder } from 'components/primitives/placeholders';
import { ListViewIcon, ListViewPassiveIcon, GridViewIcon, GridViewPassiveIcon } from 'components/primitives/icons';

const ViewMode = ({ selectedViewMode }) => {
  const buildRoute = useUrlBuilder();
  const listLink = buildRoute({ viewMode: ViewModes.List, page: 0 });
  const gridLink = buildRoute({ viewMode: ViewModes.Grid, page: 0 });
  const linkClassNames = active => `${active ? `${styles.active} ` : ''}${styles.linkView}`;

  const changerLabelPlaceholder = <Placeholder className={styles.changerLabelPlaceholder} />;
  const linkViewPlaceholder = <Placeholder className={styles.linkViewPlaceholder} />;

  const gridMode = selectedViewMode === ViewModes.Grid;

  const ListModeIcon = gridMode ? ListViewPassiveIcon : ListViewIcon;
  const GridModeIcon = gridMode ? GridViewIcon : GridViewPassiveIcon;

  return (
    <div className={styles.changerItems}>
      <span className={styles.changerLabel}>
        <SimpleText textKey="View_Label" placeholder={changerLabelPlaceholder} />
      </span>
      <UseSanaTexts
        options={['ProductList_SelectLineMode', 'ProductList_SelectGridMode']}
        placeholder={linkViewPlaceholder}
      >
        {([listModeTitle, gridModeTitle]) => (
          <>
            <Link
              {...listLink}
              title={listModeTitle}
              className={`${linkClassNames(!gridMode)} ${styles.toList}`}
              aria-current={gridMode ? null : 'page'}
              rel="nofollow"
              omitScroll
            >
              <span className="visually-hidden">{listModeTitle}</span>
              <ListModeIcon className={styles.viewModeIcon} alt={listModeTitle} />
            </Link>
            <Link
              {...gridLink}
              title={gridModeTitle}
              className={`${linkClassNames(gridMode)} ${styles.toGrid}`}
              aria-current={gridMode ? 'page' : null}
              rel="nofollow"
              omitScroll
            >
              <span className="visually-hidden">{gridModeTitle}</span>
              <GridModeIcon className={styles.viewModeIcon} alt={gridModeTitle} />
            </Link>
          </>
        )}
      </UseSanaTexts>
    </div>
  );
};

ViewMode.propTypes = {
  selectedViewMode: PropTypes.string,
};

export default React.memo(ViewMode);