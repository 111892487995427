import styles from 'components/objects/product/Details.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import ProductTabs from './tabs/ProductTabs';
import { RelatedProducts } from './relatedProducts';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { connect } from 'react-redux';
import { usePrintMode } from 'utils/hooks';
import { useMatchMediaContext } from 'components/responsive';

const numOfTabsToSetFullWidth = 4;

const ProductsTabsContainer = ({
  description,
  specifications,
  attachments,
  reviews,
  productId,
  relatedProductGroups,
  noImageUrl,
  languageId,
}) => {
  const { xl } = useMatchMediaContext();
  const isPrintMode = usePrintMode();
  const showDescription = description && description.length > 0;
  const showSpecifications = specifications && specifications.length > 0;
  const showAttachments = !isPrintMode && attachments && attachments.length > 0;
  const showRelatedProducts = !isPrintMode && noImageUrl && relatedProductGroups && !!relatedProductGroups.length;
  const numOfTabs = [showDescription, showSpecifications, showAttachments, reviews].filter(Boolean).length;
  const fullWidthRelatedProducts = (!xl && numOfTabs >= numOfTabsToSetFullWidth) || numOfTabs === 0;
  const fullWidthProductTabs = isPrintMode || fullWidthRelatedProducts || !showRelatedProducts;

  return (
    <>
      {numOfTabs > 0 &&
        <Col xs={12} lg={!fullWidthProductTabs ? 8 : null} xl={{ size: !fullWidthProductTabs ? 9 : null }}
          className={fullWidthProductTabs ? styles.productTabs : null}
        >
          <ProductTabs
            productId={productId}
            description={showDescription ? description : null}
            specifications={showSpecifications ? specifications : null}
            attachments={showAttachments ? attachments : null}
            reviews={!isPrintMode ? reviews : null}
          />
        </Col>
      }
      {showRelatedProducts && (
        <Col xs={12} lg={!fullWidthRelatedProducts ? 4 : null} xl={!fullWidthRelatedProducts ? 3 : null}
          className={fullWidthRelatedProducts ? styles.relatedProducts : null}
        >
          <RelatedProducts
            productId={productId}
            relatedProductGroups={relatedProductGroups}
            noImageUrl={noImageUrl}
            languageId={languageId}
          />
        </Col>
      )}
    </>
  );
};

ProductsTabsContainer.propTypes = {
  description: PropTypes.string,
  specifications: PropTypes.array,
  attachments: PropTypes.array,
  productId: PropTypes.string,
  reviews: PropTypes.object,
  relatedProductGroups: PropTypes.array,
  noImageUrl: PropTypes.string,
  languageId: PropTypes.number,
};

const Connected = connect(
  ({
    settings: { product },
    localization: {
      currentLanguage: { id: languageId },
    },
  }) => ({
    noImageUrl: product && product.noImage.medium,
    languageId,
  }),
)(ProductsTabsContainer);

export default connectToContext(
  [ProductContext],
  ({
    product: {
      description,
      specifications,
      attachments,
      id,
      reviews,
      relatedProductGroups,
    },
  }) => ({
    description,
    specifications,
    attachments,
    productId: id,
    reviews,
    relatedProductGroups,
  }),
)(Connected);
