import React from 'react';
import { BackToContainer } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import createSystemRenderer from 'components/objects/systemPages/createSystemRenderer';
import InvoicePayment from './InvoicePayment';

export default page => (
  <>
    <BackToContainer options={{ route: routesBuilder.forInvoices(), texts: ['ButtonText_BackToInvoices', 'MyInvoices'] }} />
    {createSystemRenderer('Payment_Header', InvoicePayment)(page)}
  </>
);
