import styles from './Subscribe.module.scss';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { RichText } from 'components/sanaText';
import { connect } from 'react-redux';
import { unsubscribeFromNewsletter } from 'behavior/newsletter';
import NewsletterForm from './NewsletterForm';
import { useSuccessMessage } from './hooks';
import { generateKey } from 'utils/helpers';

const UnSubscribe = ({ lastUpdated, updatedById, unsubscribeFromNewsletter }) => {
  const [componentId] = useState(generateKey);
  const unsubscribedByComponent = componentId === updatedById;

  const onSubmit = useCallback(formData => {
    unsubscribeFromNewsletter(formData, componentId);
  }, []);

  useSuccessMessage(lastUpdated, unsubscribedByComponent, 'NewsletterUnsubscribedSuccessfully');

  return (
    <>
      <div className={styles.intro}>
        <RichText textKey="NewsletterUnsubscribe_Description" />
      </div>
      <Row>
        <Col md={8} lg={7}>
          <NewsletterForm
            name="NewsletterUnsubscribe"
            buttonTextKey="Unsubscribe"
            onSubmit={onSubmit}
            shouldReset={unsubscribedByComponent}
            className={styles.form}
          />
        </Col>
      </Row>
    </>
  );
};

UnSubscribe.propTypes = {
  unsubscribeFromNewsletter: PropTypes.func.isRequired,
  lastUpdated: PropTypes.instanceOf(Date),
  updatedById: PropTypes.string,
};

export default connect(
  ({ newsletter: { lastUpdated, updatedById } }) => ({ lastUpdated, updatedById }),
  { unsubscribeFromNewsletter },
)(UnSubscribe);