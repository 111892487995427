import React from 'react';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';

const MyAccountLink = ({ abilities: [viewMyAccountAbility], ...attributes }) => {
  if (viewMyAccountAbility !== AbilityState.Available)
    return null;

  return (
    <Link to={routesBuilder.forMyAccount} {...attributes}>
      <SimpleText textKey="Login_MyAccount" />
    </Link>
  );
};

MyAccountLink.propTypes = {
  abilities: abilitiesPropType.isRequired,
};

export default withAbilities(MyAccountLink, [AbilityTo.ViewMyAccountPage]);
