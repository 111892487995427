import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import DefaultPaging from '../paging';
import ListContext from './ListContext';

const Paging = ({ pagingType, totalCount }) => {
  const { quantities } = useContext(ListContext);

  return <DefaultPaging pagingType={pagingType} totalCount={totalCount} hasPendingChanges={!!quantities.size} />;
};

Paging.propTypes = {
  totalCount: PropTypes.number,
  pagingType: PropTypes.string,
};

export default React.memo(Paging);
