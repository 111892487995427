import styles from './Sidebar.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';

const SidebarLink = ({ route, textKey, className, count, isActive, isLoading }) => (
  <li className={`${className} ${isActive ? styles.active : ''}`}>
    {isLoading
      ? <Placeholder />
      : (
        <Link
          to={route}
          className={isActive ? linkStyles.arrowed : null}
        >
          <SimpleText textKey={textKey} />
          {!!count && <span className={styles.counter}>({count})</span>}
        </Link>
      )
    }
  </li>
);

SidebarLink.propTypes = {
  route: PropTypes.object.isRequired,
  textKey: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  count: PropTypes.number,
  isActive: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default React.memo(SidebarLink);
