import styles from './NewsOverview.module.scss';
import React from 'react';
import Heading from 'components/primitives/headings/Heading';
import { SimpleText } from 'components/sanaText';

const NewsTitle = () => (
  <Heading className={styles.header}>
    <h1><SimpleText textKey="NewsOverview_Title" /></h1>
  </Heading>
);

export default NewsTitle;