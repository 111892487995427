import { ofType } from 'redux-observable';
import { tap, mapTo, filter, switchMap } from 'rxjs/operators';
import { ADMIN_IMPERSONATION_STOP, notifyStopped } from './actions';
import { toolDisableMutation } from '../queries';
import { Tool } from 'utils/tools';

const toolName = Tool.AccountImpersonation;

export default function (action$, _, { api, toolsStorage }) {
  const close$ = action$.pipe(
    ofType(ADMIN_IMPERSONATION_STOP),
    filter(_ => toolsStorage.toolEnabled(toolName)),
    switchMap(action => api.graphApi(toolDisableMutation, { name: toolName }, { useCookies: true }).pipe(
      tap(_ => toolsStorage.disableTool(toolName)),
      mapTo(notifyStopped(action.payload.isLogout)),
    )),
  );

  return close$;
}