export function deleteProductCalculatedInfo(product) {
  const result = deleteSkuCalculatedInfo(product);

  if (result.variants)
    result.variants = result.variants.map(deleteSkuCalculatedInfo);

  return result;
}

function deleteSkuCalculatedInfo(sku) {
  const result = { ...sku };
  delete result.price;
  delete result.listPrice;
  delete result.isOrderable;
  delete result.inventory;

  return result;
}