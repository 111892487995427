import styles from './Lines.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIsMobileViewport } from 'utils/hooks';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import ProductLine from './ProductLine';
import GroupedProductLine from './GroupedProductLine';
import ConfigurationResultLine from './ConfigurationResultLine';
import ServiceLine from './ServiceLine';
import { useOnChange } from 'utils/hooks';
import BasketLinesContext from './BasketLinesContext';
import { useUpdateContext } from '../updateContext';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';

const BasketLines = ({
  productLines,
  serviceLines,
  currencyInfo,
  showImages,
  linesUpdating,
  lastModifiedLineId,
}) => {
  const { isB2BPreset } = useUpdateContext();
  const tableRef = useRef();
  const timeoutRef = useRef();
  const [showPrice, showUom] = useHasAbilities(AbilityTo.ViewPrices, AbilityTo.ViewUnitOfMeasure);
  const isMobile = useIsMobileViewport();
  const productLinesList = productLines.list;

  const [context, setContext] = useState({
    currencyInfo,
    showImages,
    showPrice,
    showUom,
    isMobile,
    lastModifiedLineId,
  });

  useOnChange(
    () => setContext({ currencyInfo, showImages, showPrice, showUom, isMobile, lastModifiedLineId }),
    [currencyInfo, showImages, showPrice, showUom, isMobile, lastModifiedLineId],
    false,
  );

  useEffect(() => {
    if (!tableRef.current)
      return;

    if (linesUpdating) {
      timeoutRef.current = setTimeout(() => {
        tableRef.current.classList.add(styles.inactive);
      }, 150);
    } else {
      clearTimeout(timeoutRef.current);
      tableRef.current.classList.remove(styles.inactive);
    }
  }, [linesUpdating]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;
  const TableBody = isMobile ? React.Fragment : 'tbody';

  return (
    <table className={`${styles.orders} ${isB2BPreset ? styles.b2BPreset : ''}`} ref={tableRef}>
      <thead className={isMobile ? 'visually-hidden' : null}>
        <tr>
          <th colSpan={showImages || isB2BPreset ? '2' : null} id="basketProduct">
            <SimpleText textKey="General_Product_Id_Compactview" placeholder={tableHeadingPlaceholder} />
          </th>
          <th id="basketPrice" className={styles.colPrice} aria-hidden={!showPrice}>
            {showPrice &&
              <SimpleText textKey="General_Product_Price" placeholder={tableHeadingPlaceholder} />
            }
          </th>
          <th id="basketQuantity" className={styles.colQuantity}>
            <SimpleText textKey="Quantity" placeholder={tableHeadingPlaceholder} />
          </th>
          {showUom &&
            <th id="basketUom" className={styles.colUom}>
              <SimpleText textKey="UOM" placeholder={tableHeadingPlaceholder} />
            </th>
          }
          <th id="basketTotal" className={styles.colTotal} aria-hidden={!showPrice}>
            {showPrice && <SimpleText textKey="Total" placeholder={tableHeadingPlaceholder} />}
          </th>
        </tr>
      </thead>
      <BasketLinesContext.Provider value={context}>
        <TableBody>
          {productLinesList.map(line => {
            const { id: lineId, subLines, product, isSupplementary, configuration } = line;

            if (configuration) {
              return (
                <ConfigurationResultLine
                  key={lineId}
                  line={line}
                  configuration={configuration}
                />
              );
            }

            const productImage = product.image;
            if (subLines && subLines.length) {
              const [{ serviceLines }] = subLines;
              if (isB2BPreset && (subLines.length > 1 || (serviceLines && serviceLines.length))) {
                return (
                  <GroupedProductLine
                    key={lineId}
                    product={product}
                    isSupplementary={isSupplementary}
                    line={line}
                    productImage={productImage}
                  />
                );
              }

              return subLines.map(subLine => {
                const image = product.images.find(i => i.variantId && i.variantId.iEquals(subLine.variationId)) || productImage;

                return (
                  <ProductLine
                    key={subLine.id}
                    product={product}
                    isSupplementary={isSupplementary}
                    line={subLine}
                    imageUrl={image && image.small}
                  />
                );
              });
            }

            return (
              <ProductLine
                key={lineId}
                product={product}
                isSupplementary={isSupplementary}
                line={line}
                imageUrl={productImage && productImage.small}
              />
            );
          })}
          {serviceLines.map(({ id, title, price, quantity, subTotal }, index) => (
            <ServiceLine
              key={index}
              id={id}
              title={title}
              price={price}
              quantity={quantity}
              subTotal={subTotal}
              className={styles.generalServiceLine}
              isGeneral
            />
          ))}
        </TableBody>
      </BasketLinesContext.Provider>
    </table>
  );
};

BasketLines.propTypes = {
  productLines: PropTypes.shape({
    list: PropTypes.array.isRequired,
  }).isRequired,
  serviceLines: PropTypes.array.isRequired,
  currencyInfo: PropTypes.object,
  showImages: PropTypes.bool,
  linesUpdating: PropTypes.bool,
  lastModifiedLineId: PropTypes.string,
};

export default connect(({ settings, page: { linesUpdating } }) => {
  return {
    currencyInfo: settings.currency,
    showImages: settings.basket && settings.basket.showImages,
    linesUpdating,
  };
})(BasketLines);
