import styles from './PasswordStrengthIndicator.module.scss';
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { usePasswordStrengthValidator } from './usePasswordStrengthValidator';
import { UseSanaTexts } from 'components/sanaText';
import { IndicatorStatus } from './IndicatorStatus';
import { MaxScore } from './constants';
import { isEqual } from 'lodash';

const scoreTextKeys = [
  'PasswordStrength_VeryWeak',
  'PasswordStrength_Weak',
  'PasswordStrength_SoSo',
  'PasswordStrength_Good',
  'PasswordStrength_Great',
];

const feedbackTextKeys = [
  'PasswordStrengthMessage_UseFewWords',
  'PasswordStrengthMessage_UncommonWordsAreBetter',
  'PasswordStrengthMessage_StraightRowsOfKeysAreEasy',
  'PasswordStrengthMessage_ShortKeyboradPatternsAreEasy',
  'PasswordStrengthMessage_UseLongerKeyboardPatterns',
  'PasswordStrengthMessage_RepeatedCharsAreEasy',
  'PasswordStrengthMessage_RepeatedPatternsAreEasy',
  'PasswordStrengthMessage_AvoidRepeatedChars',
  'PasswordStrengthMessage_SequencesAreEasy',
  'PasswordStrengthMessage_AvoidSequence',
  'PasswordStrengthMessage_RecentYearsAreEasy',
  'PasswordStrengthMessage_AvoidRecentYears',
  'PasswordStrengthMessage_AvoidAssociatedYears',
  'PasswordStrengthMessage_DatesAreEasy',
  'PasswordStrengthMessage_AvoidAssociatedDatesAndYears',
  'PasswordStrengthMessage_Top10CommonPassword',
  'PasswordStrengthMessage_Top100CommonPassword',
  'PasswordStrengthMessage_VeryCommonPassword',
  'PasswordStrengthMessage_SimilarToCommonPassword',
  'PasswordStrengthMessage_WordIsEasy',
  'PasswordStrengthMessage_NamesAreEasy',
  'PasswordStrengthMessage_CommonNamesAreEasy',
  'PasswordStrengthMessage_CapitalizationDoesntHelp',
  'PasswordStrengthMessage_AllUppercaseDoesntHelp',
  'PasswordStrengthMessage_ReverseDoesntHelp',
  'PasswordStrengthMessage_SubstitutionDoesntHelp',
];

export const textsToPreload = scoreTextKeys.concat(feedbackTextKeys);

const EMPTY_TEXTS = [];

const PasswordStrengthIndicator = ({ password, minimumScore, inputRef, userInputs }) => {
  const [strengthTexts, setStrengthTexts] = useState(EMPTY_TEXTS);
  const setStrengthTextsIfNeeded = useCallback(setIfDifferent(strengthTexts, setStrengthTexts), [strengthTexts]);
  const [feedbackTexts, setFeedbackTexts] = useState(EMPTY_TEXTS);
  const setFeedbackTextsIfNeeded = useCallback(setIfDifferent(feedbackTexts, setFeedbackTexts), [feedbackTexts]);

  const [score, setScore] = useState(0);
  const [suggestionKeys, setSuggestions] = useState();
  const [warningKey, setWarning] = useState();
  const [isFocused, setIsFocused] = useState(false);

  const validatePasswordStrength = usePasswordStrengthValidator();
  useEffect(() => {
    if (!validatePasswordStrength || password == null)
      return;

    const strength = validatePasswordStrength(password, { feedback_messages: feedbackMessages, user_inputs: userInputs });
    setScore(strength.score);
    setSuggestions(strength.feedback.suggestions.length ? strength.feedback.suggestions : null);
    setWarning(strength.feedback.warning);
  }, [password, userInputs]);

  useEffect(() => {
    if (!inputRef || !inputRef.current)
      return;

    const inputField = inputRef.current.querySelector('input');
    if (!inputField)
      return;

    const handleFocus = e => setIsFocused(e.type === 'focus');
    inputField.addEventListener('focus', handleFocus);
    inputField.addEventListener('blur', handleFocus);

    return () => {
      inputField.removeEventListener('focus', handleFocus);
      inputField.removeEventListener('blur', handleFocus);
    };

  }, [inputRef.current]);

  const lblText = strengthTexts[score] || null;
  let status;
  if (score === MaxScore) {
    status = <span className={styles.status}>{lblText}</span>;
  } else {
    const opened = isFocused && password && password.length > 2 && score < minimumScore;
    status = React.createElement(IndicatorStatus, { opened, lblText, warningKey, suggestionKeys, feedbackTexts, anchorRef: inputRef });
  }

  return (
    <div className={styles.cnt} role="presentation">
      <div className={styles.indicator} data-score={score} aria-hidden />
      <UseSanaTexts options={scoreTextKeys}>{setStrengthTextsIfNeeded}</UseSanaTexts>
      <UseSanaTexts options={feedbackTextKeys}>{setFeedbackTextsIfNeeded}</UseSanaTexts>
      {status}
    </div>
  );
};

function setIfDifferent(original, setter) {
  return value => {
    if (!isEqual(original, value))
      setter(value);
  };
}

PasswordStrengthIndicator.propTypes = {
  password: PropTypes.string,
  minimumScore: PropTypes.number.isRequired,
  inputRef: PropTypes.object.isRequired,
  userInputs: PropTypes.array,
};

export default React.memo(PasswordStrengthIndicator);

export const feedbackMessages = {
  use_a_few_words: 0,
  no_need_for_mixed_chars: '', // This recommendation is removed because it confuses the users.
  uncommon_words_are_better: 1,
  straight_rows_of_keys_are_easy: 2,
  short_keyboard_patterns_are_easy: 3,
  use_longer_keyboard_patterns: 4,
  repeated_chars_are_easy: 5,
  repeated_patterns_are_easy: 6,
  avoid_repeated_chars: 7,
  sequences_are_easy: 8,
  avoid_sequences: 9,
  recent_years_are_easy: 10,
  avoid_recent_years: 11,
  avoid_associated_years: 12,
  dates_are_easy: 13,
  avoid_associated_dates_and_years: 14,
  top10_common_password: 15,
  top100_common_password: 16,
  very_common_password: 17,
  similar_to_common_password: 18,
  a_word_is_easy: 19,
  names_are_easy: 20,
  common_names_are_easy: 21,
  capitalization_doesnt_help: 22,
  all_uppercase_doesnt_help: 23,
  reverse_doesnt_help: 24,
  substitution_doesnt_help: 25,
};
