import styles from './LoginInfo.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { LoginInfoIcon, LoginInfoImpersonatingIcon } from 'components/primitives/icons';

const Icon = ({ isImpersonating }) => {
  if (isImpersonating)
    return <LoginInfoImpersonatingIcon className={`${styles.userIcon} ${styles.impersonated}`} />;

  return <LoginInfoIcon className={styles.userIcon} />;
};

Icon.propTypes = {
  isImpersonating: PropTypes.bool,
};

export default Icon;