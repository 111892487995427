import { SETTINGS_LOADED } from './actions';

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_LOADED:
      if (!action.payload)
        return { ...state, error: true };

      return Object.entries(action.payload).reduce((state, [key, value]) => {
        if (value == null || typeof value !== 'object')
          state[key] = value;
        else
          state[key] = { ...state[key], ...value };

        return state;
      }, { ...state, error: false, loaded: true });
    default:
      return state;
  }
}