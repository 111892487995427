exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Heading_heading h1,.Heading_heading h2,.Heading_heading h3,.Heading_heading h4,.Heading_heading h5,.Heading_heading h6{font-size:inherit}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/headings/Heading.module.scss"],"names":[],"mappings":"AAAA,wHAMI,iBACJ,CAAA","file":"Heading.module.scss","sourcesContent":[".heading h1,\n.heading h2,\n.heading h3,\n.heading h4,\n.heading h5,\n.heading h6 {\n    font-size: inherit;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"heading": "Heading_heading"
};