export const routeNames = Object.freeze({
  Home: '_HomePage',
  Login: 'Login',
  Registration: 'Registration',
  Represent: 'Represent',
  NotFound: 'PageNotFound',
  ContentPage: 'ContentPage',
  NewsOverview: 'NewsOverview',
  NewsDetail: 'NewsItem',
  ContactUs: 'ContactUs',
  VisualDesigner: 'VisualDesigner',
  WishList: 'WishList',
  MyAccount: 'MyAccount',
  NewsletterSubscribe: 'Subscribe',
  NewsletterUnSubscribe: 'Unsubscribe',
  Search: 'Search',
  ProductDetails: 'ProductDetails',
  ProductList: 'ProductList',
  FaqPage: 'FaqPage',
  BasketPage: 'Basket',
  WebsiteRedirect: 'WebsiteRedirect',
  ReturnOrders: 'ReturnOrders',
  CreateDocFreeReturnOrder: 'CreateDocFreeReturnOrder',
  CreateDocBasedReturnOrder: 'CreateDocBasedReturnOrder',
  EditProfile: 'EditProfile',
  Orders: 'Orders',
  Quotes: 'Quotes',
  DocumentDetails: 'DocumentDetails',
  Invoices: 'Invoices',
  ReturnReceipts: 'ReturnReceipts',
  CreditNotes: 'CreditNotes',
  Shipments: 'Shipments',
  SalesAgreements: 'SalesAgreements',
  SalesAgreement: 'SalesAgreement',
  OrderTemplates: 'OrderTemplates',
  SubAccounts: 'SubAccounts',
  EditSubAccount: 'SubAccount',
  NewSubAccount: 'NewSubAccount',
  OrderAuthorizations: 'OrderAuthorizations',
  CreateProspect: 'CreateProspect',
  ChangePassword: 'ChangePassword',
  ForgotPassword: 'ForgotPassword',
  ResetPassword: 'ResetPassword',
  PagePreview: 'PagePreview',
  Checkout: 'Checkout',
  QuotePromotion: 'QuotePromotion',
  QuotePromotionCancelled: 'QuotePromotionCancelled',
  InvoicePayment: 'InvoicePayment',
  OrderPayment: 'OrderPayment',
  PaymentSubmit: 'PaymentSubmit',
  PaymentCancelled: 'PaymentCancelled',
  PaymentFailed: 'PaymentFailed',
  LastViewedProducts: 'LastViewedProducts',
  ProductComparison: 'ProductComparison',
  ProductsWithCategory: 'ProductsWithCategory',
  OrderSubmit: 'OrderSubmit',
  PaymentError: 'PaymentError',
  OrderFailed: 'OrderFailed',
  OrderCancelled: 'OrderCancelled',
  Error: 'Error',
});
