import styles from '../PLP.module.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'rxjs';
import { Col, Container, Row } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { UseSanaButton } from 'components/primitives/buttons';
import AddToBasketButton from './AddToBasketButton';
import { resize$, scroll$, orientationChange$, useEventObservable } from 'utils/rxjs';
import { useResponsiveBreakpoints } from 'utils/layout';
import { useLayoutShifter, ShiftTypes } from 'utils/layout';
import { CartSummaryStickyIcon } from 'components/primitives/icons';

const ACTIVE_MINIATURE_CLASS_NAME = 'add-to-basket-miniature-active';

const StickyAddToBasketButton = ({ size, onClick }) => {
  const { topFixedElementsHeight, bottomFixedElementsHeight, updateElementsAffectingShiftData } = useLayoutShifter();
  const { xs, sm } = useResponsiveBreakpoints();
  const isMiniatureRef = useRef();
  isMiniatureRef.current = xs || sm;

  const stickyPanelRef = useRef();

  const handleVisibility = () => {
    const addToBasketButtons = document.querySelectorAll(`.${styles.control} .${styles.addToBasket}`);
    const topButtonPosition = addToBasketButtons[0].getBoundingClientRect().bottom;
    const bottomButtonPosition = addToBasketButtons[1].getBoundingClientRect().top;

    if (
      topButtonPosition < topFixedElementsHeight
      && bottomButtonPosition > window.innerHeight - bottomFixedElementsHeight
    ) {
      stickyPanelRef.current.style.visibility = 'visible';
      document.documentElement.classList.add(ACTIVE_MINIATURE_CLASS_NAME);

      if (!isMiniatureRef.current)
        updateElementsAffectingShiftData(
          ShiftTypes.BOTTOM,
          'StickyAddToBasketButton',
          stickyPanelRef.current.firstElementChild.offsetHeight,
          false,
          true,
        );
    } else {
      stickyPanelRef.current.style.visibility = 'hidden';
      document.documentElement.classList.remove(ACTIVE_MINIATURE_CLASS_NAME);

      if (!isMiniatureRef.current)
        updateElementsAffectingShiftData(ShiftTypes.BOTTOM, 'StickyAddToBasketButton');
    }
  };

  const handlePosition = () => {
    handleVisibility();
    setStickyPanelPadding(stickyPanelRef, isMiniatureRef.current, bottomFixedElementsHeight);
  };

  useEffect(() => {
    handlePosition();
    return () => {
      const scrollToTopButton = document.getElementById('scrollToTopButton');
      if (scrollToTopButton) {
        const scrollToTopButtonWrapper = scrollToTopButton.parentElement;
        scrollToTopButtonWrapper.style.marginTop = bottomFixedElementsHeight ? '-' + bottomFixedElementsHeight : '';
      }
    };
  }, [topFixedElementsHeight, bottomFixedElementsHeight]);

  useEffect(() => () => {
    document.documentElement.classList.remove(ACTIVE_MINIATURE_CLASS_NAME);
    updateElementsAffectingShiftData(ShiftTypes.BOTTOM, 'StickyAddToBasketButton');
  }, [isMiniatureRef.current]);

  useEventObservable(
    merge(scroll$, resize$, orientationChange$),
    handlePosition,
    true,
    [topFixedElementsHeight, bottomFixedElementsHeight],
  );

  const productsAmountPlaceholder = <Placeholder className={styles.productsAmountPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.addToBasketBtnPlaceholder} />;

  let content = null;

  if (isMiniatureRef.current) {
    content = (
      <span ref={stickyPanelRef} className={styles.miniature} data-scroll-lock-fill-gap role="presentation">
        <UseSanaButton textKey="AddToBasket" placeholder={btnPlaceholder}>
          {(_text, title) => (
            <button title={title} className={styles.miniatureButton} onClick={onClick}>
              <CartSummaryStickyIcon
                alt={title}
                className={styles.miniatureIcon}
              />
            </button>
          )}
        </UseSanaButton>
      </span>
    );
  } else {
    content = (
      <div className={styles.stickyFooter} ref={stickyPanelRef} data-scroll-lock-fill-gap>
        <Container>
          <Row crossAxisAlign="center">
            <Col>
              <SimpleText
                textKey="ProductList_StickyAddToCart_AmountTitle_Label"
                formatWith={[size]}
                placeholder={productsAmountPlaceholder}
              />
            </Col>
            <Col xs="auto" className={styles.btnWrapper}>
              <AddToBasketButton />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return content;
};

StickyAddToBasketButton.propTypes = {
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default React.memo(StickyAddToBasketButton);

function setStickyPanelPadding(stickyPanelRef, isMiniature, bottomShift) {
  if (!stickyPanelRef || !stickyPanelRef.current) {
    return;
  }

  const scrollToTopButton = document.getElementById('scrollToTopButton');

  let scrollToTopButtonWrapper;
  if (scrollToTopButton)
    scrollToTopButtonWrapper = scrollToTopButton.parentElement;

  stickyPanelRef.current.style.marginTop = '';
  stickyPanelRef.current.style.paddingBottom = '';

  if (isMiniature) {
    if (scrollToTopButtonWrapper)
      scrollToTopButtonWrapper.style.marginTop = bottomShift ? '-' + bottomShift + 'px' : '';

    stickyPanelRef.current.style.marginTop = bottomShift ? '-' + bottomShift + 'px' : '';
  } else {
    stickyPanelRef.current.style.paddingBottom = bottomShift - stickyPanelRef.current.firstElementChild.offsetHeight + 'px';

    const isVisible = stickyPanelRef.current.style.visibility === 'visible';

    if (scrollToTopButtonWrapper) {
      scrollToTopButtonWrapper.style.marginTop = isVisible
        ? -stickyPanelRef.current.offsetHeight + 'px'
        : bottomShift
          ? '-' + bottomShift + 'px'
          : '';
    }
  }
}