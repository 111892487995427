//only with relative paths and named import here, the IntelliSense for component props in add-ons will work correctly

export {
  Container,
  Row,
  Col,
} from '../../webstore/components/primitives/grid';

export {
  DateDisplay,
} from '../../webstore/components/primitives/time';

export {
  Button,
} from '../../webstore/components/primitives/buttons/addons';

export { default as Heading } from '../../webstore/components/primitives/headings/Heading';

export {
  Link,
  BackLink,
  LinkButton,
} from '../../webstore/components/primitives/links';

export {
  DomBody,
  DomElement,
  DomHead,
} from '../../webstore/components/primitives/dom';

export {
  ProductPrice,
  ProductStock, // DRW 20210427 -- Added this -- it's needed for Fujitsu's custom lookbook!!
} from '../../webstore/components/primitives/product';

export {
  ProductOrderButton,
} from '../../webstore/components/objects/productList/addons';

export {
  useModal,
} from '../../webstore/components/objects/modals/addons';

export {
  // ErrorBlock, InfoBlock, AlertBlock, ConfirmBlock names will be deprecated.
  // Names with 'Alert' will be used instead of old names.
  DangerAlert as ErrorBlock,
  InfoAlert as InfoBlock,
  WarningAlert as AlertBlock,
  SuccessAlert as ConfirmBlock,
  DangerAlert,
  InfoAlert,
  WarningAlert,
  SuccessAlert,
} from '../../webstore/components/primitives/alerts';

export {
  LazyImage,
  ResponsiveLazyImage,
} from '../../webstore/components/primitives/responsiveImages';

export {
  StarsRating,
} from '../../webstore/components/primitives/rating';
