import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'behavior/routing';

const BackLink = ({ goBack, children, ...attributes }) => {
  const onClick = useCallback(event => {
    if (event.button === 1)
      return;

    event.preventDefault();
    goBack();
  });
  return (
    <a href="/" onClick={onClick} {...attributes}>
      {children}
    </a>
  );
};

BackLink.propTypes = {
  goBack: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default connect(null, { goBack })(BackLink);