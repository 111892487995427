import styles from '../Checkout.module.scss';
import React, { useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Steps, selectPaymentMethod } from 'behavior/pages/checkout';
import {
  PaymentMethodStep as PaymentMethodStepBase,
  PaymentMethodStepBody,
} from '../base/payment';
import CheckoutContext from './CheckoutContext';
import { useDispatch } from 'react-redux';

const PaymentMethodStep = ({ info, isCompleted }) => {
  const { setLoading, registerStep } = useContext(CheckoutContext);

  useEffect(() => {
    registerStep(Steps.Payment, styles.paymentMethod, [Steps.Address, Steps.Payment]);
  }, []);

  const dispatch = useDispatch();

  const onSelect = useCallback(id => {
    dispatch(selectPaymentMethod(id));
    setLoading(Steps.Payment);
  }, [setLoading]);

  return (
    <PaymentMethodStepBase
      className={styles.paymentMethod}
      isQuote={info.isQuote}
      isCompleted={isCompleted}
    >
      <PaymentMethodStepBody
        totals={info.totals}
        paymentMethods={info.paymentMethods}
        selectedMethodId={info.paymentMethodId}
        onSelect={onSelect}
        isQuote={info.isQuote}
      />
    </PaymentMethodStepBase>
  );
};

PaymentMethodStep.propTypes = {
  info: PropTypes.shape({
    isQuote: PropTypes.bool,
    paymentMethods: PropTypes.array,
    totals: PropTypes.object,
    paymentMethodId: PropTypes.string,
  }).isRequired,
  isCompleted: PropTypes.bool,
};

export default PaymentMethodStep;
