import { of } from 'rxjs';
import { filter, mergeMap, tap, ignoreElements } from 'rxjs/operators';
import { requestAdminText } from 'behavior/adminTexts/epic';
import { toasts } from 'behavior/toasts';

export function skipIfPreview(state$) {
  return filter(() => !isPreview(state$.value));
}

export function skipIfPreviewWithToast(state$, deps) {
  return mergeMap(value => isPreview(state$.value) ? showActionCannotBePerformedToast(state$, deps) : of(value));
}

export function showActionCannotBePerformedToast(state$, deps) {
  return requestAdminText('Preview_ActionCannotBePerformed', state$, deps).pipe(
    tap(toasts.info),
    ignoreElements(),
  );
}

function isPreview(state) {
  return state.routing.routeData?.params?.previewToken != null;
}