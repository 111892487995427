export const EntityTemplateNames = Object.freeze({
  Registration: 'REGISTRATION',
  ShippingAddress: 'SHIPPING_ADDRESS',
  Prospect: 'PROSPECT_PROFILE',
  CustomerProfile: 'CUSTOMER_PROFILE',
});

export const TemplateFieldTypes = Object.freeze({
  EditableField: 'EditableTemplateField',
  ReadOnlyField: 'ReadOnlyTemplateField',
});
