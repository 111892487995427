import React from 'react';
import PropTypes from 'prop-types';
import InnerLine from './InnerLine';

const ExtendedText = ({ title, lineTexts }) => (
  <InnerLine value={title} lineTexts={lineTexts} />
);

ExtendedText.propTypes = {
  title: PropTypes.string,
  lineTexts: PropTypes.object.isRequired,
};

export default ExtendedText;
