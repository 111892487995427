import styles from './NonOrderable.module.scss';
import React, { useEffect } from 'react';
import { groupBy } from 'lodash';
import { NonOrderableReason as Reason } from './nonOrderableReason';
import Group from './NonOrderableGroup';
import { LinesPropType } from './PropTypes';
import { UseSanaTexts } from 'components/sanaText';
import scrollIntoView from 'scroll-into-view';

const textsToLoad = [
  'Basket_NotAvailableProducts',
  'Basket_NotInStock_CannotBeOrderedText',
  'Basket_NotInStockMessage',
];

const NonOrderableGroups = ({ nonOrderableLines }) => {
  if (!nonOrderableLines || !nonOrderableLines.length)
    return null;

  const groups = nonOrderableLines
    ? groupBy(nonOrderableLines, line => line.reason)
    : null;

  useEffect(() => {
    const nonOrderableElement = document.querySelector('.' + styles.icon);
    if (nonOrderableElement) {
      scrollIntoView(nonOrderableElement, { time: 350 });
    }
  });

  return (
    <div className={styles.groupsWrapper}>
      <UseSanaTexts options={textsToLoad} dontWait>
        {([notAvailableText, notInStockText, quantityNotInStockText]) => (
          <>
            <Group headerText={notAvailableText} lines={groups[Reason.NotAvailable]} />
            <Group headerText={notInStockText} lines={groups[Reason.NotInStock]} />
            <Group headerText={quantityNotInStockText} lines={groups[Reason.QuantityNotInStock]} />
            <Group headerText={notAvailableText} lines={groups[Reason.CustomerSpecificAssortments]} />
          </>
        )}
      </UseSanaTexts>
      {groups[Reason.Custom] && groups[Reason.Custom].map(
        (line, i) => <Group headerText={line.description} lines={null} key={i} />,
      )}
    </div>
  );
};

NonOrderableGroups.propTypes = {
  nonOrderableLines: LinesPropType,
};

export default React.memo(NonOrderableGroups);