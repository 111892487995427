import React from 'react';
import PropTypes from 'prop-types';
import { ReadOnlyFormGroup } from 'components/objects/forms/formGroups';
import Field from 'components/objects/forms/fields/Field';
import { Input } from 'components/primitives/form';

const CountryReadOnlyFormGroup = ({ displayValue: countryId, fieldName, items, ...props }) => {
  const country = items && items.find(country => country.value === countryId);
  return (
    <>
      <Field
        type="hidden"
        as={Input}
        fieldName={fieldName}
        initialValue={countryId}
      />
      <ReadOnlyFormGroup
        fieldName={fieldName}
        displayValue={country && country.name || countryId}
        {...props}
      />
    </>
  );
};

CountryReadOnlyFormGroup.propTypes = {
  displayValue: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
};

export default CountryReadOnlyFormGroup;
