import styles from './OrderTemplates.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText, UseSanaTexts } from 'components/sanaText';
import Actions from './Actions';
import { connect } from 'react-redux';
import { useOnChange } from 'utils/hooks';
import Item from './Item';
import { Checkbox } from 'components/primitives/form';
import { makeSimpleText } from 'utils/render';

const List = ({ items, status, culture }) => {
  const [selection, setSelection] = useState(new Set());

  useOnChange(() => setSelection(new Set()), [status], false);

  const toggle = useCallback(templateId => {
    setSelection(previous => {
      const newSelection = new Set(previous);
      if (newSelection.has(templateId))
        newSelection.delete(templateId);
      else
        newSelection.add(templateId);

      return newSelection;
    });
  }, []);

  if (!items)
    return null;

  if (!items.length) {
    return (
      <div className="msg-block">
        <RichText textKey="MyAccount_OrderTemplate_NoOrderTemplates" />
      </div>
    );
  }

  const allItemsSelected = selection.size === items.length;
  const allItemsIds = items.map(({ id }) => id);
  return (
    <>
      <table className={`${tableStyles.table} ${styles.templatesTable}`}>
        <thead>
          <tr>
            <th className={styles.checkboxCol}>
              <UseSanaTexts options={['SelectAll', 'DeselectAll']} dontWait>
                {([selectAllText, deselectAllText]) => {
                  const titleText = makeSimpleText(allItemsSelected ? deselectAllText : selectAllText);
                  const attributes = {};

                  if (titleText)
                    attributes.title = titleText;

                  return (
                    <Checkbox
                      onChange={() => setSelection(allItemsSelected ? new Set() : new Set(allItemsIds))}
                      checked={allItemsSelected}
                      {...attributes}
                    />
                  );
                }}
              </UseSanaTexts>
            </th>
            <th className={styles.templateNameCol}><SimpleText textKey="OrderTemplates_Name" /></th>
            <th colSpan="2"><SimpleText textKey="OrderTemplates_Created" /></th>
          </tr>
        </thead>
        {items.map(orderTemplate => (
          <Item
            key={orderTemplate.id}
            orderTemplate={orderTemplate}
            selected={selection.has(orderTemplate.id)}
            toggle={toggle}
            culture={culture}
          />
        ))}
      </table>
      <Actions selection={selection} />
    </>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  status: PropTypes.object,
  culture: PropTypes.string,
};

export default connect(({
  orderTemplates: { items, status },
  localization,
}) => ({
  items,
  status,
  culture: localization.currentLanguage && localization.currentLanguage.cultureName,
}))(List);