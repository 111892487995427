import React, { useState } from 'react';
import { noop } from 'rxjs';
import { RichText } from 'components/sanaText';
import ReturnOrderProductSearch from './ReturnOrderProductSearch';
import DocFreeReturnOrderForm from './DocFreeReturnOrderForm';

const DocFreeReturnOrder = () => {
  const [productAddCallback, setProductAddCallback] = useState(null);
  
  return (
    <>
      <div>
        <RichText textKey="ReturnOrder_Description" />
        <ReturnOrderProductSearch onProductAdd={productAddCallback || noop} />
      </div>
      <div>
        <h2><RichText textKey="ReturnOrder" /></h2>
        <DocFreeReturnOrderForm setProductAddCallback={setProductAddCallback} />
      </div>
    </>
  );
};

export default React.memo(DocFreeReturnOrder);