import styles from './WishList.module.scss';
import React from 'react';
import { SimpleText } from 'components/sanaText';
import { Container } from 'components/primitives/grid';
import { PageComponentNames } from 'behavior/pages';
import WishList from './WishList';
import Breadcrumb from './Breadcrumb';

function wishListPageRenderer(page) {
  return (
    <>
      <Container>
        <Breadcrumb />
        <h1 className={styles.title}><SimpleText textKey="WishList_Title" /></h1>
        <WishList data={page} />
      </Container>
    </>
  );
}

export default {
  [PageComponentNames.WishList]: wishListPageRenderer,
};