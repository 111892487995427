import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { useOnChange } from 'utils/hooks';

const ModalWrapper = ({ opened, resetContentOnClose, ...props }) => {
  const shouldRenderRef = useRef(opened);

  useOnChange(() => {
    if (!shouldRenderRef.current && opened)
      shouldRenderRef.current = true;
  }, [opened], false);

  if (!shouldRenderRef.current || (resetContentOnClose && !opened))
    return null;

  return <Modal opened={opened} {...props} />;
};

ModalWrapper.propTypes = {
  opened: PropTypes.bool.isRequired,
  resetContentOnClose: PropTypes.bool,
};

export default ModalWrapper;
