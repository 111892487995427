import styles from './Header.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import { LoginInfo } from 'components/objects/loginInfo';
import { LanguageSelector } from 'components/objects/languageSelector';
import { Logo } from 'components/primitives/logo';
import { Search } from 'components/objects/search';
import { DesktopNav } from 'components/objects/navigation';
import CustomerServicePage from './CustomerServicePage';
import TopInfo from './TopInfo';
import { BasketSummary } from 'components/objects/basket';
import ShoppingCartSuggestions from './ShoppingCartSuggestions';
import { HEADER_SEARCH_BOX_ID } from './constants';

const DesktopHeaderTemplate = ({ setDesktopNavHoverStatus }) => (
  <>
    <Container className={styles.desktopTopContainer}>
      <Row>
        <Col xs={6} className={styles.column}>
          <TopInfo />
        </Col>
        <Col xs={6} className={`${styles.column} ${styles.actionsBlock}`}>
          <LanguageSelector />
          <CustomerServicePage />
          <LoginInfo showLoginLink />
        </Col>
      </Row>
    </Container>
    <div className={styles.desktopMiddleContainer}>
      <Container className={styles.contentWrapper}>
        <Row crossAxisAlign="center">
          <Col xs={3}>
            <Logo />
          </Col>
          <Col xs={6}>
            <Search id={HEADER_SEARCH_BOX_ID} />
          </Col>
          <Col xs={3} className={`${styles.column} ${styles.shoppingCartSection}`}>
            <ShoppingCartSuggestions className={styles.suggestions} hideLabel />
            <BasketSummary />
          </Col>
        </Row>
      </Container>
    </div>
    <div className={styles.mainNavContainer}>
      <DesktopNav setDesktopNavHoverStatus={setDesktopNavHoverStatus} />
    </div>
  </>
);

DesktopHeaderTemplate.propTypes = {
  setDesktopNavHoverStatus: PropTypes.func,
};

export default React.memo(DesktopHeaderTemplate);