import PropTypes from 'prop-types';
import { QuoteStatus, PaymentStatus, DocumentType } from 'behavior/documents';

export const quoteStatusPropType = PropTypes.oneOf([
  QuoteStatus.Expired,
  QuoteStatus.InReview,
  QuoteStatus.Orderable,
  QuoteStatus.Cancelled,
]);

export const documentTypePropType = PropTypes.oneOf(Object.values(DocumentType));

export const paymentStatusPropType = PropTypes.oneOf(Object.values(PaymentStatus));
