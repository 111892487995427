import styles from './WishList.module.scss';
import React, { useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { routesBuilder } from 'routes';
import { joinClasses } from 'utils/helpers';
import { ProductTrackingContext } from 'components/objects/analytics';
import { useIsMobileViewport } from 'utils/hooks';
import ActionLinks from './ActionLinks';
import ProductInfo from './ProductInfo';
import DefaultTemplate from './templates/Default';
import MobileTemplate from './templates/Mobile';
import PriceNode from './PriceNode';
import MediaBlock from './MediaBlock';
import { EVENT_SOURCES } from 'behavior/analytics';
import { removeProductFromWishList, moveProductToBasket } from 'behavior/wishList/actions';
import { withAbilities } from 'components/objects/user';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import AddToBasketBlock from './AddToBasketBlock';
import { UomTitle } from 'components/primitives/product';
import { SimpleText } from 'components/sanaText';

function Line({
  productLine,
  currencyInfo,
  product,
  isVariantLine,
  removeProductFromWishList,
  moveProductToBasket,
  abilities: [orderProductAbility],
}) {
  const { price, isOrderable } = productLine;
  const { id, url, title: productTitle, image, isSpare } = product;
  const route = routesBuilder.forProduct.bind(null, id);
  const uomId = productLine.uom && productLine.uom.id;
  const uomDescription = productLine.uom && productLine.uom.description;
  const variantId = isVariantLine ? productLine.variationId : null;
  const [showPrice, showUom] = useHasAbilities(AbilityTo.ViewPrices, AbilityTo.ViewUnitOfMeasure);

  const priceNode = showPrice && <PriceNode price={price} currencyInfo={currencyInfo} className={styles.priceNode} />;

  const isMobile = useIsMobileViewport();
  const LineTemplate = isMobile ? MobileTemplate : DefaultTemplate;

  const trackingData = {
    product: { ...product, price, uom: productLine.uom },
    trackingSource: EVENT_SOURCES.wishList,
  };

  const state = useSelector(x => x);

  const basketSummary = useSelector(x => x.basket.summary);

  //GF OP18938 Work out if the basket contains a spare or a product. It might be empty.
  let basketContainsSpare = false;
  let basketContainsProduct = false;

  if (basketSummary !== undefined && basketSummary.productLines !== undefined && basketSummary.productLines.list?.length > 0 && basketSummary.productLines.list[0] !== undefined) {
    if (basketSummary.productLines.list[0].product.isSpare) {
      basketContainsSpare = true;
    } else {
      basketContainsProduct = true;
    }
  }
  else {
    //console.log('undefined objects');
  }

  const addingSpareToProductBasket = isSpare && basketContainsProduct;
  const addingProductToSpareBasket = (isSpare === false) && basketContainsSpare;

  const [disabled, setDisabled] = useState(false);

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    setDisabled(true);
    moveProductToBasket(id, uomId, variantId);
  }, []);

  const actionLinks = (
    <ActionLinks
      productUrl={url}
      route={route}
      isVariantLine={isVariantLine}
      onDelete={() => { removeProductFromWishList(id, uomId, variantId); }}
    />
  );

  const productInfo = isVariantLine
    ? (
      <span className={styles.smallTitle}>{productLine.title}</span>
    )
    :
    (
      <ProductInfo
        productUrl={url}
        route={route}
        productTitle={productTitle}
        productId={id}
        variantTitle={productLine.title}
        isSpare={isSpare}
      />
    );

  const productActionBlock = (
    <MediaBlock
      productTitle={productTitle}
      imageUrl={image && image.small}
      productUrl={url}
      route={route}
    />
  );

  const addingWrongItem = (addingSpareToProductBasket ? 
    (
      <div className={styles.largeTitle}>
        <SimpleText textKey="Cannot_Add_Spare_To_ProductBasket_WishList" />
      </div>
    ):
    (
      <div className={styles.largeTitle}>
        <SimpleText textKey="Cannot_Add_Product_To_SpareBasket_WishList" />
      </div>
    )
  );

  const addToBasketBlock = (
    addingSpareToProductBasket || addingProductToSpareBasket ?
         addingWrongItem
      :
      (
        <AddToBasketBlock
          addToBasket={orderProductAbility === AbilityState.Available ? addToBasket : null}
          isOrderable={isOrderable}
        />
      )
  );

  const uomBlock = showUom && (
    <div className={styles.uomLabel}>
      <UomTitle id={uomId} description={uomDescription || ''} />
    </div>
  );

  return (
    <ProductTrackingContext.Provider value={trackingData}>
      <LineTemplate
        className={joinClasses(
          styles.productLine,
          isVariantLine && styles.variantLine,
        )}
        productActionBlock={productActionBlock}
        productInfo={productInfo}
        actionLinks={actionLinks}
        priceNode={priceNode}
        uomBlock={uomBlock}
        addToBasketBlock={addToBasketBlock}
        data-product={id}
      />
    </ProductTrackingContext.Provider>
  );
}

Line.propTypes = {
  productLine: PropTypes.shape({
    title: PropTypes.string,
    price: PropTypes.number,
    uom: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
    variationId: PropTypes.string,
    isOrderable: PropTypes.bool,
  }),
  currencyInfo: PropTypes.object,
  product: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
    isSpare: PropTypes.bool,
  }),
  isVariantLine: PropTypes.bool,
  removeProductFromWishList: PropTypes.func.isRequired,
  moveProductToBasket: PropTypes.func.isRequired,
  abilities: abilitiesPropType,
};

const mapStateToProps = ({
  settings: { currency },
}) => ({
    currencyInfo: currency,
});

const mapDispatchToProps = {
  removeProductFromWishList,
  moveProductToBasket,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAbilities(Line, [AbilityTo.OrderProducts]));
