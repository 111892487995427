export enum DaysOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum CalendarTypes {
  ARABIC = 'Arabic',
  HEBREW = 'Hebrew',
  ISO_8601 = 'ISO 8601',
  US = 'US',
}

export enum PeriodTypes {
  CENTURY = 'century',
  DECADE = 'decade',
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}