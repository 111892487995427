import styles from './DocBasedReturnOrder.module.scss';
import React, { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSimpleTexts, SimpleText, RichText } from 'components/sanaText';
import { SanaForm, FormGroup, useFormLockerOnSubmit } from 'components/objects/forms';
import { DropdownField, TextAreaField, FileField } from 'components/objects/forms/fields';
import { FormLockKeys, unlockForm } from 'behavior/pages';
import { createReturnOrder } from 'behavior/pages/createDocBasedReturnOrder';
import { toasts } from 'behavior/toasts';
import { DangerAlert } from 'components/primitives/alerts';
import { ContactStoreLink } from 'components/primitives/links';
import ReturnOrderLinesFieldArray from './ReturnOrderLinesFieldArray';

const DocBasedReturnOrderForm = () => {
  const dispatch = useDispatch();
  const { invoice, returnReasons, fileUploadSettings, isFailed } = useSelector(({ page }) => ({
    invoice: page.invoice,
    returnReasons: page.returnReasons.forOrder,
    fileUploadSettings: page.fileUploadSettings,
    isFailed: page.isFailed,
  }), shallowEqual);
  const [
    returnReasonTitle,
    commentTitle,
    fileAttachmentsTitle,
    fileAcceptedTypesErrorText,
  ] = useSimpleTexts([
    'ReturnRequest_Reason',
    'OrderComments',
    'ReturnRequest_FileAttachments',
    'FileUploader_AcceptValidation',
  ]).texts;
  const orderReturnReasons = useMemo(() => returnReasons.map(({ id, name }) => ({ value: id, name })), [returnReasons]);

  const onSubmit = useFormLockerOnSubmit(({ lines, files, ...input }) => {
    let isValid = true;

    const selectedLines = lines.reduce((accumulator, line) => {
      if (line.selected) {
        let sumLinesQuantity = 0;

        for (const subline of line.sublines) {
          accumulator.push(subline);
          sumLinesQuantity += subline.quantity;
        }

        const invoiceLine = invoice.lines.find(l => l.lineNo === line.sublines[0].lineNo);
        if (sumLinesQuantity > invoiceLine.availableReturnQuantity)
          isValid = false;
      }

      return accumulator;
    }, []);

    if (!isValid) {
      dispatch(unlockForm(FormLockKeys.CreateDocBasedReturnOrder));
      return;
    }

    if (!selectedLines.length) {
      toasts.info(<SimpleText textKey="ReturnRequest_NoProductsSelected" />);
      dispatch(unlockForm(FormLockKeys.CreateDocBasedReturnOrder));
      return;
    }

    dispatch(createReturnOrder({
      ...input,
      id: invoice.id,
      orderId: invoice.orderId,
      lines: selectedLines,
      fileNames: Array.from(files).map(file => file.name),
    }, files));
  }, FormLockKeys.CreateDocBasedReturnOrder, [invoice]);

  return (
    <SanaForm name="CreateDocBasedReturnOrder" onSubmit={onSubmit} shouldReset={isFailed}>
      {isFailed && (
        <DangerAlert role="alert" scrollOnAppear>
          <RichText
            textKey="ReturnRequest_OrderFailed"
            formatWith={[<ContactStoreLink linkTextKey="ReturnRequest_OrderFailed_ContactUs" />]}
          />
        </DangerAlert>
      )}
      <ReturnOrderLinesFieldArray name="lines" />
      {orderReturnReasons.length !== 0 && (
        <FormGroup
          fieldName="reasonId"
          fieldTitle={returnReasonTitle}
          fieldComponent={DropdownField}
          items={orderReturnReasons}
          placeholderTextKey="ReturnRequest_SelectReasonPlaceholder"
          required
          validation={{ required: true }}
          className={styles.reasonField}
        />
      )}
      <FormGroup
        fieldName="comment"
        fieldTitle={commentTitle}
        fieldComponent={TextAreaField}
        maxLength={2000}
      />
      <FormGroup
        fieldName="files"
        fieldTitle={fileAttachmentsTitle}
        fieldComponent={FileField}
        multiple={fileUploadSettings.allowMultiple}
        validation={{
          fileAcceptedTypes: { types: fileUploadSettings.acceptedTypes, message: fileAcceptedTypesErrorText },
          fileNameMaxLength: { max: fileUploadSettings.maxNameLength },
          filesMaxSize: { max: fileUploadSettings.maxSize },
        }}
      />
      <SanaForm.SubmitRow textKey="CreateReturnOrder" />
    </SanaForm>
  );
};

export default React.memo(DocBasedReturnOrderForm);