import styles from './Details.module.scss';
import React, { memo } from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import OrderBox from './OrderBox';
import ProductProvider from './ProductProvider';
import ProductDescription from './ProductDescription';
import ProductTitle from './ProductTitle';
import ProductsTabsContainer from './ProductsTabsContainer';
import { SocialNetworks } from 'components/objects/socialSharing';
import ProductGallery from './ProductGallery';
import RetailOffers from './RetailOffers';
import { PageContent } from 'components/objects/pages';
import { Totals as ReviewsTotals } from './reviews';
import { usePrintMode } from 'utils/hooks';
import Schema from './Schema';
import PropTypes from 'prop-types';
import { Presets } from 'behavior/pages/product';
import ProductConfigurator from 'components/objects/productConfigurator/ProductConfigurator';
import LastViewed from './LastViewed';
import VariantsMatrixPrint from './variantsMatrix/VariantsMatrixPrint';
import ProductComparisonSelector from './ProductComparisonSelector';

const Details = ({ preset, middleContent, footerContent, wishListEnabled }) => {
  const isPrintMode = usePrintMode();
  const matrixPreset = preset === Presets.DetailsWithMatrix;

  return (
    <ProductProvider>
      <Container>
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 2 }} md={6}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            <ProductGallery />
          </Col>
          <Col xs={{ size: 12, order: 4 }} md={6}
            className={`${styles.displayMdBlock} ${styles.clearLeft} ${styles.floatLeft}`}
          >
            {!isPrintMode && <SocialNetworks />}
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <ProductTitle />
          </Col>
          {!isPrintMode && <ReviewsTotals />}
          <Col xs={{ size: 12, order: 3 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.description}>
              <RetailOffers matrixPreset={matrixPreset} />
              <ProductDescription matrixPreset={matrixPreset} />
            </div>
            <OrderBox matrixPreset={matrixPreset} />
            {!isPrintMode && <ProductComparisonSelector className={styles.productComparisonSelector} />}
          </Col>
        </Row>
        {isPrintMode && matrixPreset && <VariantsMatrixPrint />}
      </Container>
      <PageContent content={middleContent} position="middle" />
      <Container>
        <Row className="print-no-flex">
          <ProductsTabsContainer />
        </Row>
      </Container>
      <PageContent content={footerContent} position="footer" />
      {!isPrintMode && <LastViewed />}
      <Schema />
      <ProductConfigurator />
    </ProductProvider>
  );
};

Details.propTypes = {
  preset: PropTypes.string.isRequired,
  middleContent: PropTypes.array,
  footerContent: PropTypes.array,
  wishListEnabled: PropTypes.bool,
};

export default memo(Details);

