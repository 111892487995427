import styles from '../documents/Orders.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { Table } from 'components/primitives/table';
import { getToLocaleDate } from 'utils/format';
import { FormattedPrice } from 'components/primitives/price';
import ViewDetailsLink from '../documents/ViewDetailsLink';
import { DocumentType } from 'behavior/documents';
import { useCultureName, useCurrencyInfo, useIsOffline } from 'utils/hooks';
import { OrderAuthorizationStatus } from 'behavior/pages/document';
import { useIsTouchDevice } from 'utils/detections';
import { StatusSuccessIcon, StatusPendingIcon, StatusFailedIcon } from 'components/primitives/icons';

const List = ({ authorizations }) => {
  const isTouch = useIsTouchDevice();
  const [showPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const culture = useCultureName();
  const currency = useCurrencyInfo();
  const offlineMode = useIsOffline();

  const [approvedTitle, declinedTitle, pendingTitle] = useSimpleTexts([
    'OrderAuthorizationStatus_Approved',
    'OrderAuthorizationStatus_Declined',
    'OrderAuthorizationStatus_Pending',
  ]).texts;

  const statusClassAndTitle = {
    [OrderAuthorizationStatus.Approved]: [styles.statusApproved, approvedTitle, StatusSuccessIcon],
    [OrderAuthorizationStatus.Declined]: [styles.statusDeclined, declinedTitle, StatusFailedIcon],
    [OrderAuthorizationStatus.Pending]: [styles.statusPending, pendingTitle, StatusPendingIcon],
  };

  const toLocaleDate = getToLocaleDate(culture);

  return (
    <Table className={styles.ordersList}>
      <thead>
        <tr>
          <th id="headerId" className={styles.documentIdCell}><SimpleText textKey="OrderNumber" /></th>
          <th id="headerOwner" className={styles.ownerNameCell}><SimpleText textKey="PlacedBy" /></th>
          <th id="headerDate" className={styles.dateCell}><SimpleText textKey="OrderDate" /></th>
          {showPrices && <th id="headerAmount" className={styles.priceCell}><SimpleText textKey="OrderAmount" /></th>}
          <th id="headerStatus" className={styles.authStatusCell}><SimpleText textKey="AuthorizationStatus" /></th>
          {!offlineMode && <th className={styles.viewMoreCell} />}
        </tr>
      </thead>
      <tbody>
        {authorizations.map(({ orderId, documentId, orderUrl, ownerName, orderDate, orderAmount, authorizationStatus }) => {
          const [statusClassName, statusTitle, Icon] = statusClassAndTitle[authorizationStatus];

          return (
            <tr key={orderId}>
              <th scope="row" aria-labelledby="headerId">{documentId}</th>
              <td aria-labelledby="headerOwner">{ownerName}</td>
              <td aria-labelledby="headerDate">{toLocaleDate(orderDate)}</td>
              {showPrices &&
                <td aria-labelledby="headerAmount" className={styles.total}>
                  <FormattedPrice price={orderAmount} currencyInfo={currency} />
                </td>
              }
              <td className={styles.authStatusCell} aria-labelledby="headerStatus">
                <span className={`${styles.authStatusIcon} ${statusClassName}`} title={statusTitle}>
                  <Icon alt={statusTitle} />
                  <span aria-hidden={!isTouch} className={isTouch ? styles.statusText : 'visually-hidden'}>
                    {statusTitle}
                  </span>
                </span>
              </td>
              {!offlineMode && <td><ViewDetailsLink url={orderUrl} id={orderId} documentType={DocumentType.Order} /></td>}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

List.propTypes = {
  authorizations: PropTypes.arrayOf(PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    orderUrl: PropTypes.string.isRequired,
    ownerName: PropTypes.string,
    orderDate: PropTypes.string,
    orderAmount: PropTypes.number,
    authorizationStatus: PropTypes.oneOf([
      OrderAuthorizationStatus.Approved,
      OrderAuthorizationStatus.Declined,
      OrderAuthorizationStatus.Pending,
    ]),
  })),
};

export default List;
