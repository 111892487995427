import styles from '../Checkout.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';

const SubmitRow = ({
  isQuote,
  submitButtonTextKey,
  extraButton = null,
  disabled,
  onSubmit,
}) => {
  return (
    <div className={styles.payRow}>
      {extraButton}
      <SanaButton
        textKey={submitButtonTextKey}
        className={`${btnStyles.btn} ${btnStyles.btnAction} ${btnStyles.noIcon} ${styles.submitBtn} ${isQuote ? styles.btnQuote : styles.btnOrder}`}
        onClick={disabled ? null : onSubmit}
        aria-disabled={disabled}
      />
    </div>
  );
};

SubmitRow.propTypes = {
  isQuote: PropTypes.bool,
  submitButtonTextKey: PropTypes.string.isRequired,
  extraButton: PropTypes.node,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(SubmitRow);
