export const primaryStyleSheets = Object.freeze([
  require.resolveWeak('css/Normalize.css'),
  require.resolveWeak('css/Fonts.scss'),
  require.resolveWeak('react-lazy-load-image-component/src/effects/opacity.css'),
  require.resolveWeak('css/General.scss'),
  require.resolveWeak('css/General.print.scss'),
  require.resolveWeak('css/Animations.module.scss'),
  require.resolveWeak('components/primitives/grid/Grid.module.scss'),
  require.resolveWeak('components/primitives/placeholders/Placeholder.module.scss'),
  require.resolveWeak('components/primitives/buttons/Button.module.scss'),
  require.resolveWeak('components/primitives/links/Link.module.scss'),
  require.resolveWeak('components/primitives/table/Table.module.scss'),
  require.resolveWeak('components/objects/forms/SanaForm.module.scss'),
  require.resolveWeak('components/primitives/form/Input.module.scss'),
  require.resolveWeak('components/objects/modals/Modal.module.scss'),
]);
