import styles from './Radio.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ className = '', children = null, ...attributes }) => (
  // tabIndex is used to prevent focus been set on any parent focusable container which holds label element when clicking on it.
  <label className={`${styles.lbl} ${className}`} tabIndex="-1">
    <span className={styles.btn}>
      <input type="radio" {...attributes} />
      <ins aria-hidden />
    </span>
    {children}
  </label>
);

Radio.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Radio;