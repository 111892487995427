import styles from './header/Header.module.scss';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useResponsiveBreakpoints } from 'utils/layout';
import MobileHeaderTemplate from './header/MobileHeaderTemplate';
import StickyHeaderTemplate from './header/StickyHeaderTemplate';
import DesktopHeaderTemplate from './header/DesktopHeaderTemplate';
import withHeaderContext from './headerContext/withHeaderContext';
import { useEventObservable, scroll$ } from 'utils/rxjs';
import { useLayoutShifter, ShiftTypes } from 'utils/layout';
import { joinClasses } from 'utils/helpers';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { HEADER_SEARCH_BOX_ID } from './header/constants';

const Header = () => {
  const ref = useRef();
  const shiftValueRef = useRef(0);
  const [stickyOnDesktop, setStickyOnDesktop] = useState(false);
  const [isDesktopNavHovered, setDesktopNavHoverStatus] = useState(false);
  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;
  const { topShift, topShiftBlockHeight, updateElementsAffectingShiftData } = useLayoutShifter();

  const handleScroll = useCallback(() => {
    // StickyHeaderTemplate on desktop resolutions (lg or xl) should be shown when DesktopHeaderTemplate
    // is not in the viewport and desktop top navigation menu is not hovered
    const { pageYOffset } = window;

    if (stickyOnDesktop && pageYOffset <= shiftValueRef.current) {
      toggleOverflowAnchorState(true);
      setStickyOnDesktop(false);
      return;
    }

    if (isDesktopNavHovered)
      return;

    const isDesktopHeaderOutOfViewport = pageYOffset >= ref.current.offsetHeight;
    const isSearchBoxHasFocus = document.activeElement ? document.activeElement.id === HEADER_SEARCH_BOX_ID : false;

    if (
      !stickyOnDesktop
      && isDesktopHeaderOutOfViewport
      && !isSearchBoxHasFocus // When search input focused sticky header should not be shown because of jumping in iOS
    ) {
      handleSwitchToStickyHeader(ref.current, updateElementsAffectingShiftData);
      setStickyOnDesktop(true);
    }
  }, [stickyOnDesktop, isDesktopNavHovered]);

  const handleBlur = useCallback(e => {
    if (!isDesktop)
      return;

    if (e.target.id !== HEADER_SEARCH_BOX_ID)
      return;

    if (stickyOnDesktop || window.pageYOffset < ref.current.offsetHeight)
      return;

    toggleOverflowAnchorState(true);
    handleSwitchToStickyHeader(ref.current, updateElementsAffectingShiftData);
    setStickyOnDesktop(true);
  }, [isDesktop, stickyOnDesktop]);

  useEventObservable(scroll$, handleScroll, isDesktop, [stickyOnDesktop, isDesktopNavHovered]);

  useEffect(() => {
    ref.current.style.height = ''; // Reset header inline styled zero height
    shiftValueRef.current = stickyOnDesktop // Save current layout shift value
      ? topShift - topShiftBlockHeight
      : topShift;

    if (document.body.style.overflowAnchor !== '')
      setTimeout(toggleOverflowAnchorState, 25);

    // Fixes document.activeElement is not present on sticky header appearing when any desktop
    // header element was in focus in IE11. In other browsers document.activeElement is set to
    // the body element
    if (!document.activeElement)
      document.body.focus();

    return () => {
      if (!stickyOnDesktop)
        return;

      updateElementsAffectingShiftData(ShiftTypes.TOP, 'desktopHeader'); // Remove DesktopHeaderTemplate height from layout shift value
    };
  }, [stickyOnDesktop]);

  useEffect(() => toggleOverflowAnchorState, []);

  useEffect(() => {
    if (!!document.activeElement)
      return;

    // Fixes document.activeElement is not present on header type change if an element in previous rendered
    // type of header was in focus in IE11. In other browsers document.activeElement is set to the body element
    document.body.focus();
  }, [xs, sm, isDesktop, stickyOnDesktop]);

  useEffect(() => {
    if (!isDesktop) {
      // Reset sticky on desktop and desktop menu hover status on mobile and tablet
      updateElementsAffectingShiftData(ShiftTypes.TOP, 'desktopHeader');
      setStickyOnDesktop(false);
      setDesktopNavHoverStatus(false);
      return;
    }
    // Set sticky header status depending on current scroll position for desktop resolutions
    setStickyOnDesktop(window.pageYOffset >= ref.current.offsetHeight);
  }, [isDesktop]);

  useEffect(() => {
    if (isDesktopNavHovered) {
      const className = 'allow-root-overflow';

      ref.current.classList.add(styles.navIsHovered);
      document.documentElement.classList.add(className);

      return () => {
        ref.current.classList.remove(styles.navIsHovered);
        document.documentElement.classList.remove(className);
      };
    }

    if (window.pageYOffset < ref.current.offsetHeight)
      return;

    handleSwitchToStickyHeader(ref.current, updateElementsAffectingShiftData);
    setStickyOnDesktop(true);
  }, [isDesktopNavHovered]);

  let stickyHeader = null;

  if (sm || stickyOnDesktop)
    stickyHeader = <StickyHeaderTemplate isDesktop={isDesktop} noGutters={sm || md} />;

  const renderedHeader = xs
    ? <MobileHeaderTemplate />
    : sm
      ? stickyHeader
      : stickyOnDesktop
        ? stickyHeader
        : (
          <DesktopHeaderTemplate setDesktopNavHoverStatus={setDesktopNavHoverStatus} />
        );

  // The below eslint rule should be disabled as, in fact, here blur events captured for header children elements, not header itself.
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <header id="header" className={joinClasses(isDesktop && !stickyOnDesktop && styles.desktop)} ref={ref} onBlur={handleBlur}>{renderedHeader}</header>;
};

const MemoizedHeader = React.memo(Header);

export default withHeaderContext(MemoizedHeader);

function handleSwitchToStickyHeader(headerElement, updateElementsAffectingShiftData) {
  // Layout shift updated with DesktopHeaderTemplate height, header height temporarily set to 0
  // to prevent content jumping and preserve scroll position on StickyHeaderTemplate appearance
  updateElementsAffectingShiftData(ShiftTypes.TOP, 'desktopHeader', headerElement.offsetHeight, true, false);
  headerElement.style.height = 0;
}