import styles from './NewsOverview.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const SidebarNewsItem = ({ id, title, url }) => {
  return (
    <li className={styles.sideItem}>
      <Link url={url} to={routesBuilder.forNewsDetail(id)}>
        {title}
      </Link>
    </li>
  );
};

SidebarNewsItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default React.memo(SidebarNewsItem);