import React from 'react';
import { PropTypes } from 'prop-types';
import AddonContext from './AddonContext';

const AddonProvider = ({ registry, children }) => (
  <AddonContext.Provider value={{ registry }}>
    {children}
  </AddonContext.Provider>
);

AddonProvider.propTypes = {
  registry: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default AddonProvider;
