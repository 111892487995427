import styles from '../PLP.module.scss';
import React, { useState, useContext, useCallback, useEffect } from 'react';
import { RichText, useSanaTexts } from 'components/sanaText';
import { OfflinePriceWarning } from 'components/primitives/product';
import ProductLine from './Line';
import Schema from '../Schema';
import { ViewModes } from 'behavior/pages/productList';
import Paging from './Paging';
import { FacetsContext } from '../facets';
import { LastViewedProducts } from 'components/objects/lastViewed';
import withPageProps, { pagePropTypes } from '../withPageProps';
import { useProductListAbilities } from './useProductListAbilities';
import ListProvider from './ListProvider';
import AddToBasketButton from './AddToBasketButton';
import StickyAddToBasketPanel from './StickyAddToBasketPanel';
import { AbilityTo } from 'behavior/user/constants';
import SelectionLostPopup from './SelectionLostPopup';
import { useAriaStatusNotification, useTracking } from '../hooks';
import { HeaderRow } from '../HeaderRow';
import { trackProductListView, EVENT_SOURCES } from 'behavior/analytics';
import { connect } from 'react-redux';
import { ProductTrackingContext } from 'components/objects/analytics';
import { useMatchMediaContext } from 'components/responsive';
import { Modal } from 'components/objects/modals';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import { Page as PageModal } from '../modal/Page';
import { Tabs } from 'components/objects/tabs';
import { DangerAlert, InfoAlert } from '../../../../../adk/webstore/elements';
import { SanaButton } from 'components/primitives/buttons';

const Page = ({
  products,
  totalCount,
  selectedSorting,
  sortingEnabled,
  selectedViewMode,
  viewModeSwitchEnabled,
  pagingType,
  showThumbnails,
  id,
  lastViewedEnabled,
  relevanceAvailable,
  pageTitle,
  trackProductListView,
}) => {
  const { hasFacets, availableFacets } = useContext(FacetsContext);
  const [headerLbl] = useSanaTexts(['Aria_PLP_Header']).texts;
  const trackingSource = id === 'search' ? EVENT_SOURCES.searchResult : pageTitle;
  useTracking(products,
    ({ products }) => trackProductListView(products, trackingSource),
    [products],
  );

  const gridMode = selectedViewMode === ViewModes.Grid;
  const { xl } = useMatchMediaContext();
  const abilities = useProductListAbilities();

  const [sortingKey, setSortingKey] = useState(true);
  const restoreSorting = useCallback(() => setSortingKey(k => !k), []);

  useAriaStatusNotification(products, selectedViewMode, selectedSorting);

  if (!products.length) {
    return (
      <div className="msg-block">
        <RichText textKey="ProductList_NoResult" />
      </div>
    );
  }

  // LINKED ITEMS
  const [productslinkedItems, setProductsLinkedItems] = useState([]); // LINKED ITEMS PRODUCTS LIST
  const [showModal, setShowModal] = useState(false); // DISPLAY OR HIDE LINKED ITEMS MODAL
  const [onModalPage, setOnModalPage] = useState(false); // MAKE SURE PRODUCTS SELECTED ONLY EFFECTED ON MODAL PAGE
  const [modalProductsSelected, setModalProductsSelected] = useState([]); // KEEP TRACK OF ALL PRODUCTS SELETED ON EACH TAB
  const [currentModalPageSelected, setCurrentModalPageSelected] = useState(''); // KEEP TRACK OF CURRENT MODAL TAB PAGE INDEX
  const [currentModalQuantitySelected, setCurrentModalQuantitySelected] = useState(0); // KEEP TRACK OF CURRENT MODAL TAB QUANITY OF PRODUCTS SELECTED
  const [currentTabIndex, setCurrentTabIndex] = useState(0); // KEEP TRACK OF CURRENT TAB INDEX
  const [closeError, setCloseError] = useState(false); // DISPLAY ERROR IF MODAL STILL HAS PRODUCTS SELECTED BEFORE HIDING MODAL

  useEffect(() => {
    if (onModalPage) {
      if (!modalProductsSelected[currentModalPageSelected] || currentModalQuantitySelected !== modalProductsSelected[currentModalPageSelected]) {
        setModalProductsSelected({
          ...modalProductsSelected,
          [currentModalPageSelected]: currentModalQuantitySelected,
        });            
      }
      if (currentModalQuantitySelected === 0) {
        setCloseError(false);
      }
    }
  }, [currentModalPageSelected, currentModalQuantitySelected]);

  const displayShowModal = items => {
    setProductsLinkedItems(items);
    setShowModal(true);
    setCurrentModalQuantitySelected(0);
  };

  const handleLinkedItemsHide= e => {
    if (modalProductsSelected[currentTabIndex] && modalProductsSelected[currentTabIndex] > 0) {
      setCloseError(true);
    } else {
      setCloseError(false);
      setOnModalPage(false);
      setShowModal(false);
    }
  };

  const updateTabSelection = activeKey => {
    const getIndex = activeKey.split('_');
    setCurrentTabIndex(getIndex[getIndex.length-1]);
  }; 

  const productsSelected = selected => {
    setOnModalPage(true);
    setCurrentModalPageSelected(selected.pageIndex);
    setCurrentModalQuantitySelected(selected.quantity);
  };  

  const addButtonWithModal = <AddToBasketButton key="addToBasketB2BTop" showModal={displayShowModal} />;

  return (
    <>
      <ListProvider key={id}>
        <Schema products={products} />
        <OfflinePriceWarning />
        <div className={styles.panel}>
          <header className={styles.panelHeader} aria-label={headerLbl}>
            <HeaderRow
              key={id}
              sortingKey={sortingKey.toString()}
              totalCount={totalCount}
              selectedSorting={selectedSorting}
              sortingEnabled={sortingEnabled}
              relevanceAvailable={relevanceAvailable}
              hasFacets={hasFacets}
              availableFacets={availableFacets}
              viewModeSwitchEnabled={viewModeSwitchEnabled}
              selectedViewMode={selectedViewMode}
            />
            {abilities[AbilityTo.OrderProducts] && (
              <div className={styles.control}>
                {addButtonWithModal}
              </div>
            )}
          </header>
          <div className={`${gridMode ? `${styles.gridMode} ` : ''}${styles.list}`} role="list">
            {products.map((product, index) => (
              <ProductTrackingContext.Provider key={product.id} value={{ product, trackingSource }}>
                <ProductLine
                  product={product}
                  gridMode={gridMode}
                  showThumbnail={showThumbnails}
                  abilities={abilities}
                  index={index}
                />
              </ProductTrackingContext.Provider>
            ))}
          </div>
          <div className={`${styles.panelFooter} ${styles.b2BPanelFooter}`}>
            <Paging pagingType={pagingType} totalCount={totalCount} />
            {abilities[AbilityTo.OrderProducts] && (
              <div className={styles.control}>
                {addButtonWithModal}
              </div>
            )}
          </div>
          {abilities[AbilityTo.OrderProducts] && <StickyAddToBasketPanel showModal={displayShowModal} />}
          {lastViewedEnabled && <LastViewedProducts maxSlidesToShow={xl ? 4 : 3} />}
        </div>
        <SelectionLostPopup pagingType={pagingType} restoreSorting={restoreSorting} />
      </ListProvider>
      <Modal opened={showModal} hide={handleLinkedItemsHide} className="wish-list-confirmation-modal">
        <div className={styles.modalContainer}>
          <RichText textKey="LinkedItemsModalTopText" /><br />
          {productslinkedItems.length ? productslinkedItems.map((linkedItem, index) => (
            <div key={'linked_item_text_' + index}>
              {linkedItem.quantity} x {linkedItem.id}<br />
            </div>
          )) : null}
          <RichText textKey="LinkedItemsModalBottomText" />
          <Tabs name="linkedItemsTabs" resetDependency={productslinkedItems} key="linkedItemsTabs" afterChangedTab={updateTabSelection}>
            {productslinkedItems.length ? productslinkedItems.map((linkedItem, index) => (
              <Tabs.Item
                tabKey={'tab_item_key_' + linkedItem.id + '_' + index}
                title={linkedItem.id}
                id={'tab_item_' + linkedItem.id + '_' + index}
                disabled={modalProductsSelected[currentTabIndex] && modalProductsSelected[currentTabIndex] > 0 && index !== currentTabIndex}
              >
                <>
                  {closeError && (
                    <DangerAlert role="alert" scrollOnAppear>
                      <RichText textKey="LinkedItemsAlternateTabsWarning" />
                    </DangerAlert>
                  )}                
                  {modalProductsSelected[currentTabIndex] > 0 && productslinkedItems.length > 1 && (
                    <InfoAlert>
                      <RichText textKey="LinkedItemsAlternateTabsInformation" />
                    </InfoAlert>
                  )}
                  <PageModal {...productslinkedItems[index]} key={'page_' + index} pageIndex={index} productsSelected={productsSelected} />
                </>
              </Tabs.Item>
            )) : null}
          </Tabs>          
          
          <div className={styles.modalButtonsBlock} style={{ marginTop: '-12.5%' }}>
            <SanaButton
              textKey="LinkedItemsCancel"
              className={`${buttonStyles.noIcon}`}
              onClick={handleLinkedItemsHide}
            />            
          </div>
        </div>
      </Modal>        
    </>
  );
};

Page.propTypes = pagePropTypes;

export default connect(state =>
  ({ pageTitle: state.page.metaTitle }),
  { trackProductListView },
)(withPageProps(Page));