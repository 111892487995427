const printRegEx = /[?&]print=1/i;

export function printModeEnabled({ routing }) {
  const { location: stateLocation, navigatingTo } = routing;

  const location = navigatingTo && navigatingTo.location
    ? navigatingTo.location
    : stateLocation;

  return printRegEx.test(location.search);
}
