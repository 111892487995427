import styles from './RelatedProduct.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { ProductThumbnail } from 'components/primitives/product';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { SimpleText } from '../../../../../adk/webstore/texts';
import { Placeholder } from 'components/primitives/placeholders';
import { sanitizeId } from 'utils/helpers';

const RelatedProduct = ({ product: { url, routeData, image, title, id }, noImageUrl }) => (
  <WithProductClickTracking>
    {handleClick => (
      <Link onClick={handleClick} url={url} to={routeData} className={styles.tile}>
        <figure>
          <ProductThumbnail className={styles.thumbnailImg} src={image && image.medium} noImageSrc={noImageUrl} title={title} />
          <figcaption className={styles.title}>
              <div id={sanitizeId('relatedItemText' + id)} className={styles.relatedItemHeader}>
                <span className={styles.productIdName}>
                  <SimpleText
                    textKey="General_Product_Id"
                    placeholder={<Placeholder className={styles.productIdNamePlaceholder} />}
                  />
                </span>
                <span className={styles.productIdValue}>&nbsp;{id}</span>                            
              </div>
              {title}
          </figcaption>
        </figure>
      </Link>
    )}
  </WithProductClickTracking>
);

RelatedProduct.propTypes = {
  product: PropTypes.shape({
    url: PropTypes.string.isRequired,
    routeData: PropTypes.object.isRequired,
    image: PropTypes.shape({
      medium: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
  }).isRequired,
  noImageUrl: PropTypes.string,
};

export default React.memo(RelatedProduct);
