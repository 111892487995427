import React from 'react';

/**
 * Maps valid react children nodes within provided function selector.
 * @param {Array<React.ReactNode> | React.ReactNode} children array or single item of react children.
 * @param {Function} func map function.
 * @returns {Array} mapped array of react children.
 */
export function map<C extends React.ReactNode, R>(children: C | C[], func: (child: C, index: number) => R) {
  let index = 0;
  return React.Children.map<R | C, C>(children, child =>
    React.isValidElement(child) ? func(child, index++) : child);
}

/**
 * Iterates through valid react children nodes executing provided function for each node.
 * @param {Array<React.ReactNode> | React.ReactNode} children array or single item of react children.
 * @param {Function} func function to be executed for child node.
 */
export function forEach<C extends React.ReactNode>(children: C | C[], func: (child: C, index: number) => void): void {
  let index = 0;
  React.Children.forEach(children, child =>
    React.isValidElement(child) && func(child, index++));
}

/**
 * Calculates number of valid react children nodes.
 * @param {Array<React.ReactNode> | React.ReactNode} children array or single item of react children.
 * @returns {PositiveInteger} count of react children.
 */
export function length(children: React.ReactNode | React.ReactNode[]): number {
  let index = 0;
  React.Children.forEach(children, child =>
    React.isValidElement(child) && index++);

  return index;
}
