import styles from './LayoutShiftProvider.module.scss';
import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { LayoutShiftContext, ShiftTypes } from 'utils/layout';
import type { UpdateElementsAffectingShiftData } from 'utils/layout';

const contextInitialValue = {
  // actual padding value applied to the top shift element (includes topShiftBlockHeight and the height of top positioned
  // temporary fixed elements which shift layout)
  topShift: 0,
  // total height of constantly fixed elements inside top shift block
  topShiftBlockHeight: 0,
  // total height of all fixed positioned elements on the top of the screen which overlap layout in the viewport
  topFixedElementsHeight: 0,
  // actual padding value applied to the bottom shift element (includes bottomShiftBlockHeight and the height of bottom positioned
  // temporarily fixed elements which shift layout)
  bottomShift: 0,
  // total height of constantly fixed elements inside bottom shift block
  bottomShiftBlockHeight: 0,
  // total height of all fixed positioned elements on the bottom of the screen which overlap layout in the viewport
  bottomFixedElementsHeight: 0,
};

type ShiftTypePrefix = 'top' | 'bottom';
type ShiftTypeProp = 'topShift' | 'bottomShift'
type BlockHeightProp = 'topShiftBlockHeight' | 'bottomShiftBlockHeight';

type ShiftBlockHeights = {
  [ShiftTypes.TOP]: number;
  [ShiftTypes.BOTTOM]: number;
};

type UpdateShiftContextHandler = (shiftType: ShiftTypes, height: number) => void

type Props = {
  topShiftElRef: React.RefObject<HTMLElement>; // Ref object with link to element for applying top shift value.
  bottomShiftElRef: React.RefObject<HTMLElement>; // Ref object with link to element for applying bottom shift value.
  topBlockContent: React.ReactNode; // Content of top shift block element. Anything that can be rendered by React: numbers, strings, another React elements.
  bottomBlockContent: React.ReactNode; // Content of bottom shift block element. Anything that can be rendered by React: numbers, strings, another React elements.
  children: React.ReactNode; // Anything that can be rendered by React: numbers, strings, another React elements.
}

/**
  * Context provider which provides context with properties and methods for setting application layout
  * shift depending on presence of elements with position fixed (such as cookiebar, sticky header etc.).
  * Returns top shift block, children and bottom shift block wrapped up with the layout shift context provider.
*/

const LayoutShiftProvider = ({
  topShiftElRef,
  bottomShiftElRef,
  topBlockContent,
  bottomBlockContent,
  children,
}: Props) => {
  const topShiftBlockRef = useRef<HTMLDivElement>(null);
  const bottomShiftBlockRef = useRef<HTMLDivElement>(null);
  const prevShiftBlockHeights = usePrevShiftHeights();

  const elementsAffectingShiftDataManager = useElementsAffectingShiftDataManager();

  const updateShiftContext: UpdateShiftContextHandler = useCallback((shiftType, height) => {
    const canApplyShift = checkIfShiftCanBeApplied(topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);
    if (!canApplyShift)
      return;

    const shiftData = getShiftData(shiftType, topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);

    setContextValue(prevValues => {
      if (!elementsAffectingShiftDataManager || !shiftData.shiftElementStyleObj)
        return prevValues;

      const shiftValue = height + elementsAffectingShiftDataManager.getShiftHeight(shiftType);
      prevShiftBlockHeights[shiftType] = height;

      const shiftTypeProp = shiftData.shiftTypePrefix + 'Shift' as ShiftTypeProp;
      const shiftStyleProp = getStyleProp(shiftData.shiftTypePrefix);
      shiftData.shiftElementStyleObj[shiftStyleProp] = shiftValue + 'px';

      return {
        ...prevValues,
        [shiftTypeProp]: shiftValue,
        [shiftData.shiftTypePrefix + 'ShiftBlockHeight']: shiftData.shiftBlockHeight,
        [shiftData.shiftTypePrefix + 'FixedElementsHeight']: shiftData.shiftBlockHeight + elementsAffectingShiftDataManager.getFixedElementsHeight(shiftType),
      };
    });
  }, []);

  const updateElementsAffectingShiftData = useCallback<UpdateElementsAffectingShiftData>(
    (shiftType, elementName, height, shouldShift = false, isFixed = true) => {
      const canApplyShift = checkIfShiftCanBeApplied(topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);
      if (!canApplyShift)
        return;

      if (height) {
        elementsAffectingShiftDataManager.setData(shiftType, elementName, height, shouldShift, isFixed);
      } else {
        if (elementsAffectingShiftDataManager.hasData(shiftType, elementName)) {
          elementsAffectingShiftDataManager.removeData(shiftType, elementName);
        } else {
          return;
        }
      }

      const shiftData = getShiftData(shiftType, topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);

      setContextValue(prevValues => {
        if (!shiftData.shiftElementStyleObj)
          return prevValues;

        const shiftValue = prevValues[shiftData.shiftTypePrefix + 'ShiftBlockHeight' as BlockHeightProp] + elementsAffectingShiftDataManager.getShiftHeight(shiftType);
        const shiftStyleProp = getStyleProp(shiftData.shiftTypePrefix);
        shiftData.shiftElementStyleObj[shiftStyleProp] = shiftValue + 'px';

        return {
          ...prevValues,
          [shiftData.shiftTypePrefix + 'Shift']: shiftValue,
          [shiftData.shiftTypePrefix + 'FixedElementsHeight']: shiftData.shiftBlockHeight + elementsAffectingShiftDataManager.getFixedElementsHeight(shiftType),
        };
      });
    }, []);

  const [contextValue, setContextValue] = useState(() => ({ ...contextInitialValue, updateElementsAffectingShiftData }));

  const handleTopBlockResize = useCallback((_width?: number, height?: number) => {
    handleResize(ShiftTypes.TOP, Math.ceil(height!), prevShiftBlockHeights[ShiftTypes.TOP], updateShiftContext);
  }, []);

  const handleBottomBlockResize = useCallback((_width?: number, height?: number) => {
    handleResize(ShiftTypes.BOTTOM, Math.ceil(height!), prevShiftBlockHeights[ShiftTypes.BOTTOM], updateShiftContext);
  }, []);

  return (
    <LayoutShiftContext.Provider value={contextValue}>
      <div className={styles.topShiftBlock} ref={topShiftBlockRef}>
        <ReactResizeDetector handleHeight onResize={handleTopBlockResize} />
        {topBlockContent}
      </div>
      {children}
      <div className={styles.bottomShiftBlock} ref={bottomShiftBlockRef}>
        <ReactResizeDetector handleHeight onResize={handleBottomBlockResize} />
        {bottomBlockContent}
      </div>
    </LayoutShiftContext.Provider>
  );
};

LayoutShiftProvider.propTypes = {
  topShiftElRef: PropTypes.object,
  bottomShiftElRef: PropTypes.object,
  topBlockContent: PropTypes.node,
  bottomBlockContent: PropTypes.node,
  children: PropTypes.node,
};

export default LayoutShiftProvider;

type ElementsAffectingShiftDataManager = {
  getShiftHeight: (shiftType: ShiftTypes) => number;
  getFixedElementsHeight: (shiftType: ShiftTypes) => number;
  setData: (shiftType: ShiftTypes, name: string, height: number, shouldShift: boolean, isFixed: boolean) => void;
  hasData: (shiftType: ShiftTypes, name: string) => boolean;
  removeData: (shiftType: ShiftTypes, name: string) => void;
}

type ShiftElementsData = Map<string, { height: number; shouldShift: boolean; isFixed: boolean }>

function useElementsAffectingShiftDataManager(): ElementsAffectingShiftDataManager {
  const dataManagerRef = useRef<ElementsAffectingShiftDataManager>();

  if (dataManagerRef.current)
    return dataManagerRef.current;

  const data = {
    [ShiftTypes.TOP]: {
      elementsData: new Map() as ShiftElementsData,
      shiftHeight: 0,
      fixedElementsHeight: 0,
    },
    [ShiftTypes.BOTTOM]: {
      elementsData: new Map() as ShiftElementsData,
      shiftHeight: 0,
      fixedElementsHeight: 0,
    },
  };
  const calcHeights = (shiftType: ShiftTypes) => {
    const shiftTypeData = data[shiftType];
    shiftTypeData.shiftHeight = 0;
    shiftTypeData.fixedElementsHeight = 0;

    for (const [, { height, shouldShift, isFixed }] of shiftTypeData.elementsData) {
      if (shouldShift)
        shiftTypeData.shiftHeight += height;

      if (isFixed)
        shiftTypeData.fixedElementsHeight += height;
    }
  };

  dataManagerRef.current = {
    getShiftHeight(shiftType) {
      return data[shiftType].shiftHeight;
    },
    getFixedElementsHeight(shiftType) {
      return data[shiftType].fixedElementsHeight;
    },
    setData(shiftType, name, height, shouldShift, isFixed) {
      data[shiftType].elementsData.set(name, { height, shouldShift, isFixed });
      calcHeights(shiftType);
    },
    hasData(shiftType, name) {
      return data[shiftType].elementsData.has(name);
    },
    removeData(shiftType, name) {
      data[shiftType].elementsData.delete(name);
      calcHeights(shiftType);
    },
  };

  return dataManagerRef.current;
}

function getStyleProp(shiftTypePrefix: ShiftTypePrefix) {
  return shiftTypePrefix === 'top' ? 'paddingTop' : 'paddingBottom';
}

function handleResize(shiftType: ShiftTypes, value: number, prevValue: number, updateShiftContext: UpdateShiftContextHandler) {
  if (value === prevValue)
    return;

  updateShiftContext(shiftType, value);
}

function checkIfShiftCanBeApplied(
  topShiftElRef: React.RefObject<HTMLElement>,
  bottomShiftElRef: React.RefObject<HTMLElement>,
  topShiftBlockRef: React.RefObject<HTMLDivElement>,
  bottomShiftBlockRef: React.RefObject<HTMLDivElement>,
) {
  return !!(topShiftElRef.current && bottomShiftElRef.current && topShiftBlockRef.current && bottomShiftBlockRef.current);
}

type ShiftData = {
  shiftTypePrefix: ShiftTypePrefix;
  shiftElementStyleObj: CSSStyleDeclaration | undefined;
  shiftBlockHeight: number;
}

function getShiftData(
  shiftType: ShiftTypes,
  topShiftElRef: React.RefObject<HTMLElement>,
  bottomShiftElRef: React.RefObject<HTMLElement>,
  topShiftBlockRef: React.RefObject<HTMLDivElement>,
  bottomShiftBlockRef: React.RefObject<HTMLDivElement>,
): ShiftData {
  const isTopShift = shiftType === ShiftTypes.TOP;
  const shiftTypePrefix = isTopShift ? 'top' : 'bottom';

  const shiftElRef = isTopShift ? topShiftElRef : bottomShiftElRef;
  let shiftElementStyleObj: CSSStyleDeclaration | undefined;
  if (shiftElRef.current)
    shiftElementStyleObj = shiftElRef.current.style;

  const shiftBlockRef = isTopShift ? topShiftBlockRef : bottomShiftBlockRef;
  let shiftBlockHeight = 0;
  if (shiftBlockRef.current)
    shiftBlockHeight = shiftBlockRef.current.offsetHeight;

  return { shiftTypePrefix, shiftElementStyleObj, shiftBlockHeight };
}

function usePrevShiftHeights() {
  const prevShiftBlockHeights = useRef<ShiftBlockHeights>();

  if (!prevShiftBlockHeights.current)
    prevShiftBlockHeights.current = {
      [ShiftTypes.TOP]: contextInitialValue.topShiftBlockHeight,
      [ShiftTypes.BOTTOM]: contextInitialValue.bottomShiftBlockHeight,
    };

  return prevShiftBlockHeights.current as ShiftBlockHeights;
}
