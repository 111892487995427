import styles from 'components/objects/forms/SanaForm.module.scss';
import loginStyles from './Login.module.scss';
import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaForm, FormGroup, FieldLabel } from 'components/objects/forms';
import { EmailAddressField, PasswordField, CheckboxField } from 'components/objects/forms/fields';
import { login, resetLoginFailed } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import LoginFailedMessage from './LoginFailedMessage';
import { useHandlerLockerInPreview } from 'components/objects/preview';

export const LOGIN_FORM_NAME = 'Login';

const LoginForm = ({ showRememberMe, login, resetLoginFailed }) => {
  const onSubmit = useHandlerLockerInPreview(useCallback(formData => {
    resetLoginFailed();
    login(formData);
  }, []));

  const formikRef = useRef();

  const handleForgotPasswordClick = (_e, routeData) => {
    routeData.options.email = formikRef.current.values.email;
  };

  useEffect(() => () => resetLoginFailed(), []);

  const [emailTitle, passwordTitle, rememberMeTitle] = useSimpleTexts([
    'EmailAddress',
    'Password',
    'Login_RememberMe',
  ]).texts;

  return (
    <SanaForm name={LOGIN_FORM_NAME} onSubmit={onSubmit} formikRef={formikRef} method="post">
      <LoginFailedMessage onResetPasswordClick={handleForgotPasswordClick} />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
        }}
      />
      <FormGroup
        fieldName="password"
        fieldTitle={passwordTitle}
        fieldComponent={PasswordField}
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
        }}
      />
      <div className={`${styles.formRow} ${loginStyles.rowActions} ${styles.noOffset}`}>
        <div className={loginStyles.forgotPassword}>
          <Link
            to={routesBuilder.forForgotPassword}
            options={{ email: '' }}
            onClick={handleForgotPasswordClick}
            data-prevent-validation
          >
            <SimpleText textKey="ForgotPassword" />
          </Link>
        </div>
        {showRememberMe && (
          <span className={loginStyles.rememberMe}>
            <CheckboxField fieldName="persistent" />
            <FieldLabel fieldName="persistent">
              {rememberMeTitle}
            </FieldLabel>
          </span>
        )}
        <SanaButton textKey="Login" type="submit" className={`${styles.btn} ${loginStyles.submitBtn}`} />
      </div>
    </SanaForm>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  showRememberMe: PropTypes.bool,
  resetLoginFailed: PropTypes.func.isRequired,
};

export default connect(({ settings: { profile } }) => ({ showRememberMe: profile && profile.showRememberMe }), { login, resetLoginFailed })(
  LoginForm,
);
