import React from 'react';
import PropTypes from 'prop-types';
import { encodeSpacesInUrl } from 'utils/url';
import { isIndexed } from 'routes';
import RouteLink from './RouteLink';
import NavigationLink from './NavigationLink';
import { toPropTypes } from './helpers';

const Link = React.forwardRef(({
  to,
  url,
  omitScroll,
  replaceHistory,
  options,
  onClick,
  children,
  rel,
  target,
  ...attributes
}, ref) => {
  const createLink = (href, onLinkClick) => {
    let relValue = rel;
    if (relValue === undefined && to)
      relValue = isIndexed(to.routeName) ? null : 'nofollow';

    return (
      <a
        href={encodeSpacesInUrl(href)}
        data-broken={href ? null : 'true'}
        onClick={onLinkClick}
        ref={ref}
        rel={relValue}
        target={target || null}
        {...attributes}
      >
        {children}
      </a>
    );
  };

  if (!url && to)
    return (
      <RouteLink
        to={to}
        omitScroll={omitScroll}
        replaceHistory={replaceHistory}
        options={options}
        onClick={onClick}
        createLink={createLink}
        disabled={attributes.disabled}
      />
    );

  return (
    <NavigationLink
      url={url}
      to={to}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      onClick={onClick}
      createLink={createLink}
      disabled={attributes.disabled}
    />
  );
});

Link.propTypes = {
  url: PropTypes.string,
  to: toPropTypes,
  omitScroll: PropTypes.bool,
  replaceHistory: PropTypes.bool,
  options: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default React.memo(Link);