import styles from '../Details.module.scss';
import React from 'react';
import { useProductContext } from '../hooks';
import VariantDropdownRow from './VariantDropdownRow';
import { useQueryString } from 'utils/hooks';
import useVariantDropdownsControl from './useVariantDropdownsControl';

const VariantDropdowns = () => {
  const queryString = useQueryString(true);
  const queryVariantId = queryString && queryString.values.variantId;
  const { product, updateContext } = useProductContext();

  const [
    filteredVariantComponentGroups,
    selection,
    onSelectionChange,
  ] = useVariantDropdownsControl(
    product.variantComponentGroups,
    (variantId, variantComponents) => updateContext({ variantId, variantComponents }),
    product.id,
    queryVariantId,
  );

  const shouldRender = product
    && product.hasVariants
    && product.variantComponentGroups
    && !!product.variantComponentGroups.length;

  if (!shouldRender)
    return null;

  return (
    <fieldset className={styles.variantDropdowns}>
      {filteredVariantComponentGroups.map(({ id: groupId, components, name: groupName }) => {
        const selectedComponent = selection[groupId];
        const value = selectedComponent.id;

        return (
          <VariantDropdownRow
            id={groupId}
            key={groupId}
            groupName={groupName}
            variantComponents={components}
            onChange={value => onSelectionChange({ ...selection, [groupId]: components.find(c => c.id === value) })}
            value={value}
          />
        );
      })}
    </fieldset>
  );
};

export default React.memo(VariantDropdowns);
