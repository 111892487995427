import styles from './InvoicePayment.module.scss';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { DangerAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';
import InvoicePaymentFormsContainer from './InvoicePaymentFormsContainer';

const InvoicePayment = ({
  order,
  paymentMethods,
  additionalCustomerData,
  extraPaymentData,
  submitInvoiceOrderResult,
}) => {
  const invoicePaymentInfo = useMemo(() => ({
    order,
    paymentMethods,
    additionalCustomerData,
    extraPaymentData,
  }), [order, paymentMethods, additionalCustomerData, extraPaymentData]);

  return (
    <Row>
      <Col xs={12} md={9} lg={8}>
        {submitInvoiceOrderResult?.error && (
          <DangerAlert role="alert" scrollOnAppear>
            <RichText textKey="YouCannotPayInvoicesNow" />
          </DangerAlert>
        )}
        <InvoicePaymentFormsContainer invoicePaymentInfo={invoicePaymentInfo} />
      </Col>
      <Col xs={12} md={3} lg={4} className={styles.customText}>
        <RichText textKey="PaymentAndServicesInfo" withPlaceholder />
      </Col>
    </Row>
  );
};

InvoicePayment.propTypes = {
  order: PropTypes.object.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  additionalCustomerData: PropTypes.object,
  extraPaymentData: PropTypes.object,
  submitInvoiceOrderResult: PropTypes.shape({
    error: PropTypes.bool,
  }),
};

const Memoized = React.memo(InvoicePayment);
Memoized.selectPropsFromPage = ({
  order,
  paymentMethods,
  additionalCustomerData,
  extraPaymentData,
  submitInvoiceOrderResult,
}) => ({
  order,
  paymentMethods,
  additionalCustomerData,
  extraPaymentData,
  submitInvoiceOrderResult,
});
export default Memoized;
