import { combineEpics } from 'redux-observable';

import * as libraries from '../../../adk/webstore/_libraries.json';
import * as elements from '../../../adk/webstore/elements';
import * as forms from '../../../adk/webstore/forms';
import * as events from '../../../adk/webstore/events';
import * as routes from '../../../adk/webstore/routes';
import * as utils from '../../../adk/webstore/utils';
import * as constants from '../../../adk/webstore/constants';
import { useAppContext, getAppContext } from './context';
import texts from './texts/proxy';
import createAddonRegistry from './registry';
import addonCreatorFactory from './creator';
import createReducer from './reducer';
import createAddonEpic from './epic';
import preloadAddons from './preload';
import createMiddleware from './middleware';

import metadataEpic from './metadata/epic';

export function createAddonsContainer(addonLoader, epicServices) {
  const registry = createAddonRegistry();
  const reducer = createReducer(registry);
  const epicDepsFactory = (addonId, state$) => createAddonEpicServices(addonId, state$, epicServices);
  const sharedModules = { elements, forms, events, routes, utils, texts, constants, context: { useAppContext } };
  const epic = combineEpics(
    createEpic(registry, addonLoader, libraries, epicDepsFactory, sharedModules),
    metadataEpic,
  );
  const middleware = createMiddleware();
  const preload = (store, addons) => preloadAddons(store, registry, epic, addons);
  return { registry, reducer, epic, middleware, preload };
}

function createEpic(registry, loader, sharedLibraries, sharedServices, sharedModules) {
  const creator = addonCreatorFactory(sharedLibraries, sharedModules);
  return createAddonEpic(registry, loader, creator, sharedServices);
}

function createAddonEpicServices(_addonId, state$, epicServices) {
  const {
    api: { graphApi, fetch, trackObservable },
  } = epicServices;
  
  return {
    api: { graphApi, fetch, trackObservable },
    appContext: {
      getCurrent: () => getAppContext(state$.value),
    },
  };
}
