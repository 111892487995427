import styles from '../page/PageTemplate.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import { Facets, FacetsProvider, queryFacetUrlBuilder as facetUrlBuilder } from '../facets';
import { useIsMobileViewport } from 'utils/hooks';
import PageB2B from '../b2b/Page';
import PageB2C from '../b2c/Page';
import CalculatedFieldsLoader from '../page/CalculatedFieldsLoader';
import HeaderContent from './HeaderContent';
import { searchPageId } from '../constants';

const presets = {
  'SearchB2B': PageB2B,
  'Search': PageB2C,
};

const PageTemplate = ({ page }) => {
  const isMobile = useIsMobileViewport();
  const { facets, preset } = page;

  const Page = preset in presets
    ? presets[preset]
    : PageB2C;

  if (!Page.selectProps)
    throw new Error(`Product list page component ${Page.displayName} should implement selectProps method to map page state to props.`);

  const content = (
    <Container className={`${styles.plp} ${!page.products.length ? styles.hideLeftColumn : ''}`}>
      <Row>
        <Col xs={12} md={9} id={searchPageId} className={styles.productList}>
          <CalculatedFieldsLoader products={page.products}>
            <Page {...Page.selectProps(page)} />
          </CalculatedFieldsLoader>
        </Col>
        {!isMobile &&
          <Col md={{ size: 3, order: 'first' }} className={styles.leftColumn}>
            <Facets />
          </Col>
        }
      </Row>
    </Container>
  );
  const renderFacets = !!facets && facets.facets.length > 0;

  return (
    <>
      {renderFacets
        ? <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}><HeaderContent />{content}</FacetsProvider>
        : <><HeaderContent />{content}</>
      }
    </>
  );
};

PageTemplate.propTypes = {
  page: PropTypes.shape({
    facets: PropTypes.object,
    preset: PropTypes.string,
  }).isRequired,
};

export default PageTemplate;
