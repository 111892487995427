import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import FormGroupTemplate from '../../formGroups/FormGroupTemplate';
import { createLabelId } from '../../helpers';

const ReadOnlyFormGroup = ({ label, field, className }) => {
  const formik = useFormikContext();
  const { fieldName } = field.props;
  const labelId = createLabelId(formik.status.formName, fieldName);

  return (
    <FormGroupTemplate
      label={label}
      field={field}
      aria-labelledby={labelId}
      className={className}
    />
  );
};

ReadOnlyFormGroup.propTypes = {
  label: PropTypes.element.isRequired,
  field: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default ReadOnlyFormGroup;
