import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'components/objects/forms';
import { TextBoxField, EmailAddressField, CheckboxField } from 'components/objects/forms/fields';
import { useSimpleTexts } from 'components/sanaText';

const SubAccountFields = ({ 
  orderProducts,
  showPrices,
  showStock,
  viewOrders,
  viewInvoices,
  viewShipments,
  viewReturnReceipts,
  viewCreditMemos,
  viewQuotes,
  viewReturnOrders,
  viewStatements}) => {
  const [
    nameTitle,
    emailTitle,
    activeTitle,
    canAuthorizeOrdersTitle,
    canSeeOrdersTitle,
    canSeeInvoicesTitle,
    canSeeShipmentsTitle,
    canSeeReturnReceiptsTitle,
    canSeeCreditMemosTitle,
    canSeeQuotesTitle,
    canSeePricesTitle,
    canSeeReturnOrdersTitle,
    canSeeStockTitle,
    canOrderTitle,
    canSeeStatementsTitle,
  ] = useSimpleTexts([
    'Name',
    'EmailAddress',
    'Active',
    'MayAuthorizeOrders',
    'MayViewOrders',
    'MayViewInvoices',
    'MayViewShipments',
    'MayViewReturnReceipts',
    'MayViewCreditMemos',
    'MayViewQuotes',
    showPrices ? 'MaySeePrices' : '',
    'MayViewReturnOrders',
    showStock ? 'MaySeeStock' : '',
    orderProducts ? 'MayOrder' : '',
    'MayViewStatements',
  ]).texts;

  return (
    <>
      <FormGroup
        fieldName="name"
        fieldTitle={nameTitle}
        fieldComponent={TextBoxField}
        maxLength={50}
        required
        validation={{
          required: true,
        }}
      />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
          uniqueEmail: true,
        }}
      />
      <FormGroup
        fieldName="active"
        fieldTitle={activeTitle}
        fieldComponent={CheckboxField}
      />
      {showPrices && (
        <FormGroup
          fieldName="canSeePrices"
          fieldTitle={canSeePricesTitle}
          fieldComponent={CheckboxField}
        />
      )}
      {showStock && (
        <FormGroup
          fieldName="canSeeStock"
          fieldTitle={canSeeStockTitle}
          fieldComponent={CheckboxField}
        />
      )}
      {viewOrders && (
        <FormGroup
          fieldName="canSeeOrders"
          fieldTitle={canSeeOrdersTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewInvoices && (
        <FormGroup
          fieldName="canSeeInvoices"
          fieldTitle={canSeeInvoicesTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewShipments && (
        <FormGroup
          fieldName="canSeeShipments"
          fieldTitle={canSeeShipmentsTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewReturnReceipts && (
        <FormGroup
          fieldName="canSeeReturnReceipts"
          fieldTitle={canSeeReturnReceiptsTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewCreditMemos && (
        <FormGroup
          fieldName="canSeeCreditMemos"
          fieldTitle={canSeeCreditMemosTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewQuotes && (
        <FormGroup
          fieldName="canSeeQuotes"
          fieldTitle={canSeeQuotesTitle}
          fieldComponent={CheckboxField} />
      )}
      {viewReturnOrders && (
        <FormGroup
          fieldName="canSeeReturnOrders"
          fieldTitle={canSeeReturnOrdersTitle}
          fieldComponent={CheckboxField} />
      )}
      <FormGroup
        fieldName="canSeeStatements"
        fieldTitle={canSeeStatementsTitle}
        fieldComponent={CheckboxField} />
      {orderProducts && (
        <FormGroup
          fieldName="canOrder"
          fieldTitle={canOrderTitle}
          fieldComponent={CheckboxField}
        />
      )}
      <FormGroup
        fieldName="canAuthorizeOrders"
        fieldTitle={canAuthorizeOrdersTitle}
        fieldComponent={CheckboxField}
      />
    </>
  );
};

SubAccountFields.propTypes = {
  orderProducts: PropTypes.bool,
  showPrices: PropTypes.bool,
  showStock: PropTypes.bool,
  viewOrders: PropTypes.bool,
  viewInvoices: PropTypes.bool,
  viewShipments: PropTypes.bool,
  viewReturnReceipts: PropTypes.bool,
  viewCreditMemos: PropTypes.bool,
  viewQuotes: PropTypes.bool,
  viewReturnOrders: PropTypes.bool,
  viewStatements: PropTypes.bool,
};

export default React.memo(SubAccountFields);