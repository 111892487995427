import React from 'react';
import { Selector } from 'components/objects/productComparison';
import { useProductContext } from './hooks';

const ProductComparisonSelector = props => {
  const { product } = useProductContext();

  if (!product)
    return null;

  return (
    <Selector product={product} {...props} />
  );
};

export default React.memo(ProductComparisonSelector);