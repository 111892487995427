export const ROUTES_REQUESTED = 'ROUTES_REQUESTED';
export const loadRoutes = routes => ({
  type: ROUTES_REQUESTED,
  payload: routes,
});

export const ROUTES_LOADED = 'ROUTES_LOADED';
export const routesLoaded = routes => ({
  type: ROUTES_LOADED,
  payload: routes,
});
