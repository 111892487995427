import { PageComponentNames } from 'behavior/pages';
import { routeNames } from 'routes';
import { decapitalize } from 'utils/helpers';
import { loadSystemPageQuery } from '../system/queries';
import { pluckAsPage, getBackTo, initComponent } from '../helpers';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { tap, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType } from 'behavior/settings';

const loginPageName = decapitalize(routeNames.Login);

export default (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(settings => settings.loaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreLoginPage(routeData, state$)
        : loadSystemLoginPage(routeData, state$, api),
    ),
  );
};

function loadClosedStoreLoginPage(routeData, state$) {
  return of({
    emptyLayout: true,
    backTo: getBackTo(state$, [
      routeNames.Login,
      routeNames.Registration,
      routeNames.ResetPassword,
      routeNames.ForgotPassword,
    ], routeData.params && routeData.params.language),
  }).pipe(
    initComponent(PageComponentNames.ClosedStoreLogin),
  );
}

function loadSystemLoginPage(routeData, state$, api) {
  return api.graphApi(loadSystemPageQuery(loginPageName)).pipe(
    pluckAsPage(loginPageName, PageComponentNames.Login),
    tap(({ page }) => {
      if (page) {
        page.backTo = routeData.options && routeData.options.backTo || getBackTo(state$, [
          routeNames.Login,
          routeNames.Registration,
        ], routeData.params && routeData.params.language);

        const home = {
          url: '/Home',
          routeData: {
            ...routeData,
            routeName: routeNames.Home,
          },
        };
        page.backTo = 
          page.backTo?.routeData?.routeName === routeNames.ForgotPassword ||
          page.backTo?.routeData?.routeName === routeNames.ResetPassword ? 
            home : page.backTo;
      }
    }),
    initSystemPageContent(),
  );
}