import styles from './Containers.module.scss';
import 'css/Animations.module.scss';
import React, { useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import createColumn from './createColumn';
import MediaBackground from 'components/primitives/grid/MediaBackground';
import { useCssAnimation } from './hooks';
import { AnimationsMap } from './constants';
import { VerticalAlignmentsMap, NoBorderStyleValue } from './constants';
import { Container, Row } from 'components/primitives/grid';
import { useResponsiveBreakpoints, useLayoutShifter } from 'utils/layout';
import { getMinHeight } from './helpers';
import { ScrollDownIcon } from 'components/primitives/buttons';

const ContentBlockRow = ({
  columns,
  fullWidth,
  background,
  border,
  spacing,
  attributes,
  rowAnimation,
  verticalAlignment,
  minHeight,
  heroEffect,
}) => {
  const { id, className } = attributes;
  const showBorder = border.style !== NoBorderStyleValue && !!border.width;
  const wrapperRef = useRef(null);
  const rowRef = useRef(null);

  const wrapperStyles = {
    padding: showBorder && border.width || null,
    margin: spacing.margin || null,
  };
  const animationClassName = useCssAnimation(wrapperRef, rowAnimation);

  const { xs, sm, md } = useResponsiveBreakpoints();
  const actualMinHeight = useMemo(() => getMinHeight(minHeight, xs, sm, md), [xs, sm, md, minHeight]);

  const rowStyles = {
    padding: spacing.padding || null,
    minHeight: actualMinHeight,
  };

  const showBackground = showBorder
    || background.color
    || background.mobileImage
    || background.desktopImage
    || background.video;

  const { topShiftBlockHeight, topFixedElementsHeight, bottomShiftBlockHeight, bottomFixedElementsHeight } = useLayoutShifter();
  const shiftOffset = sm || md ? topFixedElementsHeight + bottomFixedElementsHeight : topShiftBlockHeight + bottomShiftBlockHeight;

  useEffect(() => {
    if (rowRef.current)
      rowRef.current.style.minHeight = !!heroEffect ? `calc(100vh - ${shiftOffset}px)` : actualMinHeight;
  }, [shiftOffset, heroEffect, actualMinHeight]);

  return (
    <div
      ref={wrapperRef}
      id={id || null}
      className={`${styles.wrapper} ${animationClassName} ${className || ''}`}
      style={wrapperStyles}
    >
      {showBackground &&
        <MediaBackground
          {...background}
          fullWidth={!!heroEffect || background.fullWidth}
          borderWidth={border.width}
          borderStyle={border.style}
          borderColor={border.color}
          cornerRadius={border.radius}
          imageLoadVisibleByDefault={!!AnimationsMap[rowAnimation]}
          mutedSound={heroEffect ? heroEffect.mutedSound : true}
          isMobile={xs}
          imageEffect={heroEffect?.imageEffect}
        />
      }
      <Container className={styles.contentBox} fluid={fullWidth}>
        <Row
          ref={rowRef}
          className={`${styles[VerticalAlignmentsMap[verticalAlignment]]}`}
          data-hero-effect={!!heroEffect || null}
          style={rowStyles}
          noGutters={spacing.hideSpaces}
        >
          {renderColumns(columns, fullWidth)}
        </Row>
        {heroEffect?.showScrolldownIcon && <ScrollDownIcon aimRef={wrapperRef} />}
      </Container>
    </div>
  );
};

ContentBlockRow.propTypes = {
  columns: PropTypes.array,
  fullWidth: PropTypes.bool,
  background: PropTypes.shape({
    fullWidth: PropTypes.bool,
    desktopImage: PropTypes.string,
    mobileImage: PropTypes.string,
    imageAltText: PropTypes.string,
    video: PropTypes.string,
    color: PropTypes.string,
    hideImageOnMobile: PropTypes.bool,
  }).isRequired,
  border: PropTypes.shape({
    width: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
  }).isRequired,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
    noGutters: PropTypes.bool,
  }),
  attributes: PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  rowAnimation: PropTypes.string,
  verticalAlignment: PropTypes.string,
  minHeight: PropTypes.shape({
    mobile: PropTypes.string,
    tablet: PropTypes.string,
    desktop: PropTypes.string,
  }).isRequired,
  heroEffect: PropTypes.shape({
    mutedSound: PropTypes.bool,
    showScrolldownIcon: PropTypes.bool,
    imageEffect: PropTypes.string,
  }),
};

export default React.memo(ContentBlockRow);

function renderColumns(columns, fullWidthRow) {
  if (!columns || !columns.length)
    return null;

  return columns.map((column, index) => createColumn(column, index, fullWidthRow));
}