import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { BooleanDropdownField as BooleanDropdownFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const BooleanDropdownField = hoistStatics(({ fieldName, fieldTitle, validation, required, labelId }) => (
  <BooleanDropdownFieldCore
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    required={required}
    labelId={labelId}
  />
), BooleanDropdownFieldCore);

BooleanDropdownField.propTypes = {
  ...BooleanDropdownFieldCore.propTypes,
  validation: validationFieldPropType,
  required: PropTypes.bool,
  labelId: PropTypes.string,
};

export default BooleanDropdownField;
