import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';
import { toPropTypes } from './helpers';

const LinkButton = ({ url, to, children, title, className = '', ...attributes }) => (
  <Link url={url} to={to} className={`${btnStyles.btn} ${className}`} title={title} {...attributes}>
    <span className="btn-cnt">{children}</span>
  </Link>
);

LinkButton.propTypes = {
  url: PropTypes.string,
  to: toPropTypes,
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default React.memo(LinkButton);