import { NAVIGATED } from 'behavior/routing';
import { SHOW_ERROR_TOAST, CLOSE_ERROR_TOAST } from './actions';
import { APP_INIT_HYDRATE } from 'behavior/app';
import { errorToasts } from './constants';

export const initialState = {
  toast: null,
};

export default function errorsReducer(state = initialState, action) {
  if (action.type === SHOW_ERROR_TOAST)
    return { toast: action.payload };
  if (action.type === CLOSE_ERROR_TOAST)
    return initialState;
  else if (action.type === APP_INIT_HYDRATE && state.toast)
    return { toast: { type: errorToasts.Reload } };
  else if (state.initial && action.type === NAVIGATED) {
    const newState = { ...state };
    delete newState.initial;
    return newState;
  }
  return state;
}
