import { memoize } from './helpers';
import { arrayToObject } from 'utils/helpers';

const builders = {
  language: memoize(state => {
    const { id, cultureName } = state.localization.currentLanguage;
    return { id, cultureName };
  }),
  user: memoize(state => {
    const { isAuthenticated, currencyId } = state.user;
    return { isAuthenticated, currencyId };
  }),
};

const getSection = (state, section) => {
  const builder = builders[section];
  return builder ? builder(state) : undefined;
};

const allSections = Object.keys(builders);

export const makeAppContext = (state, sections = undefined) => {
  return arrayToObject(sections || allSections, s => s, s => getSection(state, s));
};