(function () {
  let compare;

  try {
    compare = new Intl.Collator(undefined, { sensitivity: 'accent' }).compare;
  } catch {
    compare = (a, b) => {
      if (a == null && b == null)
        return 0;
      if (a == null || b == null)
        return false;

      return a.toUpperCase() === b.toUpperCase();
    };
  }

  //eslint-disable-next-line no-extend-native
  String.prototype.iEquals = function iEquals(otherString) {
    return compare(this, otherString) === 0;
  };
})();

// Polyfills for browser environment
(function () {
  if (typeof window === 'undefined')
    return;

  // Element matches and closest polyfills
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (selector) {
      let el = this;

      do {
        if (Element.prototype.matches.call(el, selector))
          return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }

  // IE11 fix, support forEach for NodeList
  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  // IE11 fix, support removing of nodes
  // https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('remove')) {
        return;
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          this.parentNode.removeChild(this);
        },
      });
    });
  })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

  // IE11 polyfill for :scope support within the selector methods Element#querySelector, Element#querySelectorAll,
  // Element#matches, and Element#closest. Based on https://github.com/jonathantneal/element-qsa-scope
  (function () {
    try {
      document.querySelector(':scope *');
    } catch (error) {
      (function (ElementPrototype) {
        ['querySelector', 'querySelectorAll', 'matches', 'closest'].forEach(fn => {
          const originalFn = ElementPrototype[fn];
          ElementPrototype[fn] = function (...args) {
            return scopePolyfill.call(this, originalFn, ...args);
          };
        });

        function scopePolyfill(originalFn, ...args) {
          const scopeRegEx = /:scope(?![\w-])/gi;
          const [selectors] = args;
          const hasScope = selectors && scopeRegEx.test(selectors);

          if (hasScope) {
            const fallbackAttr = 'q' + Math.floor(Math.random() * 9000000) + 1000000;
            args[0] = selectors.replace(scopeRegEx, '[' + fallbackAttr + ']');
            this.setAttribute(fallbackAttr, '');
            const result = originalFn.apply(this, args);
            this.removeAttribute(fallbackAttr);
            return result;
          } else {
            return originalFn.apply(this, args);
          }
        }
      })(Element.prototype);
    }
  })();
})();