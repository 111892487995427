import styles from './DocBasedReturnOrder.module.scss';
import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { AbilityTo } from 'behavior/user/constants';
import { SimpleText, useSimpleTexts } from 'components/sanaText';
import { useHasAbilities } from 'components/objects/user';
import { Checkbox } from 'components/primitives/form';
import { Table } from 'components/primitives/table';
import { FormattedPrice } from 'components/primitives/price';
import { UomTitle } from 'components/primitives/product';
import { Link } from 'components/primitives/links';
import { getFormatNumber } from 'utils/format';
import ReturnOrderSublinesFieldArray from './ReturnOrderSublinesFieldArray';
import { routesBuilder } from 'routes';

const ReturnOrderLines = React.memo(({ name, replace }) => {
  const { invoice, returnReasons, currency, culture, salesAgreementId } = useSelector(({ page, localization }) => ({
    invoice: page.invoice,
    returnReasons: page.returnReasons.forOrderLines,
    currency: page.invoice.currency,
    culture: localization.currentLanguage.cultureName,
    salesAgreementId: page.invoice.salesAgreementId,
  }), shallowEqual);
  const [unitOfMeasureTitle, selectAllText, deselectAllText, selectText, deselectText, productsToReturnTitle] = useSimpleTexts([
    'UnitOfMeasure', 'SelectAll','DeselectAll', 'Select', 'Deselect', 'ReturnRequest_LineTitle',
  ]).texts;
  const [canViewPrices, canViewUom] = useHasAbilities(AbilityTo.ViewPrices, AbilityTo.ViewUnitOfMeasure);
  const orderLineReturnReasons = useMemo(() => returnReasons.map(({ id, name }) => ({ value: id, name })), [returnReasons]);
  const formatNumber = getFormatNumber(culture);
  const { values, setFieldValue } = useFormikContext();
  const lines = getIn(values, name) || [];

  const allItemsSelected = useMemo(() => lines.length > 0 ? lines.every(line => line.selected) : false, [lines]);

  useEffect(() => {
    invoice.lines.forEach((_, index) => {
      const line = lines[index];

      if (line === undefined || (line.selected && line.sublines.length === 0))
        setFieldValue(`${name}[${index}]`, { selected: false, sublines: [] });
    });
  }, [lines, invoice]);

  const onLineSelect = useCallback((index, selected, invoiceLine) => {
    const sublines = selected ? [{
      lineNo: invoiceLine.lineNo,
      quantity: invoiceLine.uom.minimumQuantity,
      reasonId: '',
      comment: '',
    }] : [];
    replace(index, { selected, sublines });
  }, [replace]);

  const onSelectionChange = useCallback(() => {
    const selected = !allItemsSelected;

    for (let i = 0; i < invoice.lines.length; i++) {
      const line = lines[i];
      const invoiceLine = invoice.lines[i];

      if (line.selected === selected || invoiceLine.availableReturnQuantity === 0)
        continue;

      onLineSelect(i, selected, invoiceLine);
    }
  }, [invoice.lines, lines, allItemsSelected, onLineSelect]);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <thead>
          <tr>
            <th id="headerCheckbox" className={styles.colNarrow}>
              <Checkbox
                title={allItemsSelected ? deselectAllText : selectAllText}
                onChange={onSelectionChange}
                checked={allItemsSelected}
              />
            </th>
            <th id="headerId">
              <SimpleText textKey="General_Product_Id" />
            </th>
            <th id="headerTitle">
              <SimpleText textKey="General_Product_Title" />
            </th>
            {canViewPrices && <th id="headerTotal" className="text-right">
              <SimpleText textKey="Total" />
            </th>}
            <th id="headerQuantity" className="text-center">
              <SimpleText textKey="Qty" />
            </th>
            {canViewUom && <th id="headerUOM" title={unitOfMeasureTitle} className="text-center">
              <SimpleText textKey="UOM" />
            </th>}
            <th id="headerMaxReturnQuantity" className="text-center">
              <SimpleText textKey="MaxReturnQty" />
            </th>
          </tr>
        </thead>
        {invoice.lines.map((invoiceLine, index) => {
          const line = lines[index];

          if (!line)
            return null;

          return (
            <tbody key={index}>
              <tr className={styles.topRow}>
                <td aria-labelledby="headerCheckbox">
                  <Checkbox
                    title={line.selected ? deselectText : selectText}
                    onChange={() => onLineSelect(index, !line.selected, invoiceLine)}
                    checked={line.selected}
                    disabled={invoiceLine.availableReturnQuantity === 0}
                  />
                </td>
                <th scope="row" aria-labelledby="headerId">
                  {invoiceLine.productId}
                </th>
                <td aria-labelledby="headerTitle">
                  <div>{invoiceLine.title}</div>
                  {invoiceLine.variantTitle && <div>{invoiceLine.variantTitle}</div>}
                  {invoiceLine.salesAgreementLineNo && (
                    <div>
                      <SimpleText textKey="AgreementLine" />:&nbsp;
                      <Link to={routesBuilder.forSalesAgreement(salesAgreementId || invoiceLine.salesAgreementId)}>
                        {invoiceLine.salesAgreementLineNo}
                      </Link>
                    </div>
                  )}
                </td>
                {canViewPrices && (
                  <td aria-labelledby="headerTotal" className="text-right">
                    <FormattedPrice price={invoiceLine.subtotal} currencyInfo={currency} />
                  </td>
                )}
                <td aria-labelledby="headerQuantity" className="text-center">
                  {formatNumber(invoiceLine.quantity)}
                </td>
                {canViewUom && (
                  <td aria-labelledby="headerUOM" className="text-center">
                    <UomTitle id={invoiceLine.uom?.id} description={invoiceLine.uom?.description} />
                  </td>
                )}
                <td aria-labelledby="headerMaxReturnQuantity" className="text-center">
                  {formatNumber(invoiceLine.availableReturnQuantity)}
                </td>
              </tr>
              {invoiceLine.availableReturnQuantity > 0 && line.sublines.length > 0 && (
                <tr className={styles.sublineRow}>
                  <td colSpan={5 + (canViewPrices ? 1 : 0) + (canViewUom ? 1 : 0)}>
                    <div className={`h4 ${styles.sublineTitle}`}>{productsToReturnTitle}</div>
                    <ReturnOrderSublinesFieldArray
                      name={`${name}[${index}].sublines`}
                      lines={line.sublines}
                      invoiceLine={invoiceLine}
                      returnReasons={orderLineReturnReasons}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          );
        })}
      </Table>
    </div>
  );
});

ReturnOrderLines.propTypes = {
  name: PropTypes.string.isRequired,
  replace: PropTypes.func.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
const ReturnOrderLinesFieldArray = ({ name }) => (
  <FieldArray name={name}>
    {({ replace }) => <ReturnOrderLines name={name} replace={replace} />}
  </FieldArray>
);

ReturnOrderLinesFieldArray.propTypes = {
  name: PropTypes.string.isRequired,
};

export default React.memo(ReturnOrderLinesFieldArray);