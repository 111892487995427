import styles from '../PLP.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { connect } from 'react-redux';
import { addProducts } from 'behavior/basket';
import ListContext from './ListContext';
import scrollIntoView from 'scroll-into-view';
import { useSelector } from 'react-redux';
import { ProductSelectionDialog } from 'components/objects/productSelectionDialog';
import { StockAlertDialog } from 'components/objects/stockAlertDialog';
import { useModal } from 'components/objects/modals';

const AddToBasketButton = ({ addProducts, showModal }) => {
  const { quantities, resetQuantities } = useContext(ListContext);
  const { opened, toggle, show } = useModal();
  const stockAlertToggle = toggle;
  const stockAlertOpened = opened;
  const stockAlertShow = show;
  const placeholder = <Placeholder className={styles.addToBasketBtnPlaceholder} />;
  const [overInv, setOverInv] = useState([]);
  const [dlgOverInv, setDlgOverInv] = useState([]);

  const addOverInv = items => {
    setOverInv(items);
  };
  
  //GF OP18938 Pop up the ProductSelectionDialog if the customer has selected both spares and products.
  const [isSelectionMixed, setSelectionMixed] = useState(0);

  const updateIsSelectionMixed = badSelection => {
    setSelectionMixed(badSelection);
  };

  const um = useModal();

  const productSelectionOpened = um.opened;
  const productSelectionToggle = um.toggle;
  const productSelectionShow = um.show;

  useEffect(() => {
    if (isSelectionMixed <= 0) {
      return;
    }
    productSelectionShow();
    setSelectionMixed(0);
  }, [isSelectionMixed]);

  const page = useSelector(x => x.page);

  let pageProducts = [];

  if (page !== undefined && page.products !== undefined && page.products?.length > 0 && page.products[1] !== undefined) {
    pageProducts = page.products;
  }

  useEffect(() => {
    if (!overInv.length) {
      return;
    }
    setDlgOverInv(overInv);
    stockAlertShow();
    setOverInv([]);

  }, [overInv.length]);
  
  return (
    <div className={styles.addToBasket}>
      <SanaButton className={`${btnStyles.btnAction} btn-action`}
        textKey="AddToBasket"
        onClick={addToBasket.bind(this, quantities, resetQuantities, addProducts, addOverInv, pageProducts, updateIsSelectionMixed, showModal)}
        placeholder={placeholder}
        disabled={quantities.size === 0}
      />
      <StockAlertDialog products={quantities} opened={stockAlertOpened} toggle={stockAlertToggle} elements={dlgOverInv} />
      <ProductSelectionDialog opened={productSelectionOpened} toggle={productSelectionToggle} />
    </div>
  );

};

AddToBasketButton.propTypes = {
  addProducts: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default connect(null, { addProducts })(AddToBasketButton);

export function addToBasket(quantities, resetQuantities, addProducts, addOverInv,  pageProducts, updateIsSelectionMixed, showModal) {
  const lines = [];
  const overInv = [];
  const productsLinkedItemsArray = [];

 //GF OP18938 Find out if the products to be added are a mixture of isSpare and not isSpare.
  let spareCount = 0;
  let productCount = 0;

  for (const [productId] of quantities) {
    const product = getElByPropVal(pageProducts, 'id', productId);
    if (product !== undefined)
    {
      if (product.isSpare) {
        spareCount++;
      }
      else {
        productCount++;
      }
    }
  }

  if (productCount > 0 && spareCount > 0) {
    if (updateIsSelectionMixed !== undefined) {
      updateIsSelectionMixed(1);
    } else {
      alert('Please do not mix spares and products in the same basket.');
    }
    
    return;
  }

  for (const [productId, value] of quantities) {
    if (value instanceof Map) {
      for (const [uomId, uom] of value) {
        for (const [variantId, quantity] of uom) {
          const productLine = {
            productId,
            uomId,
            quantity: quantity.value,
          };

          if (variantId)
            productLine.variantId = variantId;

          lines.push(productLine);
        }
      }

      continue;
    }

    const { quantity, uomId } = value;
    if (!value.quantity.isValid) {
      const id = `${productId}_qty`;
      const invalidQuantityInput = document.getElementById(id);

      scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

      document.getElementById(id).focus();
      return;
    }

    const invId = `${productId}_inventory`;
    const invInput = document.getElementById(invId);

    if (invInput) {
      const inv = parseInt(invInput.value);
      if (inv < quantity.value) {
        overInv.push({ id: productId, quantity: quantity.value, inv });
      }
    }

    lines.push({
      productId,
      quantity: quantity.value,
      uomId,
    });

    // FETCH LINKED ITEMS
    const linkedItems = pageProducts.filter(item => item.id === productId);

    if (linkedItems.length > 0 && linkedItems[0].linkedItemsGroups && linkedItems[0].linkedItemsGroups.length > 0) {
      const linkedItemProducts = linkedItems[0].linkedItemsGroups.map(linkedItem => {
        const standardFields = {
          url: '',
          uom: {
            id: 'PCS',
          },
          uoms: [
            {
                id: 'PCS',
                description: 'Pieces',
                minimumQuantity: null,
                defaultQuantity: null,
                quantityStep: 1,
            },
          ],      
          productConfiguratorInfo: {
            isProductConfigurable: false,
          },
          calculated: true,
          calculatedInfoChanged: true,
          specifications: [],
          variantComponentGroups: [],
          hideCompare: true,
        };

        linkedItem = {
          ...linkedItem,
          stockLevels: linkedItems[0].stockLevels,
          ...standardFields,
        };

        return linkedItem;
      });

      // GENERATE LINKED PRODUCTS MODAL PAGE PROPS
      const LinkedObjects = {
        id: productId,
        quantity: quantity.value,
        products: linkedItemProducts,
        totalCount: linkedItemProducts.length,
        selectedSorting: { field: 'Id', ascending: true },
        sortingEnabled: false,
        selectedViewMode: 'List',
        viewModeSwitchEnabled: false,
        pagingType: 'LoadMore',
        showThumbnails: false,
        lastViewedEnabled: false,
        pageTitle: 'Linked Products',
      }; 

      productsLinkedItemsArray.push(LinkedObjects);
    }    
  }
  
  addOverInv(overInv);

  if (lines.length > 0)
    addProducts(lines);
    if (productsLinkedItemsArray.length > 0 && showModal) {
      // SEND PRODUCTS TO PAGE MODAL
      showModal(productsLinkedItemsArray);
    }
    resetQuantities();

  function getElByPropVal(myArray, prop, val) {
    for (let i = 0, length = myArray.length; i < length; i++) {
      if (myArray[i][prop] === val) {
        return myArray[i];
      }
    }
  }
}