import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { DropdownField as DropdownFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const DropdownField = hoistStatics(({ fieldName, fieldTitle, validation, required, items, labelId }) => (
  <DropdownFieldCore
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    required={required}
    items={items}
    labelId={labelId}
  />
), DropdownFieldCore);

DropdownField.propTypes = {
  ...DropdownFieldCore.propTypes,
  validation: validationFieldPropType,
  required: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
  })),
  labelId: PropTypes.string,
};

export default DropdownField;
