import styles from '../Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const PriceNode = ({ symbol, price }) => (
  <span className={styles.priceNode}>
    <span className="visually-hidden">{symbol + price}</span>
    <span aria-hidden className={styles.currencySign}>{symbol}</span>
    <span aria-hidden className={styles.priceValue}>{price}</span>
  </span>
);

PriceNode.propTypes = {
  symbol: PropTypes.string.isRequired,
  price: PropTypes.string,
};

export default React.memo(PriceNode);
