import React from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { DateDropdownField as DateDropdownFieldCore } from '../../fields';
import validationFieldPropType from './validationFieldPropType';

const DateDropdownField = hoistStatics(
  ({
    fieldName,
    fieldTitle,
    validation,
    required,
    className,
    minDate,
    maxDate,
  }) => (
      <DateDropdownFieldCore
        fieldName={fieldName}
        fieldTitle={fieldTitle}
        validation={validation}
        required={required}
        className={className}
        minDate={minDate}
        maxDate={maxDate}
      />
    ),
  DateDropdownFieldCore,
);

DateDropdownField.propTypes = {
  ...DateDropdownFieldCore.propTypes,
  required: PropTypes.bool,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  validation: validationFieldPropType,
};

export default DateDropdownField;
