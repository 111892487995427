import styles from './Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, className = styles.btn, ...attributes }) => (
  <button className={className} {...attributes}>
    <span className="btn-cnt">{children}</span>
  </button>
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default React.memo(Button);