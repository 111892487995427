export const APP_INIT = 'APP/INIT';
export const initApp = () => ({ type: APP_INIT });

export const APP_INIT_HYDRATE = 'APP/INIT_HYDRATE';
export const initAppHydrate = () => ({ type: APP_INIT_HYDRATE });

export const APP_HYDRATED = 'APP/HYDRATED';
export const notifyHydrated = () => ({ type: APP_HYDRATED });

export const APP_DESTROY = 'APP/DESTROY';
export const destroyApp = () => ({ type: APP_DESTROY });

export const APP_STATE_UPDATE = 'APP/STATE/UPDATE';
export const requestAppStateUpdate = () => ({ type: APP_STATE_UPDATE });

export const APP_STATE_UPDATED = 'APP/STATE/UPDATED';
export const notifyAppStateUpdated = appState => ({ type: APP_STATE_UPDATED, payload: appState });

export const OFFLINE_MODE_CHANGED = 'OFFLINE_MODE/CHANGED';
export const changeOfflineMode = appState => ({ type: OFFLINE_MODE_CHANGED, payload: appState });

export const APP_STORE_CONFIGURED = 'APP/STORE/CONFIGURED';
export const storeConfigured = () => ({ type: APP_STORE_CONFIGURED });
