import { routeNames } from './routeNames';

const nonIndexed = new Set([
  routeNames.BasketPage,
  routeNames.NotFound,
  routeNames.WishList,
  routeNames.Represent,
  routeNames.PagePreview,
  routeNames.LastViewedProducts,
  routeNames.OrderTemplates,
  routeNames.NewsletterSubscribe,
  routeNames.NewsletterUnSubscribe,
  routeNames.Search,
  routeNames.Orders,
  routeNames.OrderDetails,
  routeNames.ReturnOrders,
  routeNames.EditProfile,
  routeNames.Quotes,
  routeNames.Invoices,
  routeNames.ReturnReceipts,
  routeNames.CreditNotes,
  routeNames.Shipments,
  routeNames.InvoicePayment,
  routeNames.SalesAgreements,
  routeNames.CreateDocFreeReturnOrder,
  routeNames.CreateDocBasedReturnOrder,
  routeNames.SubAccounts,
  routeNames.EditSubAccount,
  routeNames.NewSubAccount,
  routeNames.OrderAuthorizations,
  routeNames.CreateProspect,
  routeNames.ChangePassword,
  routeNames.PagePreview,
  routeNames.Checkout,
  routeNames.OrderSubmit,
  routeNames.PaymentError,
  routeNames.OrderFailed,
  routeNames.OrderCancelled,
  routeNames.ProductComparison,
  routeNames.CreateProspect,
]);

/**
 * Checks whether route belongs to indexed pages.
 * @param {string} routeName - route name.
 * @returns {boolean} value indicating whether route should be indexed.
 */
export const isIndexed = routeName => !nonIndexed.has(routeName);
