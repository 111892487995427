exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Rating_stars{font-size:.92em;white-space:nowrap}.Rating_stars svg{margin:0 2px;width:1em}.Rating_stars svg path{fill:#f7ad00}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/rating/Rating.module.scss"],"names":[],"mappings":"AAAA,cACI,gBACA,kBACJ,CACI,kBACI,aACA,SACR,CACQ,uBACI,YACZ,CAAA","file":"Rating.module.scss","sourcesContent":[".stars {\n    font-size: .92em;\n    white-space: nowrap;\n\n    svg {\n        margin: 0 2px;\n        width: 1em;\n\n        path {\n            fill: #f7ad00;\n        }\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stars": "Rating_stars"
};