import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';

export default routeData => {
  const page = {
    component: PageComponentNames.NotFound,
    index: false,
  };

  if (routeData.options)
    page.backTo = routeData.options.backTo;

  return of({
    page,
    statusCode: 404,
    routeData,
  });
};
