exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SocialNetworks_add-this{display:table;margin:2em auto}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/socialSharing/SocialNetworks.module.scss"],"names":[],"mappings":"AAAA,yBACI,cACA,eACJ,CAAA","file":"SocialNetworks.module.scss","sourcesContent":[".add-this {\n    display: table;\n    margin: 2em auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"add-this": "SocialNetworks_add-this",
	"addThis": "SocialNetworks_add-this"
};