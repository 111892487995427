import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { routeNames } from 'routes';
import { initSystemPageContent } from '../system';
import { loadCreateReturnOrderPageQuery } from './queries';
import { getBackTo } from '../helpers';

export default (routeData, state$, { api }) => {
  const { params: { id, originalOrderId: orderId } } = routeData;

  if (!id)
    return of(null);

  return api.graphApi(loadCreateReturnOrderPageQuery, { id, orderId }).pipe(
    map(({ pages, documents: { invoices: { invoice } }, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocBasedReturnOrder;
      if (!page || !invoice)
        return null;

      page.component = PageComponentNames.CreateDocBasedReturnOrder;

      page.backTo = getBackTo(state$, [
        routeNames.CreateDocBasedReturnOrder,
      ], routeData.params && routeData.params.language);

      page.returnReasons = returnOrder.reasons;
      page.fileUploadSettings = returnOrder.attachments;
      page.splitLinesSupported = returnOrder.splitLinesSupported;
      
      invoice.lines = invoice.lines.itemLines.reduce((resultLines, { product, ...line }) => {
        const hasSublines = !!line.sublines?.length;
        
        if (hasSublines) {
          for (const subline of line.sublines)
            resultLines.push({
              ...subline,
              productId: product.id,
              title: line.title,
              variantTitle: subline.title,
              uom: getLineUom(subline, product),
              availableReturnQuantity: product.exists ? subline.availableReturnQuantity : 0,
            });
        } else {
          resultLines.push({
            ...line,
            productId: product.id,
            uom: getLineUom(line, product),
            availableReturnQuantity: product.exists ? line.availableReturnQuantity : 0,
          });
        }
        
        return resultLines;
      }, []);

      page.invoice = invoice;

      return { page };
    }),
    initSystemPageContent(),
  );
};

function getLineUom(line, product) {
  if (!product.exists)
    return null;

  if (!line.uom)
    return { minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };
  
  const productUom = product.uoms.find(uom => uom.id === line.uom.id);

  return { ...line.uom, ...productUom, maximumQuantity: line.availableReturnQuantity };
}