import React from 'react';
import PropTypes from 'prop-types';
import { getCurrencyAndNumber } from 'utils/format';
import { EmptyPriceSymbol } from 'utils/product';

const PriceNode = ({ price, currencyInfo, className }) => {
  let symbol, value = EmptyPriceSymbol;
  if (price != null)
    [symbol, value] = getCurrencyAndNumber(price || 0, currencyInfo);

  return <span className={className}>{symbol} {value}</span>;
};

PriceNode.propTypes = {
  price: PropTypes.number,
  currencyInfo: PropTypes.object,
  className: PropTypes.string,
};

export default React.memo(PriceNode);