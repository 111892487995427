import styles from './Basket.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { useDispatch } from 'react-redux';
import { clearBasket } from 'behavior/basket';
import { Row, Col } from 'components/primitives/grid';

const EditOrderInfo = ({ documentId, isB2BLayout }) => {
  const [msg, cancelText, cancelTitle] = useSanaTexts(['YouAreCurrentlyEditingOrder', 'ButtonText_Cancel', 'ButtonAltText_Cancel']).texts;
  const dispatch = useDispatch();

  return (
    <Row>
      <Col xs={12} lg={isB2BLayout ? 9 : null}>
        <div className={styles.msgBlock}>
          <span role="status">
            {makeRichText(msg, [documentId])}
          </span>
          <button
            className={linkStyles.link}
            title={makeSimpleText(cancelTitle)}
            onClick={() => dispatch(clearBasket(true))}
          >
            {makeSimpleText(cancelText)}
          </button>
        </div>
      </Col>
    </Row>
  );
};

EditOrderInfo.propTypes = {
  documentId: PropTypes.string.isRequired,
  isB2BLayout: PropTypes.bool,
};

export default EditOrderInfo;
