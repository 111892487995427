import styles from '../SanaForm.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const FormGroupTemplate = ({ label, field, error, description, className = '', ...attributes }) => (
  <div className={`${styles.formRow} ${className}`} {...attributes}>
    <div className={`${styles.control} form-row-control`}>
      <div className={`${styles.label} form-row-label`}>
        {label}
      </div>
      <div role="presentation">
        <div className={`${styles.field} form-row-field`}>
          {field}
        </div>
        {error && (
          <div className={`${styles.validation} form-row-validation`} aria-live={error ? 'polite' : null}>
            {error}
          </div>
        )}
        {description}
      </div>
    </div>
  </div>
);

FormGroupTemplate.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  field: PropTypes.node,
  error: PropTypes.node,
  description: PropTypes.node,
};

export default FormGroupTemplate;