import styles from '../Order.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UseSanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { useDispatch } from 'react-redux';
import { cancelOrderAuthorization } from 'behavior/pages/document';
import useConfirmation from 'components/objects/confirmation';
import { RichText } from 'components/sanaText';

const CancelAuthorizationButton = ({ orderId }) => {
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  useEffect(() => clearConfirmation, []);

  const dispatch = useDispatch();
  const cancelAuthorizationWithConfirmation = () => {
    const cancelAction = () => dispatch(cancelOrderAuthorization(orderId));
    showConfirmation(
      cancelAction,
      'OrderAuthorization_Cancel',
      <RichText textKey="OrderAuthorization_CancelConfirmation" />,
      {
        closeText: 'ButtonText_No',
        closeAltText: 'ButtonAltText_No',
        okText: 'ButtonText_Yes',
        okAltText: 'ButtonAltText_Yes',
      },
      undefined,
      styles.cancelAuthorizationPopup,
    );
  };

  return (
    <>
      <UseSanaButton textKey="Cancel" placeholder={<Placeholder />}>
        {(text, title) => (
          <button
            title={title}
            className={`${linkStyles.link} ${styles.cancelLink}`}
            onClick={cancelAuthorizationWithConfirmation}
          >
            {text}
          </button>
        )}
      </UseSanaButton>
      {confirmationModal}
    </>
  );
};

CancelAuthorizationButton.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default React.memo(CancelAuthorizationButton);
