const PREFIX = 'EVENT/';

export const LANGUAGE_CHANGED = PREFIX + 'LANGUAGE_CHANGED';
export const languageChanged = () => ({ type: LANGUAGE_CHANGED });

export const VIEWER_CHANGED = PREFIX + 'VIEWER_CHANGED';
export const viewerChanged = () => ({ type: VIEWER_CHANGED });

export const BASKET_CHANGE_STARTED = PREFIX + 'BASKET_CHANGE_STARTED';
export const basketChangeStarted = () => ({ type: BASKET_CHANGE_STARTED });

export const BASKET_CHANGE_COMPLETED = PREFIX + 'BASKET_CHANGE_COMPLETED';
export const basketChangeCompleted = updatedLinesAmount => ({
  type: BASKET_CHANGE_COMPLETED,
  payload: { updatedLinesAmount },
});

export const LOCATION_CHANGED = PREFIX + 'LOCATION_CHANGED';
export const locationChanged = () => ({ type: LOCATION_CHANGED });

export const NAVIGATION_REQUESTED = PREFIX + 'NAVIGATION_REQUESTED';
const emptyOptions = {};
export const navigateTo = (to, url, { omitScroll, replaceHistory } = emptyOptions) => ({
  type: NAVIGATION_REQUESTED,
  payload: { to, url, omitScroll, replaceHistory },
});