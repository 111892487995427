import React from 'react';
import PropTypes from 'prop-types';
import { SanaTextContainer } from 'components/sanaText';

const ReturnReason = ({ reason }) => (
  <SanaTextContainer textKey={`ReturnReason_${reason.id}`}>{reason.name}</SanaTextContainer>
);

ReturnReason.propTypes = {
  reason: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReturnReason;
