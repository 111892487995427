import styles from 'components/primitives/grid/Grid.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { asPropTypes } from './PropTypes.js';

const Row = React.forwardRef((
  {
    as: Tag = 'div',
    className = '',
    noGutters,
    noWrap,
    mainAxisAlign,
    crossAxisAlign,
    children,
    ...attributes
  },
  ref,
) => {
  const rowClasses = joinClasses(
    styles.row,
    noGutters && 'row-no-gutters',
    noWrap && styles.flexNowrap,
    justificationClassNames[mainAxisAlign],
    alignmentClassNames[crossAxisAlign],
    className,
  );
  return (
    <Tag className={rowClasses} {...attributes} ref={ref}>
      {children}
    </Tag>
  );
});

Row.displayName = 'Row';

Row.propTypes = {
  as: asPropTypes,
  className: PropTypes.string,
  noGutters: PropTypes.bool,
  noWrap: PropTypes.bool,
  mainAxisAlign: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around']),
  crossAxisAlign: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
  children: PropTypes.node,
};

export default React.memo(Row);

const justificationClassNames = {
  start: styles.justifyContentStart,
  center: styles.justifyContentCenter,
  end: styles.justifyContentEnd,
  between: styles.justifyContentBetween,
  around: styles.justifyContentAround,
};

const alignmentClassNames = {
  start: styles.alignItemsStart,
  center: styles.alignItemsCenter,
  end: styles.alignItemsEnd,
  baseline: styles.alignItemsBaseline,
  stretch: styles.alignItemsStretch,
};
