import styles from './Icons.module.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import iconPropTypes from './iconPropTypes';

export const createIcon = (type, Component) => {
  const Icon = ({ alt = '', className = '', ...props }) => {
    const src = useSelector(({ theme }) => theme.values && theme.values[type]);

    if (!src)
      return <Component className={`${styles.defaultIcon} ${className}`} {...props} />;

    return <img src={src} alt={alt} className={`${styles.icon} ${className}`} {...props} />;
  };

  Icon.displayName = type;
  Icon.propTypes = iconPropTypes;

  return withStyles(styles)(Icon);
};

