import styles from '../BasketFooter.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import TotalsLine from './TotalsLine';

const TotalExclTax = ({ priceExcludingTax, focus }) => {
  if (focus) {
    return (
      <TotalsLine
        textKey="Total"
        value={priceExcludingTax}
        className={`${styles.priceExlTax} ${styles.emphasis} focused-total-line`}
      />
    );
  }
  return (
    <TotalsLine
      textKey="Basket_TotalExVat"
      value={priceExcludingTax}
      className={styles.priceExlTax}
    />
  );
};

TotalExclTax.propTypes = {
  priceExcludingTax: PropTypes.node.isRequired,
  focus: PropTypes.bool.isRequired,
};

export default TotalExclTax;
