import styles from '../../Checkout.module.scss';
import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../StepLoadingIndicator';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Steps } from 'behavior/pages/checkout';
import { toUrlHash } from 'utils/url';
import Spinner from 'components/primitives/spinner/Spinner';
import useExtraCheckoutStep from './useExtraCheckoutStep';
import useExtraCheckoutStepCommand from './useExtraCheckoutStepCommand';
import { SanaTextContainer } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useOnChange } from 'utils/hooks';
import StepDoneMark from '../StepDoneMark';

const ExtraPaymentStep = ({
  className,
  headerText,
  headerTextKey,
  asLink,
  isPromotion,
  isCompleted,
  children,
}) => {
  const header = <SanaTextContainer textKey={headerTextKey}>{makeSimpleText(headerText)}</SanaTextContainer>;

  return (
    <section className={className}>
      <div className={styles.header}>
        <h2>
          {
            asLink
              ? (
                <Link
                  to={isPromotion ? routesBuilder.forQuotePromotion(Steps.ExtraPayment) : routesBuilder.forCheckout(false, Steps.ExtraPayment)}
                  url={toUrlHash(Steps.ExtraPayment)}
                >
                  {header}
                </Link>
              )
              : header
          }
        </h2>
        {isCompleted && <StepDoneMark />}
      </div>
      {children &&
        <div className={styles.body}>
          {children}
        </div>
      }
    </section>
  );
};

ExtraPaymentStep.propTypes = {
  className: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  headerTextKey: PropTypes.string.isRequired,
  asLink: PropTypes.bool,
  isPromotion: PropTypes.bool,
  isCompleted: PropTypes.bool,
  children: PropTypes.node,
};

export default ExtraPaymentStep;

// eslint-disable-next-line react/no-multi-comp
export const ExtraPaymentStepBody = props => {
  const executeCommand = useExtraCheckoutStepCommand();

  return <ExtraPaymentStepForm executeCommand={executeCommand} {...props} />;
};

export const ExtraPaymentStepForm = React.memo(({
  executeCommand,
  extraPaymentStep,
  formRef,
  onFormSubmit,
  onBeforeSubmitRef,
  submitFormOnBlur,
  setLoading,
}) => {
  const { model, addonId, paymentModuleId } = extraPaymentStep;

  const extraStepFormRef = useRef();
  const ExtraStep = useExtraCheckoutStep(addonId, paymentModuleId);

  useOnChange(() => {
    extraStepFormRef.current = null;
    formRef.current = null;
    onBeforeSubmitRef.current = null;
  }, [addonId, paymentModuleId]);

  const dataForm = useMemo(() =>
    createDataForm(extraStepFormRef, onFormSubmit, submitFormOnBlur),
    [onFormSubmit, submitFormOnBlur],
  );

  const addBeforeSubmitHandler = useCallback(handler => {
    onBeforeSubmitRef.current = handler;

    return () => onBeforeSubmitRef.current = null;
  }, []);

  useEffect(() => {
    if (!formRef)
      return;

    formRef.current = ExtraStep !== undefined
      ? createExtraStepForm(extraStepFormRef)
      : createDisabledForm();
  }, [ExtraStep]);

  if (ExtraStep === undefined)
    return <Spinner />;

  if (!ExtraStep)
    throw new Error(`Payment module ${paymentModuleId} of ${addonId} add-on does not contain payment step.`);

  return (
    <>
      <ExtraStep
        model={model}
        dataForm={dataForm}
        executeCommand={executeCommand}
        lockCheckout={() => setLoading(Steps.ExtraPayment)}
        addBeforeSubmitHandler={addBeforeSubmitHandler}
      />
      <LoadingIndicator />
    </>
  );
});

ExtraPaymentStepForm.propTypes = {
  executeCommand: PropTypes.func.isRequired,
  extraPaymentStep: PropTypes.shape({
    model: PropTypes.any,
    addonId: PropTypes.string.isRequired,
    paymentModuleId: PropTypes.string.isRequired,
  }).isRequired,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  onFormSubmit: PropTypes.func,
  onBeforeSubmitRef: PropTypes.shape({
    current: PropTypes.func,
  }).isRequired,
  submitFormOnBlur: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
};

function createDataForm(formRef, onSubmit, submitOnBlur) {
  const onBlur = submitOnBlur
    ? createOnBlurHandler(formRef, onSubmit)
    : null;

  return {
    ref: formRef,
    onSubmit,
    onBlur,
  };
}

function createOnBlurHandler(formRef, onSubmit) {
  return e => {
    if (e.currentTarget.contains(e.relatedTarget || document.activeElement))
      return;

    formRef.current.validate().then(errors => {
      if (Object.keys(errors).length)
        return;

      onSubmit(formRef.current.values);
    });
  };
}

function createExtraStepForm(extraStepFormRef) {
  return {
    validate: async () => {
      if (extraStepFormRef.current)
        return await extraStepFormRef.current.validate();
      return true;
    },
    get values() {
      return extraStepFormRef.current?.values;
    },
    set errors(errors) {
      if (extraStepFormRef.current)
        extraStepFormRef.current.errors = errors;
    },
  };
}

function createDisabledForm() {
  return {
    validate: async () => false,
    values: null,
    set errors(_errors) { },
  };
}
