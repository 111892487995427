import React from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { formatText } from 'utils/formatting';
import { formatAsPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';
import { makeRichText } from 'utils/render';
import { ContactStoreLink } from 'components/primitives/links';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { PromoteQuoteStatus } from 'behavior/documents';
import { useSelector } from 'react-redux';

const CreditLimitExceededMessage = ({ isWarning }) => {
  const [canViewPrices] = useHasAbilities(AbilityTo.ViewPrices);
  const promotionResult = useSelector(({ page }) => page.promotionResult);
  const forcePayOnline = promotionResult && promotionResult.status === PromoteQuoteStatus.CreditLimitForceOnlinePayment;

  let textKey;
  if (isWarning) {
    textKey = [
      canViewPrices ? 'CreditLimitExceededBy' : 'CreditLimitExceeded',
      forcePayOnline
        ? 'CreditLimitExceeded_PayOnline'
        : promotionResult.status === PromoteQuoteStatus.CreditLimitError
          ? 'CreditLimitExceeded_PayYourInvoices_Error'
          : 'CreditLimitExceeded_PayYourInvoices',
    ];
  } else {
    textKey = [
      canViewPrices ? 'PromotionError_CreditLimitExceededBy' : 'PromotionError_CreditLimitExceeded',
      'PromotionError_CreditLimitExceeded_PayYourInvoices',
    ];
  }

  const [
    creditLimitExceededText,
    creditLimitExceededPayText,
  ] = useSanaTexts(textKey).texts;

  const currency = useCurrencyInfo();
  const message = canViewPrices
    ? formatText(creditLimitExceededText, [formatAsPrice(promotionResult.exceededCreditLimit, currency)])
    : creditLimitExceededText;

  return (
    <>
      {makeRichText(`${message} `)}
      {forcePayOnline || isWarning
        ? makeRichText(creditLimitExceededPayText)
        : makeRichText(creditLimitExceededPayText, [<ContactStoreLink linkTextKey="PromotionError_CreditLimitExceeded_Contact" />], true)
      }
    </>
  );
};

CreditLimitExceededMessage.propTypes = {
  isWarning: PropTypes.bool,
};

export default CreditLimitExceededMessage;
