import styles from './Order.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { DangerAlert } from 'components/primitives/alerts';
import { RichText } from 'components/sanaText';
import CreditLimitExceededMessage from './quote/promotable/CreditLimitExceededMessage';

const DocumentNotifications = ({
  notificationState: {
    orderNotFound,
    orderHasNoProducts,
    cannotBePromoted,
    creditLimitExceeded,
    payInvoiceError,
  },
}) => (
    <div className={styles.documentNotifications}>
      {(orderNotFound || orderHasNoProducts) &&
        <DangerAlert role="alert" scrollOnAppear>
          <RichText textKey={orderNotFound ? 'OrderActions_OrderNotFound' : 'OrderHasNoProductsThatCanBeReordered'} />
        </DangerAlert>
      }
      {(cannotBePromoted || creditLimitExceeded) &&
        <DangerAlert role="alert" scrollOnAppear>
          {cannotBePromoted
            ? <RichText textKey="QuoteCannotBePromoted" />
            : <CreditLimitExceededMessage />
          }
        </DangerAlert>
      }
      {payInvoiceError &&
        <DangerAlert role="alert" scrollOnAppear>
          <RichText textKey="YouCannotPayInvoicesNow" />
        </DangerAlert>
      }
    </div>
  );

DocumentNotifications.propTypes = {
  notificationState: PropTypes.shape({
    orderNotFound: PropTypes.bool,
    orderHasNoProducts: PropTypes.bool,
    cannotBePromoted: PropTypes.bool,
    creditLimitExceeded: PropTypes.bool,
    payInvoiceError: PropTypes.bool,
  }).isRequired,
};

export default DocumentNotifications;
