import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { defaultSearchParams } from './constants';
import { createSearchStructure, useTrackingOptions } from 'utils/structureData';
import renderOnce from 'components/objects/renderOnce';

const Schema = ({ routePath }) => {
  const trackingOptions = useTrackingOptions();

  return (
    <Helmet>
      <script type="application/ld+json" id="searchbox-potentialAction">
        {getSchemaJson(routePath, trackingOptions)}
      </script>
    </Helmet>
  );
};

Schema.propTypes = {
  routePath: PropTypes.string.isRequired,
};

export default renderOnce(Schema);

function getSchemaJson(routePath, trackingOptions) {
  const target = routePath.replace(defaultSearchParams, '{q}');
  const data = createSearchStructure(target, trackingOptions);

  return JSON.stringify(data);
}
