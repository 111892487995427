/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './SocialNetworks.module.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderHTML } from 'utils/render';

let initializedGlobal = false;

const SocialNetworks = ({ addThis, languageName, location }) => {
  const [initialized, setInitialized] = useState(initializedGlobal);
  useEffect(() => {
    if (!addThis)
      return;

    if (!window.addthis) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${addThis.id}&domready=1`;

      window.addthis_config = {
        ui_language: 'en',
      };

      document.body.appendChild(script);

      if (!initializedGlobal) {
        initializedGlobal = true;
        setInitialized(true);
      }
    } else {
      if (document.body.classList.contains('addthis-disabled'))
        document.body.classList.remove('addthis-disabled');

      refreshAddthisLayout(languageName);
    }

    return () => {
      document.body.classList.add('addthis-disabled');
    };
  }, [addThis]);

  useEffect(() => {
    if (!window.addthis)
      return;

    refreshAddthisLayout(languageName);
  }, [languageName, location]);

  if (!addThis || !languageName || !initialized)
    return null;

  const { customHTML } = addThis;

  return (
    <div className={styles.addThis}>
      {customHTML ? renderHTML(customHTML) : (
        <div className="addthis_toolbox addthis_default_style addthis_32x32_style">
          <a className="addthis_button_facebook" />
          <a className="addthis_button_twitter" />
          <a className="addthis_button_pinterest_share" />
          <a className="addthis_button_tumblr" />
          <a className="addthis_button_more" />
          <a className="addthis_counter addthis_bubble_style" />
        </div>)
      }
    </div>
  );
};

SocialNetworks.propTypes = {
  addThis: PropTypes.shape({
    id: PropTypes.string,
    customHTML: PropTypes.string,
  }),
  languageName: PropTypes.string,
  location: PropTypes.object,
};

export default connect(
  ({
    settings: { addThis },
    localization: { currentLanguage },
    routing: { location },
  }) => ({
    addThis,
    languageName: currentLanguage && currentLanguage.name,
    location,
  }),
)(SocialNetworks);

function refreshAddthisLayout(languageName) {
  const addthis = window.addthis;

  addthis.links = [];

  if (functionExists(addthis.update)) {
    addthis.update('config', 'lang', languageName);
    addthis.update('share');
  }

  functionExists(addthis.layers.refresh) && addthis.layers.refresh();
  functionExists(addthis.toolbox) && addthis.toolbox('.addthis_toolbox');
  functionExists(addthis.counter) && addthis.counter('.addthis_counter');
}

function functionExists(func) {
  return typeof func === 'function';
}