import styles from './Header.module.scss';
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import { AccordionNav, OpenerButton, useFocusResetOnLocationChange } from 'components/objects/navigation';
import { LanguageSelector } from 'components/objects/languageSelector';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

const textsToLoad = ['Aria_MobileTopMenu_ToggleButton'];
const accordionId = 'mobileTopMenu';

const MobileTopMenu = ({ openerContainer, OpenerComponent = OpenerButton, syncSubject }) => {
  const [isExpanded, setTopMenuState] = useState(false);
  const [shouldRender, setRenderState] = useState(!!openerContainer.current);

  const handleNavSkipLinkClick = useCallback(() => setTopMenuState(true), []);
  const setCollapsed = useCallback(() => setTopMenuState(false), []);

  useFocusResetOnLocationChange(!isExpanded, setCollapsed);

  useEffect(() => {
    setRenderState(!!openerContainer.current);
  }, [openerContainer.current]);

  useEffect(() => {
    const subscription = syncSubject.subscribe(id => {
      if (id === accordionId)
        return;

      setTopMenuState(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (!shouldRender)
    return null;

  const handleOpenerClick = () => {
    if (!isExpanded)
      syncSubject.next(accordionId);

    setTopMenuState(!isExpanded);
  };

  const topNavOpener = (
    <UseSanaTexts options={textsToLoad} dontWait>
      {([ariaTopMenuToggleButtonText]) => (
        <OpenerComponent
          onClick={handleOpenerClick}
          isActive={isExpanded}
          aria-label={stripHtmlTagsAndFormatting(ariaTopMenuToggleButtonText)}
          aria-pressed={isExpanded}
          aria-controls={accordionId}
        />
      )}
    </UseSanaTexts>
  );

  return (
    <>
      {ReactDOM.createPortal(topNavOpener, openerContainer.current)}
      <VerticalSliding
        id={accordionId}
        expanded={isExpanded}
        containerClass={styles.topMenuContent}
      >
        <LanguageSelector isMobile />
        <AccordionNav isExpanded={isExpanded} handleSkipLinkClick={handleNavSkipLinkClick} />
      </VerticalSliding>
    </>
  );
};

MobileTopMenu.propTypes = {
  openerContainer: PropTypes.object.isRequired,
  OpenerComponent: PropTypes.object,
  syncSubject: PropTypes.object.isRequired,
};

export default React.memo(MobileTopMenu);