import styles from '../MainNav.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import { AccordionStateProvider } from './AccordionStateProvider';

const AccordionNav = ({ location, isExpanded, handleSkipLinkClick }) => (
  <AccordionStateProvider deps={[location, isExpanded]}>
    <MainNav NavItemComponent={NavItem} navClass={styles.accordion} handleSkipLinkClick={handleSkipLinkClick} />
  </AccordionStateProvider>
);

AccordionNav.propTypes = {
  location: PropTypes.object,
  isExpanded: PropTypes.bool,
  handleSkipLinkClick: PropTypes.func,
};

export default connect(({ routing: { location } }) => ({ location }))(AccordionNav);