import React from 'react';
import Field, { fieldPropTypes } from '../Field';
import { Input } from 'components/primitives/form';

const TextBoxField = ({ fieldName, fieldTitle, validation, ...attributes }) => (
  <Field
    type="text"
    fieldName={fieldName}
    fieldTitle={fieldTitle}
    validation={validation}
    as={Input}
    {...attributes}
  />
);

TextBoxField.propTypes = fieldPropTypes;

export default TextBoxField;