import styles from '../ClosedStore.module.scss';
import React from 'react';
import { useSimpleTexts } from 'components/sanaText';
import { usePageTitle } from 'components/objects/hooks';
import LoginForm from 'components/objects/login/LoginForm';
import { RichText } from 'components/sanaText';
import { useVerticalOverflowFix } from 'utils/hooks';
import ClosedStoreLayout from '../ClosedStoreLayout';

export default () => {
  const [title] = useSimpleTexts(['Login_Title']).texts;
  const pageTitle = usePageTitle(title);

  useVerticalOverflowFix();

  return (
    <ClosedStoreLayout pageTitle={pageTitle}>
      <div className={styles.headerText}>
        <RichText textKey="ClosedStore_Login_Header" />
      </div>
      <div className={styles.descriptionText}>
        <RichText textKey="ClosedStore_Login_Description" />
      </div>
      <LoginForm />
    </ClosedStoreLayout>
  );
};
