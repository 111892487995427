import styles from './VariantsMatrix.module.scss';
import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import VariantCell from './VariantCell';
import { throttle } from 'lodash';
import useStickyHeaders from './useStickyHeaders';
import { Subject } from 'rxjs';
import { useCultureName } from 'utils/hooks';
import { getNumberDecimalsSeparator } from 'utils/format';

const VariantsMatrix = ({ product, initialQuantities, abilities, onQuantityChanged, opened }) => {
  const {
    variantComponentGroups,
    variants,
  } = product;

  const tableWrapperRef = useRef();
  const tableRef = useRef();
  const stickyHeaderWrapperRef = useRef();
  const documentWidthRef = useRef(0);
  const tooltipOptions = useRef();
  if (!tooltipOptions.current) {
    tooltipOptions.current = { scroll$: new Subject() };
  }

  const [
    createStickyHeaders,
    scrollStickyHeaders,
  ] = useStickyHeaders(tableRef, tableWrapperRef, stickyHeaderWrapperRef, styles.reset);

  const onRendered = useMemo(() => {
    return throttle(() => {
      setTimeout(() => {
        createStickyHeaders(styles, rowHeaders.length > 0, variantComponentGroups);
        scrollStickyHeaders(styles, true, rowHeaders.length);
      }, 0);
    }, 200, { leading: false, trailing: true });
  }, [variantComponentGroups]);

  const resizeHandler = () => {
    const documentWidth = document.documentElement.clientWidth;
    if (documentWidth !== documentWidthRef.current) {
      // Horizontal resize
      createStickyHeaders(styles, rowHeaders.length > 0, variantComponentGroups);
      scrollStickyHeaders(styles, true, rowHeaders.length);
    }

    documentWidthRef.current = documentWidth;
  };

  useEffect(() => {
    const scrollTable = () => {
      scrollStickyHeaders(styles, false, rowHeaders.length);
      tooltipOptions.current.scroll$.next(null);
    };

    requestAnimationFrame(() => createStickyHeaders(styles, rowHeaders.length > 0, variantComponentGroups));

    tableWrapperRef.current.addEventListener('scroll', scrollTable);
    window.addEventListener('resize', resizeHandler);

    return () => {
      tableWrapperRef.current.removeEventListener('scroll', scrollTable);
      window.removeEventListener('resize', resizeHandler);
      onRendered.cancel();
    };
  }, []);

  useEffect(() => {
    if (opened)
      requestAnimationFrame(() => createStickyHeaders(styles, rowHeaders.length > 0, variantComponentGroups));
  }, [opened]);

  useEffect(() => void onRendered(), [variants]);
  const culture = useCultureName();
  const separator = getNumberDecimalsSeparator(culture);

  const [verticalGroup, horizontalGroup] = variantComponentGroups;

  const columnHeaders = verticalGroup
    ? verticalGroup.components
    : variants.map(({ id, title: name }) => ({ id, name }));
  const rowHeaders = horizontalGroup ? horizontalGroup.components : [];
  const variantsMap = useMemo(() => new Map(variants.map(variant =>
    [getVariantKey(variant, verticalGroup, horizontalGroup), variant])));

  const getVariantQuantity = useCallback(variantId => {
    if (!initialQuantities)
      return null;

    const quantity = initialQuantities.get(variantId);
    return quantity && quantity.value;
  }, [initialQuantities]);

  const rowsCount = rowHeaders.length || 1;
  const colsCount = columnHeaders.length || 1;
  const rows = Array(rowsCount);
  for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
    const cells = Array(colsCount);
    const row = rowHeaders[rowIndex];

    const includeRowHeader = rowHeaders.length > 0;

    for (let colIndex = 0; colIndex < colsCount; colIndex++) {
      const cellKey = getCellKey(columnHeaders[colIndex], row);
      const variant = variantsMap.get(cellKey);

      cells[colIndex] = !variant
        ? <td key={colIndex} />
        : (
          <VariantCell
            tooltipOptions={tooltipOptions.current}
            onRendered={onRendered}
            key={colIndex}
            product={product}
            variant={variant}
            getVariantQuantity={getVariantQuantity}
            abilities={abilities}
            onQuantityChanged={onQuantityChanged}
            labelledBy={`VariantColHeader${colIndex}${includeRowHeader ? ` VariantRowHeader${rowIndex}` : ''}`}
            separator={separator}
          />
        );

    }
    rows[rowIndex] = (
      <tr key={rowIndex}>
        {includeRowHeader && <th className={styles.rowHeader} id={`VariantRowHeader${rowIndex}`}>{row.name}</th>}
        {cells}
      </tr>
    );
  }

  return (
    <div className={styles.matrix}>
      <div className={styles.stickyHeaders} ref={stickyHeaderWrapperRef} role="presentation">
        <div className={styles.stickyRow}><table /></div>
        <div className={styles.stickyCol}><table /></div>
        <div className={styles.cornerCell} />
      </div>
      {/* data-scroll-lock-scrollable - attribute that allows to scroll modal on touch devices */}
      <div
        className={styles.tableWrapper}
        ref={tableWrapperRef}
        data-scroll-lock-scrollable
      >
        <table ref={tableRef}>
          {columnHeaders.length && (
            <thead>
              <tr>
                {rowHeaders.length > 0 && <th aria-hidden />}
                {columnHeaders.map(({ name }, index) => <th className="th-col" key={index} id={`VariantColHeader${index}`}>{name}</th>)}
              </tr>
            </thead>
          )}
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
};

VariantsMatrix.propTypes = {
  opened: PropTypes.bool,
  product: PropTypes.shape({
    variantComponentGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      components: PropTypes.arrayOf(PropTypes.shape({
        variants: PropTypes.arrayOf(PropTypes.string).isRequired,
      })).isRequired,
    })),
    variants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    })).isRequired,
  }).isRequired,
  initialQuantities: PropTypes.instanceOf(Map).isRequired,
  abilities: PropTypes.object.isRequired,
  onQuantityChanged: PropTypes.func,
};

export default React.memo(VariantsMatrix);

export function getVariantKey(variant, verticalGroup, horizontalGroup) {
  const { id } = variant;
  const verticalComponent = verticalGroup
    && verticalGroup.components.find(({ variants }) => variants.includes(id));
  const horizontalComponent = horizontalGroup
    && horizontalGroup.components.find(({ variants }) => variants.includes(id));

  return `${verticalComponent ? verticalComponent.id : id}\n${horizontalComponent && horizontalComponent.id}`;
}

export function getCellKey(column, row) {
  return `${column.id}\n${row && row.id}`;
}
