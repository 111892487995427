exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".RemoveButton_btn-remove-item{cursor:pointer;border:none;background:transparent;width:20px;height:20px;position:absolute;margin:auto 0;padding:1px;color:#828282}.RemoveButton_btn-remove-item:hover{opacity:.75}.RemoveButton_remove-icon{max-width:18px;max-height:18px;fill:#828282}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/RemoveButton.module.scss"],"names":[],"mappings":"AAAA,8BACI,eACA,YACA,uBACA,WACA,YACA,kBACA,cACA,YACA,aACJ,CACI,oCACI,WACR,CAGA,0BACI,eACA,gBACA,YAAJ,CAAA","file":"RemoveButton.module.scss","sourcesContent":[".btn-remove-item {\n    cursor: pointer;\n    border: none;\n    background: transparent;\n    width: 20px;\n    height: 20px;\n    position: absolute;\n    margin: auto 0;\n    padding: 1px;\n    color: #828282;\n\n    &:hover {\n        opacity: 0.75;\n    }\n}\n\n.remove-icon {\n    max-width: 18px;\n    max-height: 18px;\n    fill: #828282;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"btn-remove-item": "RemoveButton_btn-remove-item",
	"btnRemoveItem": "RemoveButton_btn-remove-item",
	"remove-icon": "RemoveButton_remove-icon",
	"removeIcon": "RemoveButton_remove-icon"
};