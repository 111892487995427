import { routeNames } from './routeNames';

export const routesBuilder = {
  forProduct: (productId, backTo) => ({
    routeName: routeNames.ProductDetails,
    params: {
      id: productId,
    },
    options: {
      backTo,
    },
  }),
  forBasket: (...args) => {
    const result = { routeName: routeNames.BasketPage };
    if (args.length === 0)
      return result;

    const [pageIndex, linesOnly] = args;
    result.params = {
      page: (pageIndex + 1).toString(),
    };

    if (linesOnly)
      result.linesOnly = true;

    return result;
  },
  forProductList: (pageIndex, params) => ({
    routeName: routeNames.ProductList,
    params: { ...params, page: (pageIndex + 1).toString() },
  }),
  forProductsWithCategory: categoryId => ({
    routeName: routeNames.ProductsWithCategory,
    params: { id: categoryId },
  }),
  forSubAccount: id => ({
    routeName: routeNames.EditSubAccount,
    params: { id },
  }),
  forDocument: (id, documentType, originalOrderId) => ({
    routeName: routeNames.DocumentDetails,
    params: { id, documentType, originalOrderId },
  }),
  forNotFound: () => ({ routeName: routeNames.NotFound }),
  forHome: () => ({ routeName: routeNames.Home }),
  forRepresent: () => ({ routeName: routeNames.Represent }),
  forLogin: () => ({ routeName: routeNames.Login }),
  forRegistration: () => ({ routeName: routeNames.Registration }),
  forMyAccount: () => ({ routeName: routeNames.MyAccount }),
  forNewSubAccount: () => ({ routeName: routeNames.NewSubAccount }),
  forEditProfile: (backTo = null) => ({
    routeName: routeNames.EditProfile,
    options: backTo ? { backTo } : undefined,
  }),
  forOrders: () => ({ routeName: routeNames.Orders }),
  forQuotes: () => ({ routeName: routeNames.Quotes }),
  forInvoices: () => ({ routeName: routeNames.Invoices }),
  forReturnOrders: () => ({ routeName: routeNames.ReturnOrders }),
  forReturnReceipts: () => ({ routeName: routeNames.ReturnReceipts }),
  forCreditNotes: () => ({ routeName: routeNames.CreditNotes }),
  forShipments: () => ({ routeName: routeNames.Shipments }),
  forSalesAgreements: () => ({ routeName: routeNames.SalesAgreements }),
  forSalesAgreement: id => ({
    routeName: routeNames.SalesAgreement,
    params: { id },
  }),
  forOrderTemplates: () => ({ routeName: routeNames.OrderTemplates }),
  forCreateDocFreeReturnOrder: () => ({ routeName: routeNames.CreateDocFreeReturnOrder }),
  forCreateDocBasedReturnOrder: (id, originalOrderId) => ({
    routeName: routeNames.CreateDocBasedReturnOrder,
    params: { id, originalOrderId },
  }),
  forSubAccounts: () => ({ routeName: routeNames.SubAccounts }),
  forOrderAuthorizations: () => ({ routeName: routeNames.OrderAuthorizations }),
  forCreateProspect: () => ({ routeName: routeNames.CreateProspect }),
  forChangePassword: () => ({ routeName: routeNames.ChangePassword }),
  forForgotPassword: () => ({ routeName: routeNames.ForgotPassword }),
  forCheckout: (asQuote = false, step = null, guest = false) => {
    const result = {
      routeName: routeNames.Checkout,
      options: { step },
    };

    if (guest)
      result.params = { guest };
    else if (asQuote)
      result.params = { asQuote };

    return result;
  },
  forQuotePromotion: (step = null) => ({
    routeName: routeNames.QuotePromotion,
    options: step ? { step } : null,
  }),
  forInvoicePayment: orderId => ({
    routeName: routeNames.InvoicePayment,
    params: { orderId },
  }),
  forOrderPayment: transactionId => ({
    routeName: routeNames.OrderPayment,
    params: { transactionId },
  }),
  forPaymentSubmit: transactionId => ({
    routeName: routeNames.PaymentSubmit,
    params: { transactionId },
  }),
  forPaymentCancelled: transactionId => ({
    routeName: routeNames.PaymentCancelled,
    params: { transactionId },
  }),
  forPaymentFailed: transactionId => ({
    routeName: routeNames.PaymentFailed,
    params: { transactionId },
  }),
  forLastViewed: () => ({ routeName: routeNames.LastViewedProducts }),
  forNewsOverview: () => ({ routeName: routeNames.NewsOverview }),
  forContactUs: () => ({ routeName: routeNames.ContactUs }),
  forNewsDetail: id => ({
    routeName: routeNames.NewsDetail,
    params: { id },
  }),
  forWishList: () => ({ routeName: routeNames.WishList }),
  forProductComparison: productIds => ({
    routeName: routeNames.ProductComparison,
    params: {
      productIds,
    },
  }),
  forOrderSubmit: transactionId => ({
    routeName: routeNames.OrderSubmit,
    params: { transactionId },
  }),
  forPaymentError: transactionId => ({
    routeName: routeNames.PaymentError,
    params: { transactionId },
  }),
  forOrderFailed: transactionId => ({
    routeName: routeNames.OrderFailed,
    params: { transactionId },
  }),
  forOrderCancelled: transactionId => ({
    routeName: routeNames.OrderCancelled,
    params: { transactionId },
  }),
};
