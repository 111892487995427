exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"_media_queries.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"isIE": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isFirefox": "(min--moz-device-pixel-ratio:0)",
	"isSafari": "not all and (min-resolution:.001dpcm)",
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)",
	"hasMultiplePointers": "(any-pointer:coarse) and (any-pointer:fine)"
};