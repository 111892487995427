import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText, makeRichText } from 'utils/render';
import { ProductSelector } from 'components/objects/productSelector';
import { EVENT_SOURCES } from 'behavior/analytics';

const ReturnOrderProductSearch = ({ onProductAdd }) => {
  const texts = useProductSearchTexts();

  return (
    <ProductSelector
      texts={texts}
      onProductAdd={onProductAdd}
      allowAddUnorderableProduct
      productClickTrackingSource={EVENT_SOURCES.returnOrder}
    />
  );
};

ReturnOrderProductSearch.propTypes = {
  onProductAdd: PropTypes.func.isRequired,
};

export default React.memo(ReturnOrderProductSearch);

function useProductSearchTexts() {
  const texts = useSanaTexts([
    'Search_SearchPrefix',
    'ButtonText_Add',
    'Loading',
    'ReturnOrder_ProductNotFound',
  ]).texts;

  return useMemo(() => ({
    searchBoxPlaceholder: makeSimpleText(texts[0]),
    addBtn: makeSimpleText(texts[1]),
    loadingText: makeSimpleText(texts[2]),
    productNotFoundText: makeRichText(texts[3]),
  }), [texts]);
}