import styles from './BasketB2C.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/primitives/grid';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { routesBuilder } from 'routes';
import BasketPaging from '../BasketPaging';
import { UseSanaButton } from 'components/primitives/buttons';
import { QuoteButton, CheckoutButton } from '../actionButtons';
import { withAbilities } from 'components/objects/user';
import { DocumentType } from 'behavior/documents';

const Actions = ({
  isEmpty = null,
  isBlocked,
  page,
  totalCount,
  showPagingTotal = false,
  abilities,
  editDocumentType,
}) => {
  let [canCreateQuote, canCreateOrder] = abilities.map(ability => ability !== AbilityState.NotAvailable);
  let paging = null;

  if (editDocumentType) {
    if (editDocumentType === DocumentType.Quote) {
      canCreateQuote = true;
      canCreateOrder = false;
    } else if (editDocumentType === DocumentType.Order) {
      canCreateQuote = false;
      canCreateOrder = true;
    }
  }

  if (page && page.size < totalCount) {
    paging = (
      <Col
        xs={{ size: 12, order: showPagingTotal ? 'first' : 'last' }}
        lg={{ size: true, order: 0 }}
        className={`${styles.container} ${styles.pagingWrapper}`}
      >
        <BasketPaging
          page={page}
          totalCount={totalCount}
          className={styles.paging}
        />
        {showPagingTotal &&
          <span className={styles.totalCount}>
            <SimpleText textKey="ItemsInCart" />
            {' ' + totalCount}
          </span>
        }
      </Col>
    );
  }

  const btnWrapperClassNames = `${styles.container} ${styles.limitedWidth}`;
  const linkBackPlaceholder = <Placeholder className={styles.linkBackPlaceholder} />;

  return (
    <>
      <Col xs={12} md="auto" className={`${styles.container} ${styles.linkContainer}`}>
        <UseSanaButton textKey="ContinueShopping" placeholder={linkBackPlaceholder}>
          {(text, title) => (
            <Link
              to={routesBuilder.forHome}
              className={`${linkStyles.link} ${styles.linkBack} ${btnStyles.font}`}
              title={title}
            >
              <span>‹</span>
              {text}
            </Link>
          )}
        </UseSanaButton>
      </Col>
      {paging}
      <Col xs={12} md="auto" className={`${styles.limitedWidth} ${styles.btnContainer}`}>
        <Row className={styles.btnRow} crossAxisAlign="center">
          {canCreateQuote &&
            <Col xs={12} md="auto" className={btnWrapperClassNames}>
              <QuoteButton className={styles.btnQuote} disabled={isEmpty} canCreateOrder={canCreateOrder} />
            </Col>
          }
          {canCreateOrder &&
            <Col xs={12} md="auto" className={btnWrapperClassNames}>
              <CheckoutButton className={styles.btnCheckout} disabled={isEmpty || isBlocked} />
            </Col>
          }
        </Row>
      </Col>
    </>
  );
};

Actions.propTypes = {
  isEmpty: PropTypes.bool,
  isBlocked: PropTypes.bool,
  page: PropTypes.object,
  totalCount: PropTypes.number,
  showPagingTotal: PropTypes.bool,
  abilities: abilitiesPropType,
  editDocumentType: PropTypes.string,
};

export default withAbilities(Actions, [AbilityTo.CreateQuote, AbilityTo.CreateOrder]);
