import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Link } from 'components/primitives/links';

const LoginPrompt = ({ registrationAllowed }) => {
  const [
    loginFirstTitle,
    loginFirstDescription,
    loginLink,
    registerLink,
  ] = useSanaTexts([
    'WishList_LoginFirstPopupTitle',
    'WishList_LoginFirstPopupDescription',
    'GoToLoginPage',
    'WishList_LoginFirstPopupGoToRegisterPageLink',
  ]).texts;

  const linkClasses = `${linkStyles.link} ${linkStyles.arrowed}`;
  return (
    <>
      <h2>{loginFirstTitle}</h2>
      {loginFirstDescription}
      <div>
        <Link
          to={routesBuilder.forLogin}
          className={linkClasses}
        >
          {loginLink}
        </Link>
      </div>
      {registrationAllowed && (
        <div>
          <Link
            to={routesBuilder.forRegistration}
            className={linkClasses}
          >
            {registerLink}
          </Link>
        </div>
      )}
    </>
  );
};

LoginPrompt.propTypes = {
  registrationAllowed: PropTypes.bool,
};

const mapStateToProps = ({
  settings: { profile },
}) => ({
  registrationAllowed: profile && profile.registrationAllowed,
});

export default connect(mapStateToProps)(LoginPrompt);
