exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProductConfigurator_iframe{width:100%;height:calc(100vh - 50px);border:none}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productConfigurator/ProductConfigurator.module.scss"],"names":[],"mappings":"AAAA,4BACI,WACA,0BACA,WACJ,CAAA","file":"ProductConfigurator.module.scss","sourcesContent":[".iframe {\n    width: 100%;\n    height: calc(100vh - 50px);\n    border: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"iframe": "ProductConfigurator_iframe"
};