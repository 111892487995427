exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Copyright_copyright{margin:0}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/copyright/Copyright.module.scss"],"names":[],"mappings":"AAAA,qBACI,QACJ,CAAA","file":"Copyright.module.scss","sourcesContent":[".copyright {\n    margin: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"copyright": "Copyright_copyright"
};