import styles from './Usp.module.scss';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const Usp = ({ model, isPrintMode }) => {
  if (isPrintMode)
    return null;

  if (!model)
    return null;

  const { items } = model;
  if (!items || !items.length)
    return null;

  return (
    <div className={styles.usp}>
      <ul>
        {items.map((item, i) => {
          const notFirstItem = i !== 0;
          const itemWithIcon = item.imagePath && item.imagePath.length;
          return (
            <Fragment key={i}>
              {notFirstItem && <li className={styles.separator}>&nbsp;</li>}
              <li className={styles.item}>
                <div>
                  {itemWithIcon && (
                    <span className={styles.media}>
                      <ResponsiveLazyImage src={item.imagePath} alt={item.text} className={styles.icon} />
                    </span>
                  )}
                  <span className={styles.text}>{item.text}</span>
                </div>
              </li>
            </Fragment>
          );
        })}
      </ul>
    </div>
  );
};

Usp.propTypes = {
  model: PropTypes.shape({
    items: PropTypes.array,
  }),
  isPrintMode: PropTypes.bool,
};

export default React.memo(Usp);
