exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ConfirmationModal_modal-container{max-width:520px;width:100%;margin:0 auto}.ConfirmationModal_modal-buttons-block{display:flex;padding:1.5em 0;margin-top:1.5em}.ConfirmationModal_btn-confirm{margin:0 auto}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/wishList/ConfirmationModal.module.scss"],"names":[],"mappings":"AAAA,mCACI,gBACA,WACA,aACJ,CAEA,uCACI,aACA,gBACA,gBACJ,CAEA,+BACI,aACJ,CAAA","file":"ConfirmationModal.module.scss","sourcesContent":[".modal-container {\n    max-width: 520px;\n    width: 100%;\n    margin: 0 auto;\n}\n\n.modal-buttons-block {\n    display: flex;\n    padding: 1.5em 0;\n    margin-top: 1.5em;\n}\n\n.btn-confirm {\n    margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"modal-container": "ConfirmationModal_modal-container",
	"modalContainer": "ConfirmationModal_modal-container",
	"modal-buttons-block": "ConfirmationModal_modal-buttons-block",
	"modalButtonsBlock": "ConfirmationModal_modal-buttons-block",
	"btn-confirm": "ConfirmationModal_btn-confirm",
	"btnConfirm": "ConfirmationModal_btn-confirm"
};