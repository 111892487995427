import React from 'react';
import { Container } from 'components/primitives/grid';
import { renderRows } from 'components/containers';
import { PageComponentNames } from 'behavior/pages';

function contentPageRenderer({ header, content }) {
  return (
    <>
      {header != null ? <Container><h1>{header}</h1></Container> : null}
      {renderRows(content)}
    </>
  );
}

export default {
  [PageComponentNames.Content]: contentPageRenderer,
};