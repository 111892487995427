import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';

const Page = ({ customer, templateFields }) => {
  if (!customer)
    return (
      <div className="msg-block">
        <SimpleText textKey="CompanyDetails_NotImpersonated_Message" />
      </div>
    );

  return templateFields ? <Form templateFields={templateFields} customerId={customer.id} /> : null;
};

Page.propTypes = {
  templateFields: PropTypes.array,
  customer: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Page.selectPropsFromPage = ({ templateFields }) => ({ templateFields });

export default connect(({ user: { customer } }) => ({ customer }))(Page);