import { useEffect } from 'react';

/**
 * Conditionally performs subscription to the event Observable object and fires handler function
 * every time this object emits event data
 * @param {object|Function} eventObservable$ - RXJS Observable or function to create observable
 * @param {Function} handler - Handler function for event
 * @param {boolean} shouldSubscribe - React.useEffect dependency allowing event observable subscription
 * @param {Array} [deps] - array of React.useEffect dependencies
 * @returns {void}
 */
export const useEventObservable = (eventObservable$, handler, shouldSubscribe = true, deps) => {
  deps = deps && deps.length ? [shouldSubscribe].concat(deps) : [shouldSubscribe];

  useEffect(() => {
    if (!shouldSubscribe)
      return;

    if (typeof eventObservable$ === 'function')
      eventObservable$ = eventObservable$();

    const eventSubscription = eventObservable$.subscribe(handler);
    return () => eventSubscription.unsubscribe();
  }, deps);
};