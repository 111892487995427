import styles from './LoginInfo.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'components/primitives/dropdown';
import { LoginLink } from 'components/primitives/links';
import LoginInfoItems from './LoginInfoItems';
import Icon from './Icon';
import { useOnChange } from 'utils/hooks';

const LoginInfo = ({ isAuthenticated, isImpersonating, showLoginLink }) => {
  const [dropdownOptions, changeDropdownOptions] = useState({ show: false });

  useOnChange(() => changeDropdownOptions({ show: false }), [isAuthenticated, isImpersonating], false);

  return (
    <>
      {showLoginLink && !isAuthenticated && (
        <LoginLink className={`${styles.link} ${styles.headerLink}`} />
      )}
      <div className={styles.wrapper}>
        <Dropdown
          bodyAlign="right"
          header={(
            <span className={styles.userIconContainer}>
              <Icon isImpersonating={isImpersonating} />
            </span>
          )}
          className="login-info"
          options={dropdownOptions}
        >
          <div className={styles.body}>
            <LoginInfoItems />
          </div>
        </Dropdown>
      </div>
    </>
  );
};

LoginInfo.propTypes = {
  isAuthenticated: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  showLoginLink: PropTypes.bool,
};

export default connect(
  ({ user: { isAuthenticated, isImpersonating } }) => ({ isAuthenticated, isImpersonating }),
)(LoginInfo);