import styles from './Alert.module.scss';
import React from 'react';
import { InfoIcon } from 'components/primitives/icons';
import Alert, { alertPropTypes } from './Alert';

const InfoAlert = ({
  children,
  className = '',
  scrollOnAppear,
  role,
  ...props
}) => (
  <Alert
    className={styles.info + ' ' + className}
    icon={InfoIcon}
    scrollOnAppear={scrollOnAppear}
    role={role}
    {...props}
  >
    {children}
  </Alert>
);

InfoAlert.propTypes = alertPropTypes;

export default InfoAlert;