import { redirectTo } from '../actions';
import { createLocation } from '../helpers';
import { Scope } from 'utils/app';
import { destroyApp } from 'behavior/app';
import { isAbsoluteUrl } from 'utils/url';

export function redirect(url, location, route, dependencies) {
  const urlLocation = createLocation(url);
  if (urlLocation.pathname === location.pathname)
    return;

  const { scope } = dependencies;
  if (scope === Scope.Client && isAbsoluteUrl(url)) {
    window.location = url;
    return destroyApp();
  }

  return redirectTo(url, 301, route);
}