import styles from '../OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import { useSimpleTexts } from 'components/sanaText';
import { AbilityTo } from 'behavior/user/constants';
import { DocumentType } from 'behavior/documents';
import { useHasAbilities } from 'components/objects/user';
import { checkoutProcessNamePropType } from '../propTypes';
import { logout } from 'behavior/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { Placeholder } from 'components/primitives/placeholders';
import { Helmet } from 'react-helmet';
import { usePageTitle } from 'components/objects/hooks';
import { useSelector } from 'react-redux';

const OrderCancelled = ({ checkoutProcessName, documentId, id: transactionId }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  let descriptionTextKey, linkTextKey, linkRoute, linkClass;

  if (checkoutProcessName === CheckoutProcessNames.Invoice) {
    descriptionTextKey = 'InvoiceCancelled_Text';
    linkTextKey = 'GotoInvoicesPage';
    linkRoute = routesBuilder.forInvoices;
    linkClass = styles.invoicesLink;
  } else if (checkoutProcessName === CheckoutProcessNames.Promotion) {
    descriptionTextKey = 'QuotePaymentCancelled_Text';
    linkTextKey = 'GotoOrderPage';
    linkRoute = routesBuilder.forDocument(documentId, DocumentType.Order);
    linkClass = styles.invoicesLink;
  } else {
    descriptionTextKey = 'OrderCancelled_Text';
    linkTextKey = 'GotoBasketPage';
    linkRoute = routesBuilder.forBasket;
    linkClass = styles.basketLink;
  }

  const [viewMyAccountAbility] = useHasAbilities(AbilityTo.ViewMyAccountPage);
  const [texts, loaded] = useOrderCancelledTexts(descriptionTextKey, linkTextKey);
  const pageTitle = usePageTitle();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <p className={styles.description}>
        {texts.description}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            <li>
              <Link
                className={`${linkStyles.arrowed} ${linkClass}`}
                to={linkRoute}
              >
                {texts.link}
              </Link>
            </li>
            {viewMyAccountAbility && isAuthenticated && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.myAccountLink}`}
                  to={routesBuilder.forMyAccount}
                  options={{ backTo: { routeData: routesBuilder.forOrderCancelled(transactionId) } }}
                >
                  {texts.account}
                </Link>
              </li>
            )}
            <li>
              <Link
                className={`${linkStyles.arrowed} ${styles.homeLink}`}
                to={routesBuilder.forHome}
              >
                {texts.homePage}
              </Link>
            </li>
            {isAuthenticated &&
              <li>
                <button
                  className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`}
                  onClick={() => dispatch(logout())}
                >
                  {texts.logout}
                </button>
              </li>
            }
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

OrderCancelled.propTypes = {
  checkoutProcessName: checkoutProcessNamePropType.isRequired,
  documentId: PropTypes.string,
  id: PropTypes.string.isRequired,
};

const Memoized = React.memo(OrderCancelled);
Memoized.selectPropsFromPage = ({ transaction }) => transaction;

export default Memoized;

function useOrderCancelledTexts(descriptionTextKey, linkTextKey) {
  const {
    texts: [
      description,
      link,
      homePage,
      account,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    descriptionTextKey,
    linkTextKey,
    'GoToHomePage',
    'GotoAccountPage',
    'SubmitLogoutLinkText',
  ]);

  return [
    {
      description,
      link,
      homePage,
      account,
      logout,
    },
    loaded,
  ];
}
