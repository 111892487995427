import React, { useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { fixIncorrectScrollAnchoring } from 'utils/helpers';

const DEFAULT_STYLES = {
  transitionProperty: 'height',
};

/**
  * @typedef {Object} ComponentProps
  * @property {boolean} expanded - property indicating transition state - from zero height to children
  * content height(expanded) or vice versa(collapsed)
  * @property {number} duration - Transition duration in milliseconds.
  * @property {string} containerClass - Custom class passed for additional styling to
  * wrapper element.
  * @property {string} timingFunction - Timing function name for height transition.
  * @property {React.ReactNode} children - Anything that can be rendered by React: numbers, strings,
  * another React elements.
  * @returns {React.ReactNode} - children wrapped up with the component.
  */

/**
  * Component which provides height transition from one component state to another over time
  * @param {ComponentProps}
  */

const VerticalSliding = ({
  expanded,
  duration = 200,
  containerClass,
  timingFunction = 'ease-in-out',
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  children,
  ...attributes
}) => {
  const timeoutRef = useRef();

  const setContainerDisplayStyle = node => {
    const height = parseInt(node.style.height, 10);
    node.style.display = (!expanded && height === 0) ? 'none' : '';
  };

  const handleEnter = node => {
    toggleOverflowAnchorState(true);
    setContainerDisplayStyle(node);
    onEnter && onEnter(node);
  };

  const handleEntering = node => {
    node.style.height = `${node.scrollHeight}px`;
    onEntering && onEntering(node);
  };

  const handleEntered = node => {
    timeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);
    node.style.height = 'auto';
    node.style.overflow = 'visible';

    onEntered && onEntered(node);
    fixIncorrectScrollAnchoring();
  };

  const handleExit = node => {
    toggleOverflowAnchorState(true);
    node.style.height = `${node.scrollHeight}px`;
    onExit && onExit(node);
  };

  const handleExiting = node => {
    timeoutRef.current = setTimeout(() => {
      node.style.height = 0;
      node.style.overflow = 'hidden';

      onExiting && onExiting(node);
    }, 25);
  };

  const handleExited = node => {
    setContainerDisplayStyle(node);
    timeoutRef.current = setTimeout(toggleOverflowAnchorState, 50);
    onExited && onExited(node);
  };

  const defaultStyles = useMemo(() => ({
    ...DEFAULT_STYLES,
    display: !expanded ? 'none' : null,
    height: expanded ? 'auto' : 0,
    transitionDuration: `${duration}ms`,
    transitionTimingFunction: timingFunction,
    overflow: expanded ? 'visible' : 'hidden',
  }), [duration, timingFunction]);

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  return (
    <Transition
      in={expanded}
      timeout={duration}
      onEnter={handleEnter}
      onEntering={handleEntering}
      onEntered={handleEntered}
      onExit={handleExit}
      onExiting={handleExiting}
      onExited={handleExited}
    >
      <div
        className={containerClass}
        style={defaultStyles}
        {...attributes}
      >
        {children}
      </div>
    </Transition>
  );
};

VerticalSliding.propTypes = {
  expanded: PropTypes.bool,
  duration: PropTypes.number,
  containerClass: PropTypes.string,
  timingFunction: PropTypes.string,
  children: PropTypes.node,
  onEnter: PropTypes.func,
  onEntering: PropTypes.func,
  onEntered: PropTypes.func,
  onExit: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func,
};

export default React.memo(VerticalSliding);

export function toggleOverflowAnchorState(shouldDisable) {
  // "overflow-anchor" is non-standard property which used for opt out of the browser's scroll anchoring behavior,
  // which adjusts scroll position to minimize content shifts. It is implemented and enabled by default in
  // Chrome, Firefox and Opera and causes unexpected behaviour when browser tab layout is dynamically changing
  // its height, so during this action it should be temporarily disabled.
  document.body.style.overflowAnchor = shouldDisable ? 'none' : '';
}