import styles from './ProductComparisonRating.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { RowRails } from '../swiper';
import RatingCell from './RatingCell';
import { ProductTrackingContext } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';
import { StarsRating } from 'components/primitives/rating';
import ReviewsLink from './ReviewsLink';
import { useIsPreview } from 'components/objects/preview';

const RatingRow = ({ products }) => {
  const { ratingsAndReviewsEnabled, productsCount } = useSelector(({ settings, page }) => ({
    ratingsAndReviewsEnabled: settings.product.ratingsAndReviewsEnabled,
    productsCount: page.previewProductsCount,
  }), shallowEqual);
  const isPreview = useIsPreview();

  if (!ratingsAndReviewsEnabled)
    return null;

  return (
    <>
      <h2 className={styles.title}><SimpleText textKey="ProductCompare_Reviews" /></h2>
      {isPreview
        ? (
          <RowRails items={Array.from(Array(productsCount))} highlightColumns>
            {() => (
              <div className={styles.fieldValue}>
                <StarsRating value={0} />
                <ReviewsLink reviewsTotal={0} />
              </div>
            )}
          </RowRails>
        )
        : (
          <RowRails items={products} highlightColumns>
            {product => (
              <ProductTrackingContext.Provider value={{ product, trackingSource : EVENT_SOURCES.compareProductsPage }}>
                <RatingCell product={product} />
              </ProductTrackingContext.Provider>
            )}
          </RowRails>
        )
      }
    </>
  );
};

RatingRow.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({ reviews: PropTypes.object }).isRequired,
  ),
};

export default RatingRow;