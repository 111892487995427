import { createLocation } from './helpers';

export const NAVIGATION_REQUESTED = '@@router/NAVIGATION_REQUESTED';
export const navigationRequested = (location, statusCode = undefined, routeData = undefined, replaceHistory = false) => ({
  type: NAVIGATION_REQUESTED,
  payload: { location, statusCode, routeData, replaceHistory },
});

export const NAVIGATING = '@@router/NAVIGATING';
export const startNavigation = (location, routeData) => ({
  type: NAVIGATING,
  payload: { location, routeData },
});

export const NAVIGATED = '@@router/NAVIGATED';
export const completeNavigation = (location, routeData, page) => ({
  type: NAVIGATED,
  payload: {
    location,
    routeData,
    page,
  },
});

export const INIT = '@@router/INIT';
export const initialize = location => ({
  type: INIT,
  payload: { location },
});

const statusCodesSet = new Set([301, 401]);

export const redirectTo = (url, statusCode = 302, routeData = undefined) => {
  const location = createLocation(url);
  return navigationRequested(location, statusCode, routeData, statusCodesSet.has(statusCode));
};

export const STATUS_CODE_RESOLVED = '@@router/STATUS_CODE_RESOLVED';
export const statusCodeResolved = statusCode => ({ type: STATUS_CODE_RESOLVED, payload: { statusCode } });

export const GO_BACK = '@@router/GO_BACK';
export const goBack = () => ({ type: GO_BACK });

export const NAVIGATE_TO_PREVIOUS = '@@router/NAVIGATE_TO_PREVIOUS';
export const navigateToPrevious = (ignoredRouteNames = [], fallbackRoute) => ({
  type: NAVIGATE_TO_PREVIOUS,
  payload: {
    ignoredRouteNames,
    fallbackRoute,
  },
});

export const RELOAD_LOCATION = '@@router/RELOAD_LOCATION';
export const reloadLocation = () => ({ type: RELOAD_LOCATION });

export const REWRITE_TO = '@@router/REWRITE_TO';
export const rewriteTo = (routeData, omitScroll) => ({ type: REWRITE_TO, payload: { routeData, omitScroll } });
