import styles from './Lines.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const ReadonlyQuantityBox = ({ quantity }) => {
  return (
    <div className={`${styles.quantity} ${styles.readOnly}`}>
      {quantity}
    </div>
  );
};

ReadonlyQuantityBox.propTypes = {
  quantity: PropTypes.number,
};

export default React.memo(ReadonlyQuantityBox);
