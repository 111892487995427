import styles from './ProductComparisonTile.module.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { ProductThumbnail } from 'components/primitives/product';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import ButtonPlaceholder from './buttons/Placeholder';
import RemoveButton from '../RemoveButton';

const PreviewProductTile = ({ compact = false }) => {
  const noImage = useSelector(({ settings }) => settings.product.noImage.medium);

  return (
    <div className={`${styles.tile} ${compact ? styles.compactView : styles.fullView}`}>
      {compact
        ? (
          <>
            <div>
              <div className={styles.imageLink}>
                <ProductThumbnail src={noImage} className={styles.productImage} visibleByDefault />
              </div>
              <div className={styles.productTitle}><Placeholder className={styles.previewPlaceholder} /></div>
              <div className={styles.prices}><Placeholder className={styles.previewPlaceholder} /></div>
            </div>
            <div className={styles.productAction}><ButtonPlaceholder className={styles.previewPlaceholder} /></div>
          </>
        )
        : (
          <>
            <div className={styles.thumbnail}>
              <div className={styles.thumbnailBox}>
                <div className={styles.imageLink}>
                  <ProductThumbnail src={noImage} className={styles.productImage} visibleByDefault />
                </div>
                <RemoveButton className={styles.btnRemoveItem} onClick={useHandlerLockerInPreview()} />
              </div>
            </div>
            <div className={styles.productInfo}>
              <div className={styles.productDescription}>
                <div className={styles.productTitle}><Placeholder className={styles.previewPlaceholder} /></div>
                <div className={styles.productId}><Placeholder className={styles.previewPlaceholder} /></div>
                <div><Placeholder className={styles.previewPlaceholder} /></div>
              </div>
              <div className={styles.productAction}>
                <div className={styles.prices}><Placeholder className={styles.previewPlaceholder} /></div>
                <ButtonPlaceholder className={styles.previewPlaceholder} />
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

PreviewProductTile.propTypes = {
  compact: PropTypes.bool,
};

export default PreviewProductTile;