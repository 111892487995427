import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import { isMaxDate, isMinDate } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxMonth } from '../datePicker/helpers';
import { pad } from 'utils/helpers';

const MonthSelect = ({
  id,
  name,
  placeholderText,
  maxDate,
  minDate,
  year,
  ...selectProps
}) => {
  const { maxMonth, minMonth } = getMinMaxMonth(maxDate, minDate, year);

  const items = useMemo(() => {
    const items = [{ name: placeholderText, value: null }];
    for (let i = minMonth; i <= maxMonth; i++)
      items.push({ name: pad(i, 2), value: i });

    return items;
  }, [maxMonth, minMonth, placeholderText]);

  return (
    <Select
      items={items}
      id={id && `${id}_month`}
      name={name && `${name}_month`}
      {...selectProps}
    />
  );
};

MonthSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  maxDate: isMaxDate,
  minDate: isMinDate,
  year: PropTypes.number,
};

export default MonthSelect;
