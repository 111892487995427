import React from 'react';
import { Col, Container, Row } from 'components/primitives/grid';
import loadable from '@loadable/component';
import NewsTitle from './NewsTitle';
import NewsList from './NewsList';
import Sidebar from './SidebarNews';
import { PageComponentNames } from 'behavior/pages';

function newsRenderer(page) {
  return layout(<>
    <NewsTitle />
    <NewsList />
  </>, 'section');
}

const NewsDetail = loadable(() => import(/*webpackChunkName:"newsDetail"*/'./NewsDetailsObject'));
function newsItemRenderer(page) {
  return layout(<NewsDetail />, 'article');
}

function layout(child, wrapperTag) {
  return (
    <Container>
      <Row>
        <Col xs={12} md={3}>
          <Sidebar />
        </Col>
        <Col as={wrapperTag} xs={12} md={9}>
          {child}
        </Col>
      </Row>
    </Container>
  );
}

export default {
  [PageComponentNames.News]: newsRenderer,
  [PageComponentNames.NewsDetails]: newsItemRenderer,
};