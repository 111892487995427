import styles from './Lines.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { formatAsPercentage } from 'utils/format';

const Discount = ({ discount, currencyInfo }) => {
  return (
    <span className={styles.discount}>
      <span>{formatAsPercentage(discount, currencyInfo, true, 2)}</span>
      <SimpleText textKey="Discount" />
    </span>
  );
};

Discount.propTypes = {
  discount: PropTypes.number.isRequired,
  currencyInfo: PropTypes.object,
};

export default React.memo(Discount);