import { redirectTo } from '../actions';
import { routeNames } from 'routes';

const ignoreRoutes = new Set([
  routeNames.Home,
  routeNames.NotFound,
  routeNames.WebsiteRedirect,
]);

const trailingRoutes = new Set([
  routeNames.ProductList,
  routeNames.MyAccount,
  routeNames.SubAccounts,
  routeNames.Orders,
  routeNames.SalesAgreements,
]);

export function trailing(ignoreRoutes, trailingRoutes, route, location) {
  if (ignoreRoutes.has(route.routeName))
    return;

  const trailingRoute = trailingRoutes.has(route.routeName);
  const trailingPath = location.pathname.endsWith('/');

  if (trailingRoute && trailingPath)
    return;

  if (!trailingRoute && !trailingPath)
    return;

  const pathname = trailingRoute
    ? location.pathname + '/'
    : location.pathname
      .substring(0, location.pathname.length - 1);

  return redirectTo(pathname + location.search, 301, route);
}

export default trailing.bind(null, ignoreRoutes, trailingRoutes);
