import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import { useSanaButton } from 'components/primitives/buttons';
import { toPropTypes } from './helpers';

const SanaLinkButton = ({
  url,
  to,
  textKey,
  titleTextKey = textKey,
  defaultText,
  placeholder,
  className,
  ...attributes
}) => {
  const { texts: [text, title], loaded } = useSanaButton(textKey, titleTextKey, defaultText);
  if (!loaded)
    return placeholder || null;

  return <LinkButton url={url} to={to} title={title} className={className} {...attributes}>{text}</LinkButton>;
};

SanaLinkButton.propTypes = {
  url: PropTypes.string,
  to: toPropTypes,
  textKey: PropTypes.string.isRequired,
  titleTextKey: PropTypes.string,
  defaultText: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.node,
};

export default React.memo(SanaLinkButton);
