import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap } from 'rxjs/operators';
import { paymentErrorQuery } from './queries';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId } } = routeData;

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentErrorQuery, { id: transactionId }).pipe(
    mergeMap(({ paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      transaction.id = transactionId;

      return of({
        page: {
          component: PageComponentNames.PaymentError,
          transaction,
        },
      });
    }),
  );
};
