import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { ExpandIcon, CollapseIcon } from 'components/primitives/icons';

const ShowHideButton = ({ show, className = '', ...attributes }) => {
  const Icon = show ? CollapseIcon : ExpandIcon;

  return (
    <button
      type="button"
      className={`${btnStyles.btn} ${btnStyles.btnSmall} ${btnStyles.btnShowHide} ${className}`}
      {...attributes}
    >
      <Icon className={btnStyles.icon} aria-hidden />
      <span className="visually-hidden"><SimpleText textKey={show ? 'Aria_Hide' : 'Aria_Show'} /></span>
    </button>
  );
};

ShowHideButton.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default ShowHideButton;