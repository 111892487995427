import styles from './VariantsMatrix.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import { Modal, useModal } from 'components/objects/modals';
import { useMatrixAbilities } from './useMatrixAbilities';
import { useOnLocationChanged } from 'utils/hooks';
import VariantsOverview from './VariantsOverview';
import VariantsMatrixForm from './VariantsMatrixForm';
import { SanaButton } from 'components/primitives/buttons';
import { AbilityTo } from 'behavior/user/constants';
import VariantsMatrixProvider from './VariantsMatrixProvider';
import { Placeholder } from 'components/primitives/placeholders';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const VariantsMatrixPopup = () => {
  const abilities = useMatrixAbilities();
  const { opened, show, hide } = useModal();

  useOnLocationChanged(hide);

  const [continueShoppingText] = useSanaTexts(['ContinueShopping'], makeSimpleText).texts;

  if (Object.values(abilities).some(ability => ability === undefined))
    return null;

  const showMatrixForm = abilities[AbilityTo.ViewPrices]
    || abilities[AbilityTo.OrderProducts]
    || abilities[AbilityTo.ViewStock];

  const btnMatrixPlaceholder = <Placeholder className={styles.btnMatrixPlaceholder} />;

  const backButton = !!continueShoppingText && (
    <button
      type="button"
      onClick={hide}
      className={`${linkStyles.link} ${linkStyles.arrowedBack} ${btnStyles.font} ${styles.continueBtn}`}
    >
      {continueShoppingText}
    </button>
  );

  const onClick = () => {
    show();
  };

  return (
    <>
      <div className={styles.row}>
        <SanaButton
          onClick={onClick}
          className={`${styles.btnMatrix} ${btnStyles.btnSmall}`}
          textKey="SelectVariants"
          placeholder={btnMatrixPlaceholder}
        />
      </div>
      <VariantsMatrixProvider>
        <Modal opened={opened} hide={hide} size="large" className={styles.variantPopup}>
          <div className={styles.variants}>
            {showMatrixForm &&
              <VariantsMatrixForm opened={opened} abilities={abilities} onProductsAdded={hide} hide={hide} backButton={backButton} />
            }
            {!showMatrixForm && <VariantsOverview backButton={backButton} />}
          </div>
        </Modal>
      </VariantsMatrixProvider>
    </>
  );
};

export default React.memo(VariantsMatrixPopup);