import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HeadTag as Gtm } from './googleTagManager';
import { HeadTag as Pwk } from './piwikPro';
import { TRACKER_TYPES } from 'behavior/analytics';

const tagRenderers = [{
  type: TRACKER_TYPES.GoogleTagManager,
  Tag: Gtm,
}, {
  type: TRACKER_TYPES.PiwikPro,
  Tag: Pwk,
}];

const HeadTags = ({ trackers, children }) => {
  if (!trackers)
    return null;

  const tags = [];
  trackers.forEach(tracker => {
    const renderer = tagRenderers.find(r => r.type === tracker.type);
    if (renderer)
      tags.push({ tracker, Tag: renderer.Tag });
  });

  return tags.map(({ tracker, Tag }, key) => <Tag key={key} tracker={tracker}>{children}</Tag>);
};

HeadTags.propTypes = {
  trackers: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
  })),
  children: PropTypes.func.isRequired,
};

export default connect(
  ({ analytics: { trackers } }) => ({ trackers }),
)(HeadTags);