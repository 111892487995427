import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExtraPaymentStepForm as Form } from 'components/objects/checkout/base/payment';
import { makeSimpleText } from 'utils/render';
import { SanaTextContainer } from 'components/sanaText';
import useExtraCheckoutDataCommand from './useExtraCheckoutDataCommand';
import { useDispatch } from 'react-redux';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';

const ExtraPaymentDataForm = ({ orderId, extraPaymentData, formRef, onFormSubmit, onBeforeSubmitRef }) => {
  const dispatch = useDispatch();
  const executeCommand = useExtraCheckoutDataCommand(orderId, extraPaymentData && extraPaymentData.paymentMethodId);

  useEffect(() => {
    if (!extraPaymentData)
      formRef.current = null;
  }, [extraPaymentData]);

  const setLoading = () => {
    dispatch(setLoadingIndicator());

    return () => {
      dispatch(unsetLoadingIndicator());
    };
  };

  if (!extraPaymentData)
    return null;

  return (
    <>
      <h2>
        <SanaTextContainer textKey={extraPaymentData.titleKey}>{makeSimpleText(extraPaymentData.title)}</SanaTextContainer>
      </h2>
      <Form
        executeCommand={executeCommand}
        extraPaymentStep={extraPaymentData}
        formRef={formRef}
        onFormSubmit={onFormSubmit}
        onBeforeSubmitRef={onBeforeSubmitRef}
        setLoading={setLoading}
      />
    </>
  );
};

ExtraPaymentDataForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  extraPaymentData: PropTypes.object,
  formRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  onFormSubmit: PropTypes.func,
  onBeforeSubmitRef: PropTypes.object.isRequired,
};

export default React.memo(ExtraPaymentDataForm);
