import { shallowEqual } from 'react-redux';

export function memoize(func) {
  let memo = null;
  let memoArgs = null;
  return (...args) => {
    if (shallowEqual(memoArgs, args))
      return memo;

    const result = func.apply(null, args);
    if (!shallowEqual(memo, result))
      memo = result;
    
    memoArgs = args;
    return memo;
  };
}