import styles from '../../checkout/OrderResult.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSimpleTexts } from 'components/sanaText';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { DocumentType } from 'behavior/documents';
import { Placeholder } from 'components/primitives/placeholders';
import { logout } from 'behavior/user';

const PaymentSuccessful = ({ transaction: { id: transactionId, documentId } }) => {
  const isAnonymousCustomer = useSelector(state => !state.user.isAuthenticated);
  const dispatch = useDispatch();
  const [canSeeDocument, canGoToMyAccount] = useHasAbilities(AbilityTo.ViewOrders, AbilityTo.ViewMyAccountPage);
  const [texts, loaded] = usePaymentSuccessfulTexts();

  return (
    <>
      <p className={`${styles.description}`}>
        {texts.description}
      </p>
      {loaded
        ? (
          <ul className={styles.actionsList}>
            {!isAnonymousCustomer && canSeeDocument && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.orderLink}`}
                  to={routesBuilder.forDocument(documentId, DocumentType.Order)}
                  options={{ backTo: { routeData: routesBuilder.forPaymentSubmit(transactionId) } }}
                >
                  {texts.documentLink}
                </Link>
              </li>
            )}
            {!isAnonymousCustomer && canGoToMyAccount && (
              <li>
                <Link
                  className={`${linkStyles.arrowed} ${styles.myAccountLink}`}
                  to={routesBuilder.forMyAccount}
                  options={{ backTo: { routeData: routesBuilder.forPaymentSubmit(transactionId) } }}
                >
                  {texts.account}
                </Link>
              </li>
            )}
            <li>
              <Link className={`${linkStyles.arrowed} ${styles.homeLink}`} to={routesBuilder.forHome}>
                {texts.goToHomepage}
              </Link>
            </li>
            {!isAnonymousCustomer && (
              <li>
                <button className={`${linkStyles.link} ${linkStyles.arrowed} ${styles.btnLogout}`} onClick={() => dispatch(logout())}>
                  {texts.logout}
                </button>
              </li>
            )}
          </ul>
        )
        : (
          <ul className={styles.actionsList}>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
            <li>
              <Placeholder className={styles.linkPlaceholder} />
            </li>
          </ul>
        )
      }
    </>
  );
};

PaymentSuccessful.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
  }).isRequired,
};

PaymentSuccessful.selectPropsFromPage = ({ transaction }) => ({ transaction });

export default PaymentSuccessful;

function usePaymentSuccessfulTexts() {
  const {
    texts: [
      description,
      documentLink,
      goToHomepage,
      account,
      logout,
    ],
    loaded,
  } = useSimpleTexts([
    'Payment_Success',
    'GotoOrderPage',
    'GoToHomePage',
    'GotoAccountPage',
    'SubmitLogoutLinkText',
  ]);

  return [{
    description,
    documentLink,
    goToHomepage,
    account,
    logout,
  }, loaded];
}